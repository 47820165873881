
// SCRATCHPAD > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchPads (context) {
    axios.get(`${API_URL}/scratchpad`).then(res => {
      const pads = res.data

      // console.log('PADS FETCHED: ', pads)

      context.commit('setPads', pads)
    })
  },

  createPad (context, pad) {
    axios.post(`${API_URL}/scratchpad`, pad).then(res => {
      const thePad = res.data

      context.commit('addPad', thePad)
    })
  },

  updatePad (context, pad) {
    axios.post(`${API_URL}/scratchpad`, pad).then(res => {
      const newPad = res.data

      context.commit('updatePad', newPad)
    })
  },

  copyPad (context, padId) {
    // console.log('Copying pad ID: ' + padId)
    axios.post(`${API_URL}/scratchpad/${padId}/copy`).then(res => {
      const cpyPad = res.data

      // console.log('COPIED PAD: ')
      // console.log(cpyPad)
      context.commit('addPad', cpyPad)
    })
  },

  deletePad (context, padId) {
    axios.delete(`${API_URL}/scratchpad/${padId}`).then(res => {
      const pad = res.data
      context.commit('removepad', pad)
    })
  }
}