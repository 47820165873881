
// DELIVERY CUSTOMERS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchDeliveryCustomers (context) {
    axios.get(`${API_URL}/deliveryCustomers`).then(res => {
      const customers = res.data
      // console.log('fetchDeliveryCustomers...')
      // console.log(customers)
      context.commit('setDeliveryCustomers', customers)
    })
  },

  createDeliveryCustomer (context, customer) {
    return axios.post(`${API_URL}/deliveryCustomers`, customer).then(res => {
      const deliveryCustomer = res.data
      context.commit('addDeliveryCustomer', deliveryCustomer)
      return deliveryCustomer
    })
  },

  updateDeliveryCustomer (context, customer) {
    axios.patch(`${API_URL}/deliveryCustomers/${customer._id}`, customer).then(res => {
      const updatedDeliveryCustomer = res.data
      context.commit('updateDeliveryCustomer', updatedDeliveryCustomer)
    })
  },

  deleteDeliveryCustomer (context, customerId) {
    axios.delete(`${API_URL}/deliveryCustomers/${customerId}`).then(res => {
      const deletedCustomer = res.data
      context.commit('removeDeliveryCustomer', deletedCustomer)
    })
  },

  // TODO: IMPLEMENT CHECK FOR EXISTING DELIVERY CUSTOMER
  findDeliveryCustomerMatches (context, payload) {
    // payload: customer: { name, phone, address }

    return new Promise((resolve, reject) => {
      // console.log('CHECKING FOR MATCHING DELIVERY CUSTOMER WITH PAYLOAD: ', payload)
  
      axios.get(`${API_URL}/deliveryCustomers`).then(res => {
        const customers = res.data
  
        let matchingCustomers = customers.filter(c => c.name.toLowerCase() === payload.name.toLowerCase() && (c.phone.toLowerCase() === payload.phone.toLowerCase() || c.address.toLowerCase() === payload.address.toLowerCase()))
  
        if (matchingCustomers) {
          // console.log('MATCHES FOUND: ', matchingCustomers)
          resolve({ numMatches: matchingCustomers.length, customers: matchingCustomers })
        } else {
          reject('ERROR - NO MATCHES FOUND')
        }
      })
    })
  },
}