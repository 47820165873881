
// MESSAGES > GETTERS

export default {

  // Get all delivery customers
  allMessages (state) {
    return state.messages
  },

  // Get single delivery customer loaded into form
  messagesByUserId: (state) => (userId) => {
    return state.messages.filter(msg => msg.userIdFrom === userId)
  }
}