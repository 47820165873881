<template>
  <!--  V2 -->
  <div>
    <NewOrderDialog
      :show="showOrderDialog"
      :editOrder="editOrder"
      @cancelOrderDialog="cancelOrderDialog"
    />
    <b-modal
      v-model="show"
      :width="900"
      scroll="keep"
      auto-focus
      trap-focus
      :can-cancel="['escape', 'x']"
    >
      <section class="card" :class="isCopyDialogBKG">
        <!-- <header class="card-header">
        <p class="card-header-title">New Haul</p>
      </header> -->

        <header class="card-header" :class="isCopyDialog">
          <p class="card-header-title" v-if="isNewHaul && !isCopyHaul">
            Create New Haul
          </p>
          <p class="card-header-title" v-if="!isNewHaul && !isCopyHaul">
            Edit Haul (Invoice #{{ editHaul.invoice }})
          </p>
          <p class="card-header-title" v-if="isCopyHaul">Copy Haul</p>
        </header>

        <div class="card-content">
          <!-- DRIVER, LOADTYPE, TRUCK, OFF DUTY -->
          <b-field grouped class="groupedMargin">
            <!-- TODO: Separate into own component and replace this dummy placeholder -->
            <b-field label="Driver" label-position="on-border" expanded>
              <b-select v-model="haulDriver" id="formDriver">
                <option
                  v-for="driver in drivers"
                  :key="driver._id"
                  :value="driver._id"
                >
                  {{ driver.firstName }} {{ driver.lastName }}
                </option>
              </b-select>
            </b-field>

            <!-- Load Type -->
            <b-field label="Load Type" label-position="on-border" expanded>
              <b-select
                v-model="form.loadType"
                id="formLoadType"
                placeholder="Choose...   "
                expanded
              >
                <option value="enddump">End Dump</option>
                <option value="flatbedperc">Flat Bed (%)</option>
                <option value="flatbedmi">Flat Bed (mi)</option>
              </b-select>
            </b-field>

            <!-- Truck -->
            <b-field label="Truck" label-position="on-border" expanded>
              <b-input type="text" id="formTruck" v-model="form.truck" expanded />
            </b-field>

            <!-- Off Duty -->
            <b-field expanded>
              <div style="margin-top: 7pt; margin-left: 14pt;">
                <b-checkbox
                  v-model="form.offDuty"
                  @input="toggleOffDuty"
                  id="formOffDuty"
                  type="is-danger"
                  tabindex="-1"
                  disabled
                  title="Feature coming soon"
                  expanded
                >
                  Off Duty
                </b-checkbox>
              </div>
            </b-field>
          </b-field>

          <!-- HAUL DATE/TIME, CUSTOMER, LOAD/REF#  -->
          <b-field grouped class="groupedMargin">
            <!-- Haul Date -->
            <b-field label="Haul Date" label-position="on-border" expanded>
              <b-datetimepicker
                v-model="selectedHaulDate"
                id="formHaulDate"
                placeholder="Choose..."
                locale="en-US"
                icon="calendar-today"
                :icon-right="selectedHaulDate ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDateTime"
                horizontal-time-picker
                append-to-body
                expanded
              >
              </b-datetimepicker>
            </b-field>

            <!-- Customer (Broker) -->
            <b-field label="Customer" label-position="on-border" expanded>
              <b-autocomplete id="formBroker" v-model="form.broker" expanded />
            </b-field>

            <!-- Load/Ref#  (invoice) -->
            <b-field label="Load / Ref #" label-position="on-border" expanded>
              <b-input type="text" id="formInvoice" v-model="form.invoice" expanded />
            </b-field>
          </b-field>

          <!-- CH INVOICE, FROM, TO -->
          <b-field grouped class="groupedMargin">
            <b-field
              label="CH Invoice"
              label-position="on-border"
              v-if="isFlatbed()"
              expanded
            >
              <b-input type="text" id="formCHInvoice" v-model="form.chinvoice" expanded />
            </b-field>

            <FromField
              id="formFrom"
              v-model="form.from"
              ref="formFromField"
              @vendorSelected="setVendorSelected"
            />

            <ToField
              id="formTo"
              v-model="form.to"
              ref="formToField"
              :data="orders"
              :vendorSelected="vendorSelected"
              :toFieldShouldUpdateOrders="toFieldShouldUpdateOrders"
              @falsifyShouldUpdateOrders="falsifyShouldUpdateOrders"
              @destinationSelected="setDestinationSelected"
            />
          </b-field>

          <!-- MATERIAL, TONS, RATE (/PAY RATE for fb), Percentage (for flatbedperc), or Miles (for flatbedmi) -->
          <b-field grouped class="groupedMargin">
            <!-- Material -->
            <MaterialField
              v-model="form.product"
              id="formProduct"
              :vendorSelected="vendorSelected"
              :order="destinationSelected"
              :disabled="productDisabled"
            />

            <!-- Rate (enddump or flatbedmi) -->
            <b-field
              label="Rate"
              label-position="on-border"
              v-if="isEnddumpOrFlatbedmi()"
              expanded
            >
              <b-input type="text" id="formRate" v-model="form.rate" expanded />
            </b-field>

            <!-- Tons -->
            <b-field label="Tons" label-position="on-border" expanded>
              <b-input type="text" id="formTons" v-model="form.tons" expanded />
            </b-field>

            <!-- Pay Rate (flatbedperc) -->
            <b-field
              label="Pay Rate"
              label-position="on-border"
              v-if="form.loadType === 'flatbedperc'"
              expanded
            >
              <b-input type="text" id="formPayRate" v-model="form.payrate" expanded />
            </b-field>

            <!-- Miles (flatbedmi) -->
            <b-field
              label="Miles"
              label-position="on-border"
              v-if="form.loadType === 'flatbedmi'"
              expanded
            >
              <b-input type="text" id="formMiles" v-model="form.miles" expanded />
            </b-field>

            <!-- Percentage (flatbedperc) -->
            <b-field
              label="Percentage"
              label-position="on-border"
              v-if="form.loadType === 'flatbedperc'"
              expanded
            >
              <b-input
                type="number"
                step="0.01"
                v-model="form.percentage"
                id="formPercentage"
                expanded
              />
            </b-field>
          </b-field>
        </div>

        <!-- FOOTER -->
        <footer>
          <div class="level card-content" style="padding-top: 0">
            <div class="level-left">
              <!-- Flag Haul Button -->
              <b-button
                type="is-secondary"
                icon-left="flag"
                style="margin-right: 8pt"
                tabindex="-1"
                title="Coming Soon"
                :disabled="true"
              >
                Flag Haul
              </b-button>

              <b-button
                type="is-secondary"
                icon-left="text-box-plus-outline"
                style="margin-right: 8pt"
                tabindex="-1"
                @click="showOrderDialog = true"
              >
                New Order
              </b-button>

              <b-button
                type="is-secondary"
                icon-left="text-box-plus-outline"
                tabindex="-1"
                tag="router-link"
                to="/office/orders"
              >
                Manage Orders
              </b-button>
            </div>

            <!-- Buttons -->
            <div class="level-right">
              <div class="footer-btns">
                <!-- Create (New Haul) -->
                <b-button
                  class="footer-createBtn"
                  icon-left="content-save"
                  @click="saveHaul"
                  style="margin-right: 4pt"
                >
                  Save
                </b-button>

                <!-- Cancel Button -->
                <b-button
                  class="footer-closeBtn"
                  icon-left="close-thick"
                  @click="cancelDialog"
                >
                  Cancel
                </b-button>
              </div>
            </div>
          </div>
        </footer>
      </section>
    </b-modal>
  </div>
</template>

<script>
import FromField from "./components/FromField"
import ToField from "./components/ToField"
import MaterialField from "./components/MaterialField"
import NewOrderDialog from "./components/NewOrder"
import dayjs from 'dayjs'

export default {
  props: ["show", "isNewHaul", "isCopyHaul", "editHaul", "driver"],

  emits: ["cancelHaulDialog"],

  components: {
    FromField,
    ToField,
    MaterialField,
    NewOrderDialog,
  },

  data: () => ({
    showOrderDialog: false,
    editOrder: null,
    vendorSelected: undefined,
    destinationSelected: undefined,
    selectedHaulDate: new Date(),
    hourFormat: undefined, // defaults to browser locale
    haulDriver: null,
    selectedDriverId: "",
    toFieldShouldUpdateOrders: null,
    productDisabled: false,
    form: {
      _id: "",
      driverId: "",
      loadType: "enddump",
      truck: "",
      offDuty: false,
      haulDateTime: new Date(),
      broker: "",
      invoice: "",
      chinvoice: "",
      from: "",
      to: "",
      product: "",
      tons: "",
      rate: "",
      payrate: "",
      miles: "",
      percentage: 0.15,
    },
    emptyForm: {
      driverId: "",
      loadType: null,
      truck: "",
      offDuty: false,
      haulDateTime: new Date(),
      broker: "",
      invoice: "",
      chinvoice: "",
      from: "",
      to: "",
      product: "",
      tons: "",
      rate: "",
      payrate: "",
      miles: "",
      percentage: 0.15,
    },
    cacheForm: {
      truck: "",
      broker: "",
      invoice: "",
      chinvoice: "",
      from: "",
      to: "",
      product: "",
      tons: "",
      rate: "",
      payrate: "",
      miles: "",
      percentage: 0.15,
    },
  }),

  mounted() {
    this.init()
  },

  computed: {
    orders: {
      get() {
        return this.$store.getters["orders/allOrders"]
      },

      set(newVal) {
        this.$store.commit("orders/setOrders", newVal)
      },
    },

    drivers() {
      return this.$store.getters["drivers/activeDrivers"]
    },

    // driverProfile () {
    //   return this.drivers.find(driver => driver._id === this.haul.driverId)
    // },

    isCopyDialog() {
      if (this.isCopyHaul) {
        return "copyHaulDialog"
      } else {
        return null
      }
    },

    isCopyDialogBKG() {
      if (this.isCopyHaul) {
        return "copyHaulDialogBKG"
      } else {
        return null
      }
    },
  },

  methods: {

    // clearLoadTypeOnChange () {
    //   console.log('LOAD TYPE CHANGED...')
    //   if (this.form.loadType === 'enddump') {
    //     this.form.payrate = 0
    //   } else if (this.form.loadType === 'flatbedperc' || this.form.loadType === 'flatbedmi') {
    //     this.form.rate = 0
    //   }
    // },

    toggleOffDuty () {
      // console.log('Off Duty: ', this.form.offDuty)
      // FIXME: Needs to be finished/polished
        // if (this.form.offDuty === true) {

        //   const formTruck = document.querySelector('#formTruck')
        //   const formBroker = document.querySelector('#formBroker')
        //   const formInvoice = document.querySelector('#formInvoice')
        //   const formCHInvoice = document.querySelector('#formCHInvoice')
        //   const formRate = document.querySelector('#formRate')
        //   const formPayRate = document.querySelector('#formPayRate')
        //   const formMiles = document.querySelector('#formMiles')
        //   const formTons = document.querySelector('#formTons')

        //   // console.log('OFF DUTY:', this.form.offDuty)

        //   this.form.truck = '-'
        //   if (formTruck) document.querySelector('#formTruck').disabled = true
          
        //   // console.log('formTruck disabled: ', document.querySelector('#formTruck').disabled)

        //   this.form.broker = '-'
        //   if (formBroker) document.querySelector('#formBroker').disabled = true

        //   this.form.invoice = '-'
        //   if (formInvoice) document.querySelector('#formInvoice').disabled = true

        //   this.form.chinvoice = '-'
        //   if (formCHInvoice) document.querySelector('#formCHInvoice').disabled = true

        //   this.form.rate = "0.00";
        //   if (formRate) document.querySelector('#formRate').disabled = true

        //   this.form.payrate = "0.00";
        //   if (formPayRate) document.querySelector('#formPayRate').disabled = true

        //   this.form.miles = "0.00";
        //   if (formMiles) document.querySelector('#formMiles').disabled = true

        //   this.form.tons = "0.00";
        //   if (formTons) document.querySelector('#formTons').disabled = true

        //   this.form.product = '-'
        //   this.productDisabled = true

        //   // Set from field
        //   this.form.from = 'OFF DUTY'
        //   this.form.to = '[REASON HERE]'

        //   document.querySelector('#formTo').focus()
        //   this.$refs.formToField.$el.focus()
        // } else {
        //   const formTruck = document.querySelector('#formTruck')
        //   const formBroker = document.querySelector('#formBroker')
        //   const formInvoice = document.querySelector('#formInvoice')
        //   const formCHInvoice = document.querySelector('#formCHInvoice')
        //   const formRate = document.querySelector('#formRate')
        //   const formPayRate = document.querySelector('#formPayRate')
        //   const formMiles = document.querySelector('#formMiles')
        //   const formTons = document.querySelector('#formTons')

        //   // console.log('OFF DUTY:', this.form.offDuty)
          
        //   if (formTruck) document.querySelector('#formTruck').disabled = false
        //   this.form.truck = this.cacheForm.truck

        //   if (formBroker) document.querySelector('#formBroker').disabled = false
        //   this.form.broker = this.cacheForm.broker

        //   if (formInvoice) document.querySelector('#formInvoice').disabled = false
        //   this.form.invoice = this.cacheForm.invoice

        //   if (formCHInvoice) document.querySelector('#formCHInvoice').disabled = false
        //   this.form.chinvoice = this.cacheForm.chinvoice

        //   if (formRate) document.querySelector('#formRate').disabled = false
        //   this.form.rate = this.cacheForm.rate

        //   if (formPayRate) document.querySelector('#formPayRate').disabled = false
        //   this.form.payrate = this.cacheForm.payrate

        //   if (formMiles) document.querySelector('#formMiles').disabled = false
        //   this.form.miles = this.cacheForm.miles

        //   if (formTons) document.querySelector('#formTons').disabled = false
        //   this.form.tons = this.cacheForm.tons

        //   this.form.product = this.cacheForm.product
        //   this.productDisabled = false

        //   this.form.from = this.cacheForm.from
        //   this.form.to = this.cacheForm.to
        // }
    },

    setSelectedOrder (order) {
      // console.log('***** HAUL SELECTED ORDER: ', order)
      // FIXME: Not updating haul form values
      this.form.product = order.material
      this.form.rate = order.freight
    },

    falsifyShouldUpdateOrders() {
      // console.log("Falsifying shouldUpdateOrders")
      this.toFieldShouldUpdateOrders = false
    },

    falsifyReloadVendor() {
      this.reloadFromFieldVendor = false
    },

    cancelDialog() {
      // FIXME: Not clearning form after viewing an EditHaul or saving a New Haul
      this.cacheForm = {...this.emptyForm}
      this.form = {...this.emptyForm}
      this.form.rate = ""
      this.$emit("cancelHaulDialog")
    },

    async cancelOrderDialog(didUpdate) {
      this.showOrderDialog = false


      if (didUpdate) {
        // console.log("Order dialog closed with didUpdate = true")
        // this.vendorSelected = undefined
        // this.form.from = ''
        this.setVendorSelected(null)
        this.setVendorSelected(this.vendorSelected)
        this.toFieldShouldUpdateOrders = true
      }
    },

    async saveHaul() {
      // SAVE NEW HAUL

      this.form.driverId = this.selectedDriverId

      // console.log('selectedHaulDate: ', this.selectedHaulDate)
      
      this.form.dateHaul = dayjs(this.selectedHaulDate).format('YYYY-MM-DD')
      this.form.timeHaul = new Date(this.selectedHaulDate).toISOString()
      this.form.dateCreated = new Date().toISOString()

      // if (this.form.product === "") {
      //   this.form.product = document.getElementById('formProduct').value
      // }


      // console.log('FORM DATE: ', this.form.dateHaul)
      // console.log('FORM TIME: ', this.form.timeHaul)

      if (this.form.loadType === 'enddump') {
        this.form.payrate = 0.00
      }

      if (this.isNewHaul && !this.isCopyHaul) {
        // this.form.dateHaul = dayjs(this.selectedHaulDate).format('YYYY-MM-DD')
        // this.form.timeHaul = dayjs(this.selectedHaulDate).toISOString()
        // this.form.dateCreated = new Date().toISOString()

        console.log("CREATING NEW HAUL...")
        console.log(this.form)

        try {
          await this.$store.dispatch('hauls/createHaul', this.form)
          this.$buefy.toast.open({
            title: "Haul Created",
            message: 'Haul created',
            type: 'is-success',
            position: 'is-bottom'
          })
          this.cancelDialog()
        } catch (error) {
          console.error(error)
          this.$buefy.toast.alert({
            message: 'Error while creating haul! Check logs'
          })
        }
        // SAVE EXISTING HAUL
      } else if (this.editHaul && !this.isCopyHaul) {
        // console.log("SAVING EXISTING HAUL...")
        this.form.driverId = this.haulDriver
        // console.log(this.form)

        try {
          this.$store.dispatch('hauls/updateHaul', this.form)

          this.$buefy.toast.open({
            message: 'Haul updated successfully',
            type: 'is-success',
            position: 'is-bottom'
          })

          this.cancelDialog()

        } catch (error) {
          console.error(error)
          this.$buefy.toast.open({
            message: 'Error while updating haul! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 8000
          })
        }
      } else if (this.isCopyHaul) {
        // console.log('COPYING HAUL...')
        this.form.driverId = this.haulDriver;

      try {
        await this.$store.dispatch("hauls/createHaul", this.form);
        this.$buefy.toast.open({
          title: "Haul Created",
          message: `Haul created`,
          position: "is-bottom",
          type: "is-success",
        });

        // Clear form and close dialog
        this.cancelDialog();
      } catch (error) {
        console.error(error.message);
        this.$buefy.toast.open({
          message: `Error while copying new haul! Check logs`,
          position: "is-bottom",
          type: "is-danger",
          duration: 5000,
        });
      }
      }
    },

    // async copyHaul() {
    //   this.haul.driverId = this.haulDriver;

    //   try {
    //     await this.$store.dispatch("hauls/createHaul", this.haul);
    //     this.$buefy.toast.open({
    //       title: "Haul Created",
    //       message: `Haul created`,
    //       position: "is-bottom",
    //       type: "is-success",
    //     });

    //     // Clear form and close dialog
    //     this.cancelDialog();
    //   } catch (error) {
    //     console.error(error.message);
    //     this.$buefy.toast.open({
    //       message: `Error while copying new haul! Check logs`,
    //       position: "is-bottom",
    //       type: "is-danger",
    //       duration: 5000,
    //     });
    //   }
    // },

    clearDateTime() {
      this.selectedHaulDate = null
    },

    isEnddumpOrFlatbedmi() {
      if (
        this.form.loadType === "enddump" ||
        this.form.loadType === "flatbedmi"
      ) {
        return true
      } else {
        return false
      }
    },

    isFlatbed() {
      if (this.form.loadType === "enddump") {
        return false
      } else {
        return true
      }
    },

    setVendorSelected(val) {
      // console.log('setVendorSelected(): ', val)
      // console.log('this.form.from: ', this.form.from)
      this.vendorSelected = val
      // this.form.from = val.name
    },

    setDestinationSelected(val) {
      // console.log('setDestinationSelected: ', val)
      this.destinationSelected = val
      console.log('DESTINATION SELECTED: ', this.destinationSelected)

      // Assign inputs to order data
      // FIXME: Not working
      // console.log('Product before: ', this.form.product)
      this.form.product = this.destinationSelected.material
      // console.log('Product after: ', this.form.product)
      this.form.rate = this.destinationSelected.freight
    },

    // setSelectedOrderRate(rate) {
    //   // console.log('[HaulDialog v2]: setSelectedOrderRate: ', rate)
    //   this.form.rate = rate
    // },

    async init() {
      try {
        await this.$store.dispatch("orders/fetchOrders")
        await this.$store.dispatch("drivers/fetchDrivers")
        // this.$store.dispatch('vendors/fetchVendors')
        // this.$store.dispatch('vendorProducts/fetchVendorProducts')
      } catch (error) {
        console.error(error)
      }
    },
  },

  watch: {

    // form: {
    //   deep: true,

    //   handler () {

   
    //   }
    // },

    editHaul() {
      if (this.editHaul) {

        this.haulDriver = this.editHaul.driverId

        this.form = { ...this.editHaul }

        // Used when user checks "Off Duty" which will cache current values, then temporarily alter until unchecked
        this.cacheForm.truck = this.form.truck
        this.cacheForm.broker = this.form.broker
        this.cacheForm.invoice = this.form.invoice
        this.cacheForm.chinvoice = this.form.chinvoice
        this.cacheForm.from = this.form.from
        this.cacheForm.to = this.form.to
        this.cacheForm.product = this.form.product
        this.cacheForm.tons = this.form.tons
        this.cacheForm.rate = this.form.rate
        this.cacheForm.payrate = this.form.payrate
        this.cacheForm.miles = this.form.miles
        this.cacheForm.percentage = this.form.percentage

        // console.log("Edit Haul form applied: ", this.form)

        // eslint-disable-next-line no-useless-escape
        // this.editHaul.dateHaul
        //   ? (this.form.dateHaul = new Date(
        //       // eslint-disable-next-line no-useless-escape
        //       this.editHaul.dateHaul.replace(/-/g, "\/").replace(/T.+/, "")
        //     ))
        //   : (this.form.dateHaul = undefined);
        // // eslint-disable-next-line no-useless-escape
        // this.editHaul.timeHaul
        //   ? (this.form.timeHaul = new Date(this.editHaul.timeHaul))
        //   : (this.form.timeHaul = undefined);
        // // eslint-disable-next-line no-useless-escape
        // // let time = new Date(this.editHaul.timeHaul.replace(/-/g, '\/').replace(/T.+/, ''))
        // // console.log('editHaul time: ', time)
        // // eslint-disable-next-line no-useless-escape
        // this.editHaul.dateCreated
        //   ? (this.form.dateCreated = new Date(
        //       // eslint-disable-next-line no-useless-escape
        //       this.editHaul.dateCreated.replace(/-/g, "\/").replace(/T.+/, "")
        //     ))
        //   : (this.form.dateCreated = undefined);
      }
    },

    haulDriver() {
      if (this.haulDriver && this.haulDriver.length > 0) {
        // console.log('Driver ID changed to: ', this.haulDriver)
        const driver = this.$store.getters['drivers/driverById'](this.haulDriver)
        this.form.truck = driver.defaultTruck
        this.form.percentage = driver.flatBedPayRate

        if (this.selectedDriverId.length <= 0) {
          this.selectedDriverId = this.haulDriver
        }
      }
    },

    show() {
      // Clear the form and cacheForm
      if (this.isNewHaul) {
        this.cacheForm = {...this.emptyForm}
        this.form = {...this.emptyForm}
      }

      if (this.driver && this.isNewHaul) {
        this.form.truck = this.driver.defaultTruck
        // console.log('FB Rate', this.driver.flatBedPayRate)
        // TODO: Changing from 0.30 to 30% (added the * 100 part) -- Make sure to reflect this on Haul Summary as well
        // this.form.percentage = parseFloat(this.driver.flatBedPayRate) * 100
        this.form.percentage = parseFloat(this.driver.flatBedPayRate)
        this.selectedDriverId = this.driver._id
        this.haulDriver = this.driver._id
      }

      if (this.editHaul && !this.isNewHaul) {

        this.haulDriver = localStorage.getItem('driverSelected')

        // console.log('EDIT HAUL DATE/TIME: ', this.editHaul.timeHaul)
        this.selectedHaulDate = new Date(this.editHaul.timeHaul)

        // this.form.from = this.editHaul.from
        
        // console.log('-------------------------- EDITING HAUL: ')
        // this.form.from = this.editHaul.from
        // this.form.to = this.editHaul.to
        // console.log('editHaul: this.form.from: ', this.form.from)
        // console.log('editHaul: this.form.to: ', this.form.to)

        // this.$refs.formFromField = this.editHaul.from
        // this.$refs.formToField = this.editHaul.to

        // console.log('editHaul: formFromField: ', this.$refs.formFromField)
        // console.log('editHaul: formToField: ', this.$refs.formToField)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.groupedMargin:not(:last-child) {
  margin-bottom: 14pt;
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.copyHaulDialog {
  background-color: #b84724;
}

.copyHaulDialogBKG {
  background-color: #f9f7f7;
}
</style>
