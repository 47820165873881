
// WORKDAYS > MUTATIONS

import dayjs from 'dayjs'
// import DayJSWeekday from 'dayjs/plugin/weekday'

export default {
  setWorkdays (state, list) {
    state.workdays = list
  },

  setCurrentWorkday (state, workday) {
    state.currentWorkday = workday
  },

  setWorkdayList (state, listInfo) {
    const { driverId, daylist } = listInfo
    console.log('inside setWorkdayList mutation...')
    console.log('DriverID: ' + driverId)
    console.log('Daylist: ')
    console.log(daylist)
    // const list = state.workdays.filter(workday => workday.driver === driverId && daylist.includes(workday.workDate))
    // const list = state.workdays

    console.log('\nLIST: ')
    console.log(state.workdays)
  },

  createOrSaveWorkday (state, workdayPayload) {
    // console.log('\n---------------------------------')
    // console.log('[createOrSaveWorkday mutation]: workdayPayload: ')
    // console.log(workdayPayload)
    
    let index = state.workdays.findIndex(day => day._id === workdayPayload._id)
    index > -1 ? state.workdays.splice(index, 1, workdayPayload) : state.workdays.push(workdayPayload)
  },

  setMissingWorkdays (state, payload) {
    // payload: { daylist (array of dates YYYY-MM-DD), driverId }

    let daylist = payload.daylist
    let driverId = payload.driverId

    // Retrieve workdays for this list of dates for the specified driver
    if (daylist.length > 1) {
      // TODO: FIX -----------------------------------------------------------------------
      // Problem: First one works fine. When I go to create next missing workday, it REPLACES the first one in this last, rather than adding to it
      // OR it is being cleared each time (hence wdays = [] initially. Problem?)

      let workdays = state.workdays.filter(wday => wday.driverId === driverId && daylist.includes(dayjs(wday.workDate).format('YYYY-MM-DD')))

      // console.log('[setMissingWorkdays mutation]: Workdays found: ')
      // console.log(workdays)
      
      
      let wdays = []
      for (let i=0; i<workdays.length; i++) {
        wdays.push(workdays[i].workDate)
      }

      // console.log("[setMissingWorkdays mutation]: workDate list THAT ALREADY EXIST: ")
      // console.log(wdays)

      // TODO -----------------------------------------------------------------------------
      
      let missingList = []
      
      // console.log('Daylist: ')
      // console.log(daylist)

      if (wdays) {
        missingList = daylist.filter(x => !wdays.includes(x))
      } else {
        console.error('[setMissingWorkdays mutation]: ERROR: wdays is empty or does not exist...')
        missingList = []
      }

      // console.log('[setMissingWorkdays mutation]: missingList: ')
      // console.log(missingList)

      state.missingWorkdays = [...missingList]
    }
  },

  removeMissingWorkday (state, payload) {
    // payload: workday object from server
    // console.log('[removeMissingWorkday mutation]: Removing newly created workday from missingWorkdays state')
    let index = state.missingWorkdays.findIndex(day => day === dayjs(payload.workDate).format('YYYY-MM-DD'))
    // console.log('[removeMissingWorkday mutation]: Index found to remove: ', index)
    state.missingWorkdays.splice(index, 1)
  },

  addWorkday (state, workdayPayload) {
    state.workdays.push(workdayPayload)
  },

  updateWorkday (state, workdayPayload) {
    let index = state.workdays.findIndex(day => day._id === workdayPayload._id)
    state.workdays.splice(index, 1, workdayPayload)
  },

  removeWorkday (state, workdayPayload) {
    let index = state.workdays.findIndex(day => day._id === workdayPayload._id)
    state.workdays.splice(index, 1)
  },
}