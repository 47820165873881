<template>
<div>
  <!-- LANDING INTRO -->
    <section class="section container">
      <div class="columns">

        <div class="column is-one-third">
          <!-- INTRO LEFT -->
          <div id="introLeft">
            <b-carousel 
              v-if="featuredMaterials.length > 0"
              indicator-position="is-top"
              class="mobileImage"
            >
              <b-carousel-item 
                v-for="(material, i) in featuredMaterials" 
                :key="i"
                arrow
                arrow-hover
              >
                <section class="hero">
                  <b-image
                    :src="material.image"
                    :alt="material.name"
                    ratio="4by4"
                    responsive
                  ></b-image>
                </section>
                  <p class="materialName">{{ material.name }}</p>
              </b-carousel-item>
            </b-carousel>  

            <div v-else>
              <img src="@/assets/blue_sb2.jpg" class="image" alt="Blue SB2" />  
              <p class="materialName">Blue SB2</p>      
            </div>
          </div>
        </div>

        <div class="column is-two-thirds">
          <!-- INTRO RIGHT -->
            <div class="imageArticle">
              <p class="title introTitle">Materials For Your Projects</p>
              <div class="articleText">
                <p class="articleTextParagraph">
                  We carry a variety of high quality landscape materials including soil, mulch, gravel, creek rock, stone, and boulders.
                </p>
              </div>
              <br/>
              <footer id="introArticleFooter">
                <div>
                  <b-button type="is-primary" tag="router-link" to="/materials">View Materials</b-button>
                </div>
              </footer>
              <!-- <footer>
                <div style="margin-left: 20pt;">
                  <div class="fb-like" data-href="https://www.facebook.com/ChLandscapeMaterials" data-layout="standard" data-action="like" data-size="large" data-share="true"></div>
                </div>
              </footer> -->
            </div>
        </div>

      </div>
    </section>
</div>
</template>

<script>
export default {
  data: () => ({}),

  computed: {
    featuredMaterials () {
      return this.$store.getters['materials/featuredMaterials']
    },
  },

  mounted () {
    this.$store.dispatch('materials/fetchMaterials')
  }
}
</script>

<style lang="css" scoped>

.imageArticle {
  /* text-align: center; */
}

.imageArticle .articleText {
  text-align: left;
  font-size: 14pt;
  max-width: 80%;
}

.introTitle {
  font-size: 2.2rem;
  /* border: 1px solid red; */
}

#introArticleFooter {
  width: 100%;
  display: flex;
  justify-content: left;
}

#introLeft {
  padding-right: 20pt;
  /* border: 2px solid blue; */
}

.materialName {
  font-weight: bold;
  color: white;
  padding: 8pt;
  font-size: 14pt;
  background-color: #5D5148;
}

@media screen and (max-width: 768px) {
  .mobileImage {
    /* border: 1px red; */
    position: relative;
    left: 25%;
    max-width: 300px;
  }

  .imageArticle {
    text-align: center;
  }

  .introTitle {
    font-size: 1.7rem;
    /* border: 1px solid green; */
  }

  .articleText {
    text-align: left;
    font-size: 14pt;
    margin-left: 0 auto;
    position: relative;
    left: 10%;
    /* border: 1px solid rgb(170, 165, 165); */
  }

  .articleTextParagraph {
    /* border: 1px solid blue; */
    position: relative;
    max-width: 100%;
  }
}

@media screen and (max-width: 425px) {
  .title.introTitle {
    /* border: 5px solid red; */
    font-size: 16pt;
  }

  .articleTextParagraph {
    /* border: 5px solid red; */
    font-size: 12pt;
  }

  #introLeft {
    /* border: 2px solid green; */
    margin: 0;
    padding: 0;
    position: relative;
    right: 54pt;
  }
}

@media screen and (max-width: 320px) {
  section.section.container {
    /* border: 5px solid purple; */
    padding: 0;
    padding-bottom: 20pt;
  }

  p.articleTextParagraph {
    /* color: red; */
    width: 100%;
    font-size: 13pt;
  }

  a.button.is-primary {
    /* border: 5px solid red; */
    padding: 8pt;
    font-size: 10pt;
    margin-bottom: 20pt;
  }

  #introLeft {
    padding-top: 20pt;
  }

  .materialName {
    font-size: 10pt;
    /* border: 3px solid red; */
  }
}
</style>