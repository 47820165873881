<template>
<div>
  <section class="hero is-primary" id="eagleHero">
    <div class="hero-body">
      <div style="display: flex; align-items: center;">
        <div>
          <p class="title">Eagle Carports</p>
        </div>
        <div>
          <img src="@/assets/eagle_logo.png" alt="Eagle Logo" id="eagleLogoImage"/>
        </div>
      </div>

    </div>
  </section>

  <section class="section container">

    <!-- ROW 1 -->
    <div class="columns">
      <div class="column is-half">
        <FeaturedEagle/>
      </div>

      <div class="column is-half">
        <article>
          <p class="title">Metal Carports, Garages, & More</p>
          <div class="container">
            <p class="paragraph">
              Eagle Carports is the largest manufacturer of metal carports in the U.S. Look no further for the perfect solution when it comes to protecting your vehicle or equipment.
            </p>

            <p class="paragraph">
              Eagle Carport structures are strong, reliable, and heavily customizable, offering the widest selection of self-supporting steel structures in the industry, distributing over 50,000 buildings per year.
            </p>
          </div>
        </article>

        <article>
          <p class="title">Visit Us For A Brochure</p>
          <div class="container">
            <p class="paragraph">
              Whether you're just looking or you're ready to order, come by our office and we will provide you with a brochure which contains various pictures, the latest pricing, sizing information, configurations, and add-ons available for your building Our business hours are posted on our contact page, so come by and we'll get started!
            </p>
          </div>
        </article>
      </div>
    </div>

    <br/><br/>


    <!-- ROW 2 -->
    <div class="columns">
      <div class="column is-half">
        <article>
          <p class="title">Highly Customizable</p>
          <div class="container">
            <p class="paragraph">
              With three different roof styles, 14 unique colors to choose from, and a wide array of configurations available, you can ensure that your structure looks just the way you want it to.
            </p>

            <p class="paragraph">
              Visit us at C&H to go over the latest pricing, configuration, delivery and setup information. Ask us about Eagle's 3D carport builder if you would like to take a peek at what your building will look like in 3D!
            </p>
          </div>
        </article>
      </div>
      <div class="column is-half">
        <div class="image eagleImage">
          <img src="@/assets/eagle_vertRoof.png" alt="DEBUG Carport" />
        </div>
      </div>
    </div>

    <br/><br/>


    <!-- ROW 3 -->
    <div class="columns">
      <div class="column is-half">
        <div class="image eagleImage">
          <img src="@/assets/eagle_garage.png" alt="DEBUG Carport" />
        </div>
      </div>
      <div class="column is-half">
        <article>
          <p class="title">Pre-Approved Rent-To-Own</p>
          <div class="container">
            <p class="paragraph">
              You can purchase your Eagle Carport outright, or you may wish to enroll in Hearland's Rent-To-Own program. There is <strong>NO CREDIT CHECK, no penalty for early pay-off, and 90 days same as cash.</strong>
            </p>

            <p class="paragraph">
              Choose either 24, 36, 48, or 60-month contracts. There is no minimum on rent-to-own, up to a maximum of $16,000 with No Origination fee on 24-36 month terms.
            </p>

            <p class="paragraph">
              Contact C&H for more information, hassle-free, no obligation.
            </p>
          </div>
        </article>
      </div>
    </div>

    <br/><br/>


    <!-- ROW 4 -->
    <div class="columns">
      <div class="column is-half">
        <article>
          <p class="title">Financing Available</p>
          <div class="container">
            <p class="paragraph">
              Eagle Carports partners with Greensky Financing to make your purchasing experience easy and affordable. You can finance on a minimum of $3,000 up to a maximum of $55,000.
            </p>

            <p class="paragraph">
              Financing with Greensky requires a credit score of 640 or higher. Subject to credit approval, with a reply usually within minutes! <a href="https://www.eaglecarports.com/financing/greensky" target="_blank">Have questions about Greensky Financing?</a>
            </p>

            <div class="paragraph">
              <a class="eagleFinancing" href="https://www.eaglecarports.com/financing" target="_blank" rel="noreferrer">Learn More About Financing</a>
            </div>
          </div>
        </article>
      </div>

      <div class="column is-half">
        <div class="image eagleImage">
          <img src="@/assets/eagle_comboUnit.png" alt="DEBUG Carport" />
        </div>
      </div>
    </div>

    <br/><br/>

    <!-- ROW 5 -->

    <div class="columns">
      <div class="column">
        <div class="card">
          <header class="card-header">
            <h3 class="card-header-title" style="color: white; margin-bottom: 0;">What To Do Before Your Building Arrives</h3>
          </header>
          
          <section class="card-content">
            <article class="content">

              <div class="paragraph">
                Use this checklist to prepare for your installation.<br/>
                <strong>(This information comes directly from the Eagle Carports website)</strong>
              </div>

              <br/>

              <div class="columns">
                <div class="column">
                  <ul class="prepList">

                    <li>
                      Mark any utility lines running underneath planned installation site. If you do not know the exact location of any water, gas, or electric lines please call 811 to have them come out and mark the lines free of charge.
                    </li>

                    <li>
                      Prepare level lot: move earth if necessary to create a level installation surface. <strong>Sites must not be more than three inches off level to avoid any cut leg fees or other additional labor/ return trip fees.</strong>
                    </li>

                    <li>
                      The installation of buildings upon concrete retaining walls or on shipping containers will be addressed on a case by case basis. Please call our corporate headquarters at 1.844.900.0501 and press option 4 for more information.
                    </li>

                    <li>
                      All concrete pads are required to be at least 2” wider on all sides of the frame. For example: for a building that has a frame measuring 20′ x 30′, the concrete pad should measure 20’4” x 30’4”.
                    </li>

                    <li>
                      Clear any and all obstacles in and around the installation site such as cars, recreational vehicles, and debris to avoid a build-over fee. Additional labor fees can be found on pricing sheets underneath additional options.
                    </li>

                    <li>
                      Installation crews need a minimum of 36” all around the building to fasten any wall paneling, end wall framing, and trim.
                    </li>

                    <li>
                      Have payment ready before installation crew arrives. We accept money orders and cashier’s checks made out to Eagle Carports, Inc., along with Visa, MasterCard, Discover, and American Express. Small units can be complete in less than an hour and often have multiple appointments in a day.
                    </li>

                    <li>
                      Any safety requirements must be disclosed to Eagle Carports, Inc. upon purchase of unit. Eagle must be aware of any safety training or safety classes/videos our installation contractors must complete prior to installation.
                    </li>

                    <li>
                      Site preparation is crucial to ensuring a smooth installation. Failure to adequately prepare your site could result in additional labor charges or even a return trip fee which is either $150 USD or %5 of the building price whichever is higher.
                    </li>
                  </ul>
                </div>
                <div class="column is-hidden-mobile">
                  <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 100pt;">
                    <div>
                      <img src="@/assets/eagle_prep1_b.png" width="250"/>
                    </div>
                  </div>

                  <div style="display: flex; align-items: center; justify-content: center;">
                    <div>
                      <img src="@/assets/eagle_prep2_c.png" width="250"/>
                    </div>
                  </div>
                </div>
              </div>

            </article>
          </section>
        </div>
      </div>
    </div>


  </section>
</div>
</template>

<script>
import FeaturedEagle from '../Landing/FeaturedEagle/FeaturedEagle'

export default {

  components: {
    FeaturedEagle
  },

  data: () => ({

  }),

  mounted () {
    this.$store.dispatch('carports/fetchCarports')
  },

  computed: {
    carports () {
      return this.$store.getters['carports/activeCarports']
    },

    featuredCarports () {
      const featuredCarports = this.carports.filter(c => c.isFeatured)
      // console.log('Featured Carports: ', featuredCarports)
      return featuredCarports
    }
  },

  methods: {

  },

  watch: {}
}
</script>

<style lang="scss" scoped>
.paragraph {
  line-height: 1.8;
  margin-bottom: 16pt;
  // max-width: 80%;
}
.eagleImage {
  max-width: 600px;
}
.eagleFinancing {
  background-color: rgb(26, 62, 116);
  border-radius: 10pt;
  color: white;
  padding: 6pt 10pt;
}
.prepList {
  // max-width: 600pt;
}
.prepList li {
  margin-bottom: 10pt;
}
#eagleHero {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/eagle_banner.png');
  background-attachment: fixed;
  background-size: cover;
  background-position-y: -140pt;
  // height: 80vh;
  margin-bottom: 20pt;
  z-index: 10;
}
#eagleLogoImage {
  height: 60px;
}
</style>