<template>
<div class="card" style="max-height: 400pt; height: 400pt; overflow-y: scroll;">
  <header class="deskHeader">
    <div class="level">
      <div class="level-left">
        <p class="card-header-title deskCardTitle">Todo List (Coming Soon!)</p>
      </div>
      <div class="level-right p-2">
        <!-- <b-button 
          type="is-light" 
          class="deskBtn deskManageBtn" 
          size="is-small"
          style="margin-right: 8pt;" 
          tag="router-link" 
          to="/office/materials"
        >Manage</b-button> -->
      </div>
    </div>
  </header>
</div>
</template>

<script>
export default {
  data: () => ({
    
  })
}
</script>

<style lang="scss" scoped>
</style>