
// DELIVERIES > GETTERS

import dayjs from 'dayjs'
import DayJSWeekday from 'dayjs/plugin/weekday'
import DayJSRelativeTime from 'dayjs/plugin/relativeTime'

export default {

  allDeliveries (state) {
    return state.deliveries
  },

  employeeNotes (state) {
    return state.employeeNotes
  },

  printDelivery (state) {
    return state.printDelivery
  },

  deliveryCustomer (state) {
    return state.deliveryCustomer
  },

  deliveriesWithoutDirections (state) {
    return state.deliveries.filter(dlv => dlv.directionsReminder === true)
  },

  deliveriesOverdue (state) {
    // Load the relative time plugin for DayJS
    dayjs.extend(DayJSRelativeTime)

    let today = dayjs()
    
    const dlvs = state.deliveries.filter(dlv => dlv.completed === false && dayjs(dlv.deliveryDate).diff(today) < 0)
    return dlvs
  },

  deliveriesByDeliveryCustomerId: (state) => (customerId) => {
    // console.log('inside getter for deliveriesByDeliveryCustomerId with id: ' + customerId)
    const dlvs = state.deliveries.filter(delivery => delivery.customerId === customerId)
    // console.log('Deliveries found: ')
    // console.log(dlvs)
    return dlvs
  },

  deliveriesYesterday (state) {
    const yesterday = dayjs().subtract(1, 'day').format('MM/DD/YYYY')

    return state.deliveries.filter(delivery => dayjs(delivery.deliveryDate).format('MM/DD/YYYY') === yesterday)
    // return formatDates(dlvs)
  },

  deliveriesToday (state) {
    const today = dayjs().format('MM/DD/YYYY')

    return state.deliveries.filter(delivery => dayjs(delivery.deliveryDate).format('MM/DD/YYYY') === today)
    // return formatDates(dlvs)
  },

  deliveriesTomorrow (state) {
    const tomorrow = dayjs().add(1, 'day').format('MM/DD/YYYY')

    return state.deliveries.filter(delivery => dayjs(delivery.deliveryDate).format('MM/DD/YYYY') === tomorrow)
    // return formatDates(dlvs)
  },

  deliveriesThisWeek (state) {
    // Load the weekday plugin for DayJS
    dayjs.extend(DayJSWeekday)
    
    // Generate a list of dates from current Sunday - current Saturday
    let weeklist = []

    for (let i=0; i<=6; i++) {
      weeklist.push(dayjs().weekday(i).format('MM/DD/YYYY'))
    }

    const dlvs = state.deliveries.filter(delivery => weeklist.includes(dayjs(delivery.deliveryDate).format('MM/DD/YYYY')))
    // console.log('DLVS FOUND: ', dlvs)
    return dlvs
  },

  deliveriesLastWeek (state) {
    // Load the weekday plugin for DayJS
    dayjs.extend(DayJSWeekday)

    let weeklist = []

    for (let i=-7; i<=-1; i++) {
      weeklist.push(dayjs().weekday(i).format('MM/DD/YYYY'))
    }

    return state.deliveries.filter(delivery => weeklist.includes(dayjs(delivery.deliveryDate).format('MM/DD/YYYY')))
    // return formatDates(dlvs)
  },

  deliveriesNextWeek (state) {
    dayjs.extend(DayJSWeekday)

    let weeklist = []

    for (let i=0; i<=6; i++) {
      weeklist.push(dayjs().add(1, 'week').weekday(i).format('MM/DD/YYYY'))
    }

    return state.deliveries.filter(delivery => weeklist.includes(dayjs(delivery.deliveryDate).format('MM/DD/YYYY')))
    // return formatDates(dlvs)
  },

  deliveriesThisMonth (state) {
    dayjs.extend(DayJSWeekday)
    const daysInMonth = dayjs().daysInMonth()

    let monthlist = []

    for (let i=1; i<=daysInMonth; i++) {
      monthlist.push(dayjs().date(i).format('MM/DD/YYYY'))
    }

    return state.deliveries.filter(delivery => monthlist.includes(dayjs(delivery.deliveryDate).format('MM/DD/YYYY')))
    // return formatDates(dlvs)
  },

  deliveriesLastMonth (state) {
    dayjs.extend(DayJSWeekday)
    const daysInMonth = dayjs().subtract(1, 'month').daysInMonth()

    let monthlist = []

    for (let i=1; i<=daysInMonth; i++) {
      monthlist.push(dayjs().subtract(1, 'month').date(i).format('MM/DD/YYYY'))
    }

    return state.deliveries.filter(delivery => monthlist.includes(dayjs(delivery.deliveryDate).format('MM/DD/YYYY')))
    // return formatDates(dlvs)
  },

  deliveriesNextMonth (state) {
    dayjs.extend(DayJSWeekday)
    const daysInMonth = dayjs().add(1, 'month').daysInMonth()

    let monthlist = []

    for (let i=1; i<=daysInMonth; i++) {
      monthlist.push(dayjs().add(1, 'month').date(i).format('MM/DD/YYYY'))
    }

    return state.deliveries.filter(delivery => monthlist.includes(dayjs(delivery.deliveryDate).format('MM/DD/YYYY')))
    // return formatDates(dlvs)
  },

  // ========================================================================================================================
  
  //    D E L I V E R Y   C U S T O M E R S 
  
  // ========================================================================================================================
  
  // Get all delivery customers
  allDeliveryCustomers (state) {
    return state.deliveryCustomers
  },

  // Get single delivery customer loaded into form
  // TODO: Deprecate, use deliveryCustomer above instead
  getDeliveryCustomer (state) {
    return state.deliveryCustomer
  },

  // Get all delivery customers matching searchString
  filterCustomer: (state) => (searchString) => {
    if (searchString !== '') {
      return state.deliveryCustomers.filter(customer => customer.name.includes(searchString) || customer.phone.includes(searchString) || customer.address.includes(searchString))
    } else {
      // console.log('Cannot search delivery customers, search string is empty')
      return []
    }
  },
}