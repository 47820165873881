
// CATEGORIES > GETTERS

export default {
  allCategories (state) {
    return state.categories
  },
  categoryName: (state) => (category) => {
    return state.categories.find(cat => cat.key === category.toLowerCase())
  }
}