<template>
<div id="section-wrapper">
  <div id="banner">
    <h1 class="banner-title">Garden Soils</h1>
  </div>
  <div id="content-area">
    <div id="garden-soils-container">
      <!-- MATERIAL CARD -->
      <div class="soil-item-container" v-for="soil in gardenSoils" :key="soil.id">
        <div class="soil-item-image-wrapper">
          <img class="soil-item-image" :src="soil.image" alt="Topsoil" />
        </div>
        <div class="soil-item-name">
          {{ soil.name }}
        </div>
        <div class="soil-item-content-wrapper">
          <p class="soil-description">{{ figureSoilDescription(soil.description) }}</p>
        </div>
      </div>
    </div>

    <div id="viewAll-container">
      <b-button type="is-primary" tag="router-link" to="/materials">View All Materials</b-button>
    </div>
  </div>
</div>
</template>

<script>
export default {
  components: {
},

  data: () => ({
    soils: [
      {
        id: 'topsoil',
        name: 'Topsoil',
        image: 'landing-soils-topsoil.jpg',
        description: 'Our topsoil is a sandy loam soil, local to our area and unscreened (unfiltered, may contain clay and occasional root clippings.)'
      },
      {
        id: 'premiumcompost',
        name: 'Premium Compost',
        image: 'landing-soils-compost.png',
        description: 'We carry an all-organic, non-burning compost made primarily from hardwood sawdust and chicken litter.\n\nUsed for planting. It is non-burning, but for best results we recommend blending it into your own soil (consider trying our unscreened topsoil for this, if your soil isn’t suitable.)'
      },
      {
        id: 'specialblend',
        name: 'Special Blend',
        image: 'landing-soils-topsoil.jpg',
        description: 'Also called "70/30 mix", consists of 70% Premium Compost + 30% Masonry Sand, finely blended together. The compost provides nourishment while the sand helps to drain water. Excellent for raised beds, sod, and planters.'
      },
      {
        id: 'craigsmix',
        name: 'Craig\'s Mix',
        image: 'landing-soils-topsoil.jpg',
        description: 'Craig\'s Mix is a blend of our topsoil, premium compost, masonry sand, and some aged mulch clippings. It is great for flower beds, raised beds, planters, etc. Not recommended for filling holes (consider topsoil for this.)'
      },
    ]
  }),

  methods: {
    figureSoilDescription (description) {
      return description && description.length > 0 ? description : '(No description available)'
    }
  },

  computed: {

    gardenSoils () {
      // Fetch soils and composts
      const soils = this.$store.getters['materials/materialsByCategory']('Soil')
      const composts = this.$store.getters['materials/materialsByCategory']('Compost')

      // Remove sand from list (by _id)
      let soilsNoSand = soils.filter(s => s.name !== 'Masonry Sand')
      
      // Aggregate soils and sort by name
      const gardensoils = [...soilsNoSand, ...composts].sort((a, b) => b.name.localeCompare(a.name))

      return gardensoils
    }
  },

  mounted () {
    // console.log('Fetching mats...')
    this.$store.dispatch('materials/fetchMaterials')
  }
}
</script>

<style lang="css" scoped>
#section-wrapper {
  background-color: #f1efeb;
  height: 100%;
}

#section-wrapper:after {
  display: block;
  content: '';
  border: 2px solid #f1efeb;
  position: relative;
  top: 0.6em;
}

#container {
  /* border: 1px solid gray; */
  width: 100%;
}



#banner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/landing-garden-soils.png');
  background-size: cover;
  background-repeat: no-repeat;
  height: 200px;
}

.banner-title {
  color: #EFE3D6;
  text-align: center;
  font-weight: bold;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 3rem;
}


/* CONTENT AREA */

#content-area {
  /* border: 2px solid red; */
  padding: 2em 2em;
}

#garden-soils-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.soil-item-container {
  /* border: 2px dotted gray; */
  max-width: 300px;
  margin-bottom: 1em;

}
.soil-item-image-wrapper {
  /* border: 2px dotted cyan; */
  line-height: 0;
}
.soil-item-name {
  background-color: rgb(91, 66, 54);
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 0.3em;
}
.soil-item-image {
  width: 100%;
}
.soil-description {
  font-size: 0.9rem;
}
.soil-item-content-wrapper {
  /* border: 2px dotted salmon; */
  background-color: rgb(220, 220, 220);
  padding: 0.5em 1em;
  height: 100px;
  overflow-y: scroll;
}

#viewAll-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid salmon; */
  margin-top: 1em;
}

@media screen and (max-width: 1024px) {
  #garden-soils-container {
    /* flex-direction: column; */
  }
}
</style>