
// PROFILE > ACTIONS

import axios from 'axios'
import dayjs from 'dayjs'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'

// const API_URL = 'https://chlm2021vue2.herokuapp.com/api'
const OKTA_DOMAIN = 'dev-2897504.okta.com'
const API_KEY = '00FHJJavcyZHzDXA-c9dfIA-A8xzNQkTELOdKIMeGo'

export default {
  fetchProfile (context) {
    axios.get(`https://${OKTA_DOMAIN}/api/v1/users/me`, {
      headers: {
        "accept": "application/json",
        "content-type": "application/json",
        "authorization": `SSWS ${API_KEY}`
      }
    }).then(res => {
      const results = res.data

        let profile = {
          id: results.id,
          lastLogin: dayjs(results.lastLogin).format('MM/DD/YYYY HH:MM:ss'),
          profile: {
            status: results.status,
            firstName: results.profile.firstName,
            lastName: results.profile.lastName,
            mobilePhone: results.profile.mobilePhone,
            organization: results.profile.organization,
            postalAddress: results.profile.postalAddress,
            state: results.profile.state,
            zipCode: results.profile.zipCode,
            email: results.profile.email,
          }
        }

        context.commit('setProfile', profile)
    })
  },

  updateProfile (context, profile) {
    axios.post(`https://${OKTA_DOMAIN}/api/v1/users/me`, profile, {
      headers: {
        "accept": "application/json",
        "content-type": "application/json",
        "authorization": `SSWS ${API_KEY}`
      }
    }).then(res => {
      const userData = res.data

      const userProfile = {
        id: userData.id,
        lastLogin: userData.lastLogin,
        profile: {
          firstName: userData.profile.firstName,
          lastName: userData.profile.lastName,
          mobilePhone: userData.profile.mobilePhone,
          organization: userData.profile.organization,
          postalAddress: userData.profile.postalAddress,
          state: userData.profile.state,
          zipCode: userData.profile.zipCode,
          email: userData.profile.email,
          status: userData.profile.status
        }
      }

      context.commit('setProfile', userProfile)

    }).catch(err => {
      console.error(err.message)
    })
  }
}