<template>
  <div v-if="show">
    <b-modal v-model="show" :width="900">
      <div class="card">
        <header class="card-header">
          <p v-if="edit" class="card-header-title">Edit Vendor Site</p>
          <p v-else class="card-header-title">New Vendor Site</p>
        </header>

        <div class="card-content">
          <div class="vendor-selector-wrapper">
            <b-field label="Vendor" label-position="on-border">
              <b-select v-model="vendorSite.vendorId" required>
                <option v-for="vendor in vendors" :key="vendor._id" :value="vendor._id">{{ vendor.name }}</option>
              </b-select>
            </b-field>

            <div v-if="vendorSiteCopy">
              <div>Vendor ID: {{ vendorSite.vendorId }}</div>
              <div>Site ID: {{  vendorSiteCopy._id }}</div>
              </div>
          </div>

          <b-field label="Site Name" label-position="on-border">
            <b-input v-model="vendorSite.name" name="name" type="text" :focus="true" required/>
          </b-field>
        </div>

        <footer class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="footer-btns">
              <b-button 
                class="footer-closeBtn" 
                @click="clearAndCloseDialog"
              >Cancel</b-button>
            </div>

            <div class="footer-btns">
              <b-button 
                class="footer-saveBtn" 
                icon-left="content-save"
                @click="saveVendorSite"
              >Save</b-button>
            </div>
          </div>
        </footer>
      </div>
      <br/><br/>
    </b-modal>
  </div>
</template>

<script>
  import dayjs from 'dayjs'
  // import VendorSelector from './VendorSelector'

  export default {
    props: [ 'show', 'edit', 'vendorSiteCopy', 'vendors', 'copyVendorChosen'],
    components: {
      // VendorSelector
    },
    data: () => ({
      // vendorChosen: null,
      vendorSite: {
        vendorId: '',
        name: ''
      },
      emptyVendorSite: {
        vendorId: '',
        name: ''
      }
    }),
    methods: {
      clearAndCloseDialog () {
        this.vendorSite = {...this.emptyVendorSite}
        this.$emit('closeVendorSitesDialog')
      },

      async saveVendorSite () {
        if (!this.vendorSite.vendorId || !this.vendorSite.name) {
          return alert('Missing fields')
        }

        if (this.edit && this.vendorSiteCopy) {
          try {
            // Add vendor id to the local vendorSite object
            this.vendorSite._id = this.vendorSiteCopy._id


            await this.$store.dispatch('vendorSites/updateVendorSite', this.vendorSite)
            // console.log('EDIT VENDOR SITE RESPONSE: ')
            // console.log(response)
  
            // Show success message
            this.$buefy.toast.open({
              message: 'Vendor site updated',
              position: 'is-bottom',
              type: 'is-success',
            })
  
            // Clear form and close dialog
            this.clearAndCloseDialog()
          } catch (error) {
            console.log(error.message)
            this.$buefy.toast.open({
              message: 'Error while updating vendor site! Check logs',
              position: 'is-bottom',
              type: 'is-danger',
              duration: 8000
            })
          }
        } else {
          // Create New Vendor Site

          // Add vendorId to field
          // this.vendorSite.vendorId = this.vendorChosen._id
          try {
            this.vendorSite.dateCreated = dayjs().format('DD/MM/YYYY')
            // console.log('NEW VENDOR SITE FORM: ')
            // console.log(this.vendorSite)

            await this.$store.dispatch('vendorSites/createVendorSite', this.vendorSite)
            // console.log('NEW VENDOR SITE RESULT: ')
            // console.log(result)
  
            // Show success message
            this.$buefy.toast.open({
              message: 'Vendor site created',
              position: 'is-bottom',
              type: 'is-success',
            })

            // Clear form and close dialog
            this.clearAndCloseDialog()
          } catch (error) {
            console.log(error.message)
            this.$buefy.toast.open({
              message: 'Error while updating vendor site! Check logs',
              position: 'is-bottom',
              type: 'is-danger',
              duration: 8000
            })
          }
        }
      },

      selectVendor (option) {
        this.vendorChosen = option
        // console.log('[VendorSitesDialog] vendorChosen:')
        // console.log(this.vendorChosen)
      }
    },

    watch: {
      show () {
        if (this.show && this.edit && this.vendorSiteCopy) {
          
          this.vendorSite.vendorId = this.vendorSiteCopy.vendorId
          this.vendorSite.name = this.vendorSiteCopy.name

          // console.log('[VendorSitesDialog] local vendorSite: ')
          // console.log(this.vendorSite)

        }
        
        // if (this.show && this.edit === false && this.vendorSiteCopy === null) {
        //   console.log('New vendor site form')
        // }

        if (this.copyVendorChosen) {
          this.vendorSite.vendorId = this.copyVendorChosen._id
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.vendor-selector-wrapper {
  margin-bottom: 2em;
}

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>