
// HAULS > GETTERS

import dayjs from 'dayjs'
import DayJSWeekday from 'dayjs/plugin/weekday'
import DayJSYesterday from 'dayjs/plugin/isYesterday'


// function getDaysArray (start, end) {
//   for(var arr=[], dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
//       arr.push(new Date(dt));
//   }
//   return arr;
// }


export default {
  allHauls (state) {
    return state.hauls 
  },

  filteredHauls (state) {
    return state.filteredHauls
  },

  reportDriver (state) {
    return state.reportDriver
  },

  reportHauls (state) {
    return state.reportHauls
  },

  reportWorkdays (state) {
    return state.reportWorkdays
  },

  getSummaryDateStart (state) {
    return state.reportDateStart
  },

  getSummaryDateEnd (state) {
    return state.reportDateEnd
  },



  // HAULS TODAY
  haulsTodayByDriver: (state) => (driverId) => {
    // Load the weekday plugin for DayJS
    dayjs.extend(DayJSWeekday)

    if (!driverId || driverId === undefined || driverId === null || driverId === '') {
      driverId = localStorage.getItem('driverSelected')
    }

    let today = dayjs(new Date()).format('YYYY-MM-DD')

    // console.log('Getting hauls for ' + today + '...')

    return state.hauls.filter(haul => haul.dateHaul === today)
    // return state.hauls.filter(haul => haul.haulDateTime === today)
  },

  // HAULS YESTERDAY
  haulsYesterdayByDriver: (state) => (driverId) => {
    dayjs.extend(DayJSYesterday)

    if (!driverId || driverId === undefined || driverId === null || driverId === '') {
      driverId = localStorage.getItem('driverSelected')
    }

    let yesterday = dayjs().subtract(1, 'day').format('YYYY-MM-DD')

    // console.log('Yesterday: ' + yesterday)

    return state.hauls.filter(haul => haul.dateHaul === yesterday)
  },



  // HAULS THIS WEEK
  haulsThisWeekByDriver: (state) => (driverId) => {
    // Load the weekday plugin for DayJS
    // console.log('Loading hauls for driver ' + driverId)
    dayjs.extend(DayJSWeekday)

    if (!driverId || driverId === undefined || driverId === null || driverId === '') {
      driverId = localStorage.getItem('driverSelected')
    }

    // Generate a list of dates from current Sunday - current Saturday
    let weeklist = []

    for (let i=0; i<=6; i++) {
      weeklist.push(dayjs().weekday(i).format('YYYY-MM-DD'))
    }

    // console.log('weeklist THIS WEEK: ', weeklist)


    // console.log('TEST DRIVER ID: ', driverId)

    let hauls = state.hauls.filter(haul => haul.driverId === driverId  && weeklist.includes(dayjs(haul.dateHaul).format('YYYY-MM-DD')))
    // let hauls = state.hauls.filter(haul => haul.driverId === driverId  && weeklist.includes(haul.haulDateTime))
    // console.log('HAULS FOR TEST DRIVER THIS WEEK: ', hauls)
    hauls.sort((a, b) => {
      return a.dateHaul && b.dateHaul && a.dateHaul.localeCompare(b.dateHaul) || a.timeHaul && b.timeHaul && a.timeHaul.localeCompare(b.timeHaul)
    })

    // console.log('FOUND: ')
    // console.log(hauls)

    return hauls
  },

  // HAULS LAST WEEK
  haulsLastWeekByDriver: (state) => (driverId) => {
    // Load the weekday plugin for DayJS
    dayjs.extend(DayJSWeekday)

    // console.log('Loading hauls for driver ' + driverId)

    if (!driverId || driverId === undefined || driverId === null || driverId === '') {
      driverId = localStorage.getItem('driverSelected')
    }

    // Generate a list of dates from previous Sunday - previous Saturday
    let weeklist = []

    for (let i=-7; i<=-1; i++) {
      // console.log(dayjs().weekday(i).format('YYYY-MM-DD'))
      weeklist.push(dayjs().weekday(i).format('YYYY-MM-DD'))
    }

    let hauls = state.hauls.filter(haul => haul.driverId === driverId && weeklist.includes(dayjs(haul.dateHaul).format('YYYY-MM-DD')))
   
    // Sort first by date, then by time
    hauls.sort((a, b) => {
      return a.dateHaul && b.dateHaul && a.dateHaul.localeCompare(b.dateHaul) || a.timeHaul && b.timeHaul && a.timeHaul.localeCompare(b.timeHaul)
    })

    return hauls
  },
  


  // HAULS THIS MONTH
  haulsThisMonthByDriver: (state) => (driverId) => {
    // Create an array of dates for the month
    const daysInMonth = dayjs().daysInMonth()   // # of days in current month
    let monthDates = []
    for (let i=1; i<=daysInMonth; i++) {
      monthDates.push(dayjs().date(i).format('YYYY-MM-DD'))
    }
    // Return filtered hauls by dates included in array
    let returnHauls = state.hauls.filter(h => h.driverId === driverId && monthDates.includes(h.dateHaul))
    returnHauls.sort((a, b) => a.dateHaul && b.dateHaul && a.dateHaul.localeCompare(b.dateHaul) || a.timeHaul && b.timeHaul && a.timeHaul.localeCompare(b.timeHaul))
    return returnHauls
  },

  // HAULS LAST MONTH
  haulsLastMonthByDriver: (state) => (driverId) => {

    // Create an array of dates for last month
    const daysInLastMonth = dayjs().subtract(1, 'month').daysInMonth()   // # of days in current month
    let lastMonthDates = []
    for (let i=1; i<=daysInLastMonth; i++) {
      lastMonthDates.push(dayjs().subtract(1, 'month').date(i).format('YYYY-MM-DD'))
    }
    // Return filtered hauls by dates included in array
    let returnHauls = state.hauls.filter(h => h.driverId === driverId && lastMonthDates.includes(h.dateHaul))
    returnHauls.sort((a, b) => a.dateHaul && b.dateHaul && a.dateHaul.localeCompare(b.dateHaul) || a.timeHaul && b.timeHaul && a.timeHaul.localeCompare(b.timeHaul))
    return returnHauls
  },



  // HAULS BY RANGE AND DRIVER
  haulsByRangeAndDriver: (state) => (payload) => {
    // payload:   { range, driverId }

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // Retrieve hauls for this list of dates for the specified driver
    if (daylist.length > 1) {
      let hauls = state.hauls.filter(haul => haul.driverId === driverId && daylist.includes(dayjs(haul.dateHaul).format('YYYY-MM-DD')))
      hauls.sort((a, b) => a.dateHaul && b.dateHaul && a.dateHaul.localeCompare(b.dateHaul) || a.timeHaul && b.timeHaul && a.timeHaul.localeCompare(b.timeHaul))
      return hauls
    }
  },

  // HAULS BY DATE RANGE AND DRIVER (DEPRECATED?)
  haulsByDateRangeAndDriver: (state) => (driverId, startDate, endDate) => {
    if (startDate && endDate) {
      let datesList = []

      // Generate a list of dates between the startDate and endDate
      const dateStart = dayjs(startDate)
      const dateEnd = dayjs(endDate)
      const diff = 1 + dateEnd.diff(dateStart, 'day')
    
      // For 'diff' # of times, create a date object and push to datesList
      for (let i=0; i<diff; i++) {
        datesList.push(dayjs(dateStart).add(i, 'day').format('YYYY-MM-DD'))
      }
  
      // Filter hauls by dates in datesList array and driverId
      let hauls = state.hauls.filter(h => h.driverId === driverId && datesList.includes(dayjs(h.dateHaul).format('YYYY-MM-DD')))

      hauls.sort((a, b) => b.dateHaul && a.dateHaul && b.dateHaul.localeCompare(a.dateHaul) || a.timeHaul && b.timeHaul && a.timeHaul.localeCompare(b.timeHaul))

      return hauls
    } else {
      // Incomplete date range selection (or none selected at all)
      return []
    }
  },

  // HAULS BY INVOICE
  haulsByInvoice: (state) => (invoice) => {
    let hauls = state.hauls.filter(haul => haul.invoice == invoice)
    return hauls
  }
}