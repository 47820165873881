<template>
<div class="top-wrapper">

  <MaterialDialog :show="showMaterialDialog" :isNewMaterial="isNewMaterial" :editMaterial="editMaterial" @closeMaterialDialog="closeMaterialDialog"/>
  <CategoryDialog :show="showCategoryDialog" @closeCategoryDialog="closeCategoryDialog"/>

  <CustHeaderComp title="Manage Materials"/>

  <section class="section is-small" style="border: 1px solid black;">
    <b-field grouped>
      <b-field>
        <b-button 
          type="is-primary"
          icon-left="plus-thick"
          @click="openNewMaterialDialog"
        >
          New Material
        </b-button>

      </b-field>
      <b-field>
        <b-button 
          type="is-info"
          icon-left="plus-thick"
          @click="openCategoryDialog"
        >
          New Category
        </b-button>
      </b-field>
    </b-field>

  </section>

  <section class="section" v-if="!loading">

    <div>
      <span style="margin-right: 10pt;">Searchable categories: </span>
      <ul id="category-list">
        <li
          v-for="category in categories"
          :key="category._id"
        >
          {{ category.name }}
        </li>
      </ul>
    </div>

    <br/>

    <b-table
      :data="materials"
      ref="table"
      :debounce-search="500"
      :opened-detailed="defaultOpenedDetails"
      detailed
      detail-key="_id"
      :detail-transition="transitionName"
      :show-detail-icon="showDetailIcon"
      sticky-header
      height="600px"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page">

      <b-table-column field="name" label="Name" searchable v-slot="props">
          {{ props.row.name }}
      </b-table-column>

      <b-table-column field="category" label="Category" searchable v-slot="props">
        {{ props.row.category }}
      </b-table-column>

      <b-table-column field="binNumber" label="Bin" searchable v-slot="props">
        {{ props.row.binNumber }}
      </b-table-column>

      <b-table-column field="isFeatured" label="Featured" searchable v-slot="props">
        {{ props.row.isFeatured }}
      </b-table-column>

      <b-table-column field="stock" label="Stock" sortable searchable v-slot="props">
        <template v-if="showDetailIcon">
          {{ props.row.stock}}
        </template>
        <template v-else>
          <a @click="props.toggleDetails(props.row)">
            {{ props.row.name }}
          </a>
        </template>
      </b-table-column>

      <b-table-column
        label="Update To"
        v-slot="props"
      >
        <b-select 
          placeholder="Change to..."
          @input="updateStock(props.row, $event)"
        >
          <option
            v-for="option in updateOptions"
            :value="option.value"
            :key="option.key"
          >
            {{ option.value }}
          </option>
        </b-select>
      </b-table-column>


      <!-- DETAILS -->

      <template #detail="props">
        <article class="media">
          <figure class="media-left">
            <p class="image" style="max-width: 300px">
              <img :src="props.row.image">
            </p>
          </figure>
          <div class="media-content">
            <div class="content">
              <div>
                <strong class="title">{{ props.row.name }}</strong>
                <br/><br/>
                <div>
                  {{props.row.description }}
                </div>
                <br/>
                <b-button type="is-primary" @click="updateMaterial(props.row)">Edit Material</b-button>
              </div>
            </div>
          </div>
        </article>
      </template>
    </b-table>
  </section>
  <SkeletonLoader v-else/>
</div>
</template>


<script>
import { ToastProgrammatic as Toast } from 'buefy'
import MaterialDialog from '@/views/office/Materials/MaterialDialog'
import CategoryDialog from '@/views/office/Materials/CategoryDialog'
import SkeletonLoader from '@/components/ui/SkeletonLoader'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'

export default {

  components: {
    MaterialDialog,
    CategoryDialog,
    SkeletonLoader,
    CustHeaderComp
  },

  data: () => ({
    blah: 'test',
    loading: true,
    isNewMaterial: false,
    showMaterialDialog: false,
    showCategoryDialog: false,
    defaultOpenedDetails: [1],
    showDetailIcon: true,
    useTransition: false,
    updateOptions: [
      { key: 'new', value: 'New Shipment' },
      { key: 'in', value: 'In Stock' },
      { key: 'low', value: 'Low Stock' },
      { key: 'out', value: 'Out of Stock' },
      { key: 'notavail', value: 'Not Available' }
    ],
    editMaterial: {}
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  computed: {
    materials () {
      return this.$store.getters['materials/allMaterials']
    },

    categories () {
      return this.$store.getters['categories/allCategories']
    },

    transitionName() {
      if (this.useTransition) {
        return 'fade'
      } else {
        return ''
      }
    }
  },

  watch: {
    materials () {
      return this.materials ? this.loading = false : this.loading = true
    }
  },

  methods: {

    updateMaterial (material) {
      // console.log('\nMaterial to edit: ')
      // console.log(material)
      this.editMaterial = {
        ...material
      }
      this.openEditMaterialDialog()
    },

    openNewMaterialDialog () {
      this.isNewMaterial = true
      this.showMaterialDialog = true
    },

    openEditMaterialDialog () {
      this.isNewMaterial = false
      this.showMaterialDialog = true
    },

    closeMaterialDialog () {
      this.showMaterialDialog = false
    },

    openCategoryDialog () {
      this.showCategoryDialog = true
    },

    closeCategoryDialog () {
      this.showCategoryDialog = false
    },

    updateStock (mat, evt) {
      // console.log(`Change ${mat.name} stock to ${evt}`)
      let val = ''
      if (evt == 'New Shipment') { val = 'new' }
      if (evt == 'In Stock') { val = 'in' }
      if (evt == 'Low Stock') { val = 'low' }
      if (evt == 'Out of Stock') { val = 'out' }
      if (evt == 'Not Available') { val = 'notavail' }

      // console.log('Val: ' + val)

      mat.stock = val

      // console.log('--Updating stock--')
      // console.log('matId: ', mat._id)
      // console.log('stock: ', mat.stock)

      try {
        // this.$store.dispatch('materials/updateMaterial', mat)
        this.$store.dispatch('materials/updateStock', { matId: mat._id, stock: mat.stock })
        Toast.open({
          message: 'Stock updated!',
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (error) {
        console.error(error.message)

        Toast.open({
          duration: 5000,
          message: `Error while updating stock! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  },

  created () {
    this.$store.dispatch('materials/fetchMaterials')
    this.$store.dispatch('categories/fetchCategories')
  }
}
</script>

<style lang="scss" scoped>
#category-list {
  display: inline !important;
}

#category-list li {
  display: inline;
  list-style-type: none;
  margin-right: 8pt;
  font-weight: normal;
  font-size: 10pt;
}
</style>