
// DELIVERIES > MUTATIONS

export default {
  setDeliveries (state, deliveries) {
    state.deliveries = deliveries
  },

  setEmployeeNotes (state, notes) {
    state.employeeNotes = notes
  },

  addDelivery (state, delivery) {
    state.deliveries.push(delivery)
  },

  setPrintDelivery (state, delivery) {
    state.printDelivery = delivery
  },

  updateDelivery (state, payloadDelivery) {
    let index = state.deliveries.findIndex(delivery => delivery._id === payloadDelivery._id)
    state.deliveries.splice(index, 1, payloadDelivery)

    // eslint-disable-next-line
    // let dlv = state.deliveries.find(delivery => delivery._id === payloadDelivery._id)
    // dlv = {
    //   ...payloadDelivery
    // }
  },

  removeDelivery (state, delDelivery) {
    let index = state.deliveries.findIndex(delivery => delivery._id === delDelivery._id)
    state.deliveries.splice(index, 1)
  },
     
  setDeliveryCustomer (state, customer) {
    // console.log('[deliveries/actions/setDeliveryCustomer]: setting deliveryCustomer to: ')
    // console.log(customer)
    state.deliveryCustomer = {
      ...customer
    }
  },

  // ==============================================================================================

  //  D E L I V E R Y   C U S T O M E R S 

  // ==============================================================================================

  setDeliveryCustomers (state, payloadCustomers) {
    state.deliveryCustomers = payloadCustomers
  },

  addDeliveryCustomer (state, payloadCustomer) {
    state.deliveryCustomers.push(payloadCustomer)
  },

  updateDeliveryCustomer (state, payloadCustomer) {
    let index = state.deliveryCustomers.findIndex(customer => customer._id === payloadCustomer._id)
    state.deliveryCustomers.splice(index, 1, payloadCustomer)
  },

  removeDeliveryCustomer (state, payloadCustomerId) {
    let index = state.deliveryCustomers.findIndex(customer => customer._id === payloadCustomerId)
    state.deliveryCustomers.splice(index, 1)
  }

}