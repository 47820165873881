
// OFFICE > TASKLISTS > MUTATIONS

export default {
  addTasklist (state, list) {
    state.tasklists.push(list)
  },

  setTasklists (state, list) {
    state.tasklists = list
  },
  
  updateTasklist (state, listPayload) {
    // console.log('[Tasklist mutations updateTasklist]: listPayload: ')
    // { userId: payloadList.userId, list: tasklistSaved }
    // console.log(listPayload)

    let index = state.tasklists.findIndex(list => list.userId === listPayload.userId)

    state.tasklists.splice(index, 1, listPayload.list)
    
    // if (index !== -1) {
    //   // Update list if it already exists
    //   state.tasklists.splice(index, 1, listPayload.list)
    // } else {
    //   // Add list if it doesn't exist
    //   if (!listPayload.alreadyAdded) {
    //     state.tasklists.push(listPayload.list)
    //   }
    // }

  },
  
  removeTasklist (state, userId) {
    let index = state.tasklists.findIndex(list => list.userId === userId)
    state.tasklists.splice(index, 1)
  }
}