<template>
<div class="card" style="max-height: 400pt; height: 400pt; overflow-y: scroll;">

  <header class="deskHeader">
    <div class="level">
      <div class="level-left">
        <p class="card-header-title deskCardTitle">Low Inventory</p>
      </div>
      <div class="level-right p-2">
        <b-button 
          type="is-light" 
          class="deskBtn deskManageBtn" 
          style="margin-right: 8pt;"
          size="is-small"
          tag="router-link" 
          to="/office/materials"
        >Manage</b-button>
      </div>
    </div>
  </header>

  <div>
    <b-table
      :data="materialsLowOrOut"
      :loading="materialsLoading"
      :mobile-cards="true"
      narrowed
      sticky-header
      height="100%"
      style="font-size: 9pt;"
    >
      <b-table-column field="name" label="PRODUCT" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column field="stock" label="STOCK" v-slot="props">
        <b-select v-model="props.row.stock" size="is-small" @input="updateStock(props.row, $event)">
          <option value="new">New</option>
          <option value="in">In</option>
          <option value="low">Low</option>
          <option value="out">Out</option>
          <option value="notavail">Not Avail</option>
        </b-select>
      </b-table-column>

      <b-table-column field="isFeatured" label="FTD." v-slot="props">
        <b-checkbox v-model="props.row.isFeatured" @input="updateFeatured(props.row)"></b-checkbox>
      </b-table-column>
    </b-table>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({
    materialsLoading: false,
  }),

  mounted () {
    this.loadData()
  },

  computed: {
    activeMaterials () {
      return this.$store.getters['materials/activeMaterials']
    },

    materialsLowOrOut () {
      return this.$store.getters['materials/lowOrOut']
    }
  },

  methods: {
    async loadData () {
      try {
        this.$store.dispatch('materials/fetchMaterials')
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while fetching materials! Check logs',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000
        })
      }
    },

    formatStock (stock) {
      switch (stock) {
        case 'new':
          return 'New'
        case 'in':
          return 'In'
        case 'low':
          return 'Low'
        case 'out':
          return 'Out'
        case 'notavail':
          return 'Not Avail'
      }
    },

    updateStock (mat, evt) {

      mat.stock = evt

      try {
        // this.$store.dispatch('materials/updateMaterial', mat)
        this.$store.dispatch('materials/updateStock', { matId: mat._id, stock: mat.stock })
        this.$buefy.toast.open({
          message: 'Stock updated!',
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (error) {
        console.error(error.message)

        this.$buefy.toast.open({
          duration: 8000,
          message: `Error while updating stock! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    updateFeatured (mat) {
      const data = { _id: mat._id, isFeatured: mat.isFeatured }

      try {
        this.$store.dispatch('materials/updateFeaturedById', data)
        this.$buefy.toast.open({
          message: 'Fetaured is updated',
          type: 'is-success',
          position: 'is-bottom',
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error trying to update "Featured". Check logs',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000
        })
      } 
    }
  }
}
</script>

<style lang="scss" scoped>
</style>