<template>
  <div v-if="show">
    <b-modal v-model="show" :width="900">
      <div class="card">
        <header class="card-header">
          <p v-if="edit" class="card-header-title">Edit Vendor</p>
          <p v-else class="card-header-title">New Vendor</p>
        </header>

        <div class="card-content">
          <b-field label="Name" label-position="on-border">
            <b-input v-model="vendor.name" name="name" type="text" :focus="true" required/>
          </b-field>

          <b-field label="Short Name" label-position="on-border">
            <b-input v-model="vendor.shortName" name="shortName" type="text" required/>
          </b-field>

          <b-field label="Vendor Fuel Surcharge" label-position="on-border">
            <b-input v-model="vendor.vendorFuelSurcharge" name="vendorFuelSurcharge" type="text" required/>
          </b-field>

          <b-field label="CHT Fuel Surcharge" label-position="on-border">
            <b-input v-model="vendor.chtFuelSurcharge" name="chtFuelSurcharge" type="text" required/>
          </b-field>
        </div>

        <footer class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="footer-btns">
              <b-button 
                class="footer-closeBtn" 
                @click="clearAndCloseDialog"
              >Cancel</b-button>
            </div>

            <div class="footer-btns">
              <b-button 
                class="footer-saveBtn" 
                icon-left="content-save"
                @click="saveVendor"
              >Save</b-button>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import dayjs from 'dayjs'

  export default {
    props: [ 'show', 'edit', 'vendorCopy'],
    components: {},
    data: () => ({
      vendor: {
        name: '',
        shortName: '',
        vendorFuelSurcharge: '',
        chtFuelSurcharge: ''
      },
      emptyVendor: {
        name: '',
        shortName: '',
        vendorFuelSurcharge: '',
        chtFuelSurcharge: ''
      }
    }),
    methods: {
      clearAndCloseDialog () {
        this.vendor = {...this.emptyVendor}
        this.$emit('closeVendorDialog')
      },
      async saveVendor () {

        if (!this.vendor.name || !this.vendor.shortName || !this.vendor.vendorFuelSurcharge || !this.vendor.chtFuelSurcharge) {
          return alert('Missing fields')
        }

        if (this.edit && this.vendorCopy) {
          try {
            // Add vendor id to the local vendor object
            this.vendor._id = this.vendorCopy._id


            await this.$store.dispatch('vendors/updateVendor', this.vendor)
            // console.log('EDIT VENDOR RESPONSE: ')
            // console.log(response)
  
            // Show success message
            this.$buefy.toast.open({
              message: 'Vendor updated',
              position: 'is-bottom',
              type: 'is-success',
            })
  
            // Clear form and close dialog
            this.clearAndCloseDialog()
          } catch (error) {
            console.log(error.message)
            this.$buefy.toast.open({
              message: 'Error while updating vendor! Check logs',
              position: 'is-bottom',
              type: 'is-danger',
              duration: 8000
            })
          }
        } else {
          // Create New Vendor
          try {
            this.vendor.dateCreated = dayjs().format('DD/MM/YYYY')
            // console.log('DEBUG NEW VENDOR FORM: ')
            // console.log(this.vendor)

            const result = await this.$store.dispatch('vendors/createVendor', this.vendor)
            // console.log('NEW VENDOR RESULT: ')
            // console.log(result)
  
            if (result) {
              // Show success message
              this.$buefy.toast.open({
                message: 'Vendor created',
                position: 'is-bottom',
                type: 'is-success',
              })
            } else {
              // Show error message
              this.$buefy.toast.open({
                message: 'Error while updating vendor! Check logs',
                position: 'is-bottom',
                type: 'is-danger',
                duration: 8000
              })
            }
  
            // Clear form and close dialog
            this.clearAndCloseDialog()
          } catch (error) {
            console.log(error.message)
            this.$buefy.toast.open({
              message: 'Error while updating vendor! Check logs',
              position: 'is-bottom',
              type: 'is-danger',
              duration: 8000
            })
          }
        }
      }
    },

    watch: {
      show () {
        if (this.show && this.edit && this.vendorCopy) {
          this.vendor.name = this.vendorCopy.name
          this.vendor.shortName = this.vendorCopy.shortName
          this.vendor.vendorFuelSurcharge = this.vendorCopy.vendorFuelSurcharge
          this.vendor.chtFuelSurcharge = this.vendorCopy.chtFuelSurcharge
        }
        
        if (this.show && this.edit === false && this.vendorCopy === null) {
          // console.log('New vendor form')
        }
      }
    }

  }
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>