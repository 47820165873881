<template>
<div class="top-wrapper">

  <CarportDialog
    :show="showCarportDialog"
    :isNew="isNewCarport"
    :passCarport="passCarport"
    @closeCarportDialog="closeCarportDialog"
  />

  <b-loading :is-full-page="true" v-model="loading" :can-cancel="true"></b-loading>

  <CustHeaderComp title="Manage Eagle Carports"/>

  <section class="section is-small" style="padding-bottom: 0;">
    <b-field>
      <b-button icon-left="plus-thick" type="is-primary" @click="openCarportDialog">Carport</b-button>
    </b-field>
  </section>

  <section class="section is-small">
    <b-message type="is-info">
      <div style="display: flex; align-items: center;">
        <b-icon icon="information" style="margin-right: 8pt;"></b-icon>
        <p>
          Featured carports (with a star) are listed first
        </p>
      </div>
    </b-message>

    <div class="columns is-multiline">

      <div 
        class="column is-one-third" 
        v-for="carport in carports" 
        :key="carport._id" 
      >
        <div class="card">
          <figure class="image is-square">
            <img :src="carport.image" :alt="carport.name" style="padding: 10pt;" />
          </figure>

          <div class="card-content" style="padding: 8pt;">
            <div class="columns">

              <div class="column">
                <div class="content">
                  <div><strong>{{ carport.name }}</strong></div>
                  <div><strong>{{ carport.size }}</strong></div>
                </div>
              </div>

              <div class="column">
                <b-icon 
                v-if="carport.isFeatured"
                icon="star" 
                size="is-medium" 
                style="color: red; text-align: center;"
              ></b-icon>
              </div>

              <div class="column">
                <div style="text-align: right;">
                  <b-button 
                    type="is-primary" 
                    icon-left="pencil" 
                    style="margin-right: 4pt;"
                    @click="editCarport(carport)"
                  ></b-button>
                  <b-button 
                    type="is-danger" 
                    icon-left="delete"
                    @click="confirmDeleteCarport(carport._id)"
                  ></b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>

    </div>
  </section>

</div>
</template>

<script>
import CarportDialog from './CarportDialog'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'

export default {
  components: {
    CarportDialog,
    CustHeaderComp
  },

  data: () => ({
    loading: false,
    showCarportDialog: false,
    isNewCarport: false,
    carportSelected: null,
    passCarport: null
  }),

  computed: {
    carports () {
      let cports = this.$store.getters['carports/allCarports']
      this.setLoading(false)
      cports.sort((a, b) => { return b.isFeatured - a.isFeatured})
      return cports
    }
  },

  mounted () {
    this.setLoading(true)
    this.$store.dispatch('carports/fetchCarports')

    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  methods: {
    setLoading (loading) {
      this.loading = Boolean(loading)
    },

    openCarportDialog () {
      if (!this.isNew) {
        this.isNewCarport = false
      }
      this.isNewCarport = true
      this.showCarportDialog = true
    },

    closeCarportDialog () {
      this.isNewCarport = false
      this.showCarportDialog = false
    },

    confirmDeleteCarport (carportId) {
      this.$buefy.dialog.confirm({
        title: 'Delete Carport?',
        message: `You are about to <strong>permanently delete</strong> this carport! This action cannot be undone. Continue?`,
        confirmText: 'Delete Carport',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => { this.deleteCarport(carportId) }
      })
    },

    deleteCarport (carportId) {
      this.$store.dispatch('carports/deleteCarport', carportId)
    },

    editCarport (carport) {
      // console.log('Edit carport: ', carport)
      this.isNewCarport = false
      this.passCarport = { ...carport }
      this.showCarportDialog = true
    }
  }
}
</script>

<style lang="scss" scoped>
// .top-wrapper {
//   background-color: rgb(226, 226, 226);
// }
</style>