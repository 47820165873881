<template>
<section class="section" id="componentWrapper">
  <div class="content container">
    <h3>Custom Banner Image</h3>

    <div style="margin-bottom: 6pt;"><strong>Banner Size: </strong> 1440px x 128px</div>
    <div style="display: flex;">
      <b-field class="file is-primary" :class="{'has-name': !!imageFile}">
        <input type="file" name="image" id="image" @change="convertToBase64AndDisplay()" placeholder="Image"/>
        <div id="saveBtn">
          <b-button :disabled="!imageSrc" type="is-dark" icon-left="content-save" @click="updateImage">Save</b-button>
        </div>
      </b-field>
    </div>

    <br/>

    <b-collapse
      aria-id="imagePreviewSection"
      class="panel"
      animation="slide"
      v-model="previewIsOpen"
      id="imagePreviewContainer"
    >
      <template #trigger>
        <div
          class="panel-heading"
          role="button"
          aria-controls="imagePreviewSection"
          id="previewWrapper"  
        >
          <strong id="previewWrapperTitle">Click To Preview ...</strong>
        </div>
      </template>
      <div class="panel-block">
        <figure id="imagePreviewWrapper" class="image">
          <img id="imagePreview" :src="imagePreviewSrc">
        </figure>
      </div>
    </b-collapse>


    
  </div>
</section>
</template>

<script>
export default {
  data: () => ({
    previewIsOpen: false,
    claims: null,
    imageFile: null,
    imageSrc: '',
    image: null,
    imageName: '',
    customHeader: {
      name: '',
      image: '',
    },
  }),

  mounted () {
    this.setup()
  },

  computed: {
    imagePreviewSrc () {
      if (this.imageSrc && this.imageSrc !== null && this.imageSrc !== '' && this.imageSrc !== undefined) {
        return this.imageSrc
      } else {
        return this.file
      }
    },
  },

  methods: {
    async updateImage () {

      if (!this.imageSrc) {
        console.error('ERROR: No image selected. Please "choose image" and click "Save".')
        this.$buefy.toast.open({
          message: 'No image was chosen. Please choose an image and click "Save"',
          duration: 8000,
          position: 'is-bottom',
          type: 'is-danger'
        })
        return;
      }


      // console.log('TODO: updateImage()')

      // Get a reference to the new image (if chosen)
      const imageFile = document.querySelector('#image').files[0]

      // If a new image exists, continue with request
      if (imageFile) {
        // Get filename
        const fileName = imageFile.name

        // Ask the user if they for sure want to override the existing image
        let confirmNewImage = confirm('WARNING: Selecting OK will OVERRIDE the existing image (if there is one). Selecting CANCEL will update everything except the image')

        if (confirmNewImage) {
          // continue, approve of new image (append to state.carport)
          this.customHeader.image = this.imageBuffer
          this.customHeader.imageName = fileName || ''
        } else {
          // do nothing; fall through to dispatch without image on this.carport
        }
      } else {
        // No new image detected, fall through to dispatch without image on this.carport
      }

      // Make the create request
      // console.log("EMPLOYEE ID: ", this.claims.sub)

      this.customHeader = {
        ...this.customHeader,
        userId: this.claims.sub ? this.claims.sub : null
      }

      try {
        // console.log('Submitting CUSTOM HEADER to server: ', this.customHeader)
        await this.$store.dispatch('siteSettings/setCustomHeader', this.customHeader)
        this.$buefy.toast.open({
          message: `Custom Header saved`,
          position: 'is-bottom',
          type: 'is-success'
        })

        // Clear form and close dialog
        this.showNewImageWarning = false

      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          duration: 8000,
          message: `Error while trying to create custom header! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    async setup () {
      this.claims = await this.$auth.getUser()
    },

    convertToBase64AndDisplay () {
      // console.log('inside convertToBase64AndDisplay...')
      let imgInput = document.getElementById('image')

      const file = imgInput.files[0],
        reader = new FileReader()

      const that = this
      reader.onloadend = function () {
        let b64 = reader.result.replace(/^data:.+;base64,/, '')
        that.imageBuffer = b64
        that.imageSrc = `data:image/jpg;base64,${that.imageBuffer}`
        that.showNewImageWarning = true
      }
      reader.readAsDataURL(file);
    },
  }
}
</script>

<style lang="scss" scoped>
#componentWrapper {
  border: 1px solid gray;
  padding: 10pt;
}
#saveBtn {
  margin-left: 5pt;
}
#imagePreview {
  border: 2px solid red;
  width: 600px;
  height: 600px;
}
#imagePreviewContainer {
  width: 530pt;
}
#imagePreviewWrapper {
  // border: 5px solid black;
  width: 600px;
  height: 600px;
  
}
#previewWrapper {
  background-color: #335780;
  font-weight: bold;
}
#previewWrapperTitle {
  color: white;
}
</style>