
// MATERIALS > index.js

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// import { v4 as uuidv4 } from 'uuid'

// const Stock = {
//   NEW: 'new',
//   IN: 'in',
//   LOW: 'low',
//   OUT: 'out',
//   NOTAVAIL: 'notavail'
// }

export default {
  namespaced: true,
  state () {
    return {
      materials: [],
      materialToEdit: null,
      stockOptions: [
        { value: 'new', name: 'New Shipment' },
        { value: 'in', name: 'In' },
        { value: 'low', name: 'Low' },
        { value: 'out', name: 'Out' },
        { value: 'notavail', name: 'Currently Unavailable' }
      ]
      // materials: [
      //   // #region SOIL ==============================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Topsoil',
      //     category: 'soil',
      //     image: 'https://picsum.photos/seed/topsoil/400/400',
      //     bin: 13,
      //     stock: Stock.IN,
      //     note: 'Has small amounts of clay'
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Masonry Sand',
      //     category: 'soil',
      //     image: 'https://picsum.photos/seed/sand/400/400',
      //     bin: 8,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region COMPOST ==============================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Premium Compost',
      //     category: 'compost',
      //     image: 'https://picsum.photos/seed/compost/400/400',
      //     bin: 14,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region MULCH ==============================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Pinebark Mulch',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/pinebarkmulch/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Hardwood Dbl-Ground',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/hardwoodmulch/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Cedar Mulch',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/cedarmulch/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Black Mulch',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/blackmulch/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Red Mulch',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/redmulch/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chocolate Brown Mulch',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/chocmulch/400/400',
      //     bin: null,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Playground (IPEMA)',
      //     category: 'mulch',
      //     image: 'https://picsum.photos/seed/plygmulch/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   // #endregion
        
      //   // #region GRAVEL ==============================================================
      //   {
      //     id: uuidv4(),
      //     name: 'White Gravel',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-white/400/400',
      //     bin: null,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: '1" Smooth',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-1insmooth/400/400',
      //     bin: 1,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: '2" to 1.5 Smooth"',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-2into1halfin/400/400',
      //     bin: 2,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Crushed Decomposed Granite',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-granite/400/400',
      //     bin: 3,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Blue Fine Screenings',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-bluefines/400/400',
      //     bin: 4,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: '2.5" Blue & White',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-bluewhite/400/400',
      //     bin: 5,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'River Boulders',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-riverboulders/400/400',
      //     bin: 6,
      //     stock: Stock.LOW,
      //     note: 'Currently very large'
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Arkansas Class 5',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-arclass5/400/400',
      //     bin: 7,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Blue River Rock',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-blueriverrock/400/400',
      //     bin: 9,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Crushed 1.5" Minus',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-crush1in/400/400',
      //     bin: 10,
      //     stock: Stock.IN,
      //     note: 'Currently has fines in it'
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Pea Gravel',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-peagravel/400/400',
      //     bin: 11,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Blue SB2',
      //     category: 'gravel',
      //     image: 'https://picsum.photos/seed/gravel-bluesb2/400/400',
      //     bin: 12,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region FLAGSTONE ============================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Colorado Red',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-colred/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Cherry Blend',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-cherrybl/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: '2" only'
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Cheyenne Brown',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-cheybrown/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Light Buckskin',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-lightbuck/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Dark Buckskin',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-darkbuck/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Cheyenne Blue',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-cheyblue/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chestnut',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-cnut/400/400',
      //     bin: null,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Silvermist',
      //     category: 'flagstone',
      //     image: 'https://picsum.photos/seed/flag-silvermist/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region CHOPPED STONE ========================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Limestone',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-limestone/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Chestnut',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-cnut/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Cheyenne Blue',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-cheyblue/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Cherry Blend',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-cherrybl/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Silvermist',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-silvermist/400/400',
      //     bin: null,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Light Buckskin',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-ltbuck/400/400',
      //     bin: null,
      //     stock: Stock.IN,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Chopped Vanilla Bean',
      //     category: 'choppedstone',
      //     image: 'https://picsum.photos/seed/chop-vanillabean/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region FIELD STONE =========================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Mossy Natural Steps (Grotto)',
      //     category: 'fieldstone',
      //     image: 'https://picsum.photos/seed/field-mossysteps/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Large Cut Stone Steps',
      //     category: 'fieldstone',
      //     image: 'https://picsum.photos/seed/field-lrgcutstonesteps/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Mossy Brick',
      //     category: 'fieldstone',
      //     image: 'https://picsum.photos/seed/field-mossybrick/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Mossy Rounds',
      //     category: 'fieldstone',
      //     image: 'https://picsum.photos/seed/field-mossyrounds/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Medium Cut Stone Steps',
      //     category: 'fieldstone',
      //     image: 'https://picsum.photos/seed/field-medcutstonesteps/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Mossy Waterfall',
      //     category: 'fieldstone',
      //     image: 'https://picsum.photos/seed/field-mossywaterfall/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region CREEK ROCK =========================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Sm. Round Creek',
      //     category: 'creekrock',
      //     image: 'https://picsum.photos/seed/creek-smround/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Med. Round Creek',
      //     category: 'creekrock',
      //     image: 'https://picsum.photos/seed/creek-mdround/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Sm. Flat Creek',
      //     category: 'creekrock',
      //     image: 'https://picsum.photos/seed/creek-smflat/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Med. Flat Creek',
      //     category: 'creekrock',
      //     image: 'https://picsum.photos/seed/creek-mdflat/400/400',
      //     bin: null,
      //     stock: Stock.NEW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Lg. Flat Creek',
      //     category: 'creekrock',
      //     image: 'https://picsum.photos/seed/creek-lgflat/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Creek Brick',
      //     category: 'creekrock',
      //     image: 'https://picsum.photos/seed/creek-creekbrick/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region COLORADO ROCK =========================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Rocky Mtn River Rock',
      //     category: 'coloradorock',
      //     image: 'https://picsum.photos/seed/col-rckymtnriverrock/400/400',
      //     bin: null,
      //     stock: Stock.NOTAVAIL,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: '5"-3" Colorado Pancakes',
      //     category: 'coloradorock',
      //     image: 'https://picsum.photos/seed/col-5to3colpan/400/400',
      //     bin: null,
      //     stock: Stock.NOTAVAIL,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: '12"-5" Colorado Pancakes',
      //     category: 'coloradorock',
      //     image: 'https://picsum.photos/seed/col-12to5colpan/400/400',
      //     bin: null,
      //     stock: Stock.NOTAVAIL,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Mexican Beach Pebbles',
      //     category: 'coloradorock',
      //     image: 'https://picsum.photos/seed/col-mexbeachpebbles/400/400',
      //     bin: null,
      //     stock: Stock.NOTAVAIL,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Colorado Skippers',
      //     category: 'coloradorock',
      //     image: 'https://picsum.photos/seed/col-skippers/400/400',
      //     bin: null,
      //     stock: Stock.NOTAVAIL,
      //     note: ''
      //   },
      //   // #endregion
  
      //   // #region BOULDERS =========================================================
      //   {
      //     id: uuidv4(),
      //     name: 'Sm. Mossy Boulders',
      //     category: 'boulder',
      //     image: 'https://picsum.photos/seed/boulder-smmossy/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Med. Mossy Boulders',
      //     category: 'boulder',
      //     image: 'https://picsum.photos/seed/boulder-medmossy/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Lg. Mossy Boulders',
      //     category: 'boulder',
      //     image: 'https://picsum.photos/seed/boulder-lgmossy/400/400',
      //     bin: null,
      //     stock: Stock.LOW,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Med. Hilltop Boulders',
      //     category: 'boulder',
      //     image: 'https://picsum.photos/seed/boulder-hilltop/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Sm. Creek Boulders',
      //     category: 'boulder',
      //     image: 'https://picsum.photos/seed/boulder-smcreek/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   },
      //   {
      //     id: uuidv4(),
      //     name: 'Med. Creek Boulders',
      //     category: 'boulder',
      //     image: 'https://picsum.photos/seed/boulder-medcreek/400/400',
      //     bin: null,
      //     stock: Stock.OUT,
      //     note: ''
      //   }
      //   // #endregion
      // ]
    }
  },
  actions,
  getters,
  mutations
}