
// VENDORS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchVendors (context) {
    axios.get(`${API_URL}/vendors`).then(res => {
      const vendors = res.data

      context.commit('setVendors', vendors)
    })
  },

  createVendor (context, payload) {
    return axios.post(`${API_URL}/vendors`, payload).then(res => {
      const vendor = res.data

      context.commit('addVendor', vendor)
      return vendor
    })
  },

  updateVendor (context, vendor) {
    axios.patch(`${API_URL}/vendors/${vendor._id}`, { 
      name: vendor.name, 
      shortName: vendor.shortName, 
      vendorFuelSurcharge: vendor.vendorFuelSurcharge,
      chtFuelSurcharge: vendor.chtFuelSurcharge
    })
    .then(res => {
      const newVendor = res.data

      context.commit('updateVendor', newVendor)
    })
  },

  deleteVendor (context, vendorId) {
    axios.delete(`${API_URL}/vendors/${vendorId}`).then(() => {
      context.commit('removeVendor', vendorId)
    })
  }
}