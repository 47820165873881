<template>
<div>
  <b-modal v-model="show">
    <div class="card">
      <header class="card-header">
        <p v-if="isNew" class="card-header-title">New FAQ</p>
        <p v-else class="card-header-title">Edit FAQ</p>
      </header>

      <main class="card-content">
        <b-field label="Question" label-position="on-border">
          <b-input v-model="faq.question" type="textarea" rows="3"/>
        </b-field>

        <b-field label="Answer" label-position="on-border">
          <b-input v-model="faq.answer" type="textarea" rows="3" style="white-space: pre-line;" />
        </b-field>

        <b-field label="URLs (optional)" label-position="on-border">
          <b-taginput
            v-model="faq.urls"
            placeholder="Add a URL"
            aria-close-label="Delete this URL"
          ></b-taginput>
        </b-field>

        <b-field grouped>
          <b-field label="Category" label-position="on-border">
            <b-select
              v-model="faq.category"
            >
              <option value="general">General</option>
              <option value="pricing">Pricing</option>
              <option value="loading">Loading</option>
              <option value="delivery">Delivery</option>
              <option value="eagle">Eagle Carports</option>
              <option value="calculator">Calculator</option>
              <option value="weights">Weight</option>
              <option value="coverage">Coverage</option>
            </b-select>
          </b-field>

          <b-field style="margin-top: 8pt; margin-left: 20pt;">
            <b-checkbox v-model="faq.isActive">Active</b-checkbox>
          </b-field>

          <b-field style="margin-top: 8pt; margin-left: 20pt;">
            <b-checkbox v-model="faq.regardsDelivery">Regarding Delivery</b-checkbox>
          </b-field>

        </b-field>

      </main>

      <footer style="display: flex; justify-content: flex-end; padding: 10pt;">
        <b-button
          type="is-secondary"
          @click="$emit('closeFAQDialog')"
          style="background-color: #343434; color: white; margin-right: 8pt;"
          tabindex="-1"
        >Cancel</b-button>

        <b-button
          type="is-primary"
          @click="saveFAQ"
          style="font-weight: bold;"
          icon-left="content-save"
        >{{ saveOrCreateText }}</b-button>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'isNew', 'editFAQ' ],

  emits: [ 'closeFAQDialog' ],

  data: () => ({
    faq: {
      question: '',
      answer: '',
      category: 'general',
      urls: [],
      isActive: true,
      regardsDelivery: false
    },

    emptyFaq: {
      question: '',
      answer: '',
      urls: [],
      category: 'general',
      isActive: true,
      regardsDelivery: false
    }
  }),

  computed: {
    saveOrCreateText () {
      return this.isNew ? 'Create' : 'Save'
    }
  },

  methods: {
    async saveFAQ () {

      if (this.isNew) {
        // Create a new FAQ
        try {
          await this.$store.dispatch('faqs/createFAQ', this.faq)
          this.$buefy.toast.open({
            message: 'FAQ created',
            type: 'is-success',
            position: 'is-bottom'
          })
          this.clearAndClose()
        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: 'Error while creating FAQ! Check logs',
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000
          })
        }
      } else {
        // Save an existing FAQ
        // console.log('Regards Delivery: ', this.faq.regardsDelivery)
        try {
          await this.$store.dispatch('faqs/updateFAQ', this.faq)
          this.$buefy.toast.open({
            message: 'FAQ updated',
            type: 'is-success',
            position: 'is-bottom'
          })
          this.clearAndClose()
        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: 'Error while updating FAQ! Check logs',
            type: 'is-danger',
            position: 'is-bottom',
            duration: 5000
          })
        }
      }
    },

    clearAndClose () {
      this.faq = {...this.emptyFaq}
      this.$emit('closeFAQDialog')
    }
  },

  watch: {
    editFAQ () {
      // console.log('editFAQ: ', this.editFAQ)
      if (this.editFAQ && this.editFAQ !== '') {
        this.faq = {...this.editFAQ}
        this.faq._id = this.editFAQ._id
      }
    }
  }
}
</script>