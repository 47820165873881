
// CARRIERS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchCarriers (context) {
    axios.get(`${API_URL}/carriers`).then(res => {
      const carriers = res.data

      context.commit('setCarriers', carriers)
    })
  },

  createCarrier (context, carrier) {
    axios.post(`${API_URL}/carriers`, carrier).then(res => {
      const theCarrier = res.data

      context.commit('addCarrier', theCarrier)
    })
  },

  updateCarrier (context, carrier) {
    let carrierMinusId = {
      name: carrier.name,
      shortName: carrier.shortName,
      dateCreated: carrier.dateCreated,
      dateModified: carrier.dateCreated,
    }
    axios.patch(`${API_URL}/carriers/${carrier.id}`, carrierMinusId).then(res => {
      const newCarrier = res.data

      context.commit('updateCarrier', newCarrier)
    })
  },

  copyCarrier (context, carrierId) {
    // console.log('Copying carrier ID: ' + carrierId)
    axios.post(`${API_URL}/carriers/${carrierId}/copy`).then(res => {
      const cpyCarrier = res.data

      // console.log('COPIED CARRIER: ')
      // console.log(cpyCarrier)
      context.commit('addCarrier', cpyCarrier)
    })
  },

  deleteCarrier (context, carrierId) {
    axios.delete(`${API_URL}/carriers/${carrierId}`).then(res => {
      const carrier = res.data
      context.commit('removeCarrier', carrier)
    })
  }
}