<template>
<div class="settingsWrapper">
  <b-field label="Mon-Fri Hours">
    <b-input type="text" v-model="settings.storeHours.monFri" :loading="isLoading" class="settingsField" />
  </b-field>
  <b-field label="Saturday Hours">
    <b-input type="text" v-model="settings.storeHours.saturday" :loading="isLoading" class="settingsField"  />
  </b-field>
  <b-field label="Sunday Hours">
    <b-input type="text" v-model="settings.storeHours.sunday" :loading="isLoading" class="settingsField"  />
  </b-field>
  <b-button type="is-dark" @click="setStoreHours">Save Hours</b-button>
</div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    storeHours: {
      monFri: '',
      saturday: '',
      sunday: ''
    }
  }),

  computed: {
    settings () {
      const settings = this.$store.getters['siteSettings/settings']
      this.setLoading(false)
      return settings
    }
  },

  methods: {
    async setStoreHours () {
      // console.log('udpating settings...')
      // console.log(this.settings)
      try {
        await this.$store.dispatch('siteSettings/updateSettings', this.settings)
        this.$buefy.toast.open({
          message: 'Settings Saved',
          type: 'is-success',
          position: 'is-bottom'
        })
      } catch (error) {
        console.error(error)
        this.$buefy.toast.open({
            title: "Error Saving Settings",
            message: 'An error occurred while saving settings! Check logs',
            type: 'is-danger',
            position: 'is-bottom'
          })
      }
    },

    setLoading (val) {
      this.isLoading = Boolean(val)
    }
  },

  async mounted () {
    this.setLoading(true)
    await this.$store.dispatch('siteSettings/fetchSettings')
  }
}
</script>

<style lang="scss" scoped>
.settingsWrapper {
  margin: 2rem;
}
.settingsField {
  width: 250px;
}
</style>