
// DELIVERY CUSTOMERS > GETTERS

export default {

  // Get all delivery customers
  allDeliveryCustomers (state) {
    return state.deliveryCustomers
  },

  allActiveDeliveryCustomers (state) {
    return state.deliveryCustomers.filter(customer => customer.isActive === true)
  },

  // Get single delivery customer loaded into form
  getDeliveryCustomer (state) {
    return state.deliveryCustomer
  },

  customerById: (state) => (id) => {
    return state.deliveryCustomers.find(customer => customer._id === id)
  },

  deliveryCustomerByPhone: (state) => (phone) => {
    return state.deliveryCustomers.find(customer => customer.phone === phone)
  },

  // Get all delivery customers matching searchString
  filterCustomer: (state) => (searchString) => {
    if (searchString !== '') {
      return state.deliveryCustomers.filter(customer => customer.name.toLowerCase().includes(searchString.toLowerCase()) || customer.phone.toLowerCase().includes(searchString.toLowerCase()) || customer.address.toLowerCase().includes(searchString.toLowerCase()))
    } else {
      // console.log('Cannot search delivery customers, search string is empty')
      return []
    }
  },
}