
// OFFICE > PAYRATES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  async fetchPayRates ({ commit }) {
    axios.get(`${API_URL}/payrates`).then(res => {
      const pay_rates = res.data

      commit('setPayRates', pay_rates)
    })
    // for (let i=0; i<yard_rates.length; i++) {
    //   // yard_rates[i]['description'] = yard_rates[i]['code']
    //   axios.get(`${API_URL}/vendors/${yard_rates[i].vendorId}`).then(res => {
    //     console.log('Vendor response: ')
    //     const vendor = res.data

    //     if (vendor.shortName) {
    //       yard_rates[i]['description'] = vendor.shortName + ' -> C&H Yard'
    //       yard_rates[i]['value'] = yard_rates[i].freight
    //     } else {
    //       yard_rates[i]['description'] = vendor.name + ' -> C&H Yard'
    //       yard_rates[i]['value'] = yard_rates[i].freight
    //     }

    //     PAY_RATES.push(yard_rates[i])
    //   })
    // }
  },

  createPayRate (context, payload) {
    axios.post(`${API_URL}/payrates`, payload).then(res => {
      const payRate = res.data
      context.commit('addPayRate', payRate)
    })
  }
}