
// VENDOR PRODUCTS > GETTERS

export default {
  allVendorProducts (state) {
    return state.vendorProducts
  },

  productsByVendor: (state) => (vendorId) => {
    // console.log('Filtering vendor products by vendorId: ' + vendorId)
    return state.vendorProducts.filter(product => product.vendorId === vendorId)
  },

  vendorProductById: (state) => (productId) => {
    return state.vendorProducts.find(product => product._id === productId)
  },

  payRatesToYard (state) {
    return state.pay_rates
  },

  productsToYard (state) {
    let products = state.vendorProducts.filter(product => product.toYard === true)
    return products
  }
}