<template>
<div id="hauls-wrapper">

  <CustHeaderComp title="Hauls"/>

  <!-- <HaulDialog 
    :show="showHaulDialog" 
    :driver="driverObj" 
    :isCopyHaul="isCopyHaul" 
    :isNewHaul="isNewHaul" 
    :editHaul="editHaul" 
    @closeHaulDialog="closeHaulDialog"
  /> -->

  <HaulDialogV2
    :show="showHaulDialog"
    :driver="driverObj" 
    :isCopyHaul="isCopyHaul" 
    :isNewHaul="isNewHaul" 
    :editHaul="editHaul" 
    @cancelHaulDialog="closeHaulDialog"
  />

  <section class="section is-small" id="haulControls">
    <b-field grouped>
      <!-- DRIVER & NEW HAUL BUTTON -->
      <b-field grouped>
        <!-- Driver -->
        <b-field label="Driver" label-position="on-border">
          <b-select 
            v-model="driverSelected"
            ref="driverField"
            placeholder="Loading..."
            :loading="driversLoading"
            id="driverSelector"
            expanded
          >
            <option
              v-for="driver in drivers"
              :key="driver._id"
              :value="driver._id"
            >
              {{ driver.firstName }} {{ driver.lastName }}
            </option>
          </b-select>
        </b-field>

        <!-- New Haul Button -->
        <b-field>
          <b-button
            type="is-primary"
            icon-left="plus-thick"
            @click="openNewHaulDialog"
          >
            New Haul
          </b-button>
        </b-field>
      </b-field>

      <!-- TIMEFRAME SELECTOR -->
      <b-field label="Timeframe" label-position="on-border" style="margin-left: 25pt;">
        <b-select 
          v-model="timeframeSelected"
          placeholder="View..."
          :disabled="!driverSelected"
          @input="useTimeframe()"
        >
          <option
            v-for="timeframe in timeframes"
            :key="timeframe.value"
            :value="timeframe.value"
          >
            {{ timeframe.key }}
          </option>
        </b-select>
      </b-field>

      <!-- OR -->
      <b-field label="OR" style="position: relative; top: 5pt;"></b-field>

      <!-- DATE RANGE SELECTOR -->
      <b-field label="View Range" label-position="on-border">
        <b-datepicker
          v-model="dateRange"
          icon="calendar-today"
          placeholder="Choose start & end"
          :locale="undefined"
          :show-week-number="false"
          :disabled="!driverSelected"
          range
          @input="useDateRange()"
          nearby-selectable-month-days
        >
        </b-datepicker>
      </b-field>

      <!-- INVOICE SEARCH -->
      <b-field label="Find by Invoice" 
        label-position="on-border"
        style="margin-right: 15pt; width: 180pt;"
      >
        <b-input
          v-model="invoiceSearch"
          placeholder="Invoice #"
          type="text"
          icon="pound-box"
          icon-right="close-circle"
          icon-right-clickable
          @icon-right-click="clearInvoiceSearch"
        ></b-input>
      </b-field>

      <!-- WORKDAYS BUTTON -->
      <b-field>
        <b-button
          class="workdayButton"
          icon-left="clock-time-eight-outline"
          tag="router-link"
          to="/office/hauls/workdays"
        >
          Workdays
        </b-button>
      </b-field>

      <!-- REPORT BUTTON -->
      <b-field>
        
        <b-tooltip type="is-light" v-if="isReportBtnDisabled">
          <template v-slot:content>
            <div>
              Choose date range
            </div>
          </template>
          <b-button
            type="is-primary"
            id="reportButton"
            :disabled="isReportBtnDisabled"
            style="margin-left: 10pt;"
            @click="prepareForChecklist"
          >
            Hauls Report
          </b-button>
        </b-tooltip>

        <b-button v-else
            type="is-primary"
            id="reportButton"
            :disabled="isReportBtnDisabled"
            style="margin-left: 10pt;"
            @click="prepareForChecklist"
          >
            Hauls Report
        </b-button>
      </b-field>

    </b-field>
  </section>

  <section class="section is-small" id="haulsTableContainer" style="height: 100%;">
    <b-table
      :data="isEmpty ? [] : filteredHauls"
      :per-page="haulsPerPage"
      :loading="haulsLoading"
      :selected.sync="selected"
      paginated
      narrowed
      hoverable
      striped
      focusable
      sort-multiple
    >
      <!-- DATE HAUL -->
      <b-table-column
        field="dateHaul"
        label="Date"
        v-slot="props"
        sortable
      >
        {{ formatDate(props.row.dateHaul) }}
      </b-table-column>

      <!-- TIME HAUL -->
      <b-table-column
        field="timeHaul"
        label="Time"
        v-slot="props"
        sortable
      >
        {{ formatTime(props.row.timeHaul) }}
      </b-table-column>

      <!-- BROKER / CUST -->
      <b-table-column
        field="broker"
        label="Customer"
        v-slot="props"
      >
        {{ props.row.broker }}
      </b-table-column>

      <!-- INVOICE / REF # -->
      <b-table-column
        field="invoice"
        label="Ref #"
        v-slot="props"
      >
        {{ props.row.invoice }}
      </b-table-column>

      <!-- CH INVOICE -->
      <b-table-column
        field="chinvoice"
        label="CH Inv"
        v-slot="props"
      >
        {{ props.row.chinvoice }}
      </b-table-column>

      <!-- FROM -->
      <b-table-column
        field="from"
        label="From"
        v-slot="props"
      >
        {{ props.row.from }}
      </b-table-column>

      <!-- TO -->
      <b-table-column
        field="to"
        label="To"
        v-slot="props"
      >
        {{ props.row.to }}
      </b-table-column>

      <!-- PRODUCT (MATERIAL) -->
      <b-table-column
        field="product"
        label="Material"
        v-slot="props"
      >
        {{ props.row.product }}
      </b-table-column>

      <!-- TONS / MI -->
      <b-table-column
        field="tons"
        label="Tons/Mi"
        v-slot="props"
      >
        {{ props.row.tons }}
      </b-table-column>

      <!-- RATE -->
      <b-table-column
        field="rate"
        label="Rate"
        v-slot="props"
      >
        {{ props.row.rate }}
      </b-table-column>

      <!-- MILES -->
      <b-table-column
        field="miles"
        label="Miles"
        v-slot="props"
      >
        {{ props.row.miles }}
      </b-table-column>

      <b-table-column
        field="payrate"
        label="Pay Rate"
        v-slot="props"
      >
        {{ props.row.payrate }}
      </b-table-column>

      <!-- TRUCK -->
      <b-table-column
        field="truck"
        label="Truck"
        v-slot="props"
      >
        {{ props.row.truck }}
      </b-table-column>

      <!-- ACTIONS -->
      <b-table-column
        label="Actions"
        v-slot="props"
      >
        <!-- Edit Button -->
        <b-button 
          type="is-primary"
          class="is-small"
          style="margin-right: 5pt;"
          icon-left="pencil"
          @click="updateHaul(props.row)"
        >
        </b-button>

        <!-- Copy Button -->
        <b-button 
          type="is-primary"
          class="copyButton is-small"
          icon-left="content-copy"
          @click="copyHaul(props.row)"
        >
        </b-button>
        

        <!-- Delete Button -->
        <b-button 
          type="is-danger"
          class="is-small"
          icon-left="delete"
          style="margin-left: 6pt;"
          @click="confirmDeleteHaul(props.row._id)"
        >
        </b-button>
      </b-table-column>

      <template #empty>
        <div class="" style="margin-top: 16pt; font-weight: bold; font-size: 16pt;">No hauls found...</div>
        <div style="font-style: italic;">Double check your selected driver and timeframe/date range</div>
      </template>
    </b-table>
  </section>

</div>
</template>

<script>
// import HaulDialog from '@/views/office/Hauls/HaulDialog'
import HaulDialogV2 from '@/views/office/Hauls/v2/HaulDialog'
import dayjs from 'dayjs'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
// import RelativeTime from 'dayjs/plugin/relativeTime'

export default {
  components: {
    // HaulDialog,
    HaulDialogV2,
    CustHeaderComp
  },

  data: () => ({
    // Haul Controls
    selected: null,
    isEmpty: false,
    isCopyHaul: false,
    driversLoading: false,
    haulsLoading: true,
    driverSelected: '',
    timeframeSelected: null,
    dateRange: [],
    timeframeType: '',
    invoiceSearch: '',
    haulsPerPage: 15,
    timeframes: [
      {
        key: 'This Week',
        value: 'thisweek'
      },
      {
        key: 'Last Week',
        value: 'lastweek'
      },
      {
        key: 'Today',
        value: 'today'
      },
      {
        key: 'Yesterday',
        value: 'yesterday'
      },
      {
        key: 'This Month',
        value: 'thismonth'
      },
      {
        key: 'Last Month',
        value: 'lastmonth'
      },
      // {
      //   key: 'All Time',
      //   value: 'alltime'
      // }
    ],
    // Haul Dialog stuff
    editHaul: {},
    isNewHaul: true,
    showHaulDialog: false,
  }),

  computed: {

    isReportBtnDisabled () {
      if (this.dateRange) {
        let driverSelected = this.driverSelected && this.driverSelected !== '' ? true : false
        return this.dateRange.length < 2 && driverSelected
      } else {
        return true
      }
    },

    driverObj () {
      if (this.driverSelected !== '') {
        // console.log('driverId: ' + this.driverSelected)
        let driver = this.$store.getters['drivers/driverById'](this.driverSelected)
        // console.log('Driver OBJ: ')
        // console.log(driver)
        return driver
      } else {
        // console.log('No driverId available...')
        return {}
      }
    },

    drivers () {
      const drivers = this.$store.getters['drivers/activeDrivers']
      return drivers
    },

    hauls: {
      get () {
        return this.$store.getters['hauls/allHauls']
      },

      set (newVal) {
        this.$store.commit('hauls/setHauls', newVal)
      }
    },

    filteredHauls: {
      get () {
        return this.figureHauls()
      },

      set (newVal) {
        this.$store.dispatch('hauls/setFilteredHauls', newVal)
      }
    }
  },

  methods: {

    prepareForChecklist () {
      // /office/hauls/workdaycheck

      // Set reportDateRange in local storage, to be used in init() in WorkdayCheck.vue
      localStorage.setItem('reportDateRange', this.dateRange)

      this.$router.push('/office/hauls/workdaycheck')
    },

    copyHaul (haul) {
      this.editHaul = {
        ...haul
      }
      this.isNewHaul = false
      this.isCopyHaul = true
      this.showHaulDialog = true
    },

    confirmDeleteHaul (haulId) {
      this.$buefy.dialog.confirm({
        title: 'Permanently Delete?',
        message: `You are about to <strong>permanently delete</strong> this haul. Are you sure?`,
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteHaul(haulId)
        }
      })
    },

    async deleteHaul (haulId) {
      try {
        await this.$store.dispatch('hauls/deleteHaul', haulId)
        this.$buefy.toast.open({
          message: 'Haul deleted',
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while deleting haul! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }
    },

    figureHauls () {
      if (this.driverSelected !== null) {

          // Basically, filter hauls via selected timeframe, dateRange, or invoice number
  
          // Get hauls This Week by driverID
          if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'thisweek' && this.invoiceSearch === '') {
            const hauls = this.$store.getters['hauls/haulsThisWeekByDriver'](this.driverSelected)
            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            return hauls
          }

          // Get hauls Last Week by driverID
          if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'lastweek' && this.invoiceSearch === '') {
            let hauls = this.$store.getters['hauls/haulsLastWeekByDriver'](this.driverSelected)
            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            return hauls
          }

          // Get hauls This Month by driverID
          if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'thismonth' && this.invoiceSearch === '') {
            let hauls = this.$store.getters['hauls/haulsThisMonthByDriver'](this.driverSelected)
            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            return hauls
          }

          // Get hauls Today by driverID
          if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'today' && this.invoiceSearch === '') {
            let hauls = this.$store.getters['hauls/haulsTodayByDriver'](this.driverSelected)
            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            return hauls
          }

          // Get hauls Yesterday by driverId
          if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'yesterday' && this.invoiceSearch === '') {
            let hauls = this.$store.getters['hauls/haulsYesterdayByDriver'](this.driverSelected)
            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            return hauls
          }

          // Get hauls Last Month by driverId
          if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'lastmonth' && this.invoiceSearch === '') {
            // console.log('set to TIMEFRAME: LAST MONTH')
            let hauls = this.$store.getters['hauls/haulsLastMonthByDriver'](this.driverSelected)
            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            return hauls
          }

          // TODO: FOR REPORT 11/9/21 FOR MOM  -----------------------------------------------------
          // if (this.timeframeType === 'timeframe' && this.timeframeSelected === 'alltime' && this.invoiceSearch === '') {
          //   console.log('Getting ALL hauls...')
          //   let hauls = this.$store.getters['hauls/allHauls']
          //   this.$store.dispatch('hauls/setFilteredHauls', hauls)
          // }
          // -----------------------------------------------------
  
          // Get hauls By Range and driverId
          if (this.timeframeType === 'daterange' && this.dateRange && this.dateRange.length > 0 && this.invoiceSearch === '') {

            let range = this.dateRange
            let driverId = this.driverSelected
  
            let hauls = this.$store.getters['hauls/haulsByRangeAndDriver']({ range, driverId })

            this.$store.dispatch('hauls/setFilteredHauls', hauls)
            // this.setHaulsLoading(false)
            return hauls
          }

          // Get haul(s) by invoice number
          if (this.invoiceSearch !== '') {

            this.timeframeSelected = null
            this.dateRange = null
            this.timeframeType = null

            let hauls = this.$store.getters['hauls/haulsByInvoice'](this.invoiceSearch)

            this.$store.dispatch('hauls/setFilteredHauls', hauls)

            return hauls
          }

          else {
            return []
          }
  
        } else {
          // No driver has been selected
          return []
        }
    },

    // findByInvoice () {
    //   if (this.invoiceSearch !== '') {

    //     let hauls = this.$store.getters['hauls/haulsByInvoice'](this.invoiceSearch)
    //     console.log('Invoice hauls found: ')
    //     console.log(hauls)

    //     // TODO: console.log above is working, but the dispatch below is not updating view with haul(s)
    //     this.$store.dispatch('hauls/setFilteredHauls', hauls)
    //     return hauls
    //   }
    // },

    setHaulsLoading(loading) {
      this.haulsLoading = Boolean(loading)
    },

    setDriversLoading(loading) {
      this.driversLoading = Boolean(loading)
    },

    closeHaulDialog () {
      this.showHaulDialog = false
    },

    updateHaul (haul) {
      // console.log('\n[Hauls.vue]: Haul to edit: ')
      // console.log(haul)

      this.isNewHaul = false
      this.isCopyHaul = false

      // console.log('\n\n\nHAUL TO PASS: ')
      // console.log(haul)

      this.editHaul = {
        ...haul
      }

      this.openEditHaulDialog()
    },

    openNewHaulDialog () {
      this.isNewHaul = true
      this.isCopyHaul = false
      this.showHaulDialog = true
    },

    openEditHaulDialog () {
      this.isNewHaul = false
      this.showHaulDialog = true
    },

    formatDate (date) {
      // console.log('Date before format: ', date)
      // console.log('Date after format: ', dayjs(date).format('M/DD/YY'))
      
      // return dayjs(date).format('M/DD/YY')
      return dayjs(date).format('MM/DD/YYYY')
    },

    formatTime (time) {
      return dayjs(time).format('hh:mm a')
    },

    clearInvoiceSearch () {
      this.invoiceSearch = ''
    },

    useTimeframe () {
      this.timeframeType = 'timeframe'

      if (this.timeframeSelected) {
        // console.log(`Setting timeframeSelected in localStorage to: ${this.timeframeSelected}`)
        localStorage.setItem('timeframeSelected', this.timeframeSelected)
      }
    },

    useDateRange () {
      this.timeframeType = 'daterange'
    },

    async loadDrivers () {
      // let selector = document.getElementById('driverSelector')

      this.setDriversLoading(true)

      try {

        await this.$store.dispatch('drivers/fetchDrivers')
        this.setDriversLoading(false)
        // if (selector) {
        //   document.querySelector('#driverSelector option:nth-child(1)').innerHTML = 'Choose...'
        // }

      } catch (error) {
        console.error(error.message)

        this.$buefy.dialog.alert({
          title: 'Cannot Load Drivers',
          type: 'is-danger',
          hasIcon: true,
          message: "Oops we have a problem! Drivers can't be loaded from the database. Try again later, or take note of this error and inform the developer",
          confirmText: 'Got It',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        this.setDriversLoading(false)
      }
    },

    async loadHauls () {

      this.setHaulsLoading(true)

      try {
        await this.$store.dispatch('hauls/fetchHauls')
        this.setHaulsLoading(false)
      } catch (error) {
        console.error(error.message)

        this.$buefy.dialog.alert({
          title: 'Cannot Load Hauls',
          type: 'is-danger',
          hasIcon: true,
          message: "Unable to obtain hauls from database. Possibly a network or internet connection problem",
          confirmText: 'Got It',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        this.setHaulsLoading(false)
      }
    },
  },

  watch: {

    driverSelected () {
      if (this.driverSelected) {
        // Capture driverID from Driver Dropdown on the UI, store in local storage
        localStorage.setItem('driverSelected', this.driverSelected)
      }
    },

    timeframeType () {
      if (this.timeframeType === 'timeframe') {
        this.dateRange = []   // using timeframe selector
      }

      if (this.timeframeType === 'daterange') {
        this.timeframeSelected = null  // using daterange selector
      }
    }
  },

  async mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'

    // Load data
    await this.loadHauls()
    await this.loadDrivers()

    // Grab last used settings from local storage (for better UX)
    const driverSelected = localStorage.getItem('driverSelected')
    const timeframeSelected = localStorage.getItem('timeframeSelected')

    if (driverSelected) {
      // console.log('[Hauls.vue] Selected Driver ID:', driverSelected)        
      let driver = await this.$store.getters['drivers/driverById'](driverSelected)

      // TODO: Make Better: Currently, will break for first time users (or if driverSelected is removed from local storage)
      if (driver) {
        this.driverSelected = driver._id
      } else {
        this.driverSelected = driverSelected
      }
    }

    // TODO: Make Better: Currently, will break for first time users (or if timeframeSelected is removed from local storage)
    if (timeframeSelected) {
      this.timeframeSelected = timeframeSelected
      this.timeframeType = 'timeframe'
      if (this.driverSelected) {
        this.figureHauls()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

body {
  background-color: gray !important;
}

.top-wrapper {
  background-color: #f3f3f3;
}

#reportButton {
  background-color: #764397;

  &:hover {
    background-color: #9e5ec9;
  }
}

.workdayButton {
  background-color: #e75b0a;
  color: white;

  &:hover {
    background-color: #bb4b0a;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.copyButton {
  background-color: #6da540;
  color: white;

  &:hover {
    background-color: #609435;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.hoverable {
  border: 2px solid red;
}
</style>