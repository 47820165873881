
// GALLERY > GETTERS

export default {
  allGalleryImages (state) {
    return state.images
  },

  activeGalleryImages (state) {
    return state.images.filter(i => i.isActive === true)
  },

  featuredImages (state) {
    return state.images.filter(i => i.isFeatured === true)
  },

  featuredImage_A (state) {
    return state.images.find(i => i.featPosition === 'A')
  },

  featuredImage_B (state) {
    return state.images.find(i => i.featPosition === 'B')
  },

  featuredImage_C (state) {
    return state.images.find(i => i.featPosition === 'C')
  }
}