
// SCRATCHPAD > GETTERS

export default {
  allPads (state) {
    return state.pads
  },

  padByUserId: (state) => (userId) => {
    if (!userId) {
      console.error('ERROR: inside padByUserId, no valid userId supplied or is falsey..')
    }

    return state.pads.find(pad => pad.userId === userId)
  }
}