<template>
<div>
  <b-navbar type="is-light" :fixed-top="true">
      <template #brand>
        <b-navbar-item tag="router-link" to="/" exact>
          <img
            src="@/assets/logo_blue.png"
            class="no-print"
            alt="C&H Landscape Materials of Texarkana, Texas"
            width="50px"
          >
        </b-navbar-item>
        <div class="scrollTopBtn no-print" title="Scroll to top of page" @click="toTop">
          <b-icon icon="mouse-move-up"></b-icon>
          <span style="margin-left: 5pt;">Top</span>
        </div>
      </template>

      <template #start>
        <div style="margin-left: 10pt;"></div>
        <b-navbar-item tag="router-link" to="/" class="navLink" exact>Home</b-navbar-item>

        <b-navbar-item tag="router-link" to="/about" class="navLink">About</b-navbar-item>

        <b-navbar-item tag="router-link" to="/materials" class="navLink">Materials</b-navbar-item>

        <!-- TODO: Implement Customer Gallery -->
        <!-- <b-navbar-item tag="router-link" to="/gallery" class="navLink">Gallery</b-navbar-item> -->

        <!-- <b-navbar-item>Loading Settings: {{ loadingSettings }}</b-navbar-item>
        <b-navbar-item>Advertise Dlvs: {{ settings }}</b-navbar-item> -->

        <b-navbar-item tag="router-link" to="/delivery" class="navLink" v-if="!loadingSettings && settings.advertiseDeliveries">Delivery</b-navbar-item>

        <b-navbar-item tag="router-link" to="/carports" class="navLink">Eagle Carports</b-navbar-item>

        <b-navbar-item tag="router-link" to="/calculator" class="navLink">Calculator</b-navbar-item>

        <b-navbar-item tag="router-link" to="/help" class="navLink">Help</b-navbar-item>

        <b-navbar-item tag="router-link" to="/contact" class="navLink">Contact Us</b-navbar-item>

        
      </template>

      <template v-if="authenticated" #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <b-navbar-dropdown type="is-primary is-light" :hoverable="true" label="Manage" style="font-weight: bold;">
              <b-navbar-item tag="router-link" to="/office/materials" class="navLink">Materials</b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/drivers" class="navLink">Drivers</b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/hauls" class="navLink">Hauls</b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/deliveries" class="navLink">
                Deliveries
                <span style="margin-left: 4pt;">
                  <b-tag type="is-danger" rounded v-if="countDeliveriesPending() > 0">
                    <strong style="color: white;">{{ countDeliveriesPending() }}</strong>
                  </b-tag>

                  <b-tag rounded v-else>
                    <strong style="color: white;">{{ countDeliveriesPending() }}</strong>
                  </b-tag>
                </span>
              </b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/carports" class="navLink">Carports</b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/vendors-and-products" class="navLink">Vendors &amp; Products</b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/orders" class="navLink">Orders</b-navbar-item>
              <hr class="navbar-divider">
              <b-navbar-item tag="router-link" to="/office/call-list" class="navLink">
                Call List
                <span style="margin-left: 4pt;">
                  <b-tag type="is-danger" rounded v-if="countRequestsIn > 0">
                    <strong style="color: white;">{{ countRequestsIn }}</strong>
                  </b-tag>

                  <b-tag rounded v-else>
                    <strong style="color: white;">{{ countRequestsIn }}</strong>
                  </b-tag>
                </span>
              </b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/cost-calculator" class="navLink">Cost Calculator</b-navbar-item>
              <b-navbar-item tag="router-link" to="/office/faq" class="navLink">FAQs</b-navbar-item>
              
              <!-- TODO: Implement Customer Gallery -->
              <!-- <b-navbar-item tag="router-link" to="/office/gallery">Customer Gallery</b-navbar-item> -->

              <b-navbar-item tag="router-link" to="/office/sitemessages" class="navLink">Site Messages</b-navbar-item>
              <hr class="navbar-divider">
              <!-- <b-navbar-item tag="router-link" to="/office/profile" class="navLink">My Profile</b-navbar-item> -->
              <b-navbar-item tag="router-link" to="/office/settings" class="navLink">Site Settings</b-navbar-item>
              <b-navbar-item @click="logout" class="navLink">Logout</b-navbar-item>
            </b-navbar-dropdown>

            <b-button class="ch-desk navLink" tag="router-link" to="/office/desk" rounded>Desk</b-button>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
</div>
</template>

<script>
export default {
  data: () => ({
    authenticated: false,
    loadingSettings: false
  }),

  async created () {
    await this.isAuthenticated()
    this.$auth.authStateManager.subscribe(this.isAuthenticated)
  },

  async mounted () {
    // this.setRequestsLoading(true)

    this.loadingSettings = true
    // console.log('Loading Settings....')
    
    try {
      this.$store.dispatch('callListRequests/fetchCallListRequests')
    } catch (error) {
      console.error(error.message)
    }
    
    try {
      this.$store.dispatch('deliveries/fetchDeliveries')
    } catch (error) {
      console.error(error.message)
    }

    try {
      this.$store.dispatch('siteSettings/fetchSettings')
      this.loadingSettings = false
      // console.log('Done loading settings...')
    } catch (error) {
      console.error(error.message)
      this.loadingSettings = false
    }
  },

  computed: {
    activeCallListRequests () {
      return this.$store.getters['callListRequests/allActiveRequests']
    },

    deliveries () {
      return this.$store.getters['deliveries/allDeliveries']
    },

    countRequestsIn () {
      let requests = this.activeCallListRequests
      let requestsIn = []

      if (requests && requests.length > 0) {
        for (let i=0; i<requests.length; i++) {
          // console.log('Call List Request Item: ', requests[i])
          if (requests[i].status === 'waitingmaterial') {
            let mat = this.$store.getters['materials/materialById'](requests[i].material)
            if (mat) {
              if (mat.stock === 'new' || mat.stock === 'in' || mat.stock === 'low') {
                requestsIn.push(requests[i])
              }
            } else {
              // console.error('countRequestsIn(): ERROR. mat returned empty, null, or falsey..')
            }
          }
        }

        this.$store.dispatch('callListRequests/setNumRequestsReady', requestsIn.length)
        return requestsIn.length
      } else if (requests.length === 0) {
        return 0
      } else {
        // console.log('WARNING: activeCallListRequests empty or is falsey..')
        return 0
      }
    },

    settings () {
      let show = this.$store.getters['siteSettings/settings']
      // console.log('SETTINGS: ', show)
      return show
    },
  },

  methods: {

    toTop () {
      window.scroll(0, 0)
    },

    async logout () {
      await this.$auth.signOut()
    },

    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },

    countDeliveriesPending () {
      if (this.deliveries) {
        let dlvPending = this.deliveries.filter(dlv => dlv.completed === false)
        return dlvPending.length
      } else {
        return 0
      }
    }
  },

  watch: {
    // Everytime the route changes, check for auth status
    '$route': 'isAuthenticated'
  },
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.scrollTopBtn {
  margin-left: 25%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ch-desk {
  background-color: $primary;
  color: white;

  &:visited {
    color: white;
  }
}

.navLink {
  font-weight: bold !important;
}

@media screen and (min-width: 1024px) {
  .scrollTopBtn {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .scrollTopBtn {
    // border: 2px solid red;
    margin-left: 40%;
  }
}
</style>