<template>
<div class="card" style="max-height: 400pt; height: 400pt; overflow-y: scroll;">
  <header class="deskHeader">
    <p class="card-header-title deskCardTitle">Scratch Pad</p>
  </header>
  <div class="card-content" style="padding: 0; padding-bottom: 8pt;">
    <b-field style="border-radius: 0;">
      <b-input 
        type="textarea" 
        v-model="pad.notes" 
        rows="19"
        style="border-radius: 0; white-space: pre; height: 100%;" 
        @blur="updatePad"
      />
    </b-field>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({
    claims: null,
    pad: {
      userId: undefined,
      notes: ''
    }
  }),

  mounted () {
    this.init()
  },

  methods: {
    updatePad () {
      try {
        this.$store.dispatch('scratchpad/updatePad', this.pad)
        this.$buefy.toast.open({
          type: 'is-success',
          message: 'Scratchpad saved',
          position: 'is-bottom'
        })
      } catch (error) {
        // console.error(error.message)
        // console.log('---- PRINTING FULL ERROR MESSAGE BELOW ----')
        // console.error(error)
        this.$buefy.toast.open({
          type: 'is-danger',
          message: 'Error while trying to save scratchpad! Check logs',
          position: 'is-bottom',
          duration: 8000
        })
      }
    },

    loadPad () {
      if (this.claims && this.claims.sub) {

        // Set userId on pad
        this.pad.userId = this.claims.sub

        // console.log(`Returning pad for userId ${this.claims.sub}: `)
        const pad = this.$store.getters['scratchpad/padByUserId'](this.claims.sub)
        // console.log(pad)

        this.pad.notes = pad.notes

      } else {
        // console.log('Trying to load scratchpad. Claims and/or claims.sub is empty or falsey..')
        return null
      }
    },

    async init () {
      // Hide the copyright footer
      document.getElementById('copyrightFooter').style.display = 'none'

      // Load pads
      this.$store.dispatch('scratchpad/fetchPads')

      // Get user info
      this.claims = await this.$auth.getUser()

      // If a user is found, pull in their pad
      this.loadPad()
    },
  }
}
</script>

<style lang="scss" scoped>
</style>