<template>
<div>
  <section class="hero is-primary" id="deliveryHero">
    <div class="hero-body">
      <p class="title">C&H Trucking Quote Request</p>
    </div>
  </section>

  <!-- SIDEBAR - INSTRUCTIONS FOR OBTAINING GOOGLE MAP COORDINATES -->
  <b-sidebar
    type="is-light"
    :fullheight="true"
    :fullwidth="false"
    :overlay="false"
    :right="true"
    v-model="sidebarOpen"
  >
    <section class="content" style="padding: 9pt">
      <h4>Google Map Coordinates</h4>

      <p>Instructions for how to obtain coordinates.</p>

      <!-- HOW-TO FOR DESKTOP / LAPTOP -->
      <b-collapse
        aria-id="howto-computer"
        class="panel"
        animation="slide"
        v-model="howtoComputerOpen"
      >
        <template #trigger>
          <div
            role="button"
            aria-controls="howto-computer"
            :aria-expanded="howtoComputerOpen"
            style="padding: 8pt; background-color: #45F3CD"
          >
            <strong>
              <b-icon icon="desktop-classic" style="margin-right: 4pt"/>
              Using Desktop / Laptop
            </strong>
          </div>
        </template>
        <div>
          <ol class="p-2">
            <li>Open <a href="https://maps.google.com" target="_blank">Google Maps</a></li>
            <li>Find the desired delivery location</li>
            <li>Right-click on this location</li>
            <li>Left-click the coordinates that appear at the top of the menu (it will copy to your clipboard)</li>
            <li>Return here, right-click inside the Delivery Coordinates textbox and click "Paste"</li>
          </ol>
        </div>
      </b-collapse>

      <!-- HOW-TO FOR MOBILE / TABLET -->
      <b-collapse
        aria-id="howto-mobile"
        class="panel"
        animation="slide"
        v-model="howtoMobileOpen"
      >
        <template #trigger>
          <div
            role="button"
            aria-controls="howto-mobile"
            :aria-expanded="howtoMobileOpen"
            style="padding: 8pt; background-color: #45D2F3"
          >
            <strong>
              <b-icon icon="tablet-cellphone" style="margin-right: 4pt"/>
              Using Mobile / Tablet
            </strong>
          </div>
        </template>
        <div>
          <ol class="p-2">
            <li>Open your Google Maps app</li>
            <li>Find the desired delivery location</li>
            <li>Press on this location to drop a pin</li>
            <li>Scroll down on the menu that appears, you should see "coordinates"</li>
            <li>Long-press on "Coordinates" and click "copy" that appears</li>
            <li>Return here, long-press on the "Delivery Coordinates" box, then click "paste"</li>
          </ol>
        </div>
      </b-collapse>
    </section>
  </b-sidebar>

  <div class="columns">
    <!-- INFO COLUMN -->
    <div class="column">
      <div class="content mt-6" id="infoColumn">
        <header>
          <h3>Requesting Truckload Quotes</h3>
        </header>
        <div>
          <div id="truckloadDisclaimer">This form is for requesting quotes on 
            <a href="https://www.google.com/search?q=end+dump+truck" id="endDumpTruckLink" target="_blank">End Dump tractor-trailer trucks</a> only
          </div>

          <div id="truckloadDisclaimer">We do not give out immediate quotes over the phone</div>

          <br/>

          <p>C&H can provide delivery services on select products. For a list of these products, click the "Material" dropdown on the form.</p>

          <div><strong>HOW DO YOU QUOTE?</strong></div>
          <p>
            Each quote involves the distance between your delivery location and the pit or quarry itself, the price of fuel, the cost of the material, and other factors. As such, we cannot give out quotes over the phone upon request. We will take all information from this form into consideration when processing each quote, so please ensure this information is correct and thorough.
          </p>

          <div><strong>HOW LONG FOR A QUOTE?</strong></div>
          <p>
            Please allow <em>at least 3-5 days</em> for our dispatcher to get in contact with the pit/quarry to receive a quote, before contacting us back.
          </p>

          <div><strong>HOW LONG FOR DELIVERY?</strong></div>
          <p>
            We run a very small trucking operation; our trucks are constantly running for C&H yard and for other customers, so same-day delivery is extremely rare. 
          </p>
          <p>
            Once your quote is received and you decide to make the purchase, your order will be put in queue with any outstanding deliveries, based on the requested delivery date on this form.
          </p>
          <p>
            We will try our best to honor this delivery date, but please keep in mind that it may take longer depending on several factors, such as: weather, pit closures, product availability, ongoing scheduled deliveries, etc.
          </p>
        </div>
      </div>
    </div>

    <!-- FORM COLUMN -->
    <div class="column">
      <!-- QUOTE FORM -->
      <section class="section" id="form-section">
        <form @submit.prevent="submitForm">
          <!-- Material -->
          <div style="margin-bottom: 12pt;"></div>

          <b-field label="Material" label-position="on-border" style="margin-bottom: 0">
            <b-select 
              v-if="truckableCategories" 
              v-model="form.material"
              placeholder="Choose One" 
              :loading="isLoading"
            >
              <optgroup v-for="cat in truckableCategories" :key="cat._id" :label="cat" id="optgroup">
                <option v-for="mat in truckableMatsByCategory(cat)" :key="mat._id">
                  {{ mat.binNumber ? `Bin #${mat.binNumber} - ` : "" }}
                  {{ mat.name }}
                </option>
              </optgroup>
            </b-select>
          </b-field>
          <span id="materialDisclaimer">If unsure, visit <a style="font-weight: bold" href="https://www.chlandscapematerials.com/materials" target="_blank">https://www.chlandscapematerials.com/materials</a> for Bin numbers and pictures. <br/><strong>Only the materials we can haul appear here.</strong></span>
          <br/>
          <br/>

          <!-- Delivery Date -->
          <b-field label="Requested Delivery Date" label-position="on-border">
            <b-datepicker
              v-model="form.deliveryDate"
              rounded
              placeholder="Requested Delivery Date"
              icon="calendar-today"
              :icon-right="deliveryDate ? 'close-circle' : ''"
              icon-right-clickable
              @icon-right-click="clearDateTime"
              required>
            </b-datepicker>
          </b-field>
          <div style="margin-bottom: 20pt"></div>

          <!-- First & Last Name -->
          <b-field grouped>
            <b-field label="First Name" label-position="on-border" expanded>
              <b-input v-model="form.firstName" required/>
            </b-field>
            <b-field label="Last Name" label-position="on-border" expanded>
              <b-input v-model="form.lastName" required/>
            </b-field>
          </b-field>

          <!-- Phone(s) -->
          <b-field label="Phone(s)" label-position="on-border" expanded>
            <b-input v-model="form.phones" type="textarea" rows="2" required></b-input>
          </b-field>

          <!-- Business Name (optional) -->
          <b-field label="Business Name" label-position="on-border" message="Leave blank if not applicable" expanded>
            <b-input v-model="form.businessName"/>
          </b-field>
          
          <div style="margin-bottom: 16pt"></div>

          <!-- Delivery Address -->
          <b-field label="Delivery Address" label-position="on-border">
            <b-input v-model="form.deliveryAddress" type="textarea" rows="3" required/>
          </b-field>

          <!-- Dump Coordinates -->
          <b-field 
            label="Dump Location Coordinates (where to dump)"
            label-position="on-border" 
            message="COORDINATES ONLY! Please DO NOT put anything else"
          >
            <b-input v-model="form.dumpCoordinates" expanded required/>
            <b-button type="is-danger" @click="sidebarOpen = true">?</b-button>
          </b-field>
          
          <div style="margin-bottom: 16pt"></div>

          <!-- Notes -->
          <b-field label="Additional Notes" label-position="on-border">
            <b-input v-model="form.notes" type="textarea" rows="5" placeholder="Any information that might be useful to the office or our truck driver. For example, notes regarding delivery location, width of gates/fences, known obstacles or road closures, additional contact info, where to e-mail receipt to (if desired), etc."/>
          </b-field>

          <!-- Submit Button -->
          <b-button type="is-primary" native-type="submit">Submit</b-button>
        </form>
      </section>
    </div>
  </div>

  
</div>
</template>

<script>
export default {
  data: () => ({
    isLoading: false,
    deliveryDate: null,
    sidebarOpen: false,
    howtoComputerOpen: false,
    howtoMobileOpen: false,
    truckableCategories: [],
    form: {
      material: null,
      deliveryDate: null,
      firstName: '',
      lastName: '',
      phones: '',
      businessName: '',
      deliveryAddress: '',
      dumpCoordinates: '',
      notes: ''
    },
    emptyForm: {
      material: null,
      deliveryDate: null,
      firstName: '',
      lastName: '',
      phones: '',
      businessName: '',
      deliveryAddress: '',
      dumpCoordinates: '',
      notes: ''
    }
  }),

  computed: {
    categories () {
      return this.$store.getters['categories/allCategories']
    },

    truckableMaterials () {
      return this.$store.getters['materials/truckableProducts']
    }
  },

  methods: {
    async init () {
      this.isLoading = true
      await this.$store.dispatch('materials/fetchMaterials')
      await this.$store.dispatch('categories/fetchCategories')
      this.isLoading = false
      this.getTruckableCategories()
    },

    truckableMatsByCategory (name) {
      const allMats = this.$store.getters['materials/materialsByCategory'](name)
      return allMats.filter(mat => mat.isTruckable === true)
    },

    clearDateTime () {
      this.deliveryDate = null
    },

    getTruckableCategories () {
      let categories = []

      for (let i=0; i<this.truckableMaterials.length; i++) {
        // console.log(this.truckableMaterials[i])
        categories.push(this.truckableMaterials[i].category)
      }

      this.truckableCategories = [...new Set(categories)]
      // console.log('Truckable Categories: ', this.truckableCategories)
    },

    submitForm () {
      // TODO: Implement saving to server (also implement Office side to manage these requests)
      // try {
      //   this.$store.dispatch('')
      // } catch (error) {
        
      // }
      this.clearForm()
      alert('Coming Soon')
    },

    clearForm () {
      this.form = { ...this.emptyForm }
    }
  },

  mounted () {
    this.init()
  },

  watch: {
    truckableMaterials () {
      if (this.truckableMaterials.length > 0) {
        this.getTruckableCategories()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.paragraph {
  margin: 8pt auto;
}
#deliveryHero {
  position: relative;
  background: rgb(2,31,79);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../../assets/trucking_banner_c.jpg');
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -100pt;
  z-index: 10;  
}
#form-section {
  padding-left: 0em;
  padding-right: 2em;
}
#infoColumn {
  padding-left: 4em;
  padding-right: 4em;
}
#materialDisclaimer {
  font-size: 10pt;
}
#truckloadDisclaimer {
  font-weight: bold;
  color: red;
}
#endDumpTruckLink {
  font-weight: bold;
  text-decoration: underline !important;
  color: rgb(73, 165, 201);
}
</style>