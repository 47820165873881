
// CATEGORIES > MUTATIONS

export default {
  setCategories (state, categories) {
    state.categories = categories
  },

  addCategory (state, category) {
    state.categories.push(category)
  }
}