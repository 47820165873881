<template>
<aside v-if="authenticated" class="sidebarAside" :class="`${isExpanded && 'isExpanded'}`" @click="ToggleSidebar">
  <div style="display: flex; align-items: center;">
    <b-icon 
      icon="note-edit" 
      size="large" 
      id="sharednote-activator"
      class="no-print"
    />
    <p class="instructions" v-if="isExpanded">Click again to cancel</p>
  </div>
  <b-input v-if="isExpanded" v-model="notes" type="textarea" rows="10" @click.native.capture.stop />
  <b-button v-if="isExpanded" type="is-primary" @click="saveSharedNotes">Save</b-button>
</aside>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
      authenticated: false,
      isExpanded: false,
      notes: ''
    }
  },

  async created () {
    await this.isAuthenticated()
    this.$auth.authStateManager.subscribe(this.isAuthenticated)
  },

  async mounted () {
    try {
      this.setLoading(true)
      await this.$store.dispatch('sharedNotes/fetchSharedNotes')
      this.setLoading(false)
    } catch (error) {
      console.error(error.message)
      this.$buefy.toast.open({
        message: 'Error loading shared notes! Check logs',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 8000
      })
    }
  },

  computed: {
    noteData () {
      const note = this.$store.getters['sharedNotes/allSharedNotes'][0]
      // console.log('NOTE: ', note)
      if (!note) return null
      return note.content
    }
  },

  watch: {
    noteData () {
      if (this.noteData) {
        this.setNoteContent(this.noteData)
      }
    }
  },

  methods: {
    setLoading (val) {
      this.loading = Boolean(val)
    },

    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },

    setNoteContent (content) {
      // console.log('Setting note content to: ', content)
      this.notes = content
    },

    ToggleSidebar () {
      this.isExpanded = !this.isExpanded
      // console.log('isExpanded: ', this.isExpanded)
    },

    KeepSidebarOpen () {
      this.isExpanded = true;
    },

    async saveSharedNotes () {
      try {
        await this.$store.dispatch('sharedNotes/updateSharedNotes', { content: this.notes })
        this.$buefy.toast.open({
        message: 'Note saved',
        position: 'is-bottom',
        type: 'is-success'
      })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while updating shared notes! Check logs',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000
        })
      }
    }
  }  
}

</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

/*
  $sidebar-primary: #4ade80;
  $sidebar-grey: #64748b;
  $sidebar-dark: #1e293b;
  $sidebar-darkAlt: #334155;
  $sidebar-light: #f1f5f9;
  $sidebar-width: 300px;
*/

aside {
  position: fixed;
  top: 4em !important;
  right: 0;
  display: flex;
  flex-direction: column;
  width: calc(2rem + 2px);
  min-height: 26px;
  overflow: hidden;
  // padding: 1rem;
  z-index: 50;

  background-color: $sidebar-dark;
  color: $sidebar-light;

  transition: 0.2s ease-out;

  &.isExpanded {
    width: $sidebar-width;
  }

  @media (max-width: 768px) {
    position: fixed;
    z-index: 99;
  }
}

.sidebarAside {
  display: flex;
  flex-direction: column;
}

#sharednote-activator {
  padding: 1rem;
  cursor: pointer;
}

.instructions {
  color: white;
  font-weight: bold;
}
</style>
