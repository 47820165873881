<template>
<section class="notes-box">

  <article>
    <strong>
      {{ formatDate(workday.workDate) }} 
      &nbsp;&nbsp;&nbsp; {{ driver.firstName }} {{ driver.lastName }}
    </strong>
  </article>

  <section class="checkbox-wrapper">
    <article>
      <div class="field">
        <b-checkbox v-model="haulsEntered">Hauls Entered</b-checkbox>
      </div>
    </article>

    <article>
      <div class="field">
        <b-checkbox v-model="dayComplete">Workday Complete</b-checkbox>
      </div>
    </article>

    <article>
      <div class="field">
        <b-button 
          type="is-primary" 
          icon-left="content-save"
          @click="saveInfo"
        >Save</b-button>
      </div>
    </article>
  </section>

</section>
</template>

<script>
import dayjs from 'dayjs'
export default {
  props: [ 'driver', 'workday'],
  data: () => ({
    haulsEntered: null,
    dayComplete: null
  }),

  methods: {
    formatDate (date) {
      return dayjs(date).format('M/D/YY')
    },

    async saveInfo () {
      try {
        await this.$store.dispatch('workdays/saveOrCreateWorkday', {
          driverId: this.driver._id,
          workday: {
            ...this.workday,
            haulsEntered: this.haulsEntered,
            dayComplete: this.dayComplete
          }
        })

        this.$buefy.toast.open({
          message: 'Day saved!',
          position: 'is-bottom',
          type: 'is-success'
        })

      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while saving workday! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }
    }
  },

  mounted () {

    // console.log('Driver: ', this.driver)
    // console.log('Workday: ', this.workday)

    if (this.driver && this.workday) {
      this.haulsEntered = Boolean(this.workday.haulsEntered)
      this.dayComplete = Boolean(this.workday.dayComplete)
    }
  }
}
</script>

<style lang="scss" scoped>
.notes-box {
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8; 
  padding: 10pt;
  margin-bottom: 10pt;
}
.icon-missing {
  color: red; 
  font-weight: bold; 
  margin-right: 5pt;
}
.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-left: 50pt;
  // border: 1px solid red;
}
.checkbox-wrapper article:not(:last-child) {
  margin-right: 20pt;
}
</style>