<template>
<div>
  <NewOrderDialog
    :show="showOrderDialog"
    :editOrder="editOrder"
    @cancelOrderDialog="cancelOrderDialog"
  />

  <CustHeaderComp title="Manage Orders"/>
  <section style="margin-top: 2rem; padding: 2rem">
    <b-button type="is-primary" @click="openOrderDialog">New Order</b-button><br/><br/>
    <b-table
      :data="orders"
      id="ordersTable"
      class="ordersDataTable"
      height="450px"
      striped
      hoverable
      focusable
      narrowed
      :sticky-header="true"
    >
      <b-table-column field="vendorName" label="Vendor" v-slot="props" searchable>
        {{ props.row.vendorName }}
      </b-table-column>

      <b-table-column field="destination" label="Destination" v-slot="props" searchable>
        {{ props.row.destination }}
      </b-table-column>

      <b-table-column field="material" label="Material" v-slot="props" searchable>
        {{ props.row.material }}
      </b-table-column>

      <b-table-column field="product" label="Product" v-slot="props">
        {{ props.row.product }}
      </b-table-column>

      <b-table-column field="freight" label="Freight" v-slot="props">
        {{ props.row.freight }}
      </b-table-column>

      <b-table-column label="Fuel Schg" v-slot="props">
        {{ getFuelSurcharge(props.row) }}
      </b-table-column>

      <b-table-column label="Actions" v-slot="props">
        <b-button type="is-primary" icon-left="pencil" @click="(e) => editTheOrder(props)" style="margin-right: 4pt" />
        <b-button type="is-danger" icon-left="delete" @click="(e) => confirmDeleteOrder(props)" />
      </b-table-column>
    </b-table>
  </section>
</div>
</template>

<script>
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import NewOrderDialog from '@/views/office/Hauls/v2/components/NewOrder'

export default {

  components: {
    CustHeaderComp,
    NewOrderDialog
  },

  data: () => ({
    showOrderDialog: false,
    editOrder: null
  }),

  mounted () {
    this.init()
  },

  computed: {
    orders () {
      const orders = this.$store.getters['orders/allOrders']
      // console.log('ManageOrders ORDERS: ', orders)

      return orders.sort((a, b) => {
        let vendorNameA = a.vendorName
        let vendorNameB = b.vendorName
        return (vendorNameA < vendorNameB) ? -1 : (vendorNameA > vendorNameB) ? 1 : 0
      })
    },

    vendors () {
      return this.$store.getters['vendors/allVendors']
    }
  },

  methods: {
    async init () {
      try {
        await this.$store.dispatch('orders/fetchOrders')
        await this.$store.dispatch('vendors/fetchVendors')
      } catch (error) {
        console.error(error)
      }
    },

    getFuelSurcharge (product) {
      // console.log('Product: ', product)
      const surcharge = this.$store.getters['vendors/fuelSurchargeByVendorId'](product.vendorId)
      // console.log('Surcharge: $', surcharge)
      return surcharge
    },

    async cancelOrderDialog() {
      this.showOrderDialog = false
    },

    openOrderDialog () {
      this.showOrderDialog = true
    },

    confirmDeleteOrder (props) {
      // console.log('props: ', props)
      const { _id } = props.row

      this.$buefy.dialog.confirm({
        title: "Confirm Delete",
        message: `You are about to <strong>permanently delete</strong> this order! Continue?`,
        cancelText: 'Cancel',
        confirmText: 'Delete',
        type: 'is-danger',
        onConfirm: async () => {
          // console.log('Confirmed deletion of Order ID ', _id)
          try {
            await this.$store.dispatch('orders/deleteOrder', _id)
            this.$buefy.toast.open({
              message: 'Order deleted',
              type: 'is-success',
              position: 'is-bottom'
            })
          } catch (error) {
            this.$buefy.toast.open({
              message: 'Error while deleting order! Check logs',
              type: 'is-danger',
              position: 'is-bottom',
              duration: 8000
            })
          }
        }
      })
    },

    editTheOrder (props) {
      // const { _id } = props.row
      // console.log('EDIT ORDER: ', _id)
      this.editOrder = { ...props.row, orderId: props.row._id }
      this.showOrderDialog = true
    }
  },
}
</script>

<style scoped>
#ordersTable, .ordersDataTable {
  /* border: 1px solid red !important; */
  height: 450px !important;
  overflow-y: scroll !important;
}
</style>