
// VENDORS > MUTATIONS

export default {

  setVendors (state, vendors) {
    state.vendors = vendors
  },

  addVendor (state, vendor) {
    state.vendors.push(vendor)
  },

  updateVendor (state, payloadVendor) {
    let index = state.vendors.findIndex(vendor => vendor._id === payloadVendor._id)
    state.vendors.splice(index, 1, payloadVendor)
  },

  removeVendor (state, vendorId) {
    let index = state.vendors.findIndex(vendor => vendor._id === vendorId)
    state.vendors.splice(index, 1)
  }
}