
// SHARED NOTES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchSharedNotes (context) {
    axios.get(`${API_URL}/shared-notes`).then(res => {
      const sharedNotes = res.data
      context.commit('setSharedNotes', sharedNotes)
    }).catch(err => console.error(err.message))
  },

  updateSharedNotes (context, noteData) {
    // console.log('UPDATING SHARED NOTES TO: ', noteData)
    axios.patch(`${API_URL}/shared-notes`, noteData).then(res => {
      const sharedNote = res.data
      console.log('sharedNote ACTION: sharedNote from server: ', sharedNote)
      // console.log('UPDATED SHARED NOTES FROM SERVER: ', sharedNote)
      context.commit('updateSharedNotes', sharedNote)
    })
  }
}