<template>
<div>
  <b-modal
    v-model="open"
  >
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">New Message</p>
      </header>

      <div class="card-content">

        <div>
          {{ users }}
        </div>

        <b-field label="Truck" expanded label-position="on-border">
          <b-input v-model="form.name"></b-input>
        </b-field>

        <b-field label="Message" label-position="on-border">
          <b-input type="textarea" v-model="form.message" :rows="5" />
        </b-field>
      </div>

      <footer class="card-footer" style="display: flex; justify-content: right; padding: 8pt;">
        <b-button type="is-primary" @click="$emit('closeMessageDialog')">Close</b-button>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {

  props: [ 'open', 'users' ],

  emits: [ 'closeMessageDialog' ],

  data: () => ({
    form: {
      name: 'Justin',
      message: 'message bound properly'
    }
  }),
}
</script>

<style lang="scss" scoped>
</style>