<template>
<div>
  <b-modal v-model="show" :width="640" scroll="keep">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title">New Driver</p>
      </div>
      <div class="card-content">
        <div class="content">
          
          <!-- NEW DRIVER FORM -->
          <form>
            <b-field grouped>
              <b-field label="First Name" label-position="on-border">
                <b-input v-model="driver.firstName" autofocus></b-input>
              </b-field>

              <b-field label="Last Name" label-position="on-border">
                <b-input v-model="driver.lastName"></b-input>
              </b-field>

              <b-field label="Default Truck #" label-position="on-border">
                <b-input v-model="driver.defaultTruck"></b-input>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field label="End Dump Pay/Ton" label-position="on-border">
                <b-input v-model="driver.endDumpPayRate"></b-input>
              </b-field>

              <b-field label="Flat Bed Pay/Ton" label-position="on-border">
                <b-input v-model="driver.flatBedPayRate"></b-input>
              </b-field>

              <b-field label="NC Rate" label-position="on-border">
                <b-input v-model="driver.ncRate"/>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field label="Date Hired" label-position="on-border">
                <b-datepicker
                  v-model="driver.dateHired"
                  :show-week-number="false"
                  :locale="undefined"
                  placeholder="Date Hired"
                  icon="calendar-today"
                  trap-focus
                ></b-datepicker>
              </b-field>

              <b-field label="Date Released" label-position="on-border">
                <b-datepicker
                  v-model="driver.dateReleased"
                  :show-week-number="false"
                  :locale="undefined"
                  placeholder="Date Released"
                  icon="calendar-today"
                  trap-focus
                ></b-datepicker>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-button 
                class="saveBtn"
                icon-left="content-save"
                @click="createDriver"
                >
                  Create Driver
              </b-button>

              <b-button
                class="clearBtn"
                @click="clearForm"
              >
                Clear
              </b-button>

              <b-button
                style="margin-left: 10pt;"
                type="is-dark"
                @click="cancelModal"
              >
                Close
              </b-button>

            </b-field>
          </form>

        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show' ],

  emits: [ 'closeModal' ],

  data: () => ({
    driver: {
      firstName: '',
      lastName: '',
      defaultTruck: '',
      endDumpPayRate: '',
      flatBedPayRate: '',
      ncRate: '',
      isActive: true,
      // dateHired: '',
      // dateReleased: '',
      dateCreated: new Date(),
      driverChosen: false
    }
  }),

  computed: {
    driverChosen () {
      if (this.driver.firstName !== '') {
        // console.log('Driver is: ' + this.driver.firstName)
        return true
      } else {
        // console.log('Driver is: ' + this.driver.firstName)
        return false
      }
    }
  },

  methods: {
    cancelModal () {
      this.$emit('closeModal')
    },

    clearForm () {
      this.driver = {
        firstName: '',
        lastName: '',
        defaultTruck: '',
        endDumpPayRate: '',
        flatBedPayRate: '',
        ncRate: '',
        isActive: true,
        // dateHired: '',
        // dateReleased: '',
        dateCreated: new Date()
      }

      this.$refs.driverFirstName.focus()
    },

    async createDriver () {
      if (!this.driver) {
        console.error('ERROR: Driver form object is falsey...')
        return
      }

      try {
        await this.$store.dispatch('drivers/createDriver', this.driver)
        this.$buefy.snackbar.open(`Driver created successfully!`)

        // Clear form and close modal
        this.driver = {
          firstName: '',
          lastName: '',
          defaultTruck: '',
          endDumpPayRate: '',
          flatBedPayRate: '',
          ncRate: '',
          isActive: true,
          dateCreated: new Date()
        }
        this.$emit('closeModal')
      } catch (error) {
        console.error(error.message)
      }
    }
  },

  mounted () {
    if (this.$refs.driverFirstName) {
      this.$refs.driverFirstName.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

form * {
  margin-bottom: 10pt;
}
.saveBtn {
  background-color: $action-btn;
  color: white;
  font-weight: bold;
  margin-right: 20pt;
  border: 0;

  &:hover {
    background-color: $action-btn-hover !important;
    color: white;
    border: 0;
  }

  &:focus {
    color: white;
  }
}

.closeBtn {
  background-color: rgb(201, 201, 201);
  color: white;
  // font-weight: bold;
  border: 0;
  margin-left: 20pt;

  &:hover {
    background-color: rgb(161, 161, 161) !important;
    color: white;
    border: 0;
  }

  &:focus {
    color: white;
  }
}

.clearBtn {
  background-color: rgb(138, 138, 138);
  color: white;
  // font-weight: bold;
  border: 0;
  margin-left: 10pt;

  &:hover {
    background-color: rgb(122, 122, 122) !important;
    color: white;
    border: 0;
  }

  &:focus {
    color: white;
  }
}
</style>
