<template>
<div>
    <!-- <SiteHeader/> -->

    <Navbar/>

    <!-- <v-main> -->
    <SharedNotes id="shared-notes" />
    <router-view></router-view>
    <!-- </v-main> -->
    <footer class="no-print" id="copyrightFooter">
      
      <!-- <div class="level">
        <div class="level-left">
          <div style="text-align: center;">
            <small style="color: white;">&copy; Copyright {{ currentYear }}</small>
          </div>
        </div>
        <div class="level-right">
          <small style="color: white">Designed by Justin Cox</small>
        </div>
      </div> -->
      <div id="copyrightBanner" class="no-print">
        <div style="text-align: center;">
          <small style="color: white;">&copy; Copyright 2010-{{ currentYear }}</small><br/>
          <small style="color: white;">Designed by Justin Cox</small>
        </div>
      </div>
    </footer>
</div>
</template>

<script>
// import SiteHeader from '@/components/layout/SiteHeader'
import Navbar from '@/components/ui/Navbar'
import SharedNotes from '@/components/office/sharedNotes/sharedNotesSidebar'
export default {
  name: 'App',

  components: {
    Navbar,
    SharedNotes
  },

  data: () => ({
    drawer: false,
    group: null,
    settingsMenuItems: [],
    authenticated: false
  }),

  async created () {
    await this.isAuthenticated()
    this.$auth.authStateManager.subscribe(this.isAuthenticated)
  },

  mounted () {
    // this.setRequestsLoading(true)
    this.$store.dispatch('callListRequests/fetchCallListRequests')
    this.$store.dispatch('deliveries/fetchDeliveries')
  },

  watch: {
    group () {
      this.drawer = false
    },
    // Everytime the route changes, check for auth status
    '$route': 'isAuthenticated'
  },

  methods: {
    async isAuthenticated () {
      this.authenticated = await this.$auth.isAuthenticated()
    },
    
    async login () {
      await this.$auth.signInWithRedirect()
    },

    async logout () {
      await this.$auth.signOut()
    },

    countDeliveriesPending () {
      if (this.deliveries) {
        let dlvPending = this.deliveries.filter(dlv => dlv.completed === false)
        return dlvPending.length
      } else {
        return 0
      }
    },
  },

  computed: {

    currentYear () {
      return new Date().getFullYear()
    },

    activeCallListRequests () {
      return this.$store.getters['callListRequests/allActiveRequests']
    },

    deliveries () {
      return this.$store.getters['deliveries/allDeliveries']
    },

    countRequestsIn () {
      let requests = this.activeCallListRequests
      let requestsIn = []

      if (requests && requests.length > 0) {
        for (let i=0; i<requests.length; i++) {
          // // console.log('Call List Request Item: ', requests[i])
          if (requests[i].status === 'waitingmaterial') {
            let mat = this.$store.getters['materials/materialById'](requests[i].material)
            if (mat) {
              if (mat.stock === 'new' || mat.stock === 'in' || mat.stock === 'low') {
                requestsIn.push(requests[i])
              }
            } else {
              /* // console.error('countRequestsIn(): ERROR. mat returned empty, null, or falsey..') */
            }
          }
        }

        this.$store.dispatch('callListRequests/setNumRequestsReady', requestsIn.length)
        return requestsIn.length
      } else if (requests.length === 0) {
        return 0
      } else {
        // console.log('WARNING: activeCallListRequests empty or is falsey..')
        return 0
      }
    }
  }
};
</script>


<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Lalezar&family=Nunito:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');

@import "@/styles/_variables.scss";

* {
  box-sizing: border-box;
}
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  width: 100%;
  height: 100% !important;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 12pt;
}

.detail-container {
  padding: 0 !important;
}

#copyrightFooter {
  background-color: #353535;
  padding: 20pt;
  // border-top: 1px dashed #757575;
}

#copyrightBanner {
  display: flex;
  justify-content: center;
  font-size: 10pt;
}

#shared-notes {
  display: fixed;
  top: 0;
  left: 0;
  z-index: 50;
}

.modal.has-overflow {
  position: fixed !important;
  overflow: auto !important;
  .modal-background {
    position: fixed !important; // so page stays dark as we scroll
  }
  .modal-content, .modal-card, .modal-card-body {
    overflow: visible !important;
  }
}







// #region DESK RELATED
.deskHeader {
  background-color: #DFEAF4 !important;
  color: black !important;
}
.deskBtn {
  font-weight: bold !important;
  color: white !important;
  background-color: #5F9AC6 !important;
}
.deskManageBtn {
  margin-right: 8pt !important;

  &:hover {
    background-color: #4378a0 !important;
    color: white !important;
  }
}
.deskActionBtn {
  &:hover {
    background-color: #4378a0 !important;
    color: white !important;
  }
}

.deskCardTitle {
  color: #57829A !important;
  font-size: 12pt !important;
}
// #endregion

/* #region UTILITY CLASSES */
.light, .white, .text-white {
  color: white;
}
.center {
  text-align: center;
}
.bold {
  font-weight: bold;
}
/* #endregion */



.top-wrapper {
  background-color: rgb(226, 226, 226);
  height: 100%;
  width: 100%;
}


.ch-hero {
  background-color: $primary;
}
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}






/* #region SITE-WIDE STYLES */
.btn-primary {
  background-color: #CEA433 !important;
  color: white !important;
  font-weight: bold !important;
}
/* #endregion */

.v-application {
  line-height: inherit !important;
}
#site-nav .p-menubar {
  padding: 0.25em 0;
  border-radius: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 12pt;
}
#site-nav .p-menubar-root-list {
  /* border: 1px solid red; */
  margin: 0 auto;
}
.container {
  max-width: 80%;
  margin: 0 auto;
}
.container.slim {
  max-width: 94%;
  margin: 0 auto;
}
.pageContainer {
  margin-top: 115pt !important;
}
.sectionHeader-title {
  color: #695542;
}
.sectionHeader-content {
  color: #988E80;
}
#the-dialog .p-dialog-header,
#new-haul-dialog .p-dialog-header, 
#edit-haul-dialog .p-dialog-header,
#new-vendor-dialog .p-dialog-header,
#new-delivery-dialog .p-dialog-header,
#new-material-dialog .p-dialog-header,
#haul-report-reminder-dialog .p-dialog-header,
#new-vendorProduct-dialog .p-dialog-header
{
  background-color: #48A57E;
  color: white;
}
#the-dialog .p-dialog-header span,
#new-haul-dialog .p-dialog-header span,
#edit-haul-dialog .p-dialog-header span, 
#new-vendor-dialog .p-dialog-header span,
#new-delivery-dialog .p-dialog-header span,
#new-material-dialog .p-dialog-header span,
#haul-report-reminder-dialog .p-dialog-header span,
#new-vendorProduct-dialog .p-dialog-header span 
{
  color: white;
}

.materialCard {
  color: white !important;
}
.materialCard .p-card-body {
  padding: 0 !important;
}
.materialCard .p-card-content {
  padding: 1em 0.5em !important;
}
.materialCard .p-card-footer {
  background-color: #1f1f1f;
  padding: 0.6em 0.5em !important;
}
.materialCard .p-card-title {
  background-color: rgb(143, 120, 109);
  position: relative;
  top: -5px;
  padding: 0.3em;
}
.materialCard.stock-new {
  background-color: #6a976a;
}
.materialCard.stock-in {
  background-color: #635047;
}
.materialCard.stock-low {
  background-color: #d49460;
}
.materialCard.stock-out {
  background-color: #bd4242;
}
.materialCard.stock-notavail {
  background-color: #919191;
}
#reportContainerSection {
  font-size: 12pt;
  font-family: sans-serif;
  margin: 4.5em auto 0;
  width: 92%;
}
#reportContainerSection td {
  font-size: 12pt;
  font-family: sans-serif;
  padding-top: 6px;
  padding-bottom: 6px;
}
#haulsReportDTable td, #workdayReportDTable td {
  font-size: 10pt;
}


/* Referenced in SoilProduct.vue [Landing]  */
.soilProduct-image {
  filter: brightness(100%) !important;
  // border: 10px solid rgb(255, 4, 46);
}
.soilProduct-name {
  background-color: rgba(136, 64, 36, 0.9) !important;
  // border: 10px solid rgb(255, 4, 46);
  // color: red !important;
  width: 100%;
}

.selected-soil-test {
  filter: brightness(100%);
}

.selected-soil-test img {
  filter: brightness(100%);
  // border: 5px solid orange;
}
/* --------------------------------------- */

/* TEST ------ */
/* #site-main-sheet {
  background-color: rgb(219, 217, 215);
  height: 100%;
} */
/* #overflowBtn {
  border: 2px solid red !important;
} */
/* END TEST (delete if not needed) */
@media print
{    
  .no-print, .no-print *
  {
    display: none !important;
  }
}
</style>