
// MATERIALS > MUTATIONS

export default {

  setMaterials (state, materials) {
    state.materials = materials
  },

  addMaterial (state, material) {
    // console.log('inside addMaterial mutation. Material to add to store: ')
    // console.log(material)
    state.materials.push(material)
  },

  updateMaterial(state, payloadMaterial) {
    let index = state.materials.findIndex(material => material._id === payloadMaterial._id)
    state.materials.splice(index, 1, payloadMaterial)
  },

  updateMaterialStock (state, info) {
    let material = state.materials.find(mat => mat._id === info.id)
    material.stock = info.stock
  },

  removeMaterial (state, materialId) {
    let index = state.materials.findIndex(material => material._id === materialId)
    state.materials.splice(index, 1)
  },

  setMaterialToEdit (state, material) {
    state.materialToEdit = material
  },

  updateFeaturedById (state, updatedMat) {
    // console.log('[Materials MUTATIONS.js]: updatedMat: ', updatedMat)
    let index = state.materials.findIndex(material => material._id === updatedMat._id)
    state.materials[index].featured = updatedMat.featured
  }
}