<template>
<div class="top-wrapper">

  <CustHeaderComp title="Site Settings"/>

  <StoreOpenSettings/>

  <DeliverySettings/>

  <StoreHours />

  <CustomerHeader/>

</div>
</template>

<script>
import StoreOpenSettings from './StoreOpenSettings'
import DeliverySettings from './DeliverySettings'
import StoreHours from './StoreHours'
import CustomerHeader from './CustomHeader'
import CustHeaderComp from './CustomHeaderComp'

export default {

  components: {
    StoreOpenSettings,
    DeliverySettings,
    StoreHours,
    CustomerHeader,
    CustHeaderComp
  },

  data: () => ({
    claims: null
  }),

  mounted () {
    // Hide copyright banner
    this.$store.dispatch('siteSettings/fetchSettings')
    document.getElementById('copyrightFooter').style.display = 'none'
  },
}
</script>

<style lang="scss" scoped>
#settingsHero {
  position: relative;
  background: #021f4f;
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../../assets/gears.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position-y: -140pt;
  z-index: 10;
}
</style>