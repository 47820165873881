
// OFFICE > PAYRATES > MUTATIONS

export default {
  setPayRates (state, payRates) {
    state.payrates = payRates
  },

  addPayRate (state, payRate) {
    state.payrates.push(payRate)
  }
}