<template>
<div>
  <section style="padding: 20pt;">
    <p style="font-weight: bold;">Loading...</p>
    <b-skeleton width="20%" :animated="true"></b-skeleton>
    <b-skeleton width="40%" :animated="true"></b-skeleton>
    <b-skeleton width="80%" :animated="true"></b-skeleton>
    <b-skeleton width="30%" :animated="true"></b-skeleton>
    <b-skeleton :animated="true"></b-skeleton>
  </section>
</div>
</template>

<script>
export default {}
</script>