import Vue from 'vue'
import Vuex from 'vuex'
import categories from '../store/categories'
import materials from '../store/materials'
import hauls from '../store/office/hauls'
import tasks from '../store/office/tasks'
import tasklists from '../store/office/tasklists'
import drivers from '../store/office/drivers'
import vendors from '../store/office/vendors'
import vendorSites from '../store/office/vendorSites'
import freightRoutes from '../store/office/freightRoutes'
import vendorProducts from '../store/office/vendorProducts'
import deliveries from '../store/office/deliveries'
import deliveryCustomers from '../store/office/deliveryCustomers'
import workdays from '../store/office/workdays'
import payrates from '../store/office/payrates'
import carriers from '../store/office/carriers'
import carports from '../store/office/carports'
import billprep from './office/billprep'
import materialTags from './office/materialtags'
import siteMessages from './office/siteMessages'
import callListRequests from './office/callListRequests'
import gallery from './office/gallery'
import faqs from './office/faqs'
import profile from './office/profile'
import siteSettings from './office/siteSettings'
import scratchpad from './office/scratchpad'
import messages from './office/messages'
import orders from './office/orders'
import sharedNotes from './office/sharedNotes'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: { 
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    categories: categories,
    materials: materials,
    materialTags: materialTags,
    tasks: tasks,
    tasklists: tasklists,
    hauls: hauls,
    drivers: drivers,
    vendors: vendors,
    vendorSites: vendorSites,
    freightRoutes: freightRoutes,
    vendorProducts: vendorProducts,
    deliveries: deliveries,
    deliveryCustomers: deliveryCustomers,
    billprep: billprep,
    workdays: workdays,
    payrates: payrates,
    carriers: carriers,
    carports: carports,
    siteMessages: siteMessages,
    callListRequests: callListRequests,
    gallery: gallery,
    faqs: faqs,
    profile: profile,
    siteSettings: siteSettings,
    scratchpad: scratchpad,
    messages: messages,
    orders: orders,
    sharedNotes: sharedNotes
  }
})
