
// DRIVERS > MUTATIONS

export default {
  updateDriver (state, payload) {
    let index = state.drivers.findIndex(driver => driver._id === payload._id)
    state.drivers.splice(index, 1, payload)
  },

  addDriver (state, driver) {
    // state.drivers.push(driver)
    state.drivers = [
      ...state.drivers,
      driver
    ]
  },

  setDrivers (state, drivers) {
    state.drivers = drivers
  },

  setCurrentDriver (state, driver) {
    // console.log('inside setCurrentDriver mutation')
    state.currentDriver = driver
  },

  showActiveDrivers (state) {
    state.drivers = state.drivers.filter(driver => driver.isActive === true)
  },

  showAllDrivers (state) {
    return state.drivers
  },

  removeDriver (state, driver) {
    let index = state.drivers.findIndex(drv => drv._id === driver._id)
    state.drivers.splice(index, 1)
  }
}