<template>
  <div>

    <VendorSitesDialog :show="showVendorSitesDialog" :edit="edit" :copyVendorChosen="vendorChosen" :vendorSiteCopy="vendorSite" :vendors="vendors" @closeVendorSitesDialog="closeVendorSitesDialog" />

    <b-button type="is-primary" class="newVendorSiteButton" icon-left="plus" @click="openVendorSitesDialog(null)">New</b-button>

    <br/><br/>

    <!-- LIST OF VENDOR SITES -->
    <b-table
      v-if="vendorChosen"
      class="vendor-sites-table"
      :data="filteredVendorSites || []"
      :loading="vendorSitesLoading"
    >
      <b-table-column label="Site Name" field="name" v-slot="props">
        {{ props.row.name }}
      </b-table-column>

      <b-table-column label="Vendor" field="vendorId" v-slot="props">
        {{ vendorNameById(props.row.vendorId) }}
      </b-table-column>

      <b-table-column label="Action" v-slot="props">
        <b-button type="is-primary" size="is-small" icon-left="pencil" @click="openVendorSitesDialog(props.row)" class="mr-2"/>
        <b-button type="is-danger" size="is-small" icon-left="delete" @click="confirmDeleteVendorSite(props.row._id)"/>
      </b-table-column>
    </b-table>

    <div v-else>
      <p class="no-vendor-chosen-instructions">Choose a vendor, on the left, to see a list of sites</p>
    </div>
  </div>
  </template>
  
  <script>
  import VendorSitesDialog from './VendorSitesDialog'

  export default {
    props: ['vendorChosen'],
    components: {
      VendorSitesDialog
    },
    data: () => ({
      vendorSite: null,
      edit: false,
      vendorsLoading: false,
      vendorSitesLoading: false,
      showVendorSitesDialog: false
    }),
  
    mounted () {
      this.setVendorsLoading(true)
      this.$store.dispatch('vendors/fetchVendors')
    },
  
    computed: {
      vendors () {
        const vendors = this.$store.getters['vendors/allVendors']
        this.setVendorsLoading(false)
        // Return vendors sorted alphabetically
        return vendors.sort((a, b) => a.name.localeCompare(b.name))
      },

      vendorSites () {
        return this.$store.getters['vendorSites/allVendorSites']
      },

      filteredVendorSites () {
        if (!this.vendorChosen) {
          return []
        }
        const sites = this.vendorSites.filter(site => site.vendorId === this.vendorChosen._id)
        // console.log('Sites: ')
        // console.log(sites)
        return sites
      }
    },
  
    methods: {
      setVendorsLoading (val) {
        this.vendorsLoading = Boolean(val)
      },

      vendorNameById (vendorId) {
        const vendor = this.vendors.find(vendor => vendor._id === vendorId)
        return vendor.name
      },

      openVendorSitesDialog (vendorSite) {
        if (!vendorSite) {
          this.edit = false
        } else {
          // this.vendorSite = vendor
          // console.log('[openVendorSitesDialog] vendorSite argument: ')
          // console.log(vendorSite)
          this.vendorSite = vendorSite
          this.edit = true
        }

        this.showVendorSitesDialog = true
      },

      closeVendorSitesDialog () {
        this.showVendorSitesDialog = false
      },

      selectVendor (vendor) {
        this.vendorChosen = vendor
      },

      async confirmDeleteVendorSite (vendorSiteId) {
        // console.log(vendorSiteId)
        let confirm = window.confirm('You are about to permanently delete this Vendor Site! Continue?')
        if (confirm) {
          try {
            await this.$store.dispatch('vendorSites/deleteVendorSite', vendorSiteId)
            this.$buefy.toast.open({
              message: 'Vendor site deleted!',
              position: 'is-bottom',
              type: 'is-success',
            })
          } catch (error) {
            console.log(error)
            this.$buefy.toast.open({
              message: 'Error deleting Vendor Site! Check logs',
              position: 'is-bottom',
              type: 'is-danger',
              duration: 8000
            })
          }
        } else {
          // do nothing
        }
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .vendor-sites-table {
      margin-top: 0.5em;
    }
    .newVendorSiteButton {
      background-color: rgb(96, 76, 111);
    }
    .newVendorSiteButton:hover {
      background-color: rgb(124, 98, 143);
    }
    .no-vendor-chosen-instructions {
      font-style: italic;
    }
  </style>
  