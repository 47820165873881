
// SCRATCHPAD > MUTATIONS

export default {
  setPads (state, pads) {
    state.pads = pads
  },

  addPad (state, pad) {
    state.pads.push(pad)
  },

  updatePad (state, payloadPad) {
    let index = state.pads.findIndex(p => p._id === payloadPad._id)
    state.pads.splice(index, 1, payloadPad)
  },

  removePad (state, delPad) {
    let index = state.pads.findIndex(p => p._id === delPad._id)
    state.pads.splice(index, 1)
  }
}