
// DRIVERS > ACTIONS

import axios from 'axios'
// import dayjs from 'dayjs'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchDrivers (context) {
    axios.get(`${API_URL}/drivers`).then(res => {
      let drivers = res.data

      for (let i=0; i < drivers.length; i++) {
        drivers[i].dateHired = new Date(drivers[i].dateHired)
        drivers[i].dateReleased = new Date(drivers[i].dateReleased)
        drivers[i].dateCreated = new Date(drivers[i].dateCreated)
      }

      let alphaDrivers = drivers.sort((a, b) => (a.firstName > b.firstName) ? 1 : -1)
      context.commit('setDrivers', alphaDrivers)
    }).catch(err => console.error(err.message))
  },

  fetchActiveDrivers (context) {
    axios.get(`${API_URL}/drivers`).then(res => {
      let drivers = res.data
      let activeDrivers = drivers.filter(driver => driver.isActive === true)
      context.commit('setDrivers', activeDrivers)
    }).catch(err => console.error(err.message))
  },

  setCurrentDriver (context, driver) {
    // console.log('inside setCurrentDriver action')
    context.commit('setCurrentDriver', driver)
  },

  setDrivers (context, drivers) {
    context.commit('setDrivers', drivers)
  },

  saveDriver (context, driver) {
    axios.patch(`${API_URL}/drivers/${driver._id}`, driver).then(savedDriver => {
      context.commit('updateDriver', savedDriver.data)
    })
  },

  createDriver (context, driver) {
    axios.post(`${API_URL}/drivers`, driver).then(driver => {
      context.commit('addDriver', driver.data)
    })
  },

  setActiveDrivers (context ) {
    context.commit('showActiveDrivers')
  },

  setAllDrivers (context) {
    axios.get(`${API_URL}/drivers`).then(res => {
      let drivers = res.data
      context.commit('setDrivers', drivers)
    }).catch(err => console.error(err.message))
  },

  deleteDriver (context, driverId) {
    axios.delete(`${API_URL}/drivers/${driverId}`).then(res => {
      const driver = res.data
      context.commit('removeDriver', driver)
    })
  }
}