
// VENDOR PRODUCTS > index.js

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state () {
    return {
      vendorProducts: [],
      pay_rates: []
    }
  },
  actions,
  getters,
  mutations
}