<template>
<div class="top-wrapper">

  <NewDriverModal :show="newDriverModalActive" @closeModal="closeNewDriverModal"/>

  <CustHeaderComp title="Drivers"/>

  <section class="section">
    <div class="columns">

      <div class="column is-4">
        <!-- CHOOSE DRIVER CARD -->
        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Choose Driver</p>
          </header>

          <div class="card-content">
            <div class="level">
              <div class="level-left">
                <b-field grouped>
                  <b-field label="Only show active"></b-field>
                  <b-field>
                    <b-switch class="chPrimary" v-model="showingActive" :disabled="!driverChosen"></b-switch>
                  </b-field>
                </b-field>
              </div>
              <div class="level-right">
                <b-field>
                  <b-button 
                    class="btn-newDriver"
                    icon-left="account-plus"
                    @click="showNewDriverModal"
                  >
                    New Driver
                  </b-button>
                </b-field>
              </div>
            </div>
            <b-menu v-if="!loading" class="chList">
              <b-menu-list class="chList">
                <b-menu-item 
                  :icon="determineDriverIcon(driver.isActive)"
                  v-for="driver in drivers" 
                  :key="driver._id" 
                  :label="driverFullName(driver)"
                  @click="selectDriver(driver)"
                ></b-menu-item>
              </b-menu-list>
            </b-menu>
            <SkeletonLoader v-else />
          </div>
        </div>
      </div>

      <div class="column">
        <div class="card">

          <div class="card-header" id="driverInfoHeader">
            <p class="card-header-title">Driver Information</p>
          </div>

          <div v-if="!loading" class="card-content driverList">

            <b-field label="Status">
              <b-switch v-model="driver.isActive">{{ activeStatus(driver.isActive) }}</b-switch>
            </b-field>

            <b-field grouped>
              <b-field label="First Name" label-position="on-border">
                <b-input v-model="driver.firstName" :disabled="!driverChosen"></b-input>
              </b-field>

              <b-field label="Last Name" label-position="on-border">
                <b-input v-model="driver.lastName" :disabled="!driverChosen"></b-input>
              </b-field>

              <b-field label="Default Truck #" label-position="on-border">
                <b-input v-model="driver.defaultTruck" :disabled="!driverChosen"></b-input>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field label="End Dump Pay/Ton" label-position="on-border">
                <b-input v-model="driver.endDumpPayRate" :disabled="!driverChosen"></b-input>
              </b-field>

              <b-field label="Flat Bed Pay/Ton" label-position="on-border">
                <b-input v-model="driver.flatBedPayRate" :disabled="!driverChosen"></b-input>
              </b-field>

              <b-field label="NC Rate" label-position="on-border">
                <b-input v-model="driver.ncRate" :disabled="!driverChosen"/>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field label="Date Hired" label-position="on-border">
                <b-datepicker
                  v-model="driver.dateHired"
                  :show-week-number="false"
                  :locale="undefined"
                  placeholder="Date Hired"
                  icon="calendar-today"
                  :disabled="!driverChosen"
                  trap-focus
                ></b-datepicker>
              </b-field>

              <b-field label="Date Released" label-position="on-border">
                <b-datepicker
                  v-model="driver.dateReleased"
                  :show-week-number="false"
                  :locale="undefined"
                  placeholder="Date Released"
                  icon="calendar-today"
                  :disabled="!driverChosen"
                  trap-focus
                ></b-datepicker>
              </b-field>
            </b-field>

            <b-field>
              <div class="level">
                <div class="level-left">
                  <b-button 
                    class="saveBtn"
                    icon-left="content-save"
                    @click="saveDriver"
                    :disabled="!driverChosen"
                    >
                      Save
                  </b-button>
                </div>
                <div class="level-right">
                  <b-button
                    type="is-secondary"
                    icon-left="delete"
                    @click="confirmDeleteDriver(driver._id)"
                    :disabled="!driverChosen"
                  >Delete</b-button>
                </div>
              </div>
            </b-field>
            
          </div>

          <SkeletonLoader v-else />
        </div>

      </div>
    </div>
  </section>
</div>
</template>

<script>
import NewDriverModal from '@/views/office/Drivers/NewDriverModal'
import SkeletonLoader from '@/components/ui/SkeletonLoader'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'

export default {

  components: {
    NewDriverModal,
    SkeletonLoader,
    CustHeaderComp
  },

  data: () => ({
    loading: true,
    showingActive: true,
    newDriverModalActive: false,
    driverChosen: false,
    driver: {
      firstName: '',
      lastName: '',
      defaultTruck: '',
      endDumpPayRate: '',
      flatBedPayRate: '',
      ncRate: '',
      isActive: null,
      // dateHired: '',
      // dateReleased: '',
      // dateCreated: ''
    }
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  computed: {

    // driverChosen () {
    //   if (this.driver && this.driver.firstName !== null && this.driver.firstName !== '' && this.driver.firstName !== undefined) {
    //     return false
    //   } else {
    //     return true
    //   }
    // },

    drivers: {
      get () {
        if (this.showingActive) {
          return this.$store.getters['drivers/allDrivers'].filter(d => d.isActive)
        } else {
          return this.$store.getters['drivers/allDrivers']
        }
      },

      set (val) {
        this.$store.dispatch('drivers/setDrivers', val)
      }
    }
  },

  watch: {
    drivers () {
      if (this.drivers.length > 0) {
        this.loading = false
      }
    }

    
  },

  methods: {

    confirmDeleteDriver (driverId) {
      this.$buefy.dialog.confirm({
        title: 'Delete Driver',
        message: `Are you sure you want to <strong>permanently delete</strong> ${this.driver.firstName} ${this.driver.lastName}? This action cannot be undone!`,
        confirmText: 'Delete Driver',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => { this.deleteDriver(driverId) }
      })
    },

    deleteDriver (driverId) {
      this.$store.dispatch('drivers/deleteDriver', driverId)
    },

    closeNewDriverModal () {
      this.newDriverModalActive = false
    },

    showNewDriverModal () {
      this.newDriverModalActive = true
    },

    // testCalendar () {
    //   console.log('Test cal: Wed Jun 02 2021 00:00:00 GMT-0500 (Central Daylight Time)')
    //   const strDate = 'Wed Jun 02 2021 00:00:00 GMT-0500 (Central Daylight Time)'
    //   const today = new Date()
    //   const theDate = new Date(strDate)
    //   console.log('theDate: ' + theDate)
    //   console.log('typeof: ' + typeof(theDate))
    //   console.log('today: ' + today)
    //   console.log('typeof today: ' + typeof(today))
    // },

    async saveDriver () {
      if (this.driver === null || this.driver === undefined || this.driver === '') {
        console.error('ERROR: Driver not selected or has a falsey value...')
        return
      }

      try {
        await this.$store.dispatch('drivers/saveDriver', this.driver)
        this.$buefy.toast.open({
          message: 'Driver updated!',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while updating...',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 4000
        })
      }

    },

    determineDriverIcon (status) {
      return status ? 'account' : 'account-cancel-outline'
    },

    selectDriver (driver) {
      // console.log('Driver chosen: ')
      this.driverChosen = true
      // console.log(driver)
      if (!driver) {
        return
      }
      this.driver = { ...driver }
    },

    activeStatus (status) {
      return status ? 'Driver Active' : 'Driver Inactive'
    },

    driverFullName (driver) {
      return driver.firstName + ' ' + driver.lastName
    }
  },

  async created () {
    await this.$store.dispatch('drivers/fetchDrivers')
    this.loading = false
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

// ALWAYS SHOW SCROLLBAR (macOS)
::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}
// -----------------------------------------------

// .top-wrapper {
//   background-color: rgb(226, 226, 226);
// }

.saveBtn {
  background-color: $action-btn;
  color: white;
  font-weight: bold;
  border: 0;

  &:hover {
    background-color: $action-btn-hover !important;
    color: white;
    border: 0;
  }

  &:focus {
    color: white;
  }
}

.btn-newDriver {
  background-color: $action-btn;
  color: white;
  font-weight: bold;
  border: 0;

  &:hover {
    background-color: $action-btn-hover !important;
    color: white;
    border: 0;
  }

  &:focus {
    color: white;
  }
}

.chList {
  overflow-y: scroll;
  max-height: 400px;
  padding: 10pt;
  border: 1px solid #e2e2e2;
}

.driverList * {
  margin-bottom: 20pt;
}

#driverInfoHeader {
  background-color: #726057;
}
</style>