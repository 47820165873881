
// VENDORSITES > GETTERS

export default {
  allVendorSites (state) {
    return state.vendorSites
  },

  vendorSiteById:  (state) => (vendorSiteId) => {
    return state.vendorSites.filter(vendorSite => vendorSite._id === vendorSiteId)
  },

  vendorSiteByName: (state) => (name) => {
    return state.vendorSites.find(vendorSite => vendorSite.name === name)
  }
}