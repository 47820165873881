<template>
<div>
  <b-modal v-model="show" :width="1260" scroll="keep">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title" v-if="isNewMaterial">Create New Material</p>
        <p class="card-header-title" v-else>Edit {{ material.name }}</p>
      </div>

      <div class="card-content">
        <div class="media">
          
          <!-- IMAGE COLUMN -->
          <div class="media-left">
            <b-field class="file is-primary" :class="{'has-name': !!imageFile}">
              <label v-if="isNewMaterial" for="image" style="margin-right: 10pt;">Upload Image: </label>
              <label v-else for="image" class="p-mr-3">Upload a different Image: </label>
              <input type="file" name="image" id="image" @change="convertToBase64AndDisplay()" placeholder="Image"/>

              <!-- <b-upload v-model="imageFile" class="file-label">
                <span class="file-cta">
                  <b-icon class="file-icon" icon="upload"></b-icon>
                  <span class="file-label">Click to upload image</span>
                </span>
                <span class="file-name" v-if="imageFile">
                  {{ imageFile.name }}
                </span>
              </b-upload> -->
            </b-field>

            <figure class="image is-square">
              <img :src="imagePreviewSrc" style="max-width: 90%; max-height: 90%;">
            </figure>

            <b-tag v-if="showNewImageWarning"
              type="is-danger"
              closable
              aria-close-label="Close tag"
              @close="showNewImageWarning = false">
              You've selected a new image, don't forget to save these changes!
            </b-tag>
          </div>

          <!-- CONTENT COLUMN -->
          <div class="media-content">

            <p class="notice"></p>
            <b-message type="is-warning is-small" aria-close-label="Close message">
              Leave a field blank if it's not applicable, aside from required fields
            </b-message>

            <b-message v-if="serverError" type="is-danger is-small" aria-close-label="Close message">
              Oops! There seems to be a problem with the request sent to the server. Contact the developer if the problem persists.
            </b-message>

            <b-field grouped>
              <b-field label="Name" expanded label-position="on-border">
                <b-input v-model="material.name" ref="matName" required autofocus></b-input>
              </b-field>

              <b-field label="Category" label-position="on-border">
                <b-select 
                  v-model="material.category"
                  required
                >
                  <option 
                    v-for="category in categories" 
                    :key="category._id"
                  >
                    {{ category.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field label="Bin #" label-position="on-border">
                <b-input v-model="material.binNumber"></b-input>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field label="Size" label-position="on-border">
                <b-input v-model="material.size"></b-input>
              </b-field>

              <b-field label="Current Stock" label-position="on-border">
                <b-select v-model="material.stock" placeholder="Choose...">
                  <option
                    v-for="option in stockOptions"
                    :key="option.value"
                    :value="option.value"
                    required
                  >
                    {{ option.key }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field label="Description" label-position="on-border">
              <b-input v-model="material.description" type="textarea" rows="2"></b-input>
            </b-field>

            <b-field>
              <b-field label="Tags" label-position="on-border">
                <b-taginput
                  v-model="material.keywords"
                  :data="materialTags"
                  autocomplete
                  :allow-new="true"
                  :open-on-focus="false"
                  field="name"
                  icon="label"
                  placeholder="Add a tag"
                  @typing="getTags"
                >
                </b-taginput>
              </b-field>
            </b-field>

            <b-field>
              <b-button type="is-primary" @click="showMaterialTagDialog = true">Memorize a Tag</b-button>
              <MaterialTagDialog :show="showMaterialTagDialog" :isNewMaterialTag="isNewMaterialTag" @closeMaterialTagDialog="closeMaterialTagDialog"/>
            </b-field>

            <br/>

            <b-field label="Notes (private)" label-position="on-border">
              <b-input type="textarea" v-model="material.notes" rows="2"></b-input>
            </b-field>

            <b-field grouped>
              <b-field>
                <b-switch 
                  v-model="material.newMaterial"
                  type="is-primary"
                  style="margin-right: 10pt;"
                >New Product</b-switch>
              </b-field>

              <b-field>
                <b-switch 
                  v-model="material.isFeatured"
                  type="is-info"
                  style="margin-right: 10pt;" 
                >Featured</b-switch>
              </b-field>

              <b-field>
                <b-switch 
                  v-model="material.isTruckable"
                  type="is-info"
                  style="margin-right: 10pt;" 
                >Truckable</b-switch>
              </b-field>

              <b-field>
                <b-switch 
                  v-model="material.isActive"
                  type="is-success" 
                >Public</b-switch>
              </b-field>
            </b-field>

          </div>
        </div>
      </div>

      <footer>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="footer-btns">
              <b-button 
                v-if="isNewMaterial" 
                class="footer-createBtn" 
                icon-left="content-save" 
                @click="createMaterial"
              >
                Create
              </b-button>

              <b-button 
                v-else 
                class="footer-saveBtn" 
                icon-left="content-save" 
                @click="saveMaterial"
              >
                Save
              </b-button>

              <b-button 
                class="footer-clearBtn"
                @click="clearForm"
              >
                Clear
              </b-button>
              <b-button 
                class="footer-closeBtn" 
                icon-left="close-thick" 
                @click="cancelDialog"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
import MaterialTagDialog from '@/views/office/Materials/MaterialTagDialog'

export default {
  props: [ 'show', 'isNewMaterial', 'editMaterial' ],

  emits: [ 'closeMaterialDialog' ],

  components: {
    MaterialTagDialog
  },

  data: () => ({
    tags: [],
    isNewMaterialTag: true,
    showMaterialTagDialog: false,
    imageFile: null,
    imageSrc: '',
    serverError: false,
    showNewImageWarning: false,
    material: {
      name: '',
      category: '',
      image: '',
      binNumber: '',
      size: '',
      description: '',
      keywords: [],
      stock: '',
      notes: '',
      newMaterial: true,
      isFeatured: false,
      isTruckable: false,
      isActive: true
    },
    emptyMaterial: {
      name: '',
      category: '',
      image: '',
      binNumber: '',
      size: '',
      description: '',
      keywords: [],
      stock: '',
      notes: '',
      newMaterial: true,
      isFeatured: false,
      isTruckable: false,
      isActive: true
    },
    stockOptions: [
      { key: 'New Shipment', value: 'new' },
      { key: 'In Stock', value: 'in' },
      { key: 'Low Stock', value: 'low' },
      { key: 'Out of Stock', value: 'out' },
      { key: 'Not Available', value: 'notavail' }
    ]
  }),

  computed: {

    imagePreviewSrc () {
      if (this.imageSrc && this.imageSrc !== null && this.imageSrc !== '' && this.imageSrc !== undefined) {
        return this.imageSrc
      } else {
      return this.material.image
      }
    },
    
    categories () {
      return this.$store.getters['categories/allCategories']
    },

    materialTags () {
      return this.$store.getters['materialTags/allTags']
    }
  },

  methods: {

    cancelDialog () {
      this.material = { ...this.emptyMaterial }
      this.$emit('closeMaterialDialog')
    },

    convertToBase64AndDisplay () {
      // console.log('inside convertToBase64AndDisplay...')
      let imgInput = document.getElementById('image')

      const file = imgInput.files[0],
            reader = new FileReader()

      const that = this
      reader.onloadend = function () {
        let b64 = reader.result.replace(/^data:.+;base64,/, '')
        that.imageBuffer = b64
        that.imageSrc = `data:image/jpg;base64,${that.imageBuffer}`
        that.showNewImageWarning = true
      }
      reader.readAsDataURL(file);
    },

    clearForm () {
      // Confirm clear
      this.$buefy.dialog.confirm({
        title: 'Warning!',
        message: 'Are you sure you want to clear the form? <br/><strong>This action cannot be undone.</strong>',
        confirmText: 'Clear Form',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => {
          // Clear the form and focus on material name field
          this.material = { ...this.emptyMaterial }
          // this.$refs.matName.focus()
        }
      })
    },

    async createMaterial () {
      // console.log('creating...')
      // Create the material object to send to the server
      const matObj = {
        name: this.material.name,
        category: this.material.category,
        binNumber: this.material.binNumber,
        size: this.material.size,
        description: this.material.description,
        keywords: this.material.keywords,
        stock: this.material.stock,
        notes: this.material.notes,
        newMaterial: this.material.newMaterial,
        isFeatured: this.material.isFeatured,
        isTruckable: this.material.isTruckable,
        isActive: this.material.isActive
      }

      if (this.editMaterial) {
        matObj._id = this.editMaterial._id
      }

      // Get a reference to the new image (if chosen)
      const imageFile = document.querySelector('#image').files[0]

      // If a new image exists, continue with request
      if (imageFile) {
        // Get filename
        const fileName = imageFile.name

        // Ask the user if they for sure want to override the existing image
        let confirmNewImage = confirm('WARNING: Selecting OK will OVERRIDE the existing image (if there is one). Selecting CANCEL will update everything except the image')

        if (confirmNewImage) {
          // continue, approve of new image (append to matObj)
          matObj.image = this.imageBuffer
          matObj.imageName = fileName || ''

          // console.log('New material (matObj) to send to server: ')
          // console.log(matObj)
        } else {
          // do nothing; fall through to dispatch without image on matObj
        }
      } else {
        // No new image detected, fall through to dispatch without image on matObj
      }

      // Make the create request
      try {
        await this.$store.dispatch('materials/createMaterial', matObj)
        this.$buefy.toast.open({
          message: `Material created`,
          position: 'is-bottom',
          type: 'is-success'
        })

        // Clear form and close dialog
        this.showNewImageWarning = false
        this.material = { ...this.emptyMaterial }
        this.$emit('closeMaterialDialog')

      } catch (error) {
        console.error(error.message)
        this.serverError = true
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error while trying to create new material! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    async saveMaterial () {
      // console.log('updating material...')

      // Create the material object to send to the server
      const matObj = {
        name: this.material.name,
        category: this.material.category,
        binNumber: this.material.binNumber,
        size: this.material.size,
        description: this.material.description,
        keywords: this.material.keywords,
        stock: this.material.stock,
        notes: this.material.notes,
        newMaterial: this.material.newMaterial,
        isFeatured: this.material.isFeatured,
        isTruckable: this.material.isTruckable,
        isActive: this.material.isActive
      }

      if (this.editMaterial) {
        matObj._id = this.editMaterial._id
      }

      // Get a reference to the new image (if chosen)
      const imageFile = document.querySelector('#image').files[0]

      // If a new image exists, continue with request
      if (imageFile) {
        // Get filename
        const fileName = imageFile.name

        // Ask the user if they for sure want to override the existing image
        let confirmNewImage = confirm('WARNING: Selecting OK will OVERRIDE the existing image (if there is one). Selecting CANCEL will update everything except the image')

        if (confirmNewImage) {
          // continue, approve of new image (append to matObj)
          matObj.image = this.imageBuffer
          matObj.imageName = fileName || ''

          // console.log('Patch object (matObj) to send to server: ')
          // console.log(matObj)
        } else {
          // do nothing; fall through to dispatch without image on matObj
        }
      } else {
        // No new image detected, fall through to dispatch without image on matObj
      }

      // Create the patch request
      try {
        await this.$store.dispatch('materials/updateMaterial', matObj)

        this.$buefy.toast.open({
          message: `Material updated`,
          position: 'is-bottom',
          type: 'is-success'
        })

        // Clear the form and close the modal
        this.showNewImageWarning = false
        this.material = { ...this.emptyMaterial }
        this.$emit('closeMaterialDialog')

      } catch (error) {
        console.error(error.message)
        this.serverError = true
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error while updating material! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    closeMaterialTagDialog () {
      this.showMaterialTagDialog = false
    },

    getTags(text) {
      this.tags = this.materialTags.filter((option) => {
        return option
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    }
  },

  watch: {
    editMaterial () {
      if (this.editMaterial) {
        // console.log('----------')
        // console.log('Material to edit: ')
        // console.log(this.editMaterial)
        this.material = {
          name: this.editMaterial.name,
          category: this.editMaterial.category,
          image: this.editMaterial.image,
          binNumber: this.editMaterial.binNumber,
          size: this.editMaterial.size,
          description: this.editMaterial.description,
          keywords: this.editMaterial.keywords,
          stock: this.editMaterial.stock,
          notes: this.editMaterial.notes,
          newMaterial: this.editMaterial.newMaterial,
          isFeatured: this.editMaterial.isFeatured,
          isTruckable: this.editMaterial.isTruckable,
          isActive: this.editMaterial.isActive,
        }
      }
    }
  },

  created () {
    this.$store.dispatch('categories/fetchCategories')
    this.$store.dispatch('materialTags/fetchTags')
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.notice {
  color: red;
  font-weight: bold;
}

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>