<template>
<div>
  <CustHeaderComp title="Site Messages"/>

  <section class="section is-small">
    <b-button
      type="is-primary"
      icon-left="plus-thick"
      @click="openNewMessageDialog"
    >
      New Message
    </b-button>
    <MessageDialog :show="showMessageDialog" :isNewMessage="isNewMessage" :editMessage="editMessage" @closeSiteMessageDialog="closeSiteMessageDialog"/>
  </section>


  <section class="section">
    <b-table
      :data="isEmpty ? [] : siteMessages"
      :striped="true"
    >
      <!-- TITLE -->
      <b-table-column
        field="title"
        label="Title"
        v-slot="props"
      >
        {{ props.row.title }}
      </b-table-column>

      <!-- MESSAGE -->
      <b-table-column
        field="message"
        label="Message"
        v-slot="props"
      >
        {{ props.row.message }}
      </b-table-column>

      <!-- DATE CREATED -->
      <b-table-column
        field="dateCreated"
        label="Created"
        v-slot="props"
      >
        {{ formatDate(props.row.dateCreated) }}
      </b-table-column>

      <!-- DATE START -->
      <b-table-column
        field="dateStart"
        label="Start Date"
        v-slot="props"
      >
        {{ formatDate(props.row.dateStart) }}
      </b-table-column>

      <!-- DATE END -->
      <b-table-column
        field="dateEnd"
        label="End Date"
        v-slot="props"
      >
        {{ formatDate(props.row.dateEnd) }}
      </b-table-column>

      <!-- PAGE -->
      <b-table-column
        field="page"
        label="Page"
        v-slot="props"
      >
        {{ props.row.page }}
      </b-table-column>

      <!-- ACTIVE -->
      <b-table-column
        field="active"
        label="Active"
        v-slot="props"
      >
        <b-icon icon="check" size="is-small" v-if="props.row.active"></b-icon>
        <div v-else></div>
      </b-table-column>

      <!-- SHOW DATE POSTED (BOOL) -->
      <b-table-column
        field="showPosted"
        label="Show Date Posted"
        v-slot="props"
      >
        <b-icon icon="check" size="is-small" v-if="props.row.showPosted"></b-icon>
        <div v-else></div>
      </b-table-column>

      <!-- EDIT BUTTON -->
      <b-table-column
        label="Edit"
        v-slot="props"
      >
        <div style="display: flex; justify-content: space-between">
          <b-button 
            type="is-primary"
            icon-left="pencil"
            @click="updateMessage(props.row)"  
            style="margin-right: 8pt;"
          ></b-button>
          <b-button 
            type="is-danger"
            icon-left="delete"
            title="Delete message"
            @click="deleteMessage(props.row._id)"  
          ></b-button>
        </div>
      </b-table-column>
    </b-table>
  </section>

</div>
</template>

<script>
import MessageDialog from '@/views/office/SiteMessages/MessageDialog'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import dayjs from 'dayjs'

export default {

  components: {
    MessageDialog,
    CustHeaderComp
  },

  data: () => ({
    isEmpty: false,
    showMessageDialog: false,
    isNewMessage: false,
    editMessage: {}
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  computed: {
    siteMessages () {
      return this.$store.getters['siteMessages/allSiteMessages']
    }
  },

  watch: {

  },

  methods: {

    async deleteMessage (msgId) {

      this.$buefy.dialog.confirm({
        title: 'Permanently Delete Message?',
        message: `Delete this message <strong style="color: red;">permanently?</strong>`,
        cancelText: 'Cancel',
        confirmText: 'Yes',
        type: 'is-danger',
        onConfirm: async () => {
          try {
            await this.$store.dispatch('siteMessages/deleteSiteMessage', msgId)
            this.$buefy.toast.open({
              message: `Message deleted`,
              position: 'is-bottom',
              type: 'is-success'
            })
          } catch (error) {
            console.error(error.message)
            this.$buefy.toast.open({
              message: `Error while deleting message! Check logs`,
              position: 'is-bottom',
              type: 'is-danger',
              duration: 5000
            })
          }
        }
      })

    },

    formatActive (isActive) {
      // console.log('isActive: ', isActive)
      if (isActive) {
        return ``
      } else {
        return `<b-icon icon="close"></b-icon>`
      }
    },

    formatDate (theDate) {
      if (!theDate) {
        return '---'
      }
      return dayjs(theDate).format('ddd MM/DD/YY')
    },

    updateMessage (message) {
      // console.log('\nMessage to edit: ')
      // console.log(message)

      this.editMessage = {
        ...message
      }

      this.openEditMessageDialog()
    },

    openNewMessageDialog () {
      this.isNewMessage = true
      this.showMessageDialog = true
    },

    openEditMessageDialog () {
      this.isNewMessage = false
      this.showMessageDialog = true
    },

    closeSiteMessageDialog () {
      this.showMessageDialog = false
    }
  },

  created () {
    this.$store.dispatch('siteMessages/fetchSiteMessages')
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}
</style>