
// MESSAGES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchMessages (context) {
    axios.get(`${API_URL}/messages`).then(res => {
      const messages = res.data
      console.log('Fetched Messages: ', messages)
      context.commit('setMessages', messages)
    })
  },

  createMessage (context, payload) {
    /*
        payload: {
          userIdFrom: String,
          userIdTo: String,
          userNameFrom: String,
          userNameTo: String,
          message: String,
          priority: String,
          dateTimeSent: String
        }
    */
    return axios.post(`${API_URL}/messages`, payload).then(res => {
      const msg = res.data
      context.commit('addMessage', msg)
      return msg
    })
  },

  updateMessage (context, payload) {
    /*
        payload: {
          _id: String,
          << any updates go below (see createMessage for list of variables) >>
        }
    */
    axios.patch(`${API_URL}/messages/${payload._id}`, payload).then(res => {
      const msg = res.data
      context.commit('updateMessage', msg)
    })
  },

  deleteMessage (context, messageId) {
    axios.delete(`${API_URL}/messages/${messageId}`).then(res => {
      const delMsg = res.data
      context.commit('removeMessage', delMsg)
    })
  },
}