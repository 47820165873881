<template>
<div>

  <DeliveryCustomerDialog 
    :show="showDeliveryCustomerDialog" 
    :isNew="isNewCustomer"
    :passCustomer="customerToEdit"
    @cancelDeliveryCustomerDialog="cancelDeliveryCustomerDialog"
  />

  <DeliveryDialog
    :show="showDeliveryDialog"
    :loading="deliveriesLoading"
    :isNew="isNewDelivery"
    :passDelivery="deliveryToEdit"
    :passCustomer="passDeliveryCustomer"
    @cancelDeliveryDialog="cancelDeliveryDialog"
  />

  <CustHeaderComp title="Deliveries"/>

  <!-- DELIVERY CONTROLS-->
  <section class="section" style="padding-bottom: 10pt;">
    <div style="display: flex;">

      <!-- CREATE DELIVERY BUTTON -->
      <b-field>
        <b-button 
          type="is-primary" 
          icon-left="plus-thick"
          @click="createNewDelivery"
        >Delivery</b-button>
      </b-field>

      <!-- TIMEFRAME -->
      <div style="margin-left: 20pt;">
        <b-field label="Timeframe" label-position="on-border">
          <b-select
            v-model="timeframeSelected"
            placeholder="Choose..."
          >
            <option v-for="time in timeframes" :key="time.value" :value="time.value">
              {{ time.key }}
            </option>
          </b-select>
        </b-field>
      </div>

      <!-- CUSTOMER SEARCH -->
      <div style="margin-left: 40pt; width: 40%;">
        <b-field label="Customer Search" label-position="on-border">
          <b-autocomplete
            v-model="selectedCustomer"
            :data="filteredDataArray"
            dropdown-position="bottom"
            field="name"
            placeholder="Search by name, phone, address"
            clearable
          >
            <template slot-scope="props">
              <div class="media" style="padding: 10pt;">

                <div class="media-left">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <b-icon icon="account" style="margin-right: 10pt;"></b-icon>
                    <div style="white-space: pre;">
                      <p>{{ props.option.name }}</p>
                      <p>{{ props.option.phone }}</p>
                      <p style="white-space: pre;">{{ props.option.address }}</p>
                    </div>
                  </div>
                </div>

                <div class="media-content">
                  <div style="display: flex; justify-content: space-between;">

                    <div></div>

                    <div>
                      <!-- NEW DELIVERY FOR THIS CUSTOMER -->
                      <b-button
                        type="is-primary"
                        icon-left="plus-thick"
                        size="is-small"
                        @click.stop="newDeliveryForCustomer(props.option)"
                        style="margin-right: 16pt;"
                      >Delivery</b-button>

                      <!-- EDIT BUTTON -->
                      <b-button 
                        type="is-primary"
                        icon-left="pencil"
                        size="is-small"
                        style="margin-right: 8pt;"
                        @click.stop="editDeliveryCustomer(props.option)"
                      ></b-button>

                      <!-- VIEW CUSTOMER'S DELIVERIES -->
                      <b-button
                        type="is-primary"
                        icon-left="magnify"
                        size="is-small"
                        style="margin-right: 8pt;"
                        @click.stop="viewCustomerDeliveries(props.option)"
                      ></b-button>

                      <!-- OPEN COORDS IN GOOGLE MAPS -->
                      <b-button
                        v-if="props.option.coordinates"
                        type="is-primary"
                        icon-right="google-maps"
                        size="is-small"
                        @click="locateCoordinates(props.option)"
                      ></b-button>
                    </div>

                  </div>
                </div>
                
              </div>
            </template>

            <template #empty>No customers found</template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>

    <br/>

    <!-- PER PAGE SELECTOR -->
    <b-field grouped>
      <b-field label="Per Page" label-position="on-border">
        <b-select v-model="perPage" style="width: 100pt;">
          <option value="1">1</option>
          <option value="3">3</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="25">25</option>
          <option value="30">30</option>
        </b-select>
      </b-field>

      <b-field style="margin-top: 6pt;">
        <b-switch v-model="hideCompleted">Hide Completed</b-switch>
      </b-field>

      <b-field style="margin-top: 6pt;">
        <b-switch v-model="showDeliveriesNeedingDirections">
          Needing Directions 
          <b-tag type="is-danger" rounded v-if="countDeliveriesNeedingDirections() > 0">
            <strong style="color: white;">{{ countDeliveriesNeedingDirections() }}</strong>
          </b-tag>

          <b-tag rounded v-else>
            <strong style="color: white;">{{ countDeliveriesNeedingDirections() }}</strong>
          </b-tag>
        </b-switch>
      </b-field>
    </b-field>
  </section>

  <!-- DELIVERIES TABLE -->
  <section class="section" style="padding-top: 10pt;">

    <!-- EMPLOYEE NOTES -->
    <b-field label="Employee Notes" message="Tab to save" style="width: 600pt;">
      <b-input type="textarea" v-if="employeeNotes" v-model="employeeNotes.text" rows="3" @blur="saveEmployeeNotes" style="white-space: pre;"></b-input>
    </b-field>
    
    <br/><br/>

    <p class="text-showingDeliveriesFor" v-if="showingCustomerDeliveries">Showing deliveries for {{ selectedCustomer }}</p>

    <!-- DELIVERIES TABLE -->
    <b-table
      :data="filteredDeliveries"
      :loading="deliveriesLoading"
      :paginated="true"
      :per-page="perPage"
      :current-page.sync="currentPage"
      striped
      default-sort="deliveryDate"
      hoverable
    >
      <b-table-column field="deliveryDate" label="Deliver On" sortable v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          {{ formatDate(props.row.deliveryDate) }}
        </div>
      </b-table-column>

      <b-table-column field="customer" label="Customer" sortable v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          {{ props.row.customer }}
        </div>
      </b-table-column>

      <b-table-column field="product" label="Materials" v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          {{ props.row.product }}
        </div>
      </b-table-column>

      <b-table-column field="quantity" label="Quantities" v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          {{ props.row.quantity }}
        </div>
      </b-table-column>

      <b-table-column field="phone" label="Phone" v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          {{ props.row.phone }}
        </div>
      </b-table-column>

      <b-table-column field="address" label="Address" v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          <!-- {{ props.row.address }} -->
          <strong><a :href="formatMapUrl(props.row.address)" target="_blank">{{ props.row.address }}</a></strong>
        </div>
      </b-table-column>

      <b-table-column label="Locate" v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          <b-button v-if="props.row.coordinates && props.row.coordinates !== ''" type="is-primary" icon-right="google-maps" size="is-small" @click="locateCoordinates(props.row)"></b-button>
        </div>
      </b-table-column>

      <b-table-column field="paid" label="Paid" sortable v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          <b-icon :icon="formatCompleted(props.row.paid)" :class="classFormatCompleted(props.row.paid)"></b-icon>
        </div>
      </b-table-column>

      <b-table-column field="completed" label="Completed" sortable v-slot="props">
        <div class="tableData" :class="classOverdueDelivery(props.row)">
          <b-icon :icon="formatCompleted(props.row.completed)" :class="classFormatCompleted(props.row.completed)"></b-icon>
        </div>
      </b-table-column>

      <b-table-column label="Actions" v-slot="props">
        <div style="display: flex;">
          <b-button
            type="is-primary"
            size="is-small"
            icon-right="pencil"
            style="margin-right: 8pt;"
            @click="editDelivery(props.row)"
          ></b-button>

          <b-button
            type="is-secondary"
            size="is-small"
            icon-right="printer"
            style="margin-right: 8pt;"
            @click="printDelivery(props.row)"
          ></b-button>

          <b-button
            type="is-danger"
            size="is-small"
            icon-right="delete"
            @click="confirmDeleteDelivery(props.row._id)"
          ></b-button>
        </div>
      </b-table-column>

      <template #empty>
        <section class="section">
          <div style="font-size: 16pt;" class="has-text-centered bold">Nothing here</div>
          <div class="has-text-centered">No deliveries in the selected time frame</div>
        </section>
      </template>
    </b-table>
  </section>

</div>
</template>

<script>
import DeliveryCustomerDialog from './components/DeliveryCustomerDialog'
import DeliveryDialog from './components/DeliveryDialog'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import dayjs from 'dayjs'

export default {
  components: {
    DeliveryCustomerDialog,
    DeliveryDialog,
    CustHeaderComp
  },

  data: () => ({
    currentPage: 1,
    hideCompleted: false,
    perPage: 15,
    deliveriesLoading: false,
    selectedCustomer: '',
    showDeliveryCustomerDialog: false,
    isNewCustomer: false,
    customerToEdit: undefined,
    showDeliveryDialog: false,
    isNewDelivery: false,
    deliveryToEdit: undefined,
    passDeliveryCustomer: undefined,
    deliveryCustomerToPreview: undefined,
    timeframeSelected: 'thisweek',
    showingCustomerDeliveries: false,
    showDeliveriesNeedingDirections: false,
    deliveriesWithDirectionsReminder: [],
    dlvsNeedingDirections: [],
    timeframes: [
      { key: 'Last Month', value: 'lastmonth' },
      { key: 'Last Week', value: 'lastweek' },
      { key: 'Yesterday', value: 'yesterday' },
      { key: 'Today', value: 'today' },
      { key: 'Tomorrow', value: 'tomorrow' },
      { key: 'This Week', value: 'thisweek' },
      { key: 'Next Week', value: 'nextweek' },
      { key: 'This Month', value: 'thismonth' },
      { key: 'Next Month', value: 'nextmonth' }
    ],
    // employeeNotes: ''
  }),

  async mounted () {
    await this.init()
  },

  computed: {
    employeeNotes: {
      get () {
        return this.$store.getters['deliveries/employeeNotes']
      },

      set (newVal) {
        this.$store.commit('deliveries/setEmployeeNotes', newVal)
      }
    },

    customers () {
      let custs = this.$store.getters['deliveryCustomers/allDeliveryCustomers']
      // console.log('Custs: ', custs)
      return custs
    },

    filteredDataArray() {
      if (this.customers && this.customers.length > 0) {
        return this.customers.filter((option) => {
          // console.log('OPTIONS: ', option)
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.selectedCustomer.toLowerCase()) >= 0
          || option.phone
            .toString()
            .toLowerCase()
            .indexOf(this.selectedCustomer.toLowerCase()) >= 0
          || option.address
            .toString()
            .toLowerCase()
            .indexOf(this.selectedCustomer.toLowerCase()) >= 0
        })
      } else {
        return []
      }
    },

    deliveries: {
      get () {
        const dlvs = this.$store.getters['deliveries/allDeliveries']
        return dlvs
      },

      set (newVal) {
        this.$store.commit('deliveries/setDeliveries', newVal)
      }
    },

    filteredDeliveries () {
      if (this.deliveryCustomerToPreview && this.deliveryCustomerToPreview !== '') {
        let dlvs = this.deliveries.filter(dlv => dlv.customerId === this.deliveryCustomerToPreview._id || dlv.customer === this.selectedCustomer)

        if (this.hideCompleted) {
          // console.log('--HIDING COMPLETED--')
          dlvs = dlvs.filter(d => d.completed === false)
        }
        this.setDeliveriesLoading(false)
        return dlvs

      } else if (this.showDeliveriesNeedingDirections) {
        return this.deliveries.filter(dlv => dlv.directionsReminder === true)
      } else if (this.timeframeSelected && this.timeframeSelected !== '') {
        let dlvs = this.getDeliveriesInTimeframe(this.timeframeSelected, true)
        if (this.hideCompleted) {
          // console.log('--HIDING COMPLETED--')
          dlvs = dlvs.filter(d => d.completed === false)
        }
        this.setDeliveriesLoading(false)
        return dlvs
      } else {
        this.setDeliveriesLoading(false)
        if (this.hideCompleted) {
          // console.log('--HIDING COMPLETED--')
          return this.deliveries.filter(d => d.completed === false)
        } else {
          return this.deliveries
        }
      }
    }
  },

  methods: {

    locateCoordinates (customer) {
      // console.log('Customer: ', customer.coordinates)
      if (customer) {
        if (customer.coordinates) {
          const url = `https://www.google.com/maps/search/?api=1&query=${encodeURI(customer.coordinates)}`

          window.open(url)

        } else {
          alert('No coordinates on file')
        }
      } else {
        alert('No customer file found')
      }
    },

    classOverdueDelivery (delivery) {
      // console.log(dayjs(delivery.deliveryDate).diff(dayjs(), 'day'))
      if (!delivery.completed && dayjs(delivery.deliveryDate).diff(dayjs(), 'day') < 0) {
        return 'overdueDelivery'
      } else {
        return ''
      }
    },

    formatMapUrl (address) {
      // console.log('Address: ', address)
      // console.log('Encoded: ', encodeURI(address))
      let url = `https://www.google.com/maps/search/?api=1&query=${encodeURI(address)}`
      return url
    },

    printDelivery (delivery) {
      // console.log('\nPRINT DELIVERY: ', delivery)
      this.$store.commit('deliveries/setPrintDelivery', delivery)
      this.$router.push("/office/deliveries/print")
    },

    setDeliveriesLoading (loading) {
      this.deliveriesLoading = Boolean(loading)
    },

    async init () {
      this.setDeliveriesLoading(true)

      await this.$store.dispatch('deliveryCustomers/fetchDeliveryCustomers')
      await this.$store.dispatch('deliveries/fetchDeliveries')
      await this.$store.dispatch('deliveries/fetchEmployeeNotes')

      // Hide copyright banner
      document.getElementById('copyrightFooter').style.display = 'none'
    },

    getDeliveriesInTimeframe (timeframe, showOverdueFirst) {

      this.showingCustomerDeliveries = false
      this.deliveryCustomerToPreview = null

      switch (timeframe) {
        case 'lastmonth': {
          // console.log('Getting deliveries for: lastmonth')
          let dlvs = this.$store.getters['deliveries/deliveriesLastMonth']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate)) <= 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'lastweek': {
          // console.log('Getting deliveries for: lastweek')
          let dlvs = []
          dlvs = this.$store.getters['deliveries/deliveriesLastWeek']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate), 'day') < 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'yesterday': {
          // console.log('Getting deliveries for: yesterday')
          let dlvs = this.$store.getters['deliveries/deliveriesYesterday']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate), 'day') < 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'today': {
          // console.log('Getting deliveries for: today')
          let dlvs = this.$store.getters['deliveries/deliveriesToday']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate), 'day') !== 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'tomorrow': {
          // console.log('Getting deliveries for: tomorrow')
          let dlvs = this.$store.getters['deliveries/deliveriesTomorrow']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate)) > 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'thisweek': {
          // console.log('Getting deliveries for: thisweek')
          let dlvs = this.$store.getters['deliveries/deliveriesThisWeek']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate)) <= 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'nextweek': {
          // console.log('Getting deliveries for: nextweek')
          let dlvs = this.$store.getters['deliveries/deliveriesNextWeek']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate)) <= 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }

        case 'thismonth': {
          // console.log('Getting deliveries for: thismonth')
          let dlvs = this.$store.getters['deliveries/deliveriesThisMonth']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate)) <= 0) {
                dlvs.unshift(overdue[i])
              }
            }
          }
          return dlvs
        }

        case 'nextmonth': {
          // console.log('Getting deliveries for: nextmonth')
          let dlvs = this.$store.getters['deliveries/deliveriesNextMonth']
          if (showOverdueFirst) {
            let overdue = this.$store.getters['deliveries/deliveriesOverdue']
            // console.log('Overdue Found: ', overdue)
            for (let i=0; i<overdue.length; i++) {
              if (dayjs().diff(dayjs(overdue[i].deliveryDate)) <= 0) {
                dlvs.unshift(overdue[i])
              }
            }
            dlvs = [...new Set(dlvs)]
          }
          return dlvs
        }
      }
    },

    formatDate (date) {
      // console.log('Delivery date to format: ', date)
      return dayjs(date).format('ddd MM/DD/YY')
    },

    formatCompleted (val) {
      return val ? "check-bold" : "close-thick"
    },

    classFormatCompleted (val) {
      return val ? "delivery-complete" : "delivery-incomplete"
    },

    // #region CUSTOMER SEARCH BOX BUTTONS

    editDeliveryCustomer (customer) {
      // console.log(`Edit customer: `, customer)
      this.customerToEdit = { ...customer }
      this.isNewCustomer = false
      this.showDeliveryCustomerDialog = true
    },

    newDeliveryForCustomer (customer) {
      // console.log('TODO: New Delivery For This Customer: ', customer)
      this.passDeliveryCustomer = { ...customer }
      this.isNewDelivery = true
      this.showDeliveryDialog = true
    },

    viewCustomerDeliveries (customer) {
      // console.log('TODO: View Customer Deliveries: ', customer)
      this.selectedCustomer = customer.name
      this.showingCustomerDeliveries = true
      this.deliveryCustomerToPreview = { ...customer }
    },
    // #endregion

    createNewCustomer () {
      this.isNewCustomer = true
      this.showDeliveryCustomerDialog = true
    },

    createNewDelivery () {
      this.isNewDelivery = true
      this.passDeliveryCustomer = null
      this.deliveryToEdit = null
      this.showDeliveryDialog = true
    },

    cancelDeliveryCustomerDialog () {
      this.isNewCustomer = false
      this.showDeliveryCustomerDialog = false
      this.selectedCustomer = ''
    },

    cancelDeliveryDialog () {
      this.isNewDelivery = false
      this.showDeliveryDialog = false
    },

    editDelivery (delivery) {
      // console.log('Edit delivery: ', delivery)
      this.isNewDelivery = false
      this.deliveryToEdit = { ...delivery }

      // console.log('DELIVERY TO EDIT: ', this.deliveryToEdit)

      // TODO: PASS DELIVERY CUSTOMER, IF POSSIBLE
      if (delivery.phone) {
        const customer = this.$store.getters['deliveryCustomers/deliveryCustomerByPhone'](delivery.phone)
        this.passDeliveryCustomer = {...customer}
        // console.log('PASSING: ', customer)
      }
      
      this.showDeliveryDialog = true
    },

    confirmDeleteDelivery (deliveryId) {
      this.$buefy.dialog.confirm({
        title: 'Delete Delivery?',
        message: `Are you sure you want to <strong>permanently delete</strong> this delivery? This action cannot be undone!`,
        type: 'is-danger',
        hasIcon: true,
        confirmText: 'Delete Delivery',
        onConfirm: () => { this.deleteDelivery(deliveryId) }
      })
    },

    async deleteDelivery (deliveryId) {
      // console.log('Delete deliveryID: ', deliveryId)
      try {

        await this.$store.dispatch('deliveries/deleteDelivery', deliveryId)

        this.$buefy.toast.open({
          message: `Delivery deleted`,
          type: 'is-success',
          position: 'is-bottom'
        })
        
      } catch (error) {
        console.error(error.message)

        this.$buefy.toast.open({
          message: `Delivery deleted`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        })
      }
    },

    figureDeliveriesNeedingDirections () {
      if (this.showDeliveriesNeedingDirections) {
        // console.log('DELIVERIES TODO: Implement showDeliveriesWithoutDirections()...')
        // TODO: Set the view to only show deliveries with "directionsReminder" boolean = true
        if (this.deliveries && this.deliveries.length > 0) {
          this.dlvsNeedingDirections = this.deliveries.filter(dlv => dlv.directionsReminder === true)
          // console.log('Deliveries found needing directions: ', this.dlvsNeedingDirections)
          this.deliveries = [...this.dlvsNeedingDirections]
        } else {
          console.error('ERROR: Tried to fetchDeliveries inside showDeliveriesNeedingDirections(), but no deliveries were returned or conditional is falsey...')
        }
      } else {
        // if false
      }
    },

    countDeliveriesNeedingDirections () {
      let dlvs = this.deliveries.filter(dlv => dlv.directionsReminder === true)
      return dlvs.length
    },

    async saveEmployeeNotes () {

      console.log('Print employeeNotes: ', this.employeeNotes.text)

      try {
        await this.$store.dispatch('deliveries/setEmployeeNotes', this.employeeNotes.text)
        this.$buefy.toast.open({
          message: 'Note saved',
          position: 'is-bottom',
          type: 'is-success',
          duration: 3000
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while updating employee notes! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 3000
        })
      }
    }
  },

  watch: {
    selectedCustomer () {
      if (this.selectedCustomer === '') {
        this.deliveryCustomerToPreview = undefined
      } else {
        // console.log('Selected customer: ', this.selectedCustomer)
      }
    },

    async showDeliveriesNeedingDirections () {
      if (this.showDeliveriesNeedingDirections === true) {
        this.figureDeliveriesNeedingDirections()
      } else {
        // console.log('Setting deliveries back to normal..')
        try {
          await this.$store.dispatch('deliveries/fetchDeliveries')
        } catch (error) {
          console.error(error.message)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#newCustomerBtn {
  background-color: rgb(7, 135, 139);
  color: white;
  margin-left: 14pt;
}
.delivery-complete {
  color: rgb(49, 168, 79);
  font-weight: bold;
}
.delivery-incomplete {
  color: rgb(236, 52, 52);
  font-weight: bold;
}
.text-showingDeliveriesFor {
  color: red;
  font-size: 18pt;
  font-weight: bold;
  margin-bottom: 12pt;
}
.tableData {
  // border: 1px solid red;
  text-align: left;
  white-space: pre;
}
.overdueDelivery {
  color: red !important;
  font-weight: bold !important;
  // background-color: pink;
}
tr > div.overdueDelivery {
  background-color: red !important;
}
</style>