
// FREIGHTROUTES > GETTERS

export default {
  allFreightRoutes (state) {
    return state.freightRoutes
  },

  freightRoutesByVendorSiteId:  (state) => (vendorSiteId) => {
    return state.freightRoutes.filter(freightRoute => freightRoute.vendorSiteId === vendorSiteId)
  },
}