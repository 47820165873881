<template>
<div>
    <!-- LOADING CARPORTS (SKELETON LOADER) -->
  <div v-if="featuredCarports">
    <div v-if="loading">
      <div style="text-align: center;">
        <p style="position: relative; top: 115px;"><strong style="color: gray;">LOADING...</strong></p>
      </div>
      
      <div>
        <b-skeleton circle width="200px" height="200px"></b-skeleton>
      </div>
    </div>

    <!-- DONE LOADING - CARPORTS FOUND -->
    <div v-else>
      <!-- Show carousel if more than one featured carport -->
      <b-carousel v-if="featuredCarports && featuredCarports.length > 1">
        <b-carousel-item v-for="carport in featuredCarports" :key="carport._id" style="max-width: 600px;">
          <b-image v-if="carport && carport.image" :src="carport.image" :alt="carport.name" :title="carport.size + ' ' + carport.name"/>
        </b-carousel-item>
      </b-carousel>

      <!-- Else show a static image if only one featured carport -->
      <div v-else style="max-width: 500px;">
        <b-image v-if="featuredCarports[0]" :src="featuredCarports[0].image" :alt="featuredCarports[0].name" :title="featuredCarports[0].size + ' ' + featuredCarports[0].name"/>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({
    loading: null
  }),

  computed: {
    featuredCarports () {
      const carports = this.$store.getters['carports/featuredCarports']
      if (carports && carports.length > 0) {
        // console.log('CARPORTS: ', carports)
        this.setLoading(false)
        return carports
      } else {
        // console.log('Carports empty or still loading...')
        return []
      }
    }
  },

  methods: {
    setLoading (val) {
      this.loading = Boolean(val)
      // console.log('LOADING was set to: ', this.loading)
    }
  },

  mounted () {
    this.loading = true
    this.$store.dispatch('carports/fetchCarports')
  },
}
</script>

<style lang="scss" scoped>
</style>