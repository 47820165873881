
// MATERIALS > GETTERS

export default {
  allMaterials (state) {
    return state.materials
  },

  activeMaterials (state) {
    return state.materials.filter(mat => mat.isActive === true)
  },

  featuredMaterials (state) {
    return state.materials.filter(mat => mat.isFeatured === true)
  },

  materialsByCategory: (state) => (searchCategory) => {
    return state.materials.filter(mat => mat.category == searchCategory)
  },

  stockOptions (state) {
    return state.stockOptions
  },

  materialById: (state) => (matId) => {
    return state.materials.find(mat => mat._id === matId)
  },

  materialToEdit (state) {
    return state.materialToEdit
  },

  truckableProducts (state) {
    return state.materials.filter(mat => mat.isTruckable === true)
  },

  lowOrOut (state) {
    return state.materials.filter(mat => mat.isActive === true && (mat.stock === 'low' || mat.stock === 'out'))
  }
}