import Vue from 'vue'
import VueRouter from 'vue-router'
// AUTH STUFF
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue'
import { LoginCallback } from '@okta/okta-vue'
// VIEWS & COMPONENTS
import Landing from '../views/Landing/Landing'
import About from '../views/About/about'
import Delivery from '../views/Delivery/delivery'
import Materials from '../views/Materials/materials'
import Desk from '../views/office/Desk/Desk'
import CallList from '../views/office/CallList/CallList'
import Hauls from '../views/office/Hauls/Hauls'
import HaulSummaryReport from '../views/office/Hauls/Report/HaulSummary'
import Drivers from '../views/office/Drivers/Drivers'
import Carports from '../views/Carports/carports'
// import Gallery from '../views/Gallery/gallery'
// import OfficeGallery from '../views/office/Gallery/officeGallery'
import TruckingQuote from '../views/Trucking/Quote/Quote'
import Contact from '../views/Contact/contact'
import Help from '../views/Help/help'
import OfficeFAQ from '../views/office/FAQ/FAQ'
import OfficeCarports from '../views/office/Carports/Carports'
import Calculator from '../views/Calculator/calculator'
import Workdays from '../views/office/Hauls/Workdays/Workdays'
import NotFound from '../views/NotFound'
import Deliveries from '../views/office/Deliveries/Deliveries'
import PrintDelivery from '../views/office/Deliveries/PrintDelivery'
import Profile from '../views/office/Profile/Profile'
import ManageMaterials from '../views/office/Materials/ManageMaterials'
import ManageOrders from '../views/office/Orders/ManageOrders'
import SiteMessages from '../views/office/SiteMessages/SiteMessages'
import SiteSettings from '../views/office/SiteSettings/SiteSettings'
import OldVendorsAndProducts from '../views/office/VendorsAndProducts/VendorsAndProducts'
import VendorsAndProductsRework from '../views/office/VendorsAndProducts/VendorsAndProductsRework'
import CostCalculator from '../views/office/CostCalculator/CostCalculator'
import PrintCostCalculator from '../views/office/CostCalculator/Print'
import WorkdayCheck from '../views/office/Hauls/Report/WorkdayCheck'
import LoggedIn from '@/components/LoggedIn'
import LoggedOut from '@/components/LoggedOut'

// import Login from './views/Login.vue';
// import Register from './views/Register.vue';

const OKTA_CLIENT_ID = '0oa26bwhs9Sk3Au5T5d6'
const OKTA_DOMAIN = 'dev-2897504.okta.com'


const routes = [
  {
    path: '/',
    component: Landing
  },
  {
    path: '/materials',
    component: Materials,
    children: [
      { 
        path: ':category', 
        // component: MaterialViewer 
      }
    ]
  },
  {
    path: '/login',
    name: 'LoggedIn',
    component: LoggedIn,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/loggedOut',
    name: 'LoggedOut',
    component: LoggedOut
  },
  {
    path: '/implicit/callback',
    component: LoginCallback
  },
  {
    path: '/about',
    name: 'About',
    component: About
  },
  {
    path: '/carports',
    name: 'Carports',
    component: Carports
  },
  {
    path: '/delivery',
    name: 'Delivery',
    component: Delivery
  },
  {
    path: '/trucking/quote',
    name: 'TruckingQuote',
    component: TruckingQuote,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/gallery',
  //   name: 'Gallery',
  //   component: Gallery
  // },
  {
    path: '/calculator',
    name: 'Calculator',
    component: Calculator
  },
  {
    path: '/help',
    name: 'Help',
    component: Help
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/office/desk',
    name: 'Desk',
    component: Desk,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/faq',
    name: 'Office FAQ',
    component: OfficeFAQ,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/office/gallery',
  //   name: 'OfficeGallery',
  //   component: OfficeGallery,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/office/carports',
    name: 'OfficeCarports',
    component: OfficeCarports,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/sitemessages',
    name: 'Site Messages',
    component: SiteMessages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/settings',
    name: 'SiteSettings',
    component: SiteSettings,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/profile',
    name: 'User Profile',
    component: Profile,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/call-list',
    name: 'Call List',
    component: CallList,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/hauls',
    name: 'Hauls',
    component: Hauls,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/hauls/workdays',
    name: 'Workdays',
    component: Workdays,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/hauls/report',
    name: 'Haul Summary',
    component: HaulSummaryReport,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/cost-calculator',
    name: 'Cost Calculator',
    component: CostCalculator,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/cost-calculator/print',
    name: 'Print Cost Calculator',
    component: PrintCostCalculator,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/drivers',
    name: 'Drivers',
    component: Drivers,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/deliveries',
    name: 'Deliveries',
    component: Deliveries,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/orders',
    name: 'Orders',
    component: ManageOrders,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/deliveries/print',
    name: 'PrintDelivery',
    component: PrintDelivery,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/office/featured-materials',
  //   name: 'Featured Materials',
  //   component: VuetFeatMat,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },


  {
    path: '/office/old-vendors-and-products',
    name: 'OldVendorsAndProducts',
    component: OldVendorsAndProducts,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/office/vendors-and-products',
    name: 'VendorsAndProductsRework',
    component: VendorsAndProductsRework,
    meta: {
      requiresAuth: true
    }
  },


  {
    path: '/office/materials',
    name: 'OfficeMaterials',
    component: ManageMaterials,
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/office/eagle',
  //   name: 'OfficeEagle',
  //   component: VuetOfficeEagle,
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/office/hauls/workdaycheck',
    name: 'OfficeWorkdayCheck',
    component: WorkdayCheck,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/:notFound(.*)',
    name: 'Not Found',
    component: NotFound
  }
]

Vue.use(VueRouter)

const oktaAuth = new OktaAuth({
  issuer: `https://${OKTA_DOMAIN}/oauth2/default`,
  clientId: OKTA_CLIENT_ID,
  redirectUri: window.location.origin + '/implicit/callback',
  scopes: ['openid', 'profile', 'email'],
  tokenManager: {
    storage: 'sessionStorage'
  }
})
Vue.use(OktaVue, { oktaAuth })



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  scrollBehavior() {
    window.scrollTo(0, 0)
  },
  routes
})

export default router
