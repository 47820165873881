
// ORDERS > GETTERS

export default {

  allOrders (state) {
    return state.orders
  },

  ordersByVendorId: (state) => (vendorId) => {
    return state.orders.filter(order => order.vendorId === vendorId)
  }
}