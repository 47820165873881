
// CARRIERS > MUTATIONS

export default {
  setCarriers (state, carriers) {
    state.carriers = carriers
  },

  addCarrier (state, carrier) {
    state.carriers.push(carrier)
  },

  updateCarrier (state, payloadCarrier) {
    let index = state.carriers.findIndex(carrier => carrier._id === payloadCarrier.id)
    state.carriers.splice(index, 1, payloadCarrier)
  },

  removeCarrier (state, delCarrier) {
    let index = state.carriers.findIndex(carrier => carrier._id === delCarrier._id)
    state.carriers.splice(index, 1)
  }
}