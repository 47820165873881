
// WORKDAYS > GETTERS

import dayjs from 'dayjs'
import DayJSWeekday from 'dayjs/plugin/weekday'

export default {
  allWorkdays (state) {
    return state.workdays
  },

  missingWorkdays (state) {
    return state.missingWorkdays
  },

  getCurrentWorkday (state) {
    return state.currentWorkday
  },

  workdayById (state, workdayId) {
    return state.workdays.filter(workday => workday._id === workdayId)
  },

  workdaysByDriverId (state, driverId) {
    return state.workdays.filter(workday => workday.driver === driverId)
  },

  workdayByDate (state, date) {
    return state.workdays.filter(workday => dayjs(workday.workDate).format('MM/DD/YYYY') === dayjs(date).format('MM/DD/YYYY'))
  },

  workdaysByDriverAndDateRange: (state) => (info) => {

    // info: { dateStart, dateEnd, driverId }

    // console.log('\n\n\ninside workdaysByDriverAndDateRange...')
    dayjs.extend(DayJSWeekday)

    // console.log('info object: ')
    // console.log(info)

    // const driverId = info.driverId
    const dateStart = dayjs(info.dateStart)
    const dateEnd = dayjs(info.dateEnd)
    const driverId = info.driverId

    // Get a list of dates between two dates above


    // Convert ranges provided from template into DayJS dates
    
    // Get the number of days between this range
    let diff = Math.abs(dateStart.diff(dateEnd, 'day'))

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(dateStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('DAY LIST: ')
    // console.log(daylist)

    // line directly below is for reference only:
    // let hauls = state.hauls.filter(haul => haul.driverId === driverId  && weeklist.includes(haul.dateHaul))
    
    //  Fix? below (need driverID and workdays within date range)
    // console.log('\n\n')
    // console.log('WORKDAYS: ')
    // console.log(state.workdays)
    const workdays = state.workdays.filter(workday => workday.driverId === driverId && daylist.includes(workday.workDate))
    // console.log('getter WORKDAYS: ')
    // console.log(workdays)
    return workdays
  },

  // WORKDAYS BY RANGE AND DRIVER
  workdaysByRangeAndDriver: (state) => (payload) => {
    // payload:   { range, driverId }

    // console.log('inside workdaysByRangeAndDriver with payload: ')
    // console.log(payload)

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // console.log('Diff: ', diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    // Retrieve workdays for this list of dates for the specified driver
    if (daylist.length > 1) {
      let workdays = state.workdays.filter(wday => wday.driverId === driverId && daylist.includes(dayjs(wday.workDate).format('YYYY-MM-DD')))
      workdays.sort((a, b) => a.workDate.localeCompare(b.workDate))
      return workdays
    }
  },

  missingWorkdaysInRange: (state) => (payload) => {
    // payload:   { range, driverId }

    // console.log('inside missingWorkdaysInRange with payload: ')
    // console.log(payload)

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // console.log('Diff: ', diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    // Retrieve workdays for this list of dates for the specified driver
    if (daylist.length > 1) {

      let workdays = state.workdays.filter(wday => wday.driverId === driverId && daylist.includes(dayjs(wday.workDate).format('YYYY-MM-DD')))

      // console.log('Workdays found: ')
      // console.log(workdays)
      
      
      let wdays = []
      for (let i=0; i<workdays.length; i++) {
        wdays.push(workdays[i].workDate)
      }

      // console.log("workDate list THAT ALREADY EXIST: ")
      // console.log(wdays)
      
      let missingList = []
      
      // console.log('Daylist: ')
      // console.log(daylist)

      if (wdays) {
        missingList = daylist.filter(x => !wdays.includes(x))
      } else {
        console.error('ERROR: wdays is empty or does not exist...')
        missingList = []
      }

      // console.log('missingList: ')
      // console.log(missingList)

      // workdays.sort((a, b) => a.workDate.localeCompare(b.workDate))
      return missingList
    }
  },

  missingCHHours: (state) => (payload) => {
    // payload:   { range, driverId }

    // console.log('inside missingCHHours with payload: ')
    // console.log(payload)

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // console.log('Diff: ', diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    // Retrieve workdays that have empty/null/undefined chhours
    if (daylist.length > 1) {

      let workdays = state.workdays.filter(wday => wday.driverId === driverId && daylist.includes(dayjs(wday.workDate).format('YYYY-MM-DD')) && (wday.chhours === '' || wday.chhours === undefined || wday.chhours === null))

      // console.log('[missingCHHours getter]: Workdays with missing CH Hours found: ')
      // console.log(workdays)

      return workdays
    }
  },

  missingNCHours: (state) => (payload) => {
    // payload: { range (arr), driverId (str) }

    // console.log('inside missingNCHours with payload: ')
    // console.log(payload)

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // console.log('Diff: ', diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    // Retrieve workdays that have empty/null/undefined chhours
    if (daylist.length > 1) {

      let workdays = state.workdays.filter(wday => wday.driverId === driverId && daylist.includes(dayjs(wday.workDate).format('YYYY-MM-DD')) && (wday.nchours !== '' && wday.ncreasons === '' || wday.nchours === '' && wday.ncreasons !== ''))

      // console.log('[missingNCHours getter]: Workdays with missing NC Hours found: ')
      // console.log(workdays)

      return workdays
    }
  },

  missingWorkdayComplete: (state) => (payload) => {
    // payload: { range (arr), driverId (str) }


    // console.log('inside missingWorkdayComplete with payload: ')
    // console.log(payload)

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // console.log('Diff: ', diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    // Retrieve workdays that have empty/null/undefined chhours
    if (daylist.length > 1) {

      let workdays = state.workdays.filter(wday => { 
        return wday.driverId === driverId 
            && daylist.includes(dayjs(wday.workDate).format('YYYY-MM-DD')) 
            && (!wday.haulsEntered || !wday.dayComplete)
      })

      // console.log('[missingNCHours getter]: Workdays with missing NC Hours found: ')
      // console.log(workdays)

      return workdays
    }
  },

  workweekByDriver: (state) => (driverId, startDate) => {
    dayjs.extend(DayJSWeekday)

    let dateStart = dayjs(startDate)
    let dateEnd = dayjs(startDate).add(6, 'day')

    // console.log(`Workdays on ${driverId} for ${dateStart} thru ${dateEnd}`)
    
    // Get the number of days between this range
    let diff = Math.abs(dateStart.diff(dateEnd, 'day'))

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(dateStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    let workweek = []

    for (let i=0; i<daylist.length; i++) {
      let day = state.workdays.find(workday => workday.driverId === driverId && workday.workDate == daylist[i])

      workweek.push(day || null)
    }

    // const workdays = state.workdays.filter(workday => workday.driverId === driverId && daylist.includes(workday.workDate))

    return workweek

  },

  getWorkdayList (state) {
    return state.workdayList
  }
}