<template>
<div class="card" style="max-height: 400pt; height: 400pt; overflow-y: scroll;">

  <MessageDialog :open="isMessageDialogOpen" :users="users" @closeMessageDialog="closeMessageDialog"/>

  <header class="deskHeader">
    <div class="level">
      <div class="level-left">
        <p class="card-header-title deskCardTitle">Message Center (Coming Soon!)</p>
      </div>
      <div class="level-right p-2">
        <b-button 
          type="is-light" 
          class="deskBtn deskActionBtn" 
          icon-left="plus-thick" 
          @click="openMessageDialog"
          style="margin-right: 8pt;"
        ></b-button>
        <b-button type="is-dark">
          Test
        </b-button>
      </div>
    </div>
  </header>

  <div>
    <b-table
      :data="userMessages"
      :mobile-cards="true"
      narrowed
    >
      
    </b-table>
  </div>
</div>
</template>

<script>
import axios from 'axios'
import MessageDialog from './MessageDialog'

export default {

  props: [ 'user' ],

  components: {
    MessageDialog
  },

  data: () => ({
    isMessageDialogOpen: false
  }),

  mounted () {
    this.init()
  },

  computed: {
    users () {
      return []
    },

    userMessages () {
      if (this.user && this.user !== null && this.user !== undefined && this.user !== '') {
        // console.log('USER: ', this.user)
        return this.$store.getters['messages/messagesByUserId'](this.user.sub)
      } else {
        return []
      }
    }
  },

  methods: {
    async init () {
      // Hide the copyright footer
        document.getElementById('copyrightFooter').style.display = 'none'

      // Load user messages
      this.$store.dispatch('messages/fetchMessages')

      this.getUsers()
    },

    openMessageDialog () {
      this.isMessageDialogOpen = true
    },

    closeMessageDialog () {
      // console.log('Closing message dialog..')
      this.isMessageDialogOpen = false
    },

    getUsers () {
      // endpoint: https://${yourOktaDomain}/api/v1/users?limit=200

      console.log('Fetching users...')

      const OKTA_DOMAIN = 'dev-2897504.okta.com'
      // const API_KEY = '00FHJJavcyZHzDXA-c9dfIA-A8xzNQkTELOdKIMeGo'


      // "Access-Control-Allow-Origin": "*",
      // "Content-Type": "application/json",
      // "Authorization": `SSWS ${API_KEY}`
      axios.get(`https://${OKTA_DOMAIN}/api/v1/groups/00g1gdin9sdL5s15R5d7/users`, {
        headers: {
          'Content-Type': 'application/json',
          'Credentials': 'include'
        }
      }).then(res => {
        const data = res.data
        console.log('DATA FOUND: ', data)
      })
    }
  }
}
</script>