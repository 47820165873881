<template>
<div>
  <section class="section">
    <div v-if="settings">
      <b-field>
        <b-field style="margin-top: 4pt;">
          <b-switch v-model="storeOpen">Store Open</b-switch>
        </b-field>
      </b-field>

      <b-field grouped v-if="!storeOpen">
        <b-field>
          <b-input v-model="reasonClosed" type="textarea" :rows="3" style="width: 400px;"></b-input>
        </b-field>
      </b-field>

      <b-field v-if="!storeOpen">
        <b-button type="is-primary" icon-left="content-save" @click="saveReasonClosed">Save</b-button>
      </b-field>
    </div>

    <div v-else>
      <p>No settings loaded...</p>
    </div>
  </section>
</div>
</template>

<script>
export default {

  data: () => ({
    reasonClosed: '',
    loading: false
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
    
    this.init()
  },

  computed: {
    settings () {
      return this.$store.getters['siteSettings/settings']
    },

    storeOpen: {
      get () {
        const settings = this.$store.getters['siteSettings/settings']
        // console.log('STORE OPEN SETTING: ', settings.storeOpen)
        return settings.storeOpen
      },

      set (newVal) {
        this.$store.dispatch('siteSettings/updateSettings', { storeOpen: newVal })
      }
    }
  },

  methods: {
    async init () {
      // Fetch settings
      try {
        // console.log('Loading reasons...')
        this.loading = true
        await this.$store.dispatch('siteSettings/fetchSettings')
        // console.log('Done loading reasons!')
        this.loading = false
      } catch (error) {
        console.error(error.message)
      }
    },

    async saveReasonClosed () {

      this.editReasonDisabled = true

      // console.log('Closed reason: ')
      // console.log(this.reasonClosed)

      try {
        // payload (dynamic): { property: value }
        await this.$store.dispatch('siteSettings/updateSettings', { reasonClosed: this.reasonClosed })
        this.$buefy.toast.open({
          message: 'Reason closing updated',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while trying to update reason closed! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 8000
        })
      }
    }
  },

  watch: {

    settings: {
      deep: true,
      handler () {
        if (this.settings.reasonClosed) {
          // console.log('REASON CLOSED FOUND: ')
          // console.log(this.settings.reasonClosed)
          this.reasonClosed = this.settings.reasonClosed
        }
      }
    },

    storeOpen () {
      if (this.storeOpen === true) {
        this.reasonClosed = ''
        // console.log('Setting reasonClosed to: ', this.reasonClosed)
        this.saveReasonClosed()
      } else {
        // console.log('store set to not open...')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>