<template>
<div>
  <section class="hero is-primary" id="contactHero">
    <div class="hero-body">
      <p class="title">Contact Us</p>
    </div>
  </section>

  <section class="section">
    <div class="columns">
      <!-- INFO -->
      <div class="column is-half">
        <div class="content">
          <h2>Get In Touch</h2>
        </div>
        <address style="font-style: normal">
          5725 W 7th St
          Texarkana, TX 75501
        </address>
        <div><strong>Phone: </strong>903-334-7350</div>

        <br/>

        <div class="fb-like" data-href="https://www.facebook.com/ChLandscapeMaterials" data-width="" data-layout="standard" data-action="like" data-size="large" data-share="true"></div>

        <br/>

        <div class="card">
          <div class="card-header">
            <p class="card-header-title">Current Hours of Operation</p>
          </div>
          <div class="card-content">
            <div v-if="!settingsLoading">
              <div><strong>Monday-Friday: </strong>{{ settings.storeHours.monFri }}</div>
              <div><strong>Saturday: </strong>{{ settings.storeHours.saturday }}</div>
              <div><strong>Sunday: </strong>{{ settings.storeHours.sunday }}</div>
            </div>
            <div v-else>
              <p>Loading...</p>
            </div>
          </div>
          <footer class="card-footer">
            <div class="card-content" style="padding: 4pt; margin-left: 12pt;">
              <p style="font-style: italic;">All hours are weather-permitting and subject to change</p>
            </div>
          </footer>
        </div>
      </div>

    
      <!-- MAP -->
      <div class="column is-half">
        <iframe 
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3330.321890784579!2d-94.12337298485153!3d33.41485135849622!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x863440e2a84ed4a1%3A0xbc62af402ff3d75a!2s5725%20W%207th%20St%2C%20Texarkana%2C%20TX%2075501!5e0!3m2!1sen!2sus!4v1628097166277!5m2!1sen!2sus" 
          width="600" 
          height="450" 
          style="border:0;" 
          allowfullscreen="" 
          loading="lazy"></iframe>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  data: () => ({
    message: 'Hello',
    settingsLoading: false
  }),

  computed: {
    settings () {
      const settings = this.$store.getters['siteSettings/settings']
      return settings
    }
  },

  methods: {
    setSettingsLoading (val) {
      this.settingsLoading = Boolean(val)
    }
  },

  async mounted () {
    this.settingsLoading = true
    await this.$store.dispatch('siteSettings/fetchSettings')
    this.settingsLoading = false
  }
}
</script>

<style lang="scss" scoped>
#contactHero {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/contact_us.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position-y: -160pt;
  // height: 80vh;
  margin-bottom: 20pt;
  z-index: 10;
}
</style>