<template>
<div id="templateWrapper" v-if="!loadingSettings && settings.advertiseDeliveries">
  <section id="deliveryLandingContainer">
    <div class="container landingMobileDeliveryContainer">
      <div class="columns">
        <div class="column landingMobileDeliveryInsideContainer">
          <header class="content">
            <h1 id="deliveryTitle">Need It Delivered?</h1>
            <div style="margin-top: -12pt; font-size: 13pt; color: white;">
              (<b-tooltip label="Gravel, topsoil, compost, sand, and mulch" position="is-right">
                  <span style="font-style: italic; font-weight: bold; color: #C2A799; border-bottom: 3px double;">bulk materials</span>
              </b-tooltip>
              only)
            </div>
          </header>

          <article class="articleText white">
            <p>
              We offer delivery services on our bulk products, which can be transported and dumped in and around the Texarkana area.
            </p>
            <br/>
            <b-button tag="router-link" type="is-light" outlined to="/delivery" class="deliveryLearnMoreLink buttonLink" style="margin-right: 8pt;">Learn More</b-button>
            <b-button tag="router-link" type="is-light" outlined to="/materials" class="buttonLink">View Materials</b-button>
          </article>
        </div>
        <div class="column">
          <img src="@/assets/dumpTrailer_2.png" class="is-hidden-mobile" alt="Image of a 14 foot dump trailer"/>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {

  data: () => ({
    loadingSettings: false
  }),

  mounted () {
    window.scrollTo(0, 0)

    try {
      this.loadingSettings = true
      this.$store.dispatch('siteSettings/fetchSettings')
      this.loadingSettings = false
    } catch (error) {
      console.error(error.message) 
      this.loadingSettings = false
    }
  },

  computed: {
    settings () {
      return this.$store.getters['siteSettings/settings']
    }
  }
}
</script>

<style lang="scss" scoped>
.articleText p {
  font-size: 16pt;
}
// #templateWrapper {
//   position: relative;
//   background-color: green;
//   border: 3px solid red;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: -10;
// }
#deliveryLandingContainer {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/dirt_rake.jpg');
  background-attachment: fixed;
  background-size: cover;
  height: 84%;
  margin-bottom: 20pt;
  z-index: 10;
}
.white {
  color: white;
}
.deliveryLearnMoreLink {
  border: 1px solid cyan !important;
  color: cyan !important;
}
.deliveryLearnMoreLink:hover {
  border: 1px solid black !important;
  color: rgb(34, 34, 34) !important;
}
.landingMobileDeliveryContainer {
  padding-top: 40pt;
}
.landingMobileDeliveryInsideContainer {
  padding-top: 70pt;
}

#deliveryTitle {
  color: white;
  font-size: 30pt;
}

@media screen and (max-width: 1024px) {
  .landingMobileDeliveryContainer {
    // border: 1px solid red;
    padding-top: 30pt;
  }
  .landingMobileDeliveryInsideContainer {
    padding-top: 40pt;
  }
}

@media screen and (max-width: 768px) {
  .landingMobileDeliveryInsideContainer {
    padding-top: 20pt;
    padding-bottom: 20pt;
  }
}

@media screen and (max-width: 320px) {
  #deliveryTitle {
    font-size: 22pt;
  }

  .articleText p {
    font-size: 12pt;
  }

  .buttonLink {
    // border: 2px solid red !important;
    padding: 8pt;
    font-size: 10pt;
  }
}
</style>