<template>
  <b-field label="From" label-position="on-border" expanded>
    <!-- <b-autocomplete
      v-model="model"
      id="fromField"
      ref="fromFieldValue"
      :keep-first="keepFirst"
      :open-on-focus="openOnFocus"
      :data="filteredDataObj"
      field="name"
      @select="option => doStuff(option)"
      :clearable="clearable"
      expanded
    >
    </b-autocomplete> -->
    <b-autocomplete
      v-model="model"
      id="fromField"
      ref="fromFieldValue"
      :keep-first="keepFirst"
      :open-on-focus="openOnFocus"
      :data="filteredDataObj"
      field="name"
      @select="(option) => doStuff(option)"
      :clearable="clearable"
      expanded
      append-to-body
    >
    </b-autocomplete>
  </b-field>
</template>

<script>
export default {
  name: "FromField",
  mounted() {
    this.init()
  },

  // props: ['from'],
  props: ['value'],

  data: () => ({
    keepFirst: false,
    openOnFocus: true,
    vendor: "",
    selected: null,
    clearable: false,
    vendorSelected: undefined,
  }),

  methods: {
    
    async init() {
      try {
        await this.$store.dispatch("vendors/fetchVendors")
      } catch (error) {
        console.error(error)
      }
    },

    doStuff (option) {
      this.vendor = option
      // console.log('this.vendor: ', this.vendor)

      this.$emit('input', option.name)
      this.$emit('vendorSelected', option)
    }
  },

  computed: {

    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },

    vendors() {
      return this.$store.getters["vendors/allVendors"]
    },

    filteredDataObj() {
      if (this.vendors) {
        return this.vendors.filter(option => {
          // console.log('VENDOR OPTION: ', option)
          return (
            option && option.name
              .toString()
              .toLowerCase()
              // .indexOf(this.form.vendorName.toLowerCase()) >= 0
              .indexOf(this.model.toLowerCase()) >= 0
          )
        })
      } else {
        return []
      }
    },

    // filteredDataObj() {
    //   const val = this.vendors.filter((vendorOption) => {
    //     // console.log('vendor filtered: ', vendorOption.name)
    //     return (
    //       vendorOption.name
    //         .toString()
    //         .toLowerCase()
    //         .indexOf(this.vendor.toLowerCase()) >= 0
    //     )
    //   })
    //   return val
    // },
  },

  watch: {
    vendor () {
      // console.log('FromField: Vendor: ', this.vendor)
    }
  }
}
</script>

<style lang="scss" scoped></style>
