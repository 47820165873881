<template>
<div>
  <CallListDialog
    :show="showCallListDialog"
    :isNew="isNewCallListRequest"
    :passRequest="requestToEdit"
    @closeCallListDialog="closeCallListDialog"
  />

  <CustHeaderComp title="Call List" />

  <section class="section">

    <b-field grouped>
      <b-field>
        <b-button
          type="is-primary"
          icon-left="plus-thick"
          @click="openCallListDialog(null)"
        >Add</b-button>
      </b-field>

      <b-field style="margin-left: 8pt; margin-top: 6pt;">
        <b-switch v-model="showWhatsIn">
          Show What's In
          <b-tag type="is-danger" rounded v-if="countRequestsIn() > 0">
            <strong style="color: white;">{{ countRequestsIn() }}</strong>
          </b-tag>

          <b-tag rounded v-else>
            <strong style="color: white;">{{ countRequestsIn() }}</strong>
          </b-tag>
        </b-switch>
      </b-field>
    </b-field>

    <br/><br/>

    <b-table
      :data="filteredCallListRequests"
      :loading="requestsLoading"
      detailed
      detail-key="_id"
      detail-transition="fade"
      show-detail-icon
      paginated
      per-page="5"
      current-page.sync="1"
      pagination-position="bottom"
    >
      <b-table-column field="customerName" label="Customer" sortable searchable v-slot="props">
        <span v-if="props">{{ props.row.customerName }}</span>
      </b-table-column>

      <b-table-column field="status" label="Status" sortable searchable v-slot="props">
        <span v-if="props">{{ formatStatus(props.row.status) }}</span>
      </b-table-column>

      <b-table-column field="material" label="Material" sortable>
        <template v-if="props" slot="searchable" slot-scope="props">
          <b-input
            placeholder="Search..."
            icon="magnify"
            size="is-small"
          />
        </template>
        <template v-slot="props">
          <span v-if="props">{{ materialNameFromId(props.row.material) }}</span>
        </template>
      </b-table-column>

      <b-table-column field="quantity" label="Quantity" sortable v-slot="props">
        <span v-if="props">{{ props.row.quantity }}</span>
      </b-table-column>

      <b-table-column field="customerContact" label="Contact Info" sortable searchable v-slot="props">
        <span v-if="props" style="white-space: pre;">{{ props.row.customerContact }}</span>
      </b-table-column>

      <b-table-column field="dateCreated" label="Created" sortable v-slot="props">
        <span v-if="props">{{ formatDate(props.row.dateCreated) }}</span>
      </b-table-column>

      <template #detail="props">
        <div style="display: flex;" v-if="props">
          <div style="margin-right: 40pt;">
            <div><strong>Customer:</strong> <span>{{ props.row.customerName }}</span></div>
            <div><strong>Contact:</strong> <span>{{ props.row.customerContact }}</span></div>
            <div><strong>Added:</strong> <span>{{ formatDate(props.row.dateCreated) }}</span></div>
          </div>

          <div>
            <div><strong>Material:</strong> <span>{{ materialNameFromId(props.row.material) }}</span></div>
            <div><strong>Quantity:</strong> <span>{{ props.row.quantity }}</span></div>
            <div class="status-text"><strong>Status:</strong> <span>{{ formatStatus(props.row.status) }}</span></div>
          </div>
        </div>

        <br/>

        <div><strong>Notes: </strong></div>
        <div v-if="props">{{ props.row.notes }}</div>
        <br/>
        <b-field class="btnGroup" grouped v-if="props">
          <b-button type="is-primary" icon-left="pencil" @click="openCallListDialog(props.row)">Edit</b-button>
          <b-button type="is-danger" icon-left="delete" @click="confirmDelete(props.row._id)">Delete</b-button>
        </b-field>
      </template>

    </b-table>
  </section>
</div>
</template>

<script>
import CallListDialog from './CallListDialog'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import dayjs from 'dayjs'

export default {
  components: {
    CallListDialog,
    CustHeaderComp
  },

  data: () => ({
    requestsLoading: false,
    showCallListDialog: false,
    showActiveRequestsOnly: false,
    isNewCallListRequest: undefined,
    requestToEdit: undefined,
    showWhatsIn: false
  }),

  mounted () {
    this.setRequestsLoading(true)
    this.$store.dispatch('callListRequests/fetchCallListRequests')

    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  computed: {
    callListRequests () {
      return this.$store.getters['callListRequests/allRequests']
    },

    activeCallListRequests () {
      return this.$store.getters['callListRequests/allActiveRequests']
    },

    filteredCallListRequests () {
      if (this.showWhatsIn) {
        // console.log('PRINTING REQUESTS...')
        let requests = this.activeCallListRequests
        let matsIn = []

        for (let i=0; i<requests.length; i++) {
          // console.log(requests[i])
          if (requests[i].material && requests[i].material !== '') {
            let mat = this.$store.getters['materials/materialById'](requests[i].material)
            if (mat) {
              // console.log('MAT INVENTORY FOUND: ', mat.stock)
              if (mat.stock === 'new' || mat.stock === 'in' || mat.stock === 'low') {
                matsIn.push(mat._id)
                // console.log('Pushing: ', { _id: mat._id, name: mat.name})
                // matsIn.push({ _id: mat._id, name: mat.name})
              }
            } else {
              console.log('WARNING: Could not load requests[i] material..')
            }
          } else {
            // console.error('ERROR: request.material is empty or falsey. Printing this object now: ')
            // console.error(requests[i])
          }
        }

        if (matsIn && matsIn.length > 0) {
          // console.log('found some mats..')
          // console.log(requests)
          return requests.filter(req => matsIn.includes(req.material))
        } else {
          console.error('ERROR: No mats found..')
          return []
        }

        // return requests
      } else {
        if (this.showActiveRequestsOnly) {
          const requests = this.activeCallListRequests
          this.setRequestsLoading(false)
          return requests
        } else {
          const requests = this.callListRequests
          this.setRequestsLoading(false)
          return requests
        }
      }
    },

    transitionName () {
      if (!this.useTransition)
        return
  
      return 'fade'
    }
  },

  methods: {

    // figureFiltered (val) {
    //   // console.log('VAL: ', val)
    // },

    setRequestsLoading (loading) {
      this.requestsLoading = Boolean(loading)
    },

    openCallListDialog (request) {
      if (request) {
        // console.log('Preparing to edit request: ', request)
        this.isNewCallListRequest = false
        this.requestToEdit = { ...request }
        this.showCallListDialog = true
      } else {
        // console.log('Preparing to open NEW request dialog...')
        this.isNewCallListRequest = true
        this.requestToEdit = null
        this.showCallListDialog = true
      }
    },

    closeCallListDialog () {
      this.isNewCallListRequest = false
      this.showCallListDialog = false
      this.requestToEdit = undefined
    },

    formatDate (date) {
      return dayjs(date).format('M/D/YY')
    },

    formatStatus (status) {
      if (status === 'waitingmaterial') return 'Waiting'
      if (status === 'informed') return 'Informed'
      if (status === 'leftmessage') return 'Left Message'
      if (status === 'cannotreach') return 'Cannot Reach'
    },

    confirmDelete (requestId) {
      this.$buefy.dialog.confirm({
        title: 'Deleting Request',
        message: 'Are you sure you want to <b>permanently delete</b> this request? This action cannot be undone.',
        confirmText: 'Delete Request',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => { this.deleteRequest(requestId) }
      })
    },

    deleteRequest (requestId) {
      this.$store.dispatch('callListRequests/deleteCallListRequest', requestId)
    },

    materialNameFromId (id) {
      return this.$store.getters['materials/materialById'](id) ? this.$store.getters['materials/materialById'](id).name : '[error]'
    },

    countRequestsIn () {
      let requests = this.activeCallListRequests
      let requestsIn = []

      if (requests && requests.length > 0) {
        for (let i=0; i<requests.length; i++) {
          // // console.log('Call List Request Item: ', requests[i])
          if (requests[i].status === 'waitingmaterial') {
            let mat = this.$store.getters['materials/materialById'](requests[i].material)
            if (mat) {
              if (mat.stock === 'new' || mat.stock === 'in' || mat.stock === 'low') {
                requestsIn.push(requests[i])
              }
            } else {
              /* // console.error('countRequestsIn(): ERROR. mat returned empty, null, or falsey..') */
            }
          }
        }

        this.$store.dispatch('callListRequests/setNumRequestsReady', requestsIn.length)
        return requestsIn.length
      } else {
        // console.log('WARNING: activeCallListRequests empty or is falsey..')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.status-text {
  color: red;
}
.btnGroup * {
  margin-right: 8pt;
}
</style>