
// OFFICE > TASKS > MUTATIONS

export default {
  addTask (state, task) {
    state.tasks.push(task)
  },

  setTasklist (state, list) {
    state.tasks = list
  },
  
  updateTask (state, taskPayload) {
    // console.log('[Task mutations updateTask]: taskPayload: ')
    // console.log(taskPayload)
    // console.log('taskPayload ID: ' + taskPayload._id)
    let index = state.tasks.findIndex(task => task._id === taskPayload._id)
    state.tasks.splice(index, 1, taskPayload)


    // const updItem = state.taskLists.find(list => list.userId === taskPayload.userId)
    // console.log('updItem: ')
    // console.log(updItem)
    // Object.assign(updItem, taskPayload)

    // TODO: Update localStorage here??
    // localStorage.setItem(`tasklist_${taskPayload.userId}`, JSON.stringify())
  },
  
  setTaskText (state, payload) {
    let task = state.tasks.find(task => task._id === payload.taskId)
    task.text = payload.text
  },

  removeTask (state, taskId) {
    let index = state.tasks.findIndex(task => task._id === taskId)
    state.tasks.splice(index, 1)
  }
}