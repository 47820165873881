<template>
<div>

  <ProfileForm
    :show="showProfileForm"
    :userInfo="userData"
    @closeProfileDialog="closeProfileDialog"
  />

  <section class="hero is-primary is-medium">
    <div class="hero-body">
      <p class="title">My Profile</p>
    </div>
  </section>

  <section class="section">
    <div class="content" v-if="!loading">
      <div class="columns">
        <div class="column">
          <table v-if="userData && userData.profile">
            <tr>
              <td>Status </td>
              <td>
                <div>
                  <b-tag
                    :type="determineStatusType(userData.profile.status)"
                  >
                    <strong style="color: white;">{{ userData.profile.status }}</strong>
                  </b-tag>
                  <b-tag 
                    v-if="statusToLowerCase === 'provisioned'"
                    type="is-warning"
                    style="margin-left: 6pt;"
                  >
                    You may have a verifcation e-mail pending, sent to the e-mail listed below
                  </b-tag>
              </div>
              </td>
            </tr>
            <tr>
              <td>Last Login</td>
              <td>
                {{ userData.lastLogin }}
              </td>
            </tr>
            <tr>
              <td>Name: </td>
              <td>{{ userData.profile.firstName }} {{ userData.profile.lastName }}</td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>{{ userData.profile.mobilePhone }}</td>
            </tr>
            <tr>
              <td>Organization</td>
              <td>{{ userData.profile.organization }}</td>
            </tr>
            <tr>
              <td>Address</td>
              <td>{{ userData.profile.postalAddress }}</td>
            </tr>
            <tr>
              <td>State</td>
              <td>{{ userData.profile.state }}</td>
            </tr>
            <tr>
              <td>Zip Code</td>
              <td>{{ userData.profile.zipCode }}</td>
            </tr>
            <tr>
              <td>E-mail</td>
              <td>{{ userData.profile.email }}</td>
            </tr>
            <tr>
              <td>ID</td>
              <td>
                <b-collapse :open="false" aria-id="userDataId">
                  <template #trigger>
                      <b-button
                        label="Private, click to reveal"
                        type="is-outline"
                        aria-controls="userDataId" />
                  </template>
                  <div>
                    <div class="content">
                      {{ userData.id }}
                    </div>
                  </div>
                </b-collapse>
              </td>
            </tr>
          </table>
        </div>
        <div class="column">

        </div>
      </div>

      <b-button
        type='is-primary'
        icon-left="pencil"
        @click="showProfileForm = true"
      >Edit</b-button>
    </div>
    <div class="content" v-else>
      <b-skeleton width="20%" :animated="true"></b-skeleton>
      <b-skeleton width="40%" :animated="true"></b-skeleton>
      <b-skeleton width="80%" :animated="true"></b-skeleton>
      <b-skeleton :animated="true"></b-skeleton>
    </div>
  </section>
</div>
</template>

<script>
// import axios from 'axios'
// import dayjs from 'dayjs'
import ProfileForm from './ProfileForm.vue'

export default {

  components: {
    ProfileForm
  },

  data: () => ({
    showProfileForm: false,
    loading: false,
    claims: [],
    OKTA_DOMAIN: 'dev-2897504.okta.com',
    API_KEY: '00FHJJavcyZHzDXA-c9dfIA-A8xzNQkTELOdKIMeGo',
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  computed: {
    statusToLowerCase () {
      // console.log('USERDATA: ', this.userData)
      return this.userData.profile.status.toLowerCase()
    },

    userData () {
      return this.$store.getters['profile/currentProfile']
    }
  },

  async created () {
    this.loading = true
    this.claims = await Object.entries(await this.$auth.getUser()).map(entry => ({ claim: entry[0], value: entry[1] }))
    this.setup()
  },

  methods: {

    determineStatusType (status) {
      if (status) {
        // console.log('TEST STATUS: ', status)
        const shortStatus = status.toLowerCase()

        switch (shortStatus) {
          case 'active':
            return 'is-success'
          
          case 'staged':
            return 'is-warning'

          case 'provisioned':
            return 'is-danger'
        }
      }
    },

    closeProfileDialog () {
      this.showProfileForm = false
    },

    async setup () {
      try {
        this.$store.dispatch('profile/fetchProfile')
        this.loading = false
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while fetching profile! Check logs',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>