
// OFFICE > TASKLISTS > GETTERS

export default {
  allTasklists (state) {
    return state.tasklists
  },
 
  tasklistsByUserId: (state) => (userId) => {
    // console.log(`Getter tasklistsByUserId with ID: ${userId}`)
    // console.log('LISTS IN STORE: ', state.tasklists)
    return state.tasklists.filter(list => {
      return list && list.userId === userId
    })
  }
 }