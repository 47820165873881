<template>
<div style="height: 100%;">

  <CustHeaderComp title="Office Desk"/>

  <section style="margin: 8pt 8pt 20pt 8pt">

    <div class="columns">
      <div class="column is-one-third">
        <DeskDeliveries/>
      </div>
      <div class="column is-one-third">
        <DeskTodoList/>
      </div>
      <div class="column is-one-third">
        <DeskCallList/>
      </div>
    </div>

    <div class="columns">
      <div class="column is-one-quarter">
        <DeskScratchpad/>
      </div>
      <div class="column is-half">
        <DeskMessages :user="user"/>
      </div>
      <div class="column is-one-quarter">
        <DeskLowInventory/>
      </div>
    </div>
  </section>

</div>
</template>

<script>
// import axios from 'axios'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import DeskDeliveries from './DeskDeliveries'
import DeskScratchpad from './DeskScratchpad'
import DeskCallList from './DeskCallList'
import DeskLowInventory from './DeskLowInventory'
import DeskMessages from './DeskMessages'
import DeskTodoList from './DeskTodoList'
// import SharedNotes from '@/components/office/sharedNotes/sharedNotesSidebar'

export default {
  components: { 
    CustHeaderComp,
    DeskDeliveries,
    DeskScratchpad,
    DeskCallList,
    DeskLowInventory,
    DeskMessages,
    DeskTodoList,
    // SharedNotes
  },
  
  data: () => ({
    claims: null
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'

    this.init()
  },

  computed: {
    user () {
      if (this.claims && this.claims.sub && this.claims.sub.length > 0) {
        // console.log('[Desk.vue] computed user: ', this.claims)
        return this.claims
      } else {
        return null
      }
    },

    userMessages () {
      if (this.user) {
        console.log('User loaded: ', this.user)
        return this.$store.getters['messages/messagesByUserId'](this.user.sub)
      } else {
        return []
      }
    }
  },

  methods: {
    async init () {
      // Hide the copyright footer
      document.getElementById('copyrightFooter').style.display = 'none'

      // Load user messages
      this.$store.dispatch('messages/fetchMessages')

      // Get user info
      // console.log('AUTH: ', this.$auth)
      this.claims = await this.$auth.getUser()

    },

    // getUsers () {
    //   // endpoint: https://${yourOktaDomain}/api/v1/users?limit=200

    //   console.log('Fetching users...')

    //   const OKTA_DOMAIN = 'dev-2897504.okta.com'
    //   // const API_KEY = '00FHJJavcyZHzDXA-c9dfIA-A8xzNQkTELOdKIMeGo'


    //   // "Access-Control-Allow-Origin": "*",
    //   // "Content-Type": "application/json",
    //   // "Authorization": `SSWS ${API_KEY}`
    //   axios.get(`https://${OKTA_DOMAIN}/api/v1/groups/00g1gdin9sdL5s15R5d7/users`, {
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'Credentials': 'include'
    //     }
    //   }).then(res => {
    //     const data = res.data
    //     console.log('DATA FOUND: ', data)
    //   })
    // }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
</style>