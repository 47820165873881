<template>
<div>
  <section>
    <article style="text-align: center;">
      <div class="subtitle smallerSubtitle">C&H Trucking</div>
      <div class="title smallerTitle">Haul Summary</div>
    </article>
  </section>

  <section class="section is-small">
    <div style="display: flex; justify-content: space-between; margin-bottom: 10pt;">

      <!-- DRIVER -->
      <p class="tableHeader">
        <strong>Driver:</strong> 
        <span v-if="driver">
          {{ driver.firstName }} {{ driver.lastName }}
        </span>
      </p>

      <!-- PERIOD -->
      <p class="tableHeader">
        <strong>Period: </strong>
        <span v-if="dateRange">
          {{ formatDate(dateRange[0]) }} - {{ formatDate(dateRange[1]) }}
        </span>
      </p>
    </div>

    <!-- CONTROLS -->
    <div class="no-print">
      <b-button :class="activeNCFlatPay" @click="addNCFlatPay">
        <span v-if="!ncFlatPay"><strong>Add</strong></span>
        <span v-else><strong>Remove</strong></span> 
        NC Flat Pay
      </b-button>
    </div>

    <!-- HAULS SUMMARY TABLE -->
    <b-table
      class="dataTable"
      :data="isEmpty ? [] : haulsInRange"
      striped
      narrowed
      :loading="isLoading"
    >
      <b-table-column field="dateHaul" label="Date" v-slot="props">
        <strong v-if="firstRowInDate(props)">
          {{ formatDate(props.row.dateHaul) }}
        </strong>
        <span v-else>
          {{ formatDate(props.row.dateHaul) }}
        </span>
      </b-table-column>

      <b-table-column field="broker" label="Cust" v-slot="props">
        {{ props.row.broker }}
      </b-table-column>

      <b-table-column field="invoice" label="Ref #" v-slot="props">
        {{ props.row.invoice }}
      </b-table-column>

      <b-table-column field="chinvoice" label="CH Inv" v-slot="props">
        {{ props.row.chinvoice }}
      </b-table-column>

      <b-table-column field="product" label="Material" v-slot="props">
        {{ props.row.product }}
      </b-table-column>

      <b-table-column field="from" label="From" v-slot="props">
        {{ props.row.from }}
      </b-table-column>

      <b-table-column field="to" label="To" v-slot="props">
        {{ props.row.to }}
      </b-table-column>

      <b-table-column field="tons" label="Tons/Mi" v-slot="props">
        {{ figureTonsOrMiles(props.row)}}
      </b-table-column>

      <b-table-column field="rate" label="Rate" v-slot="props">
        {{ props.row.rate }}
      </b-table-column>

      <b-table-column label="CH Hrs" v-slot="props">
        {{ renderCHHours(props) }}
      </b-table-column>

      <b-table-column label="NC Hrs" v-slot="props">
        {{ renderNCHours(props) }}
      </b-table-column>

      <b-table-column label="Freight Pay" v-slot="props">
        {{ renderFreightPay(props) }}
      </b-table-column>

      <b-table-column label="Driver Pay" v-slot="props">
        {{ renderDriverPay(props) }}
      </b-table-column>
    </b-table>

    <br/>

    <!-- REPORT DETAILS (BOTTOM OF PAGE) -->
    <section class="columns">
      <div class="column">
        <!-- Hours/Rate Column -->
        <table class="summariesTable">
          <tr>
            <td>C&H Hours: </td>
            <td>{{ totalCHHours() }}</td>
          </tr>
          <tr>
            <td>NC Hours: </td>
            <td>{{ totalNCHours() }}</td>
          </tr>
          <tr>
            <td>NC Rate: </td>
            <td>$<span v-if="driver.ncRate">{{ driver.ncRate }}</span></td>
          </tr>
        </table>
      </div>
      <div class="column">
        <!-- NC Reasons Column -->
        <strong style="font-size: 10pt; margin-bottom: 10pt;">NC Reasons: </strong>
        <div
          v-for="workday in workdaysInRange"
          :key="workday._id"
          style="font-size: 10pt;"
        >
          <span v-if="workday.ncreasons && workday.ncreasons !== ''">
            {{ formatShortDate(workday.workDate) }}: {{ workday.ncreasons }} ({{ workday.nchours }} <span v-if="workday.nchours > 1">hrs</span><span v-else>hr</span>)
          </span>
        </div>
      </div>
      <div class="column">
        <!-- Totals Column -->
        <div style="display: flex; justify-content: flex-end;">
          <table class="summariesTable">
            <tr>
              <td>Total Freight Pay: </td>
              <td>${{ calc_TotalFreightPay() }}</td>
            </tr>
            <tr>
              <td>Driver Subtotal: </td>
              <td>${{ calc_DriverSubtotal() }}</td>
            </tr>
            <tr>
              <td>NC Total: </td>
              <td>${{ calc_NCTotal() }}</td>
            </tr>
            <tr v-if="parseFloat(this.ncFlatPay) > 0">
              <td>NC Flat Pay: </td>
              <td>${{ this.ncFlatPay }}</td>
            </tr>
            <tr>
              <td style="padding-top: 8pt">Driver Total: </td>
              <td style="padding-top: 8pt">${{ calc_DriverTotal() }}</td>
            </tr>
          </table>
        </div>
      </div>
    </section>
  </section>
</div>
</template>

<script>
import dayjs from 'dayjs'

let LAST_ROW_DATE = undefined
let LAST_NC_ROW_DATE = undefined

export default {
  data: () => ({
    isEmpty: false,
    isLoading: false,
    driver: undefined,
    dateRange: undefined,
    haulsInRange: undefined,
    workdaysInRange: undefined,
    lastRowDate: undefined,
    lastNCRowDate: undefined,
    dontRender: [],
    
    totalFreightPay: 0,
    driverSubtotal: 0,
    totalNonComm: 0,
    driverTotal: 0,

    ncFlatPay: 0
  }),

  computed: {
    ncRate () {
      return this.driver.ncRate
    },

    activeNCFlatPay () {
      if (parseFloat(this.ncFlatPay) > 0) {
        return 'flatPay-active'
      } else {
        return 'flatPay-inactive'
      }
    }
  },

  mounted () {

    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'

    this.isLoading = true
    
    this.loadResources()

    let haulSummaryReportData = JSON.parse(localStorage.getItem('haulSummaryReportData'))
    // console.log('Haul Summary Report Data Loaded: ')
    // console.log(haulSummaryReportData)

    if (haulSummaryReportData && haulSummaryReportData !== undefined && haulSummaryReportData !== '') {
      this.driver = haulSummaryReportData.driver,
      this.dateRange = haulSummaryReportData.dateRange,
      this.workdaysInRange = haulSummaryReportData.workdaysInRange
      this.haulsInRange = haulSummaryReportData.haulsInRange

      // console.log('HAULS IN RANGE: ')
      // console.log(this.haulsInRange)
    }

    // console.log('Local State Data: ')
    // console.log({
    //   driver: this.driver,
    //   dateRange: this.dateRange,
    //   haulsInRange: this.haulsInRange,
    //   workdaysInRange: this.workdaysInRange
    // })
    this.isLoading = false
  },

  methods: {

    addNCFlatPay () {
      if (this.ncFlatPay) {
        this.ncFlatPay = 0
        this.$buefy.toast.open({
          message: 'Removed NC Flat Pay',
          duration: 3000,
          position: 'is-bottom',
          type: 'is-success'
        })
      } else {
        this.ncFlatPay = this.$buefy.dialog.prompt({
          message: 'Enter a flat-rate dollar amount to add as non-commission',
          inputAttrs: {
            type: 'number',
            placeholder: 'Amount',
          },
          trapFocus: true,
          confirmText: 'Add',
          onConfirm: (value) => {
            this.ncFlatPay = parseFloat(value).toFixed(2)
            this.$buefy.toast.open({
              message: 'NC Flat Pay: $' + this.ncFlatPay,
              duration: 3000,
              position: 'is-bottom',
              type: 'is-success'
            })
            // console.log('ncFlatPay updated: $' + this.ncFlatPay)
          }
        })
      }
    },

    figureTonsOrMiles (data) {
      // console.log('TONS OR MILES DATA: ')
      // console.log(data)
      if (data.loadType === 'enddump') {
        return data.tons
      }

      if (data.loadType === 'flatbedmi') {
        return data.miles
      }
    },

    loadResources () {
      this.$store.dispatch('hauls/fetchHauls')
      this.$store.dispatch('workdays/fetchWorkdays')
      this.$store.dispatch('drivers/fetchDrivers')
    },

    formatDate (date) {
      return dayjs(date).format('M/DD/YYYY')
    },

    formatShortDate (date) {
      return dayjs(date).format('M/DD')
    },

    firstRowInDate (props) {
      if (props) {
        return LAST_ROW_DATE !== props.row.dateHaul ? true : false
      } else {
        // No data argument passed
        // console.log('[HaumSummary.vue/renderCHHours]: No data argument passed')
        return false
      }
    },

    calc_TotalFreightPay () {
      let totalFreightPay = 0

      if (this.haulsInRange) {
        this.haulsInRange.map(haul => {
          if (haul.loadType === 'enddump') {
            // console.log('ED: totalFreightPay += ', (parseFloat(haul.tons) * parseFloat(haul.rate)))
            totalFreightPay += (parseFloat(haul.tons) * parseFloat(haul.rate))
          }
  
          if (haul.loadType === 'flatbedmi') {
            // console.log('F-Mi: totalFreightPay += ', (parseFloat(haul.miles) * parseFloat(haul.rate)))
            totalFreightPay += (parseFloat(haul.miles) * parseFloat(haul.rate))
          }
  
          if (haul.loadType === 'flatbedperc') {
            // console.log('F-%: totalFreightPay += ', parseFloat(haul.payrate))
            totalFreightPay += parseFloat(haul.payrate)
          }
        })
  
        // console.log('Total Freight Pay: ', totalFreightPay.toFixed(2))
        return totalFreightPay.toFixed(2)
      }

    },

    calc_DriverSubtotal () {
      let driverSubtotal = 0

      if (this.haulsInRange) {
        this.haulsInRange.map(haul => {
          if (haul.loadType === 'enddump') {
            // TODO: Currently testing out / 100  (remove this in all places here to return to normal)
            driverSubtotal += ((parseFloat(haul.tons) * parseFloat(haul.rate)) * parseFloat(this.driver.endDumpPayRate))
          }
  
          if (haul.loadType === 'flatbedmi') {
            // TODO: Currently testing out / 100  (remove this in all places here to return to normal)
            driverSubtotal += ((parseFloat(haul.miles) * parseFloat(haul.rate)) * parseFloat(this.driver.flatBedPayRate))
          }
  
          if (haul.loadType === 'flatbedperc') {
            driverSubtotal += parseFloat(haul.payrate) * parseFloat(haul.percentage)
          }
  
        })
        return driverSubtotal.toFixed(2)
      }

    },

    calc_NCTotal () {
      let amount = (parseFloat(this.totalNCHours()) * parseFloat(this.ncRate)).toFixed(2)
      return amount
    },

    calc_DriverTotal () {
      let driverSubtotal = parseFloat(this.calc_DriverSubtotal())
      let NCTotal = parseFloat(this.calc_NCTotal())
      // console.log('Driver total: ')
      // console.log('driverSubtotal: $', driverSubtotal)
      // console.log('NCTotal: $', NCTotal)
      let NCFlatPay = parseFloat(this.ncFlatPay)
      if (NCFlatPay > 0) {
        return parseFloat(driverSubtotal + NCTotal + NCFlatPay).toFixed(2)
      } else {
        return parseFloat(driverSubtotal + NCTotal).toFixed(2)
      }
    },

    renderCHHours (props) {
      if (props) {

        // Render CHHours once per date
        if (LAST_ROW_DATE !== props.row.dateHaul) {

          let workday = this.workdaysInRange.find(wday => wday.workDate === props.row.dateHaul)
          if (LAST_ROW_DATE !== props.row.dateHaul) {
            LAST_ROW_DATE = props.row.dateHaul
          }
          // this.lastRowDate = props.row.dateHaul

          return workday.chhours
        }

      } else {
        // No data argument passed
        console.log('[HaumSummary.vue/renderCHHours]: No data argument passed')
      }
    },

    renderNCHours (props) {
      if (props) {

        // Render NCHours once per date
        if (LAST_NC_ROW_DATE !== props.row.dateHaul) {

          let workday = this.workdaysInRange.find(wday => wday.workDate === props.row.dateHaul)
          LAST_NC_ROW_DATE = props.row.dateHaul

          return workday.nchours
        }

      } else {
        // No data argument passed
        console.log('[HaumSummary.vue/renderCHHours]: No data argument passed')
      }
    },

    renderFreightPay (props) {
      if (props.row.loadType === 'enddump') {
        // console.log('FreightPay enddump: ')
        let amt = (parseFloat(props.row.tons) * parseFloat(props.row.rate)).toFixed(2)
        // console.log(amt)
        return amt
      }

      if (props.row.loadType === 'flatbedmi') {
        // console.log('FreightPay flatbedmi: ')
        let amt = (parseFloat(props.row.miles) * parseFloat(props.row.rate)).toFixed(2)
        // console.log(amt)
        return amt
      }

      if (props.row.loadType === 'flatbedperc') {
        // console.log('FreightPay flatbedperc: ')
        // console.log('payrate: ', props.row.payrate)
        let amt = (parseFloat(props.row.payrate)).toFixed(2)
        // console.log(amt)
        return amt
      }
    },

    renderDriverPay (props) {
      if (props.row.loadType === 'enddump') {
        // TODO: Currently testing out / 100  (remove this in all places here to return to normal)
        return ((parseFloat(props.row.tons) * parseFloat(props.row.rate)) * parseFloat(this.driver.endDumpPayRate)).toFixed(2)
      }

      if (props.row.loadType === 'flatbedmi') {
        // console.log('DriverPay flatbedmi: ')
        // TODO: Currently testing out / 100  (remove this in all places here to return to normal)
        let amt = ((parseFloat(props.row.miles) * parseFloat(props.row.rate)) * parseFloat(this.driver.flatBedPayRate)).toFixed(2)
        // console.log(amt)
        return amt
      }

      if (props.row.loadType === 'flatbedperc') {
        // console.log('DrivePay flatbedperc: ')
        // console.log('payrate: ', props.row.payrate)
        // console.log('percentage: ', parseFloat(props.row.percentage))
        // let amt = (parseFloat(props.row.payrate) * parseFloat(this.driver.flatBedPayRate)).toFixed(2) DEPRECATED
        // TODO: Currently testing out / 100  (remove this in all places here to return to normal)
        let amt = (parseFloat(props.row.payrate) * parseFloat(props.row.percentage)).toFixed(2)
        // console.log('AMOUNT TO RETURN: ', amt)
        return amt
      }
    },

    totalCHHours () {
      if (this.workdaysInRange) {
        let totalHours = 0

        this.workdaysInRange.filter(wday => totalHours += parseFloat(wday.chhours))
        return totalHours.toFixed(2)
      }
    },

    totalNCHours () {
      if (this.workdaysInRange) {
        let totalHours = 0

        this.workdaysInRange.filter(wday => totalHours += (parseFloat(wday.nchours) || 0))
        return totalHours.toFixed(2)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.smallerTitle {
  font-size: 18pt !important;
}
.smallerSubtitle {
  font-size: 12pt !important;
}

.tableHeader {
  font-size: 10pt;
}

.dataTable {
  font-size: 10pt;
}

.summaryCol {
  // border: 1px solid black;
}

.red {
  // border-color: red;
}

.blue {
  // border-color: blue;
}

.green {
  // border-color: green;
}

.summariesTable {
  font-size: 10pt;
}
.summariesTable tr td:not(:last-child) {
  padding-right: 10pt !important;
  // border: 1px solid green;
}
.summariesTable tr td:first-child {
  font-weight: bold;
}
.summariesTable tr td:nth-child(2) {
  text-align: right;
}
.flatFee-active {
  border: 2px solid red;
}
.flatFee-inactive {
  border: 1px solid rgb(158, 158, 158);
  border-radius: 4pt;
  color: rgb(158, 158, 158);
}
</style>
