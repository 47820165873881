
// MESSAGES > MUTATIONS

export default {
  setMessages (state, payload) {
    state.messages = payload
  },

  addMessage (state, msg) {
    state.messages.push(msg)
  },

  updateMessage (state, payload) {
    let index = state.messages.findIndex(msg => msg._id === payload._id)
    state.messages.splice(index, 1, payload)
  },

  removeMessage (state, msgId) {
    let index = state.messages.findIndex(msg => msg._id === msgId)
    state.messages.splice(index, 1)
  }
}