
// MATERIALS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchMaterials (context) {
    axios.get(`${API_URL}/materials`).then(res => {
      const materials = res.data

      context.commit('setMaterials', materials)
    })
  },

  setMaterials (context, materials) {
    context.commit('setMaterials', materials)
  },

  setMaterialsByTags ({commit, state}, tags) {
    // Convert tags to lowercase 
    // let searchTags = tags.map(tag => tag = tag.toLowerCase())
    // console.log('Tags: ', tags)
    const mats = []

    state.materials.map(mat => {

      // console.log('Checking ', mat)

      for (let i=0; i<tags.length; i++) {
        // if (tags[i].includes(mat.name.toLowerCase()) || tags[i].includes(mat.category.toLowerCase()) || mat.keywords.includes(tags[i])) {
        //   mats.push(mat)
        // }
        if (mat.name.toLowerCase().includes(tags[i].toLowerCase())) {
          mats.push(mat)
        } else {
          for (let j=0; j<mat.keywords.length; j++) {
            if (mat.keywords[j].toLowerCase() === tags[i].toLowerCase()) {
              mats.push(mat)
            }
          }
          // if (mat.keywords.includes(tags[i])) {
          //   mats.push(mat)
          // }
        }
      }
      // console.log('MATERIALS FOUND BY TAGS: ', mats)
    })

    commit('setMaterials', mats)
  },

  fetchMaterialById(context, id) {
    axios.get(`${API_URL}/materials/${id}`).then(res => {
      const material = res.data

      context.commit('updateMaterial', material)
    })
  },

  setMaterialToEditById (context, materialId) {
    axios.get(`${API_URL}/materials/${materialId}`).then(res => {
      const material = res.data
      context.commit('setMaterialToEdit', material)
    })
  },

  createMaterial (context, material) {
    // console.log('inside createMaterial action...')
    // console.log('material to create: ')
    // console.log(material)
    axios.post(`${API_URL}/materials`, material).then(res => {
      const newMaterial = res.data

      // console.log('newMaterial from POST request: ')
      // console.log(newMaterial)
      context.commit('addMaterial', newMaterial)
    }).catch(err => console.error(err.message))
  },

  updateMaterial (context, material) {
    // let materialMinusId = {
    //   name: material.name,
    //   category: material.category,
    //   image: material.image,
    //   imageFileName: material.imageFileName,
    //   binNumber: material.binNumber,
    //   size: material.size,
    //   description: material.description,
    //   stock: material.stock,
    //   notes: material.notes,
    //   newMaterial: material.newMaterial,
    //   isFeatured: material.isFeatured,
    //   isActive: material.isActive,
    // }
    axios.patch(`${API_URL}/materials/${material._id}`, material).then(res => {
      const newMaterial = res.data
      // console.log('UPDATED MATERIAL: ')
      // console.log(newMaterial)
      context.commit('updateMaterial', newMaterial)
    }).catch(err => console.error(err.message))
  },

  deleteMaterial (context, materialId) {
    axios.delete(`${API_URL}/materials/${materialId}`).then(() => {
      context.commit('removeMaterial', materialId)
    })
  },

  updateStock (context, payload) {
    // payload: { matId (String), stock (String) }

    axios.post(`${API_URL}/materials/update-stock/${payload.matId}`, { stock: payload.stock }).then(() => {
      context.commit('updateMaterialStock', { id: payload.matId, stock: payload.stock })
    })
  },

  updateStockById (context, info) {
    axios.patch(`${API_URL}/materials/${info.id}`, { stock: info.stock }).then(() => {
      context.commit('updateMaterialStock', { id: info.id, stock: info.stock })
    })
  },

  updateFeaturedById (context, payload) {
    // payload:  { _id (Sring), isFeatured (Boolean) }

    // console.log('[Materials ACTIONS.js]: Inside updateFeaturedById with payload: ', payload)

    axios.post(`${API_URL}/materials/update-featured/${payload._id}`, { isFeatured: payload.isFeatured}).then((res) => {
      const updatedMat = res.data
      context.commit('updateFeaturedById', updatedMat)
    })
  }
}