<template>
  <b-field label="To" label-position="on-border" expanded>
    <!-- <p v-if="vendorSelected && vendorSelected._id">{{ this.vendorSelected._id }}</p> -->
    <b-autocomplete
      v-model="model"
      :keep-first="keepFirst"
      :open-on-focus="openOnFocus"
      :data="filteredDataObj"
      field="destination"
      @select="(option) => doStuff(option)"
      :clearable="clearable"
      expanded
      append-to-body
    >
      <template slot-scope="props">
        <p>
          <strong style="color: #EE9B7E">{{ props.option.destination }}</strong>
          (<strong style="color: #2CBC85">{{ props.option.material }}</strong
          >)
        </p>
      </template>
    </b-autocomplete>
    <!-- <b-autocomplete
      :value="to"
      :keep-first="keepFirst"
      :open-on-focus="openOnFocus"
      :data="filteredDataObj"
      field="destination"
      @select="(option) => doStuff(option)"
      :clearable="clearable"
      expanded
      append-to-body
    >
      <template slot-scope="props">
        <p>
          <strong style="color: #EE9B7E">{{ props.option.destination }}</strong>
          (<strong style="color: #2CBC85">{{ props.option.material }}</strong
          >)
        </p>
      </template>
    </b-autocomplete> -->
  </b-field>
</template>

<script>
export default {
  props: [ "value", "to", "data", "vendorSelected", "toFieldShouldUpdateOrders"],
  data: () => ({
    keepFirst: false,
    openOnFocus: true,
    destination: "",
    selected: null,
    clearable: false,
    destinationSelected: undefined,
  }),

  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    
    filteredDataObj() {
      if (this.vendorSelected && this.vendorSelected._id) {
        const val = this.data.filter((option) => {
          // console.log('TO FIELD OPTION: ', option)

          if (option.destination && option.destination.length > 0) {
            return (
              option.destination
                .toString()
                .toLowerCase()
                .indexOf(this.destination.toLowerCase()) >= 0 &&
              option.vendorId === this.vendorSelected._id
            );
          }

          if (option.status && option.status === 200) {
            return (
              option.data.destination
                .toString()
                .toLowerCase()
                .indexOf(this.destination.toLowerCase()) >= 0 &&
              option.data.vendorId === this.vendorSelected._id
            );
          }
        });

        // console.log('VAL VAL: ', val)
        return val;
      } else {
        return null;
      }
    },
  },

  methods: {
    doStuff(option) {
      // option => (selected = option)
      // console.log('TO Option: ', option)
      const opt = { ...option };
      this.destinationSelected = opt;
      this.$emit('input', this.destinationSelected.destination)
      this.$emit("destinationSelected", opt);
    },

    async updateOrders() {
      // this.toFieldShouldUpdateOrders = false
      this.$emit("falsifyShouldUpdateOrders");

      await this.$store.dispatch("orders/fetchOrders");
      this.data = this.$store.getters["orders/allOrders"];
    },
  },

  watch: {
    toFieldShouldUpdateOrders() {
      if (this.toFieldShouldUpdateOrders === true) {
        this.updateOrders();
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
