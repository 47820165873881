
// CARPORTS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchCarports (context) {
    axios.get(`${API_URL}/carports`).then(res => {
      const carports = res.data
      context.commit('setCarports', carports)
    })
  },

  createCarport (context, carport) {
    axios.post(`${API_URL}/carports`, carport).then(res => {
      const theCarport = res.data

      context.commit('addCarport', theCarport)
    })
  },

  updateCarport (context, carport) {
    // console.log('inside updateCarport action...')
    // console.log(carport)
    // console.log('Carport ID: ' + carport._id)

    axios.patch(`${API_URL}/carports/${carport._id}`, carport).then(res => {
      const newCarport = res.data

      // console.log('Carport updated to: ')
      // console.log(newCarport)

      context.commit('updateCarport', newCarport)
    })
  },

  copyCarport (context, carportId) {
    // console.log('Copying carport ID: ' + carportId)
    axios.post(`${API_URL}/carports/${carportId}/copy`).then(res => {
      const cpyCarport = res.data

      // console.log('COPIED CARPORT: ')
      // console.log(cpyCarport)
      context.commit('addCarport', cpyCarport)
    })
  },

  deleteCarport (context, carportId) {
    axios.delete(`${API_URL}/carports/${carportId}`).then(res => {
      const carport = res.data
      context.commit('removeCarport', carport)
    })
  }
}