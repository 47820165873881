
// MATERIALTAGS > GETTERS

export default {
  allTags (state) {
    return state.tags
  },
  
  tagByName: (state) => (tag) => {
    return state.tags.find(theTag => theTag.name === tag.toLowerCase())
  }
}