
// SITESETTINGS > GETTERS

export default {

  settings (state) {
    return state.settings
  },

  storeOpen (state) {
    return state.settings.storeOpen
  },

  reasonClosed (state) {
    return state.settings.reasonClosed
  },

  storeHours (state) {
    return state.settings.storeHours
  },

  advertiseDeliveries (state) {
    return state.settings.advertiseDeliveries
  },

  customHeaderImageUrl: (state) => (userId) => {
    return state.settings.customHeaders.find(el => el.userId === userId)
  }
}