
// MATERIALTAGS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  
  fetchTags (context) {
    axios.get(`${API_URL}/materialtags`).then(res => {
      const tags = res.data

      context.commit('setTags', tags)
    })
  },

  createMaterialTag (context, tag) {
    axios.post(`${API_URL}/materialtags`, tag).then(res => {
      const newTag = res.data

      // console.log('newTag from POST request: ')
      // console.log(newTag)
      context.commit('addTag', newTag)
    }).catch(err => console.error(err.message))
  }
}