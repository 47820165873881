
// GALLERY > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchGalleryImages (context) {
    axios.get(`${API_URL}/gallery`).then(res => {
      const images = res.data
      context.commit('setImages', images)
    })
  },

  createGalleryImage (context, image) {
    axios.post(`${API_URL}/gallery`, image).then(res => {
      const theImage = res.data

      context.commit('addImage', theImage)
    })
  },

  // setFeatured (context, image) {
  //   if (image && image.featPosition === 'A') {
  //     axios.post(`${API_URL}/gallery/featured`, image).then(res => {
  //       const img = res.data
  
  //       context.commit('setFeaturedA', img)
  //     })
  //   }

  //   if (image && image.featPosition === 'B') {
  //     axios.post(`${API_URL}/gallery/featured`, image).then(res => {
  //       const img = res.data
  
  //       context.commit('setFeaturedB', img)
  //     })
  //   }

  //   if (image && image.featPosition === 'C') {
  //     axios.post(`${API_URL}/gallery/featured`, image).then(res => {
  //       const img = res.data
  
  //       context.commit('setFeaturedC', img)
  //     })
  //   }
  // },

  updateGalleryImage (context, image) {
    // console.log('inside updateGalleryImage action...')
    // console.log(image)
    // console.log('Image ID: ' + image._id)

    axios.patch(`${API_URL}/gallery/${image._id}`, image).then(res => {
      const newImage = res.data

      // console.log('Image updated to: ')
      // console.log(newImage)

      context.commit('updateImage', newImage)
    })
  },

  copyGalleryImage (context, imageId) {
    // console.log('Copying gallery image ID: ' + imageId)
    axios.post(`${API_URL}/gallery/${imageId}/copy`).then(res => {
      const cpyImage = res.data

      // console.log('COPIED IMAGE: ')
      // console.log(cpyImage)
      context.commit('addImage', cpyImage)
    })
  },

  deleteGalleryImage (context, imageId) {
    axios.delete(`${API_URL}/carports/${imageId}`).then(res => {
      const image = res.data
      context.commit('removeImage', image)
    })
  }
}