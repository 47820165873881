<template>
<div>
  <b-modal v-model="show" :width="840" scroll="keep">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title">Create New Category</p>
      </div>

      <div class="card-content">
        <div class="container">
          <b-field label="Category Name" label-position="on-border" autofocus>
            <b-input v-model="category.name" autofocus></b-input>
          </b-field>

          <b-field grouped>
            <b-field label="Visible to Public"></b-field>
            <b-field>
              <b-checkbox v-model="category.isPublic"></b-checkbox>
            </b-field>
          </b-field>
        </div>
      </div>

      <footer class="card-footer">
        <div class="footer-btns">
          <b-button class="footer-saveBtn" icon-left="content-save" @click="createCategory">Save</b-button>
          <b-button class="footer-clearBtn">Clear</b-button>
          <b-button class="footer-closeBtn" icon-left="close-thick" @click="$emit('closeCategoryDialog')">Close</b-button>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show' ],

  emits: [ 'closeCategoryDialog' ],

  data: () => ({
    category: {
      name: '',
      isPublic: true
    }
  }),

  methods: {
    createCategory () {
      try {
        this.$store.dispatch('categories/createCategory', this.category)
        this.$buefy.toast.open({
          message: `Category created successfully!`,
          position: 'is-bottom',
          type: 'is-success'
        })

        // Clear the form and close the dialog
        this.category = {
          name: '',
          isPublic: true
        }
        this.$emit('closeCategoryDialog')
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error while creating category! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    // testCheckbox (evt) {
    //   console.log('test...')
    //   console.log(evt)
    // }
  },

  created () {
    this.$store.dispatch('categories/fetchCategories')
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>