<template>
<div>
<b-modal v-model="show" :width="800" scroll="clip" style="z-index: 9 !important;">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title" v-if="driver && isNewWorkday && dateFor">New Workday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ driver.firstName }} {{ driver.lastName }} &nbsp;&nbsp;&nbsp; {{ formatDate(dateFor) }}</p>
        <p class="card-header-title" v-else-if="driver && isNewWorkday && !dateFor">New Workday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ driver.firstName }} {{ driver.lastName }}</p>
        <p class="card-header-title" v-else-if="driver">Edit Workday &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; {{ driver.firstName }} {{ driver.lastName }} &nbsp;&nbsp;&nbsp; {{ formatDate(workday.workDate) }} </p>
        <p class="card-header-title" v-else>Workday</p>
      </div>

      <div class="card-content">

        <!-- Workday ID -->
        <b-field>
          <b-input v-model="workday._id" disabled></b-input>
        </b-field>

        <!-- CH HOURS & NC HOURS -->
        <b-field grouped>
          <b-field label="CH Hours" label-position="on-border">
            <b-input v-model="workday.chhours"></b-input>
          </b-field>

          <b-field label="NC Hours" label-position="on-border">
            <b-input v-model="workday.nchours"></b-input>
          </b-field>
        </b-field>

        <!-- NC Reasons & Workday Notes -->
        <b-field grouped>
          <b-field label="NC Reasons" label-position="on-border">
            <b-input type="textarea" v-model="workday.ncreasons" rows="3"></b-input>
          </b-field>

          <b-field label="Workday Notes (not visible)" label-position="on-border">
            <b-input type="textarea" v-model="workday.dayNotes" rows="3"></b-input>
          </b-field>
        </b-field>

        <!-- Miles Driven (NOT YET IN PRODUCTION) -->
        <!-- <b-field label="Miles Driven Today" label-position="on-border" style="width: 200px">
          <b-input type="number" v-model="workday.milesDriven"></b-input>
        </b-field> -->

        <!-- Hauls Enterred -->
        <b-field grouped>
          <b-field grouped>
            <b-field>Hauls Entered</b-field>
            <b-field>
              <b-checkbox v-model="workday.haulsEntered"></b-checkbox>
            </b-field>
          </b-field>

          <!-- Fuel Receipt Collected -->
          <b-field grouped>
            <b-field>Fuel Receipt Collected</b-field>
            <b-field>
              <b-checkbox v-model="workday.fuelTicket"></b-checkbox>
            </b-field>
          </b-field>

          <!-- Day Complete -->
          <b-field grouped>
            <b-field>Day Complete</b-field>
            <b-field>
              <b-checkbox v-model="workday.dayComplete"></b-checkbox>
            </b-field>
          </b-field>

        </b-field>
      </div>

      <!-- FOOTER -->
      <footer>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="footer-btns">

              <!-- Save Button -->
              <b-button 
                class="footer-saveBtn" 
                icon-left="content-save" 
                @click="saveWorkday"
              >
                Save
              </b-button>

              <!-- Cancel Button -->
              <b-button 
                class="footer-closeBtn" 
                icon-left="close-thick" 
                @click="cancelDialog"
              >
                Cancel
              </b-button>

            </div>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: [ 'show', 'dateFor', 'isNewWorkday', 'driver', 'workdayModel' ],
  
  emits: [ 'closeWorkdayDialog', 'forceCheckIsComplete' ],

  data: () => ({
    workday: {
      _id: '',
      workDate: '',
      driverId: '',
      chhours: '',
      nchours: '',
      ncreasons: '',
      milesDriven: '',
      haulsEntered: false,
      dayNotes: '',
      fuelTicket: false,
      offDuty: false,
      offDutyReason: '',
      dayComplete: false
    },

    emptyWorkday: {
      _id: '',
      workDate: '',
      driverId: '',
      chhours: '',
      nchours: '',
      ncreasons: '',
      milesDriven: '',
      haulsEntered: false,
      dayNotes: '',
      fuelTicket: false,
      offDuty: false,
      offDutyReason: '',
      dayComplete: false
    }
  }),

  methods: {
    cancelDialog () {
      this.workday = {
        ...this.emptyWorkday
      }
      this.$emit('closeWorkdayDialog')
    },

    formatDate (date) {
      return dayjs(date).format('ddd, M/DD/YY')
    },

    async saveWorkday () {

      // Add driverId property
      this.workday.driverId = this.driver._id
      this.workday.offDuty = false                // TODO: This needs to be included in the form layout and read from its v-model!! (remove this line when done)
      this.workday.offDutyReason = ''             // TODO: This needs to be included in the form layout and read from its v-model!! (remove this line when done)

      this.workday.workDate = dayjs(this.dateFor).format('YYYY-MM-DD')

      if (this.workday.chhours && this.workday.chhours !== '') {
        this.workday.chhours = parseFloat(this.workday.chhours).toFixed(2)
      }

      if (this.workday.nchours && this.workday.nchours !== '') {
        this.workday.nchours = parseFloat(this.workday.nchours).toFixed(2)
      }

      // if (!this.workday.milesDriven || this.workday.milesDriven === 0 || this.workday.milesDriven === '0' || this.workday.milesDriven === undefined || this.workday.milesDriven === null || this.workday.milesDriven === '') {
      //   this.$buefy.dialog.alert({
      //     title: 'Missing Miles Driven',
      //     message: `Miles Driven is used to track when trucks are due for servicing and is not advised to leave blank or 0. <strong>The workday will still be saved, but please provide this value as soon as possible!</strong>`,
      //     type: 'is-warning',
      //   })
      // }

      try {
        await this.$store.dispatch('workdays/saveOrCreateWorkday', {
          driverId: this.driver._id,
          workday: this.workday
        })
        this.$buefy.toast.open({
          message: `Workday saved`,
          position: 'is-bottom',
          type: 'is-success'
        })
        this.$emit('forceCheckIsComplete')
        this.cancelDialog()
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: `Error while creating workday! Check logs`,
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }
    }
  },

  watch: {
    workdayModel () {
      if (this.workdayModel) {
        this.workday = {
          ...this.workdayModel
        }
      } else {
        this.workday = {
          ...this.emptyWorkday
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>