
// OFFICE > TASKLISTS > ACTIONS

// import dayjs from 'dayjs'
import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchAllTasklists (context) {
    // console.log('fetching all tasklists...')
    axios.get(`${API_URL}/tasklists`).then(res => {
      const tasklists = res.data

      context.commit('setTasklists', tasklists)
    })
  },

  fetchTasklistByUserId (context, userId) {
    // console.log('Fetching list for USERID: ', userId)
    axios.get(`${API_URL}/tasklists/user/${userId}`).then(res => {
      // console.log('TASK DATA: ')
      // console.log(res.data[0])
      const tasks = res.data[0].tasks

      // console.log('UserID: ', userId)
      // console.log('TASKLIST FETCHED: ', tasks)

      context.commit('setTasklists', tasks)
    })
  },

  async createTasklist (context, tasklistPayload) {
    // tasklistPayload:  { userId, tasks(array) }
    
    axios.post(`${API_URL}/tasklists`, tasklistPayload).then(res => {
      const newTasklist = res.data

      context.commit('addTasklist', newTasklist)
    })
  },

  deleteTasklist (context, tasklistId) {
    // Remove task from database
    axios.delete(`${API_URL}/tasklists/${tasklistId}`).then(() => {
      // Save this list to state
      context.commit('removeTasklist', tasklistId)
    })
  },

  async updateTasklist (context, payloadList) {
    /* payloadList: {
      userId: this.claims.sub, 
      tasks: this.tasklist, 
      alreadyAdded: true 
    }
    */

    // console.log('[updateTasklist action] payloadList: ', payloadList)

    // Use the userId field inside payloadList object

    axios.post(`${API_URL}/tasklists/user/${payloadList.userId}`, payloadList).then(res => {
      const tasklistSaved = res.data

      // console.log('ACTIONS tasklist saved for mutation: ')
      // console.log(tasklistSaved)
      context.commit('updateTasklist', { userId: payloadList.userId, list: tasklistSaved })
    })

  },

  // async updateTasklistTask (context, taskPayload) {
  //   /* taskPayload:
  //     {
  //       _id,
  //       userId,
  //       text,
  //       status
  //       (new) prevText
  //     }
  //   */

  //   taskPayload.prevText = 

  //   axios.post(`${API_URL}/tasklists/update/${taskPayload.userId}`, taskPayload).then(res => {
  //     const resp = res.data
  //     console.log('\nSERVER RESPONSE: ')
  //     console.log(resp)
  //   })
  // }
}