<template>
<div>

  <DeliveryCustomerDialog
    :show="showDeliveryCustomerDialog"
    :isNew="isNewDeliveryCustomer"
    :passCustomer="editCustomer"
    :passNewCustomer="delivery"
  />

  <UpdateCustomerDialog
    :show="showUpdateCustomerDialog"
    :updates="customerUpdates"
    @cancelUpdateCustomerDialog="cancelUpdateCustomerDialog"
    @updateCacheObj="updateCacheObj"
  />

  <b-modal
    v-model="show"
    :width="800"
    scroll="keep"
  >
    <div class="card">
      <header class="card-header">
        <p class="card-header-title" v-if="isNew">New Delivery</p>
        <p class="card-header-title" v-else>Edit {{ delivery.name }}</p>
      </header>

      <section class="card-content">

        <!-- TODO: DEBUG ONLY : REMOVE/HIDE WHEN DONE -->
        <b-field v-if="debug" grouped>
          <b-field v-show="true" label="DeliveryID" label-position="on-border">
            <b-input v-model="delivery._id" :disabled="true"/>
          </b-field>
          <b-field v-show="true" label="CustomerID" label-position="on-border">
            <b-input v-model="delivery.customerId" :disabled="true"/>
          </b-field>
        </b-field>

        <!-- DELIVERY DATE & CUSTOMER NAME -->
        <b-field class="fieldRow" grouped>
          <b-field label="Delivery Date" label-position="on-border">
            <b-datepicker
              v-model="delivery.deliveryDate"
              placeholder="Deliver on..."
              icon="calendar-today"
              locale="en-US"
              editable
            ></b-datepicker>
          </b-field>

          <!-- CUSTOMER NAME -->
          <b-field label="Customer Name" label-position="on-border" expanded>
            <b-autocomplete
              v-model="delivery.customer"
              :data="filteredDataArray"
              dropdown-position="bottom"
              field="name"
              placeholder="Search by name"
            >
              <template slot-scope="props">
                <div class="media" style="padding: 10pt;" @click="loadCustomerInfo(props.option)">
                  <div class="media-content">
                    <div style="display: flex; align-items: center;">
                      <b-icon icon="account" style="margin-right: 10pt;"></b-icon>
                      <div>
                        <p>{{ props.option.name }}</p>
                        <p>{{ props.option.phone }}</p>
                        <p style="white-space: pre;">{{ props.option.address }}</p>
                      </div>
                    </div>
                  </div>              
                </div>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>

        <!-- PHONE & ADDRESS -->
        <b-field class="fieldRow" grouped>
          <b-field label="Phones" label-position="on-border" expanded>
            <b-input v-model="delivery.phone" type="textarea" rows="2"/>
          </b-field>

          <b-field label="Address" label-position="on-border" expanded>
            <b-input v-model="delivery.address" type="textarea" rows="2"/>
          </b-field>
        </b-field>

        <!-- TODO: Finish implementing -->
        <b-field class="fieldRow">
          <b-field label="Coordinates" label-position="on-border" expanded>
            <b-input v-model="delivery.coordinates" type="text" />
          </b-field>
        </b-field>

        <!-- PRODUCT & QUANTITY -->
        <b-field class="fieldRow" grouped>
          <b-field label="Materials" label-position="on-border" ref="material" expanded>
            <b-input v-model="delivery.product" type="textarea" rows="2"/>
          </b-field>

          <b-field label="Quantities" label-position="on-border" expanded>
            <b-input v-model="delivery.quantity" type="textarea" rows="2"/>
          </b-field>
        </b-field>

        <!-- <b-field label="Carrier" label-position="on-border" expanded>
          <b-input v-model="delivery.carrier"/>
        </b-field> -->

        <!-- DIRECTIONS & NOTES -->
        <b-field class="fieldRow" grouped>
          <b-field label="Directions" label-position="on-border" expanded>
            <b-input v-model="delivery.directions" type="textarea" rows="6"/>
          </b-field>

          <b-field label="Notes" label-position="on-border" expanded>
            <b-input v-model="delivery.notes" type="textarea" rows="6"/>
          </b-field>
        </b-field>

        <!-- PAID & COMPLETED -->
        <b-field class="fieldRow" grouped>

          <!-- Paid -->
          <b-field style="margin-right: 26pt;">
            <b-checkbox v-model="delivery.paid">Paid</b-checkbox>
          </b-field>

          <!-- Completed -->
          <b-field>
            <b-checkbox v-model="delivery.completed" :disabled="!hasPaid">Completed</b-checkbox>
          </b-field>

          <!-- Enter Directions Later -->
          <b-field>
            <b-checkbox v-model="delivery.directionsReminder" :disabled="delivery.directions.length > 0 || delivery.directions !== ''">Enter directions later</b-checkbox>
          </b-field>

          <b-field>
            <div style="margin-right: 30pt;"></div>
          </b-field>
        </b-field>

      </section>

      <!-- FOOTER -->
      <footer>
        <div class="card">
          <div style="display: flex; justify-content: space-between; padding: 6pt;">
            <div>
              <!-- <div v-if="!isNew">
                <b-button
                  type="is-danger"
                  icon-left="delete"
                  @click="confirmDelete(this.delivery._id)"
                >Delete</b-button>
              </div> -->
            </div>
            <div>
              <b-button 
                id="cancelButton"
                type="is-primary" 
                @click="clearAndClose"
                tabindex="-1"
              >Cancel</b-button>
              <b-button 
                type="is-primary"
                @click="saveDelivery"
                icon-left="content-save"
              >Save</b-button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
// import dayjs from 'dayjs'
import DeliveryCustomerDialog from './DeliveryCustomerDialog'
import UpdateCustomerDialog from './UpdateCustomerDialog'

export default {
  props: [ 'show', 'isNew', 'passDelivery', 'passCustomer' ],

  emits: [ 'cancelDeliveryDialog' ],

  components: {
    DeliveryCustomerDialog,
    UpdateCustomerDialog
  },

  data: () => ({
    debug: false,
    showDeliveryCustomerDialog: false,
    isNewDeliveryCustomer: false,
    editCustomer: undefined,
    showUpdateCustomerDialog: false,

    cachePhone: '',
    cacheAddress: '',
    cacheCoordinates: '',
    cacheDirections: '',

    customerUpdates: {},

    customerData: {},

    customerObj: {
      name: '',
      phone: '',
      address: '',
      coordinates: '',
      directions: '',
      isActive: true
    },

    delivery: {
      deliveryDate: undefined,
      customerId: undefined,
      customer: undefined,
      phone: '',
      address: '',
      coordinates: '',
      product: '',
      quantity: '',
      carrier: '',
      notes: '',
      directions: '',
      completed: false,
      paid: false,
      dateCreated: new Date(),
      dateModified: undefined,
      directionsReminder: false,
    },

    emptyDelivery: {
      deliveryDate: undefined,
      customerId: undefined,
      customer: undefined,
      phone: '',
      address: '',
      coordinates: '',
      product: '',
      quantity: '',
      carrier: '',
      notes: '',
      directions: '',
      completed: false,
      paid: false,
      dateCreated: new Date(),
      dateModified: undefined,
      directionsReminder: false
    },

    formErrors: []
  }),

  mounted () {
    this.$store.dispatch('deliveryCustomers/fetchDeliveryCustomers')
  },

  computed: {
    customers () {
      return this.$store.getters['deliveryCustomers/allDeliveryCustomers']
    },

    hasPaid () {
      return this.delivery.paid ? true : false
    },

    filteredDataArray() {
      let customerName = this.delivery.customer
      if (customerName && customerName !== '') {
        return this.customers.filter((option) => {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(customerName.toLowerCase()) >= 0
        })
      } else {
        return null
      }
    }
  },

  methods: {

    updateCacheObj (deliveryCustomer) {
      // console.log('updateCacheObj() with deliveryCustomer: ', deliveryCustomer)

      this.cachePhone = deliveryCustomer.phone
      this.cacheAddress = deliveryCustomer.address
      this.cacheCoordinates = deliveryCustomer.coordinates
      this.cacheDirections = deliveryCustomer.directions

      // Update customerUpdates so all cache is up-to-date
      this.customerUpdates.oldPhone = deliveryCustomer.phone
      this.customerUpdates.oldAddress = deliveryCustomer.address
      this.customerUpdates.oldCoordinates = deliveryCustomer.coordinates
      this.customerUpdates.oldDirections = deliveryCustomer.directions

      
    },

    loadCustomerInfo (customer) {
      // console.log(customer)
      if (customer) {
        this.delivery.customerId = customer._id
        this.delivery.phone = customer.phone
        this.delivery.address = customer.address
        this.delivery.coordinates = customer.coordinates
        this.delivery.directions = customer.directions

        this.cachePhone = customer.phone
        this.cacheAddress = customer.address
        this.cacheCoordinates = customer.coordinates
        this.cacheDirections = customer.directions

        // this.printCache()
      }
      // this.$refs.material.focus()
    },

    clearAndClose () {
      this.delivery = { ...this.emptyDelivery }
      this.$emit('cancelDeliveryDialog')
    },

    checkForm () {
      // Check for required fields (date, customer)

      this.formErrors = []

      if (!this.delivery.deliveryDate || this.delivery.deliveryDate === '') {
        this.formErrors.push('A delivery date must be provided')
      }

      if (!this.delivery.customer || this.delivery.customer === '') {
        this.formErrors.push('You must provide a customer name')
      }

      if (!this.delivery.product || this.delivery.product === '') {
        this.formErrors.push("You must provide at least one material")
      }

      return this.formErrors.length > 0 ? false : true
    },

    async saveDelivery () {

      // Validate form
      const checkPromise = new Promise((resolve, reject) => {
        if (this.checkForm()) {
          resolve()
        } else {
          reject()
        }
      })

      checkPromise.then(async () => {
        if (this.isNew) {
          // Create new delivery

          // this.findDeliveryCustomerMatches(this.delivery.customer)

          if (this.delivery && this.delivery.customerId) {
            // console.log('Creating new delivery for CustomerID: ', this.delivery.customerId)

            try {
              await this.$store.dispatch('deliveries/createDelivery', this.delivery)
      
              this.$buefy.toast.open({
                message: 'Delivery created',
                position: 'is-bottom',
                type: 'is-success',
              })
      
              this.clearAndClose()
      
            } catch (error) {
              console.error(error.message)
      
              this.$buefy.toast.open({
                message: 'Error while creating delivery! Check logs',
                position: 'is-bottom',
                type: 'is-danger',
                duration: 8000
              })
            } 
          } else {
            // console.log('TODO: Prompt user to create a new DeliveryCustomer...')

            this.$buefy.dialog.confirm({
              title: 'Add Delivery Customer?',
              message: `<strong>${this.delivery.customer}</strong> was not found in the system.`,
              confirmText: 'Add Customer',
              cancelText: 'Cancel',
              onConfirm: () => {
                this.createCustomerOnTheFly()
              }
            })
          }       
        } else {
          // Update existing delivery
          this.delivery.dateModified = new Date()

          const updatePromise = new Promise((resolve) => {
            resolve(this.checkForUpdatedCustomerInfo())
          })

          updatePromise.then(async () => {
            try {
  
              await this.$store.dispatch('deliveries/updateDelivery', this.delivery)
  
              this.$buefy.toast.open({
                message: 'Delivery updated',
                position: 'is-bottom',
                type: 'is-success',
              })
  
              this.clearAndClose()
  
            } catch (error) {
              console.error(error.message)
  
              this.$buefy.toast.open({
                message: 'Error while updating delivery! Check logs',
                position: 'is-bottom',
                type: 'is-danger',
                duration: 5000
              })
            }
          }).catch(error => console.error(error))

        }
      }, () => {

        let errors = ``

        for (let i=0; i<this.formErrors.length; i++) {
          errors += `<p style="color: red; font-weight: bold;">- ${this.formErrors[i]}</p>`
        }


        this.$buefy.dialog.alert({
          title: 'Form Errors',
          message: `Please correct the following errors: <br/><br/> ${errors}`,
          type: 'is-danger',
          hasIcon: true,
          ariaRole: 'alertdialog',
          ariaModel: true
        })
        console.error(this.formErrors)
      })
    },

    listMatchingCustomers (customers) {
      let str = ``
      for (let i=0; i<customers.length; i++) {
        // <strong>Name:</strong> ${matches.customers[0].name}<br/>
        // <strong>Phone:</strong> ${matches.customers[0].phone}<br/>
        // <strong>Address:</strong> ${matches.customers[0].address}<br/><br/>
        str += `<div style="margin-bottom: 9pt;">
          <strong>Name: </strong> ${customers[i].name}<br/>
          <strong>Phone: </strong> ${customers[i].phone}<br/>
          <strong>Address: </strong> ${customers[i].address}<br/>
        </div>`
      }

      return str
    },

    async createDelivery () {
      try {

        this.delivery.dateCreated = new Date()

        await this.$store.dispatch('deliveries/createDelivery', this.delivery)

        this.$buefy.toast.open({
          message: 'Delivery created',
          position: 'is-bottom',
          type: 'is-success',
        })

        this.clearAndClose()

      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while creating delivery! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }
    },

    // confirmDelete (id) {
    //   console.log('ID TO DELETE: ', id)
    // },

    async createCustomerOnTheFly () {
      // console.log('DELIVERY: ', this.delivery)

      let customerCreated = new Promise((resolve, reject) => {
        // Gather customer details from delivery

        this.customerObj = {
          name: this.delivery.customer,
          phone: this.delivery.phone,
          address: this.delivery.address,
          coordinates: this.delivery.coordinates,
          directions: this.delivery.directions,
          isActive: true
        }

        // console.log('DeliveryCustomer to create: ', this.customerObj)

        try {
          this.$store.dispatch('deliveryCustomers/createDeliveryCustomer', this.customerObj).then(data => {
            if (!data) {
              console.error('ERROR: No data returned from server (inside createCustomerOnTheFly)')
              reject('ERROR: No data returned from server (inside createCustomerOnTheFly)')
            }

            if (data._id) {
              this.delivery.customerId = data._id

              this.$buefy.toast.open({
              message: 'Customer created',
              position: 'is-bottom',
              type: 'is-success',
            })

            resolve()

            } else {
              console.error('(inside createCustomerOnTheFly()): Created a new deliveryCustomer (should have) but missing ._id needed to create the delivery...')
            }
            // console.log('CU DATA: ', data)
          })
        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: 'Error while creating delivery customer! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
          reject('Error while creating delivery customer! Check logs')
        }
      })

      customerCreated.then(async () => {
        // ------------- CREATE THE DELIVERY -----------------------
        try {
  
          // console.log('customberObj.phone: ', this.customerObj.phone)
          const cust = this.$store.getters['deliveryCustomers/deliveryCustomerByPhone'](this.customerObj.phone)
          // console.log('cust: ', cust)

          if (!cust || cust === '' || cust === undefined || cust === null) {
            return console.error('ERROR inside createCustomerOnTheFly() while creating delivery: cust is falsey, and ._id is needed for the delivery to be created successfully..')
          }
  
          this.delivery.customerId = cust._id
          // this.delivery.customerId = this.TEST

          // console.log('Delivery to create: ', this.delivery)
  
          await this.$store.dispatch('deliveries/createDelivery', this.delivery)
  
          this.$buefy.toast.open({
            message: 'Delivery created',
            position: 'is-bottom',
            type: 'is-success',
          })
  
          this.clearAndClose()
  
        } catch (error) {
          console.error(error.message)
  
          this.$buefy.toast.open({
            message: 'Error while creating delivery! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 8000
          })
        } 
      }).catch(err => console.error(err.message))


    },

    printCache () {
      // console.log('cachePhone: ', this.cachePhone)
      // console.log('cacheAddress: ', this.cacheAddress)
      // console.log('cacheDirections: ', this.cacheDirections)
    },

    checkForUpdatedCustomerInfo () {

      console.log('inside checkForUpdatedCustomerInfo()...')
      console.log('Customer Data variable: ', this.customerData)

      let hasUpdates = false

      // if (this.delivery.phone !== this.cachePhone) {
      //   this.customerUpdates.oldPhone = this.cachePhone
      //   this.customerUpdates.newPhone = this.delivery.phone
      // }
      if (this.delivery.phone !== this.customerData.phone) {
        this.customerUpdates.oldPhone = this.customerData.phone
        this.customerUpdates.newPhone = this.delivery.phone
        hasUpdates = true 
      }

      // if (this.delivery.address !== this.cacheAddress) {
      //   this.customerUpdates.oldAddress = this.cacheAddress
      //   this.customerUpdates.newAddress = this.delivery.address
      // }
      if (this.delivery.address !== this.customerData.address) {
        this.customerUpdates.oldAddress = this.customerData.address
        this.customerUpdates.newAddress = this.delivery.address
        hasUpdates = true 
      }

      // if (this.delivery.coordinates !== this.cacheCoordinates) {
      //   this.customerUpdates.oldCoordinates = this.cacheCoordinates
      //   this.customerUpdates.newCoordinates = this.delivery.coordinates
      // }
      if (this.delivery.coordinates !== this.customerData.coordinates) {
        this.customerUpdates.oldCoordinates = this.customerData.coordinates
        this.customerUpdates.newCoordinates = this.delivery.coordinates
        console.log('Old Coords: ', this.customerUpdates.oldCoordinates)
        console.log('New Coords: ', this.customerUpdates.newCoordinates)
        hasUpdates = true 
      }

      // if (this.delivery.directions !== this.cacheDirections) {
      //   this.customerUpdates.oldDirections = this.cacheDirections
      //   this.customerUpdates.newDirections = this.delivery.directions
      // }
      if (this.delivery.directions !== this.customerData.directions) {
        console.log('Directions are same: ', this.delivery.directions === this.customerData.directions)
        this.customerUpdates.oldDirections = this.customerData.directions
        this.customerUpdates.newDirections = this.delivery.directions
        hasUpdates = true 
      }

      console.log('Delivery Customer Updates: ', this.customerUpdates)

      // SHOW A MODAL AND ASK WHICH ITEMS USER WANTS TO SERVE
      if (this.customerUpdates.newPhone && hasUpdates || 
          this.customerUpdates.newAddress && hasUpdates || 
          this.customerUpdates.newCoordinates && hasUpdates || 
          this.customerUpdates.newDirections && hasUpdates) 
      {
        
        // let phoneChanged = null
        // let addressChanged = null
        // let coordinatesChanged = null
        // let directionsChanged = null

        // if (this.customerUpdates.oldPhone && this.customerUpdates.newPhone) {
        //   if (this.customerUpdates.oldPhone !== this.customerUpdates.newPhone) {
        //     phoneChanged = true
        //   }
        // }

        this.customerUpdates._id = this.delivery.customerId
        // console.log('[changes detected] customerUpdates: ')
        // console.log(this.customerUpdates)
        this.showUpdateCustomerDialog = true

        // if (this.customerUpdates.oldAddress && this.customerUpdates.newAddress) {
        //   if (this.customerUpdates.oldAddress !== this.customerUpdates.newAddress) {
        //     addressChanged = true
        //   }
        // }

        // if (this.customerUpdates.oldCoordinates && this.customerUpdates.newCoordinates) {
        //   if (this.customerUpdates.oldCoordinates !== this.customerUpdates.newCoordinates) {
        //     coordinatesChanged = true
        //   }
        // }

        // if (this.customerUpdates.oldDirections && this.customerUpdates.newDirections) {
        //   if (this.customerUpdates.oldDirections !== this.customerUpdates.newDirections) {
        //     directionsChanged = true
        //   }
        // }

        // if (phoneChanged || addressChanged || coordinatesChanged || directionsChanged) {
        //   this.customerUpdates._id = this.delivery.customerId
        //   // console.log('[changes detected] customerUpdates: ')
        //   // console.log(this.customerUpdates)
        //   this.showUpdateCustomerDialog = true
        // } else {
        //   console.log('Changed detected, but it\'s the same value..')
        // }



      } else {
        // console.log('[nothing changed] customerUpdates: ')
        // console.log(this.customerUpdates)
        this.showUpdateCustomerDialog = false
        return
      }

    },

    cancelUpdateCustomerDialog () {
      this.showUpdateCustomerDialog = false
    }
  },

  watch: {
    show () {
      if (this.passDelivery) {
        this.delivery = {
          ...this.passDelivery,
          deliveryDate: new Date(this.passDelivery.deliveryDate)
        }
      } else {
        this.delivery = { ...this.emptyDelivery }
      }

      if (this.isNew) {
        // console.log('New delivery...')
        if (this.passCustomer) {
          this.delivery = { ...this.emptyDelivery }
          // console.log('Prefilling delivery with customer info...', this.passCustomer)
          this.delivery.deliveryDate = new Date()
          this.delivery.customer = this.passCustomer.name
          this.delivery.customerId = this.passCustomer._id
          this.delivery.phone = this.passCustomer.phone
          this.delivery.address = this.passCustomer.address
          this.delivery.coordinates = this.passCustomer.coordinates
          this.delivery.directions = this.passCustomer.directions

          console.log('Loading customer data: ')
          this.customerData = { ...this.passCustomer }
          console.log(this.customerData)
        } else {
          // console.log('No customer info to prefill...')

          this.delivery = { ...this.emptyDelivery }
          this.delivery.deliveryDate = new Date()
        }
      } else {
        // console.log('Existing delivery...')
        // console.log(this.passCustomer)
        if (this.passCustomer) {
          // console.log('Passing existing customer...')
          this.delivery.customerId = this.passCustomer._id

          // Load customer data
          console.log('Loading customer data: ')
          this.customerData = { ...this.passCustomer }
          console.log(this.customerData)

          // CACHE DELIVERY CUSTOMER FIELDS IN ORDER TO LATER COMPARE WHEN SAVING
          this.cachePhone = this.passCustomer.phone
          this.cacheAddress = this.passCustomer.address
          this.cacheCoordinates = this.passCustomer.coordinates
          this.cacheDirections = this.passCustomer.directions

          // console.log('Printing cache...')
          // this.printCache()
        }

      }
    },

    delivery: {
      deep: true,
      handler() {
        if (this.delivery.directions.length > 0) {
          this.delivery.directionsReminder = false
        } else {
          this.delivery.directionsReminder = true
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#cancelButton {
  background-color: rgb(37, 37, 37);
  color: white;
  margin-right: 10pt;
}
.fieldRow {
  margin-bottom: 14pt;
}
</style>