<template>
<div>
  <h1>Welcome {{ activeUser.name }}</h1>
</div>
</template>

<script>
export default {
  name: 'LoggedIn',
  data () {
    return {
      activeUser: {
        name: null
      }
    }
  },
  async created () {
    await this.refreshActiveUser()
  },
  methods: {
    async refreshActiveUser () {
      this.activeUser = await this.$auth.getUser()
    },
  }
}
</script>