
// OFFICE > TASKS > ACTIONS

import dayjs from 'dayjs'
import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchAllTasks (context) {
    // console.log('fetching all tasks...')
    axios.get(`${API_URL}/tasks`).then(res => {
      const tasks = res.data

      context.commit('setTasklist', tasks)
    })
  },

  fetchTasksByUserId (context, userId) {
    axios.get(`${API_URL}/tasks/user/${userId}`).then(res => {
      let list = res.data
      // console.log('TASKS FOR CURRENT USER FETCHED: ', list)
      context.commit('setTasklist', list)
    }).catch(err => {
      console.error('[store/office/tasks/fetchTasksByUserId]: An error occurred while fetching user tasks from database: ')
      console.error(err.message)
    })
  },

  // setUserTasklist (context, payload) {
  //   // payload: { tasklist [arr], userId [str] } 
  // },

  async createTask (context, task) {

    const deadline = task.deadline ? dayjs(task.deadline).format('MM/DD/YYYY hh:mm') : null
    const dateCreated = task.dateCreated ? dayjs(task.dateCreated).format('MM/DD/YYYY hh:mm') : dayjs(new Date()).format('MM/DD/YYYY hh:mm')

    const taskObj = {
      userId: task.userId,
      dateCreated,
      deadline,
      text: task.text,
      status: task.status,
      isActive: task.isActive
    }
    
    // Store in database
    axios.post(`${API_URL}/tasks`, taskObj).then(res => {
      const addedTask = res.data

      context.commit('addTask', addedTask)
    })
  },

  deleteTask (context, taskId) {
    // Remove task from database
    axios.delete(`${API_URL}/tasks/${taskId}`).then(() => {
      // Save this list to state
      context.commit('removeTask', taskId)
    })
  },

  async updateTask (context, task) {

    // task.status = task.status.value
    
    const sendTask = {
      ...task
    }

    // console.log('updating task to: ')
    // console.log(sendTask)

    axios.patch(`${API_URL}/tasks/${task._id}`, sendTask).then(res => {
      const taskObj = res.data

      // console.log('TaskObj for mutation: ')
      // console.log(taskObj)
      context.commit('updateTask', taskObj)
    })
  }
}