<template>
<div class="card" style="max-height: 400pt; height: 400pt; overflow-y: scroll;">

  <DeliveryDialog
    :show="showDeliveryDialog"
    :loading="deliveriesLoading"
    :isNew="isNewDelivery"
    :passDelivery="deliveryToEdit"
    :passCustomer="passDeliveryCustomer"
    @cancelDeliveryDialog="cancelDeliveryDialog"
  />

  <header class="deskHeader">
    <div class="level">
      <div class="level-left">
        <p class="card-header-title deskCardTitle">Deliveries Today</p>
      </div>
      <div class="level-right p-2">
        <b-button type="is-light" size="is-small" class="deskBtn deskManageBtn" style="margin-right: 8pt;" tag="router-link" to="/office/deliveries">Manage</b-button>
        <b-button type="is-light" size="is-small" class="deskBtn deskActionBtn" icon-left="plus-thick" @click="createNewDelivery"></b-button>
      </div>
    </div>
  </header>
  
  <div>
    <b-table
      :data="filteredDeliveries"
      :loading="deliveriesLoading"
      ref="table"
      detailed
      detail-key="_id"
      :opened-detailed="defaultOpenedDetails"
      :detail-transition="transitionName"
      :show-detail-icon="showDetailIcon"
      :mobile-cards="true"
      :sticky-header="true"
      height="600px"
      narrowed
    >
      <b-table-column field="completed" label="Completed" v-slot="props">
        <b-icon :class="formatCompletedClass(props.row.completed)" :icon="formatCompletedIcon(props.row.completed)"/>
      </b-table-column>
      <b-table-column field="deliveryDate" label="Deliver" v-slot="props">
        {{ formatShortDate(props.row.deliveryDate) }}
      </b-table-column>

      <b-table-column field="customer" label="Customer" v-slot="props">
        {{ props.row.customer }}
      </b-table-column>

      <b-table-column field="product" label="Product" v-slot="props">
        {{ props.row.product }}
      </b-table-column>

      <template #detail="props">
        <div>
          <b-table>
              <tr>
                <td class="tableText"><strong>PAID: </strong></td>
                <td class="tableText" style="white-space: pre;">
                  <span>
                    <b-icon :class="formatPaidClass(props.row.paid)" :icon="formatPaidIcon(props.row.paid)"></b-icon>
                    <span v-if="props.row.paid" :class="formatPaidClass(props.row.paid)">PAID</span>
                    <span v-else :class="formatPaidClass(props.row.paid)" style="margin-left: 4pt;">NEED PAYMENT</span>
                  </span>
                </td>
              </tr>
              <tr>
                <td class="tableText"><strong>CUSTOMER: </strong></td>
                <td class="tableText" style="white-space: pre;">{{ props.row.customer }}</td>
              </tr>
              <tr>
                <td class="tableText"><strong>PRODUCT: </strong></td>
                <td class="tableText" style="white-space: pre;">{{ props.row.product }}</td>
              </tr>
              <tr>
                <td class="tableText"><strong>QUANTITY: </strong></td>
                <td class="tableText" style="white-space: pre;">{{ props.row.quantity }}</td>
              </tr>
              <tr>
                <td class="tableText"><strong>ADDRESS: </strong></td>
                <td class="tableText" style="white-space: pre;">{{ props.row.address }}</td>
              </tr>
              <tr>
                <td class="tableText"><strong>PHONES: </strong></td>
                <td class="tableText" style="white-space: pre;">{{ props.row.phone }}</td>
              </tr>
              <tr>
                <td class="tableText"><strong>NOTES: </strong></td>
                <td class="tableText" style="white-space: pre;">{{ props.row.notes }}</td>
              </tr>
              <tr>
                <td class="tableText"><strong>COMPLETED: </strong></td>
                <td class="tableText" style="white-space: pre;">
                  <b-icon :class="formatCompletedClass(props.row.completed)" :icon="formatCompletedIcon(props.row.completed)"/>
                </td>
              </tr>
          </b-table>

          <br/>

          <div>
            <b-button icon-left="pencil" class="deskBtn" @click="editDelivery(props.row)" style="margin-right: 8pt;">Edit</b-button>
            <b-button
              type="is-danger"
              icon-left="delete"
              @click="confirmDeleteDelivery(props.row._id)"
            >Delete</b-button>
          </div>
        </div>
      </template>

      <template #empty>
        <div class="has-text-centered"><em>No deliveries found</em></div>
      </template>
    </b-table>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import DeliveryDialog from '@/views/office/Deliveries/components/DeliveryDialog'

export default {
  components: {
    DeliveryDialog
  },
  data: () => ({
    deliveriesLoading: false,
    filteredHauls: [],
    timeframe: 'today',
    defaultOpenedDetails: [1],
    showDetailIcon: true,
    useTransition: true,
    showDeliveryDialog: false,
    isNewDelivery: false,
    deliveryToEdit: undefined,
    passDeliveryCustomer: undefined,
  }),

  mounted () {
    this.loadData()
  },

  computed: {
    allDeliveries () {
      return this.$store.getters['deliveries/allDeliveries']
    },

    deliveriesToday () {
      return this.$store.getters['deliveries/deliveriesToday']
    },

    filteredDeliveries () {
      switch (this.timeframe) {
        case 'today':
          this.setDeliveriesLoading(false)
          return this.deliveriesToday     // TODO: Hardcode for now, make dynamic soon!!
        default:
          // console.log('Empty deliveries, or loading...')
          this.setDeliveriesLoading(false)
          return []
      }
    },

    transitionName () {
      if (!this.useTransition)
        return
  
      return 'fade'
    }
  },

  methods: {
    loadData () {
      this.$store.dispatch('deliveries/fetchDeliveries')
      this.setDeliveriesLoading(true)
    },

    formatShortDate (date) {
      return dayjs(date).format('MM/DD')
    },

    formatPaidIcon (paid) {
      if (!paid)
        return 'currency-usd'
      return 'check-bold'
    },

    formatPaidClass (paid) {
      if (!paid)
        return 'not-paid'
      return 'paid'
    },

    createNewDelivery () {
      this.isNewDelivery = true
      this.passDeliveryCustomer = null
      this.deliveryToEdit = null
      this.showDeliveryDialog = true
    },

    editDelivery (delivery) {
      // console.log('Edit delivery: ', delivery)
      this.isNewDelivery = false
      this.deliveryToEdit = { ...delivery }

      // console.log('DELIVERY TO EDIT: ', this.deliveryToEdit)

      if (delivery.phone) {
        const customer = this.$store.getters['deliveryCustomers/deliveryCustomerByPhone'](delivery.phone)
        this.passDeliveryCustomer = {...customer}
        // console.log('PASSING: ', customer)
      }
      
      this.showDeliveryDialog = true
    },

    setDeliveriesLoading (loading) {
      this.deliveriesLoading = Boolean(loading)
    },

    cancelDeliveryDialog () {
      this.isNewDelivery = false
      this.showDeliveryDialog = false
    },

    confirmDeleteDelivery (deliveryId) {
      this.$buefy.dialog.confirm({
        title: 'Delete Delivery?',
        message: `Are you sure you want to <strong>permanently delete</strong> this delivery? This action cannot be undone!`,
        type: 'is-danger',
        hasIcon: true,
        confirmText: 'Delete Delivery',
        onConfirm: () => { this.deleteDelivery(deliveryId) }
      })
    },

    async deleteDelivery (deliveryId) {
      // console.log('Delete deliveryID: ', deliveryId)
      try {

        await this.$store.dispatch('deliveries/deleteDelivery', deliveryId)

        this.$buefy.toast.open({
          message: `Delivery deleted`,
          type: 'is-success',
          position: 'is-bottom'
        })
        
      } catch (error) {
        console.error(error.message)

        this.$buefy.toast.open({
          message: `Delivery deleted`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 5000
        })
      }
    },

    formatCompletedIcon (completed) {
      if (completed) { 
        return 'check-bold' 
      } else {
        return 'close-thick'
      }
    },

    formatCompletedClass (completed) {
      if (completed) {
        return 'isComplete'
      } else {
        return 'isIncomplete'
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  tr.detail td .detail-container {
    padding: 0 !important;
  }
  .tableText {
    font-size: 10pt;
  }
  .not-paid {
    font-weight: bold;
    color: red;
  }
  .paid {
    font-weight: bold;
    color: green;
  }
  .isComplete {
    font-weight: bold;
    color: green;
  }
  .isIncomplete {
    font-weight: bold;
    color: red;
  }
</style>