import { render, staticRenderFns } from "./CustomHeader.vue?vue&type=template&id=48cb48af&scoped=true&"
import script from "./CustomHeader.vue?vue&type=script&lang=js&"
export * from "./CustomHeader.vue?vue&type=script&lang=js&"
import style0 from "./CustomHeader.vue?vue&type=style&index=0&id=48cb48af&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48cb48af",
  null
  
)

export default component.exports