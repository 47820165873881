
// VENDOR PRODUCTS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchVendorProducts (context) {
    axios.get(`${API_URL}/vendorProducts`).then(res => {
      const products = res.data
      context.commit('setVendorProducts', products)
    })
  },

  // setSelectedProduct (context, productId) {
  //   context.commit('setSelectedProduct', productId)
  // },

  setVendorProducts (context, products) {
    context.commit('setVendorProducts', products)
  },

  fetchRatesToYard (context) {
    axios.get(`${API_URL}/vendorProducts`).then(res => {
      const products = res.data
      const rates = []
      for (let i=0; i<products.length; i++) {
        if (products[i].toYard) {
          rates.push({
            name: `${products[i].name} -> C&H ($${products[i].freight})`,
            value: products[i].freight
          })
        }
      }
      context.commit('setPayRates', rates)
    })
  },

  createVendorProduct (context, product) {
    axios.post(`${API_URL}/vendorProducts`, product).then(res => {
      const vendorProduct = res.data

      // console.log('[vendorProducts actions createVendorProduct()]: Vendor Product saved from server:  ')
      // console.log(vendorProduct)

      context.commit('addVendorProduct', vendorProduct)
    })
  },

  vendorNameById (_, vendorId) {
    axios.get(`${API_URL}/vendors/${vendorId}`).then(res => {
      const vendor = res.data

      return vendor.name
    })
  },

  updateVendorProduct (context, vendorProduct) {
    let vendorProductMinusId = {
      vendorId: vendorProduct.vendorId,
      name: vendorProduct.name,
      code: vendorProduct.code,
      product: vendorProduct.product,
      freight: vendorProduct.freight,
      notes: vendorProduct.notes || '',
      dateModified: vendorProduct.dateModified,
      isLookup: vendorProduct.isLookup,
      toYard: vendorProduct.toYard
    }
    
    axios.patch(`${API_URL}/vendorProducts/${vendorProduct._id}`, vendorProductMinusId).then(res => {
      const newVendorProduct = res.data
      context.commit('updateVendorProduct', newVendorProduct)
    })
  },

  deleteVendorProduct (context, vendorProductId) {
    axios.delete(`${API_URL}/vendorProducts/${vendorProductId}`).then(() => {
      context.commit('removeVendorProduct', vendorProductId)
    })
  }
}