
// HAULS > MUTATIONS

export default {
  setHauls (state, payload) {
    state.hauls = payload
  },

  addHaul (state, haul) {
    state.hauls.push(haul)
  },

  updateHaul (state, payload) {
    let index = state.hauls.findIndex(haul => haul._id === payload._id)
    state.hauls.splice(index, 1, payload)
  },

  removeHaul (state, haulId) {
    let index = state.hauls.findIndex(haul => haul._id === haulId)
    state.hauls.splice(index, 1)
  },

  setFilteredHauls (state, hauls) {
    state.filteredHauls = hauls
  },


  // HAULS REPORT SUMMARY

  setReportHauls (state, haulsList) {
    state.reportHauls = haulsList
  },

  setReportWorkdays (state, workdays) {
    state.reportWorkdays = workdays
  },

  setReportDateStart (state, dateStart) {
    state.reportDateStart = dateStart
  },
  setReportDateEnd (state, dateEnd) {
    state.reportDateEnd = dateEnd
  },

  // eslint-disable-next-line
  setSummaryDriver (state, driver) {
    state.reportDriver = driver
  }
}