<template>
<div>
  <section class="hero is-primary" id="calculatorHero">
    <div class="hero-body">
      <p class="title">Bulk Material Calculator</p>
    </div>
  </section>

  <!-- <div style="height: 20pt" v-if="!shapeChosen"></div> -->

  <!-- SHAPE SELECTOR -->
  <section class="section" style="padding-bottom: 4pt; padding-top: 20pt;">
    <div class="card" style="background-color: #58483F;">
      <div class="card-content">
        <p><strong style="color: white;">Choose the shape that best matches your project</strong></p>
        <b-select
          v-model="shapeChosen"
          style="margin-top: 6pt;"
        >
          <option value="null">Choose Shape...</option>
          <option v-for="(shape, i) in shapes" :key="i">{{ shape }}</option>
        </b-select>
      </div>
    </div>
  </section>


  <!-- #region SHAPES -->

    <!-- SQUARE -->
    <section class="section" v-if="shapeChosen === 'Square'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-square.svg" alt="Square Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>PERFECT SQUARE</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- SQUARE : LENGTH -->
              <b-field grouped>
                <b-field label="Length" label-position="on-border">
                  <b-input v-model="square.length"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="square.lengthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- SQUARE : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="square.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="square.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- SQUARE : RESULTS -->
              <div class="columns" v-if="square.length && square.lengthUnit && square.depth && square.depthUnit">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsSquare }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsSquare) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- RECTANGLE -->
    <section class="section" v-if="shapeChosen === 'Rectangle'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-rectangle.svg" alt="Rectangle Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>RECTANGLE</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- RECTANGLE : LENGTH -->
              <b-field grouped>
                <b-field label="Length" label-position="on-border">
                  <b-input v-model="rectangle.length"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangle.lengthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- RECTANGLE : WIDTH -->
              <b-field grouped>
                <b-field label="Width" label-position="on-border">
                  <b-input v-model="rectangle.width"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangle.widthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- RECTANGLE : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="rectangle.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangle.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- RECTANGLE: RESULTS -->
              <div class="columns" v-if="rectangle.length && rectangle.lengthUnit && rectangle.width && rectangle.widthUnit && rectangle.depth && rectangle.depthUnit">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsRectangle }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsRectangle) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- RECTANGLE BORDER -->
    <section class="section" v-if="shapeChosen === 'Rectangle Border'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-rectangleBorder.svg" alt="Rectangle Border Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>RECTANGLE BORDER</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- RECTANGLE BORDER : LENGTH -->
              <b-field grouped>
                <b-field label="Inner Length" label-position="on-border">
                  <b-input v-model="rectangleBorder.innerLength"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangleBorder.innerLengthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- RECTANGLE BORDER : INNER WIDTH -->
              <b-field grouped>
                <b-field label="Inner Width" label-position="on-border">
                  <b-input v-model="rectangleBorder.innerWidth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangleBorder.innerWidthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- RECTANGLE BORDER : BORDER WIDTH -->
              <b-field grouped>
                <b-field label="Border Width" label-position="on-border">
                  <b-input v-model="rectangleBorder.borderWidth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangleBorder.borderWidthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- RECTANGLE BORDER : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="rectangleBorder.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="rectangleBorder.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- RECTANGLE BORDER: RESULTS -->
              <div class="columns" v-if="rectangleBorder.innerLength && rectangleBorder.innerWidth && rectangleBorder.borderWidth && rectangleBorder.depth">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsRectangleBorder }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsRectangleBorder) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- TRIANGLE -->
    <section class="section" v-if="shapeChosen === 'Triangle'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-triangle.svg" alt="Triangle Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>TRIANGLE</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- TRIANGLE : SIDE A -->
              <b-field grouped>
                <b-field label="Side A" label-position="on-border">
                  <b-input v-model="triangle.sideA"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="triangle.sideAUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- TRIANGLE : SIDE B -->
              <b-field grouped>
                <b-field label="Side B" label-position="on-border">
                  <b-input v-model="triangle.sideB"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="triangle.sideBUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- TRIANGLE : SIDE C -->
              <b-field grouped>
                <b-field label="Side C" label-position="on-border">
                  <b-input v-model="triangle.sideC"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="triangle.sideCUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- TRIANGLE : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="triangle.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="triangle.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- TRIANGLE: RESULTS -->
              <div class="columns" v-if="triangle.sideA && triangle.sideB && triangle.sideC && triangle.depth">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsTriangle }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsTriangle) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- TRAPEZOID -->
    <section class="section" v-if="shapeChosen === 'Trapezoid'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-trapezoid.svg" alt="Trapezoid Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>TRAPEZOID</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- TRAPEZOID : SIDE A -->
              <b-field grouped>
                <b-field label="Side A" label-position="on-border">
                  <b-input v-model="trapezoid.sideA"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="trapezoid.sideAUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- TRAPEZOID : SIDE B -->
              <b-field grouped>
                <b-field label="Side B" label-position="on-border">
                  <b-input v-model="trapezoid.sideB"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="trapezoid.sideBUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- TRAPEZOID : HEIGHT (H) -->
              <b-field grouped>
                <b-field label="Height (H)" label-position="on-border">
                  <b-input v-model="trapezoid.height"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="trapezoid.heightUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- TRAPEZOID : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="trapezoid.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="trapezoid.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- TRAPEZOID : RESULTS -->
              <div class="columns" v-if="trapezoid.sideA && trapezoid.sideB && trapezoid.height && trapezoid.depth">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsTrapezoid }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsTrapezoid) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CIRCLE -->
    <section class="section" v-if="shapeChosen === 'Circle'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-circle.svg" alt="Circle Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>CIRCLE</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- CIRCLE : DIAMETER -->
              <b-field grouped>
                <b-field label="Diameter" label-position="on-border">
                  <b-input v-model="circle.diameter"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="circle.diameterUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- CIRCLE : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="circle.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="circle.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- CIRCLE : RESULTS -->
              <div class="columns" v-if="circle.diameter && circle.depth">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsCircle }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsCircle) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- CIRCLE BORDER -->
    <section class="section" v-if="shapeChosen === 'Circle Border'" style="padding-top: 0;">
      <div class="card">
        <div class="columns">
          <div class="column is-one-third">
            <div class="card-content">
              <img src="@/assets/calculator/diagram-circleBorder.svg" alt="Circle Border Diagram" />
            </div>
          </div>
          <div class="column is-two-thirds">
            <div class="card-content">
              <strong>CIRCLE BORDER</strong>
              <br/>
              <p>Your material is represented by the green shaded area</p>
              <br/>

              <!-- CIRCLE BORDER : INNER DIAMETER -->
              <b-field grouped>
                <b-field label="Inner Diameter" label-position="on-border">
                  <b-input v-model="circleBorder.innerDiameter"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="circleBorder.innerDiameterUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- CIRCLE BORDER : BORDER WIDTH -->
              <b-field grouped>
                <b-field label="Border Width" label-position="on-border">
                  <b-input v-model="circleBorder.borderWidth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="circleBorder.borderWidthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <!-- CIRCLE BORDER : DEPTH -->
              <b-field grouped>
                <b-field label="Depth" label-position="on-border">
                  <b-input v-model="circleBorder.depth"/>
                </b-field>
                <b-field label="Unit" label-position="on-border">
                  <b-select v-model="circleBorder.depthUnit">
                    <option value="ft">ft</option>
                    <option value="in">in</option>
                  </b-select>
                </b-field>
              </b-field>

              <br/>

              <!-- CIRCLE BORDER : RESULTS -->
              <div class="columns" v-if="circleBorder.innerDiameter && circleBorder.borderWidth && circleBorder.depth">
                <div class="column is-one-third">
                  <p><strong>Result: </strong> About {{ resultsCircleBorder }} cubic yard(s)</p>
                  <p>({{ figureScoops(resultsCircleBorder) }})</p>
                </div>
                <div class="column is-two-thirds">
                  <div style="max-width: 300px">
                    <img src="@/assets/calculator/skidsteer.png" alt="Half-yard skid steer"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  <!-- #endregion -->


    <!-- DIRECTIONS -->
  <section class="section">
    <!-- INSTRUCTIONS -->
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Instructions</p>
      </header>
      <div class="card-content">
        <p>
          <strong>This calculator can help you determine about how many cubic yards of material you need for your project.</strong>
        </p>
        <div class="content">
          <ol>
            <li>Choose a shape above and look at the diagram</li>
            <li>Measure each dimension of your project according to the diagram</li>
            <li>Type in each measurement as indicated above, and click 'Calculate'</li>
            <li>The result will be displayed in cubic yards</li>
          </ol>
          <br/>

          <p>
            <strong>We strongly recommend rounding up to the nearest cubic yard, or better.</strong>
           <br/>In most cases it's usually better to have extra than not enough
          </p>

          <b-message title="DISCLAIMER" type="is-warning" has-icon :closable="false">
            <div>
              <strong>These formulas are designed for smaller bulk materials only (topsoil, sand, mulch, and 1"-1.5" gravels)</strong>
              <br/><strong>This calculator serves as a rough estimate only and should not be considered an absolution.</strong>
            </div>
          </b-message>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  data: () => ({
    instructionsOpen: false,
    shapeChosen: null,

    shapes: [
      'Square',
      'Rectangle',
      'Rectangle Border',
      'Triangle',
      'Trapezoid',
      'Circle',
      'Circle Border'
    ],

    square: {
      length: '',
      lengthUnit: 'ft',
      depthUnit: 'in'
    },

    rectangle: {
      length: '',
      width: '',
      depth: '',
      lengthUnit: 'ft',
      widthUnit: 'ft',
      depthUnit: 'in'
    },

    rectangleBorder: {
      innerLength: '',
      innerWidth: '',
      borderWidth: '',
      depth: '',

      innerLengthUnit: 'ft',
      innerWidthUnit: 'ft',
      borderWidthUnit: 'ft',
      depthUnit: 'in'
    },

    triangle: {
      sideA: '',
      sideB: '',
      sideC: '',
      depth: '',

      sideAUnit: 'ft',
      sideBUnit: 'ft',
      sideCUnit: 'ft',
      depthUnit: 'in'
    },

    trapezoid: {
      sideA: '',
      sideB: '',
      height: '',
      depth: '',

      sideAUnit: 'ft',
      sideBUnit: 'ft',
      heightUnit: 'ft',
      depthUnit: 'in'
    },

    circle: {
      diameter: '',
      depth: '',

      diameterUnit: 'ft',
      depthUnit: 'in'
    },

    circleBorder: {
      innerDiameter: '',
      borderWidth: '',
      depth: '',

      innerDiameterUnit: 'ft',
      borderWidthUnit: 'ft',
      depthUnit: 'in'
    }
  }),

  computed: {
    resultsSquare () {
      if (this.square.length !== '') {
        let LENGTH, DEPTH, RESULT

        this.square.lengthUnit === 'in' ? LENGTH = parseFloat(this.square.length) / 12 : LENGTH = parseFloat(this.square.length)
        this.square.depthUnit === 'in' ? DEPTH = parseFloat(this.square.depth) / 12 : DEPTH = parseFloat(this.square.depth)

        RESULT = ((LENGTH * LENGTH * DEPTH) / 27.00).toFixed(2)

        // console.log(`L:${LENGTH} x L:${LENGTH} @ D:${DEPTH} = Result:   ${RESULT} cu yds`)

        return RESULT
      } else {
        return ''
      }
    },

    resultsRectangle () {
      if (this.rectangle.length !== '' && this.rectangle.width !== '' && this.rectangle.depth !== '') {
        let LENGTH, WIDTH, DEPTH, RESULT

        this.rectangle.lengthUnit === 'in' ? LENGTH = parseFloat(this.rectangle.length) / 12 : LENGTH = parseFloat(this.rectangle.length)
        this.rectangle.widthUnit === 'in' ? WIDTH = parseFloat(this.rectangle.width) / 12 : WIDTH = parseFloat(this.rectangle.width)
        this.rectangle.depthUnit === 'in' ? DEPTH = parseFloat(this.rectangle.depth) / 12 : DEPTH = parseFloat(this.rectangle.depth)

        RESULT = ((LENGTH * WIDTH * DEPTH) / 27.00).toFixed(2)

        // console.log(`L:${LENGTH} x W:${WIDTH} @ D:${DEPTH} = Result:   ${RESULT} cu yds`)

        return RESULT
      } else {
        return ''
      }
    },

    resultsRectangleBorder () {
      if (this.rectangleBorder.innerLength !== '' && this.rectangleBorder.innerWidth !== '' && this.rectangleBorder.borderWidth !== '' && this.rectangleBorder.depth !== '') {
        let INNER_LENGTH, INNER_WIDTH, BORDER_WIDTH, DEPTH, RESULT

        this.rectangleBorder.innerLengthUnit === 'in' ? INNER_LENGTH = parseFloat(this.rectangleBorder.innerLength) / 12 : INNER_LENGTH = parseFloat(this.rectangleBorder.innerLength)
        this.rectangleBorder.innerWidthUnit === 'in' ? INNER_WIDTH = parseFloat(this.rectangleBorder.innerWidth) / 12 : INNER_WIDTH = parseFloat(this.rectangleBorder.innerWidth)
        this.rectangleBorder.borderWidthUnit === 'in' ? BORDER_WIDTH = parseFloat(this.rectangleBorder.borderWidth) / 12 : BORDER_WIDTH = parseFloat(this.rectangleBorder.borderWidth)
        this.rectangleBorder.depthUnit === 'in' ? DEPTH = parseFloat(this.rectangleBorder.depth) / 12 : DEPTH = parseFloat(this.rectangleBorder.depth)

        // RESULT = ((LENGTH * WIDTH * DEPTH) / 27.00).toFixed(2)

        let innerArea = INNER_LENGTH * INNER_WIDTH
        // console.log('innerArea: ', innerArea)

        let totalArea = (INNER_LENGTH + (2 * BORDER_WIDTH)) * (INNER_WIDTH + (2 * BORDER_WIDTH))
        // console.log('Calculating TOTAL AREA: ')
        // console.log(`(INNER_LENGTH + (2 * BORDER_WIDTH)) * (INNER_WIDTH + (2 * BORDER_WIDTH))`)
        // console.log(`(${INNER_LENGTH} + (2 * ${BORDER_WIDTH})) * (${INNER_WIDTH} + (2 * ${BORDER_WIDTH}))`)
        // console.log(`totalArea: ${totalArea}`)

        // console.log('Calculating AREA: ')
        let area = parseFloat(totalArea) - parseFloat(innerArea)
        // console.log('Area: ', area)
        RESULT = ((DEPTH * area) / 27.00).toFixed(2)

        return RESULT
      } else {
        return ''
      }
    },

    resultsTriangle () {
      if (this.triangle.sideA !== '' && this.triangle.sideB !== '' && this.triangle.sideC !== '' && this.triangle.depth !== '') {
        let SIDE_A, SIDE_B, SIDE_C, DEPTH, RESULT

        this.triangle.sideAUnit === 'in' ? SIDE_A = parseFloat(this.triangle.sideA) / 12 : SIDE_A = parseFloat(this.triangle.sideA)
        this.triangle.sideBUnit === 'in' ? SIDE_B = parseFloat(this.triangle.sideB) / 12 : SIDE_B = parseFloat(this.triangle.sideB)
        this.triangle.sideCUnit === 'in' ? SIDE_C = parseFloat(this.triangle.sideC) / 12 : SIDE_C = parseFloat(this.triangle.sideC)
        this.triangle.depthUnit === 'in' ? DEPTH = parseFloat(this.triangle.depth) / 12 : DEPTH = parseFloat(this.triangle.depth)

        /* FORMULA: 
            Area = (1/4) * sqrt[ (a+b+c) * (b+c-a) * (c+a-b) * (a+b-c) ]
            RESULT = (Depth * Area) / 27
        */

        // console.log('Side A: ', SIDE_A)
        // console.log('Side B: ', SIDE_B)
        // console.log('Side C: ', SIDE_C)

        let iter1 = SIDE_A + SIDE_B + SIDE_C
        let iter2 = SIDE_B + SIDE_C - SIDE_A
        let iter3 = SIDE_C + SIDE_A - SIDE_B
        let iter4 = SIDE_A + SIDE_B - SIDE_C

        let sub = 0.25 * Math.sqrt(iter1 * iter2 * iter3 * iter4)

        sub = DEPTH * sub

        RESULT = (sub / 27.00).toFixed(2)

        return RESULT
      } else {
        return ''
      }
    },

    resultsTrapezoid () {
      if (this.trapezoid.sideA !== '' && this.trapezoid.sideB !== '' && this.trapezoid.height !== '' && this.trapezoid.depth !== '') {
        let SIDE_A, SIDE_B, HEIGHT, DEPTH, RESULT

        this.trapezoid.sideAUnit === 'in' ? SIDE_A = parseFloat(this.trapezoid.sideA) / 12 : SIDE_A = parseFloat(this.trapezoid.sideA)
        this.trapezoid.sideBUnit === 'in' ? SIDE_B = parseFloat(this.trapezoid.sideB) / 12 : SIDE_B = parseFloat(this.trapezoid.sideB)
        this.trapezoid.heightUnit === 'in' ? HEIGHT = parseFloat(this.trapezoid.height) / 12 : HEIGHT = parseFloat(this.trapezoid.height)
        this.trapezoid.depthUnit === 'in' ? DEPTH = parseFloat(this.trapezoid.depth) / 12 : DEPTH = parseFloat(this.trapezoid.depth)

        /* FORMULA: 
            Area = ((a + b) / 2) * h
            RESULT = (Depth * Area) / 27
        */

        // console.log('Side A: ', SIDE_A)
        // console.log('Side B: ', SIDE_B)
        // console.log('Height: ', HEIGHT)

        let area = ((SIDE_A + SIDE_B) / 2) * HEIGHT

        RESULT = ((DEPTH * area) / 27.00).toFixed(2)

        return RESULT
      } else {
        return ''
      }
    },

    resultsCircle () {
      if (this.circle.diameter !== '' && this.circle.depth !== '') {
        let DIAMETER, DEPTH, RESULT

        this.circle.diameterUnit === 'in' ? DIAMETER = parseFloat(this.circle.diameter) / 12 : DIAMETER = parseFloat(this.circle.diameter)
        this.circle.depthUnit === 'in' ? DEPTH = parseFloat(this.circle.depth) / 12 : DEPTH = parseFloat(this.circle.depth)

        /*
          FORMULA: 
            Area = PI * (Diameter / 2) ^ 2
            RESULT = (DEPTH * Area) / 27
        */

        let area = Math.PI * Math.pow((DIAMETER / 2), 2)
        // console.log(`Area: PI * (${DIAMETER} / 2) ^ 2 = ${area}`)

        RESULT = ((DEPTH * area) / 27).toFixed(2)

        return RESULT
      } else {
        return ''
      }
    },

    resultsCircleBorder () {
      if (this.circleBorder.innerDiameter !== '' && this.circleBorder.borderWidth !== '' && this.circleBorder.depth !== '') {
        let INNER_DIAMETER, BORDER_WIDTH, DEPTH, RESULT

        this.circleBorder.innerDiameterUnit === 'in' ? INNER_DIAMETER = parseFloat(this.circleBorder.innerDiameter) / 12 : INNER_DIAMETER = parseFloat(this.circleBorder.innerDiameter)
        this.circleBorder.borderWidthUnit === 'in' ? BORDER_WIDTH = parseFloat(this.circleBorder.borderWidth) / 12 : BORDER_WIDTH = parseFloat(this.circleBorder.borderWidth)
        this.circleBorder.depthUnit === 'in' ? DEPTH = parseFloat(this.circleBorder.depth) / 12 : DEPTH = parseFloat(this.circleBorder.depth)

        /*
          FORMULA: 
            OuterDiam = InnerDiam + (2 * BorderWidth)
            OuterArea =  PI * (OuterDiam / 2) ^ 2
            InnerArea = PI * (InnerDiam / 2) ^ 2
            Area = OuterArea - InnerArea
            RESULTS = (Depth * Area) / 27
        */

        let outerDiam = INNER_DIAMETER + (2 * BORDER_WIDTH)
        let outerArea = Math.PI * Math.pow((outerDiam / 2), 2)
        let innerArea = Math.PI * Math.pow((INNER_DIAMETER / 2), 2)
        let area = outerArea - innerArea

        RESULT = ((DEPTH * area) / 27).toFixed(2)

        return RESULT
      } else {
        return ''
      }
    }
  },

  methods: {
    figureScoops (yds) {
      // let yards = parseFloat((Math.round(yds * 2) / 2))
      // console.log('Yards rounded: ', yards)

      // let numScoops = parseFloat((parseFloat(yds) / 0.5))

      let numScoops = parseFloat((Math.round(yds * 2) / 2) / 0.5)

      // console.log('NUM SCOOPS: ', numScoops)

      if (numScoops > 0 && numScoops <= 0.30) {
        return 'roughly half a bucket [1/4 yd]'
      }

      if (numScoops >= 0.40 && numScoops <= 1) {
        return 'roughly 1 bucket'
      }

      if (numScoops > 1) {
        return `roughly ${numScoops} buckets`
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#calculatorHero {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/calculator_banner.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -250pt;
  // height: 20vh;
  // margin-bottom: 20pt;
  z-index: 10;  
}</style>