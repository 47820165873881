
// CALL LIST REQUESTS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchCallListRequests (context) {
    axios.get(`${API_URL}/call-list-requests`).then(res => {
      const requests = res.data
      context.commit('setRequests', requests)
    })
  },

  createCallListRequest (context, request) {
    axios.post(`${API_URL}/call-list-requests`, request).then(res => {
      const theRequest = res.data

      context.commit('addRequest', theRequest)
    })
  },

  updateCallListRequest (context, request) {
    // console.log('inside updateCallListRequest action...')
    // console.log(request)
    // console.log('CallListRequest ID: ' + request._id)

    axios.patch(`${API_URL}/call-list-requests/${request._id}`, request).then(res => {
      const newRequest = res.data

      // console.log('CallListRequest updated to: ')
      // console.log(newRequest)

      context.commit('updateRequest', newRequest)
    })
  },

  copyCallListRequest (context, requestId) {
    // console.log('Copying CallListRequest ID: ' + requestId)
    axios.post(`${API_URL}/call-list-requests/${requestId}/copy`).then(res => {
      const cpyRequest = res.data

      // console.log('COPIED CALL LIST REQUEST: ')
      // console.log(cpyRequest)
      context.commit('addRequest', cpyRequest)
    })
  },

  deleteCallListRequest (context, requestId) {
    axios.delete(`${API_URL}/call-list-requests/${requestId}`).then(res => {
      const request = res.data
      context.commit('removeRequest', request)
    })
  },

  setNumRequestsReady (context, num) {
    context.commit('setNumRequestsReady', num)
  }
}