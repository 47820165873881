<template>
<div v-if="message">
  <div class="notes-box">
    <p><strong>Date: </strong>{{ formatDate(date) }}</p>
    <p>{{ message }}</p>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: [ 'date', 'message' ],

  methods: {
    formatDate (date) {
      return dayjs(date).format('M/DD/YY')
    }
  }
}
</script>

<style lang="scss" scoped>
 .notes-box {
  border: 1px solid #d8d8d8; 
  padding: 10pt;
  margin-bottom: 10pt;
} 
</style>