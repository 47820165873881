
// ORDERS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchOrders (context) {
    axios.get(`${API_URL}/orders`).then(res => {
      const orders = res.data
      context.commit('setOrders', orders)
    }).catch(err => console.error(err.message))
  },

  createOrder (context, order) {
    axios.post(`${API_URL}/orders`, order).then(res => {
      const ord = res.data
      context.commit('addOrder', ord)
    })
  },

  updateOrder (context, order) {
    // console.log('UPDATING ORDER WITH: ', order)
    axios.post(`${API_URL}/orders/${order.orderId}`, order).then(res => {
      const ord = res.data
      // console.log('UPDATED ORDER FROM SERVER: ', ord)
      context.commit('updateOrder', ord)
    })
  },

  deleteOrder (context, orderId) {
    axios.delete(`${API_URL}/orders/${orderId}`).then(res => {
      const order = res.data
      context.commit('removeOrder', order)
    })
  }
}