<template>
<div>
  <b-modal v-model="show" :width="900">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title" v-if="isNew">New Vendor</p>
        <p class="card-header-title" v-else>Edit {{ vendor.name }}</p>
      </header>

      <div class="card-content">
        <b-field label="Name" label-position="on-border">
          <b-input v-model="vendor.name" type="text"/>
        </b-field>

        <b-field label="Short Name" label-position="on-border">
          <b-input v-model="vendor.shortName" type="text"/>
        </b-field>

        <b-field label="Vendor Fuel Surcharge" label-position="on-border">
          <b-input v-model="vendor.vendorFuelSurcharge" type="text"/>
        </b-field>

        <b-field label="CHT Fuel Surcharge" label-position="on-border">
          <b-input v-model="vendor.chtFuelSurcharge" type="text"/>
        </b-field>
      </div>

      <footer class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="footer-btns">
            <b-button 
              class="footer-closeBtn" 
              @click="$emit('closeVendorDialog')"
            >Cancel</b-button>
          </div>

          <div class="footer-btns">
            <b-button 
              class="footer-saveBtn" 
              icon-left="content-save" 
              @click="saveVendor"
            >Save</b-button>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'passVendor', 'isNew' ],
  emits: [ 'closeVendorDialog' ],
  data: () => ({
    vendor: {
      name: '',
      shortName: '',
      vendorFuelSurcharge: '',
      chtFuelSurcharge: ''
    },

    emptyVendor: {
      name: '',
      shortName: '',
      vendorFuelSurcharge: '',
      chtFuelSurcharge: ''
    }
  }),

  methods: {
    clearAndCloseDialog () {
      this.vendor = {...this.emptyVendor}
      this.$emit('closeVendorDialog')
    },

    async saveVendor () {
      // Create new if isNew, otherwise save existing vendor
      if (this.isNew) {
        // TODO: Need to tie this new product to the selected vendor. NOT YET WORKING!!!!
        try {
          let vendor = await this.$store.dispatch('vendors/createVendor', this.vendor)

          if (!vendor) {
            this.$buefy.toast.open({
              message: 'Server error while creating vendor',
              position: 'is-bottom',
              type: 'is-danger',
            })

            // console.log('Vendor Saved: ', vendor)
            this.$emit('selectNewVendor', null)
          } else {

            this.$buefy.toast.open({
              message: 'Vendor created',
              position: 'is-bottom',
              type: 'is-success',
            })

            // console.log('Vendor Saved: ', vendor)
            this.$emit('selectNewVendor', vendor._id)
          }


          // Clear form and close dialog
          this.clearAndCloseDialog()

        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: 'Error while creating new vendor! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      } else {
        // Update existing vendor
        try {
          // console.log('Changes: ', this.vendor)
          await this.$store.dispatch('vendors/updateVendor', this.vendor)
          this.$buefy.toast.open({
            message: 'Vendor updated',
            position: 'is-bottom',
            type: 'is-success',
          })
          // Clear form and close dialog
          this.clearAndCloseDialog()
        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: 'Error while updating vendor! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 8000
          })
        }
      }
    }
  },

  watch: {
    show () {
      if (!this.isNew && this.passVendor) {
        this.vendor = { ...this.passVendor }
      } else {
        this.vendor = {...this.emptyVendor }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>