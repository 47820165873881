
// DRIVERS > GETTERS

import dayjs from 'dayjs'

export default {

  drivers (state) {
    let drivers = state.drivers
    // console.log('[Drivers getters]: DRIVERS: ')
    // console.log(drivers)
    for (let i=0; i<drivers.length; i++) {
      if (drivers[i]) {
        if (drivers[i].dateHired != '') { drivers[i].dateHired = dayjs(drivers[i].dateHired).format('MM/DD/YYYY') }
        if (drivers[i].dateReleased != '') { drivers[i].dateReleased = dayjs(drivers[i].dateReleased).format('MM/DD/YYYY') }
        if (drivers[i].dateCreated != '') { drivers[i].dateCreated = dayjs(drivers[i].dateCreated).format('MM/DD/YYYY') }
      }
    }
    return drivers
  },

  currentDriver (state) {
    // console.log('inside currentDriver getter')
    return state.currentDriver
  },

  allDrivers (state) {
    const drivers = state.drivers

    for (let i=0; i < drivers.length; i++) {
      drivers[i].dateHired = new Date(drivers[i].dateHired)
      drivers[i].dateReleased = new Date(drivers[i].dateReleased)
      drivers[i].dateCreated = new Date(drivers[i].dateCreated)
    }
    return drivers
  },

  activeDrivers (state) {
    let drivers = state.drivers.filter(driver => driver.isActive === true)
    return drivers
  },

  driverById:  (state) => (driverId) => {
    // console.log('search id: ' + driverId)
    const drv = state.drivers.find(driver => driver._id === driverId)
    // console.log('inside driver getters driverById : found: ')
    // console.log(drv)
    return drv
  }
}