<template>
<div>
  <b-modal
    v-model="show"
    :width="1200"
    scroll="keep"
  >
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Update Customer's File?</p>
      </header>
      <section class="card-body">
        <div class="card-content">
          <p>We noticed a few changes from what's on file for this customer: </p>
          <br/>
          <div>
            <!-- Data comes in as { oldPhone, newPhone, oldAddress, newAddress, oldDirections, newDirections } -->
            <div v-if="updates.oldPhone !== updates.newPhone">
              <div><strong>Current Phone:</strong> {{ updates.oldPhone  }}</div>
              <div><strong>Updated Phone: {{ updates.newPhone  }}</strong></div>
              <br/>
            </div>

            <div v-if="updates.newAddress !== updates.oldAddress">
              <div><strong>Current Address:</strong> {{ updates.oldAddress  }}</div>
              <div><strong>Updated Address: {{ updates.newAddress  }}</strong></div>
              <br/>
            </div>

            <div v-if="updates.newCoordinates !== updates.oldCoordinates">
              <div><strong>Current Coordinates:</strong> {{ updates.oldCoordinates  }}</div>
              <div><strong>Updated Coordinates: {{ updates.newCoordinates  }}</strong></div>
              <br/>
            </div>

            <div v-if="updates.newDirections !== updates.oldDirections">
              <div><strong>Current Directions:</strong> </div>
              <div style="white-space: pre;">
                {{ updates.oldDirections  }}
              </div>
              <br/>
              <div><strong>Updated Directions: </strong></div>
              <div style="white-space: pre;">
                {{ updates.newDirections  }}
              </div>
              <br/>
            </div>

            <br/>

            <div>
              <p><strong>Choose what to save: </strong></p>
              <b-field grouped>
                <b-field v-if="updates.oldPhone !== updates.newPhone">
                  <b-checkbox v-model="keep.phone">Update Phone on file</b-checkbox>
                </b-field>
                <b-field v-if="updates.newAddress !== updates.oldAddress">
                  <b-checkbox v-model="keep.address">Update Address on file</b-checkbox>
                </b-field>
                <b-field v-if="updates.newCoordinates !== updates.oldCoordinates">
                  <b-checkbox v-model="keep.coordinates">Update Coordinates on file</b-checkbox>
                </b-field>
                <b-field v-if="updates.newDirections !== updates.oldDirections">
                  <b-checkbox v-model="keep.directions">Update Directions on file</b-checkbox>
                </b-field>
              </b-field>
            </div>
          </div>
        </div>
      </section>
      <footer class="card-footer">
        <b-button class="card-footer-item actionBtn" @click="$emit('cancelUpdateCustomerDialog')">Cancel</b-button>
        <b-button class="card-footer-item actionBtn" type="is-primary" @click="save" :disabled="nothingToKeep">Save</b-button>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'updates' ],

  emits: [ 'cancelUpdateCustomerDialog', 'updateCacheObj' ],

  components: {},

  computed: {
    nothingToKeep () {
      if (!this.keep.phone && !this.keep.address && !this.keep.coordinates && !this.keep.directions) {
        return true
      } else {
        return false
      }
    }
  },

  data: () => ({
    keep: {
      phone: false,
      address: false,
      coordinates: false,
      directions: false
    },
    emptyKeep: {
      phone: false,
      address: false,
      coordinates: false,
      directions: false
    }
  }),

  methods: {
    save () {
      // console.log('Save the following: ')
      // console.log(this.keep)

      if (this.keep.phone || this.keep.address || this.keep.coordinates || this.keep.directions) {
        // console.log('Keeping for ', this.updates._id)
        if (this.updates._id && this.updates._id !== '') {

          let deliveryCustomer = {}
          deliveryCustomer._id = this.updates._id

          if (this.keep.phone) {
            deliveryCustomer.phone = this.updates.newPhone
          }

          if (this.keep.address) {
            deliveryCustomer.address = this.updates.newAddress
          }

          if (this.keep.coordinates) {
            deliveryCustomer.coordinates = this.updates.newCoordinates
          }

          if (this.keep.directions) {
            deliveryCustomer.directions = this.updates.newDirections
          }

          try {
            // console.log('attempting to update deliveryCustomer: ', deliveryCustomer)
            this.$store.dispatch('deliveryCustomers/updateDeliveryCustomer', deliveryCustomer)

            this.$buefy.toast.open({
              message: 'Customer info updated',
              type: 'is-success',
              position: 'is-bottom'
            })
            

            this.$emit('updateCacheObj', deliveryCustomer)

            this.keep = { ...this.emptyKeep }

            // Close dialog
            this.$emit('cancelUpdateCustomerDialog')

          } catch (error) {
            console.error(error.message)
            this.$buefy.toast.open({
              message: `Error while updating delivery customer! Check logs`,
              type: 'is-danger',
              position: 'is-bottom',
              duration: 8000
            })
          }
        } else {
          this.$buefy.dialog.alert({
            title: 'No CustomerID',
            message: `Critical Error! CustomerID is missing. Please try again or contact website administrator`,
            type: 'is-danger',
            hasIcon: true,
            position: 'is-bottom',
            duration: 8000
          })
        }
      } else {
        console.log('You chose not to keep any changes...')
      }
    }
  },

  watch: {
    show () {
      if (this.updates && this.updates.length > 0) {
        // console.log('DEBUG UPDATES: ', this.updates)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.actionBtn {
  border-radius: 0 !important;
}
</style>