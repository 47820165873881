<template>
<div>
  <WorkdayDialog 
    :show="showWorkdayDialog" 
    :dateFor="date" 
    :isNewWorkday="true" 
    :driver="driver" 
    @closeWorkdayDialog="closeWorkdayDialog"
  />
  
  <div class="notes-box" style="display: flex; margin-bottom: 20pt;">
    <span><b-icon icon="calendar-question" class="icon-missing"></b-icon></span>
    <span style="margin-right: 20pt;"><strong>Driver: </strong>{{ driver.firstName }} {{ driver.lastName }}</span>
    <span><strong>Date: </strong>{{ formatDate(date) }}</span>
    <div style="margin-left: 20pt;">
      <b-button 
        type="is-primary" 
        class="is-small forceBold"
        icon-left="calendar-plus"
        @click="openWorkdayDialog"
      >
        Create Workday
      </b-button>
    </div>
  </div>
</div>
</template>

<script>
import WorkdayDialog from '../../Workdays/WorkdayDialog.vue'
import dayjs from 'dayjs'

export default {
  props: [ 'driver', 'date' ],

  components: {
    WorkdayDialog
  },

  data: () => ({
    showWorkdayDialog: false
  }),

  methods: {
    formatDate (date) {
      return dayjs(date).format('ddd M/DD/YY')
    },

    openWorkdayDialog () {
      this.showWorkdayDialog = true
    },

    closeWorkdayDialog () {
      this.showWorkdayDialog = false
    }
  },

  mounted () {
    this.$store.dispatch('workdays/fetchWorkdays')
  }
}
</script>

<style lang="scss" scoped>
.icon-missing {
  color: red; 
  font-weight: bold; 
  margin-right: 5pt;
}
</style>
