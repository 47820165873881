import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Buefy from 'buefy'
// import VeeValidate from 'vee-validate'
// import 'buefy/dist/buefy.css'

Vue.config.productionTip = false

// Vue.use(VeeValidate)
Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
