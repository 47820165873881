
// DELIVERY CUSTOMERS > MUTATIONS

export default {

  setDeliveryCustomers (state, payloadCustomers) {
    state.deliveryCustomers = payloadCustomers
  },

  addDeliveryCustomer (state, payloadCustomer) {
    state.deliveryCustomers.push(payloadCustomer)
  },

  updateDeliveryCustomer (state, payloadCustomer) {
    let index = state.deliveryCustomers.findIndex(customer => customer._id === payloadCustomer._id)
    state.deliveryCustomers.splice(index, 1, payloadCustomer)
  },

  removeDeliveryCustomer (state, payloadCustomerId) {
    let index = state.deliveryCustomers.findIndex(customer => customer._id === payloadCustomerId)
    state.deliveryCustomers.splice(index, 1)
  }

}