<template>
<div class="card nonCommCard">
  <div class="card-header">
    <div class="card-title white bold p-2">{{ driver.firstName }} {{ driver.lastName }} (on {{ formatDate(workday.workDate) }})</div>
  </div>
  <div class="card-content">
    <div>
      <b-field style="margin-right: 20pt; margin-bottom: 20pt;" label="NC Hours" label-position="on-border">
        <b-input v-model="nchours" type="text"></b-input>
      </b-field>

      <b-field label="Reasons" style="margin-bottom: 20pt;" label-position="on-border">
        <b-input v-model="ncreasons" type="textarea" rows="2" placeholder="Enter reasons" style="margin-right: 10pt;"></b-input>
      </b-field>
      <b-field>
        <b-button type="is-primary" icon-left="content-save" @click="saveNonComm">Save</b-button>
      </b-field>
    </div>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: [ 'driver', 'workday' ],

  data: () => ({
    nchours: '',
    ncreasons: ''
  }),

  methods: {
    async saveNonComm () {
      // console.log('TODO: Saving non-comm....')
      // console.log({ nchours: this.nchours, ncreasons: this.ncreasons })

      let newWday = {
        ...this.workday,
        nchours: this.nchours,
        ncreasons: this.ncreasons
      }

      try {
        await this.$store.dispatch('workdays/saveOrCreateWorkday', {
          driverId: this.driver._id,
          workday: newWday
        })
        this.$buefy.toast.open({
          message: 'Non-comm data saved',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
       console.error(error.message) 
       this.$buefy.toast.open({
          message: 'Error while saving non-comm data! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }
    },

    formatDate (date) {
      return dayjs(date).format('M/D/YY')
    }
  },

  mounted () {
    this.nchours = this.workday.nchours
    this.ncreasons = this.workday.ncreasons
  }
}
</script>

<style lang="scss" scoped>
.nonCommCard {
  max-width: 400pt;
  margin-right: 20pt;
  margin-bottom: 18pt;
}
</style>