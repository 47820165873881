<template>
<div>
  <b-modal v-model="show">
    <section class="card">
      <header class="card-header">
        <p class="card-header-title">Add To Call List</p>
      </header>

      <div class="card-content">
        <!-- DATE CREATED -->
        <b-field label="Date Created" label-position="on-border">
          <b-datepicker
            v-model="callListRequest.dateCreated"
          />
        </b-field>

        <!-- CUSTOMER NAME -->
        <b-field label="Name" label-position="on-border">
          <b-input v-model="callListRequest.customerName" placeholder="First and last" icon="account"/>
        </b-field>

        <!-- CUSTOMER CONTACT INFO -->
        <b-field label="Contact Info" label-position="on-border">
          <b-input v-model="callListRequest.customerContact" type="textarea" placeholder="Phone, e-mail, etc." rows="3"/>
        </b-field>

        <!-- MATERIAL & QUANTITY & STATUS -->
        <b-field grouped>
          <b-field label="Material" label-position="on-border">
            <b-select
              placeholder="Choose..."
              v-model="callListRequest.material"
              :loading="elementsLoading"
              icon="tag-outline"
            >
              <optgroup v-for="cat in categories" :key="cat._id" :label="cat.name">
                <option v-for="mat in materialsOfType(cat.name)" :key="mat._id" :value="mat._id">
                  {{ mat.name }}
                </option>
              </optgroup>
            </b-select>
          </b-field>

          <b-field label="Quantity" label-position="on-border">
            <b-input v-model="callListRequest.quantity" placeholder="example: 3 yds" icon="cart-arrow-down"/>
          </b-field>

          <b-field label="Status" label-position="on-border">
            <b-select
              v-model="callListRequest.status" placeholder="Choose..." icon="update"
            >
              <option
                v-for="option in statusOptions"
                :key="option.value"
                :value="option.value"
              >
                {{ option.key }}
              </option>
            </b-select>
          </b-field>

          <!-- POSSIBLY IMPLEMENT AT A LATER TIME -->
          <!-- <b-field>
            <b-tooltip 
              position="is-bottom" 
              :triggers="['click']"
              :auto-close="['outside', 'escape']"
            >
              <template #content>
                <p>Missing Implementation: <br/>Possibly need to convert customer.material and customer.quantity into arrays.</p>
                <p>Possibly need to convert customer.material and customer.quantity into arrays.</p>
              </template>
              <b-button icon-left="plus-thick"></b-button>

            </b-tooltip>
          </b-field> -->
        </b-field>

        <!-- NOTES & SPECIAL REQUESTS -->
        <b-field label="Notes & Special Requests" label-position="on-border">
          <b-input v-model="callListRequest.notes" type="textarea" rows="4"/>
        </b-field>
      </div>

      <footer>
        <b-button class="cancelBtn" @click="clearAndClose">Cancel</b-button>
        <b-button type="is-primary" @click="saveRequest">Save</b-button>
      </footer>
    </section>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'isNew', 'passRequest' ],

  emits: [ 'closeCallListDialog' ],

  data: () => ({
    materialsLoading: false,
    categoriesLoading: false,
    statusOptions: [
      { key: 'Waiting', value: 'waitingmaterial' },
      { key: 'Informed', value: 'informed' },
      { key: 'Left Message', value: 'leftmessage' },
      { key: 'Cannot Reach', value: 'cannotreach' },
    ],

    callListRequest: {
      dateCreated: new Date(),
      customerName: '',
      customerContact: '',
      material: undefined,
      materialId: undefined,
      quantity: '',
      notes: '',
      isActive: true
    },

    emptyCallListRequest: {
      dateCreated: new Date(),
      customerName: '',
      customerContact: '',
      material: undefined,
      materialId: undefined,
      quantity: '',
      notes: '',
      isActive: true
    }
  }),

  mounted () {
    this.setMaterialsLoading(true)
    this.setCategoriesLoading(true)

    this.$store.dispatch('materials/fetchMaterials')
    this.$store.dispatch('categories/fetchCategories')
  },

  computed: {
    materials () {
      const materials = this.$store.getters['materials/allMaterials']
      // console.log('MATS: ', materials)
      this.setMaterialsLoading(false)
      return materials
    },

    categories () {
      const categories = this.$store.getters['categories/allCategories']
      this.setCategoriesLoading(false)
      return categories
    },

    elementsLoading () {
      return (this.materials.length <= 0 && this.categories.length <= 0) ? true : false
    }
  },

  methods: {

    async saveRequest () {
      if (this.isNew) {
        try {
          await this.$store.dispatch('callListRequests/createCallListRequest', this.callListRequest)

          this.$buefy.toast.open({
            message: `Request created`,
            position: 'is-bottom',
            type: 'is-success',
          })

          this.clearAndClose()

        } catch (error) {
          console.error(error.message)
          
          this.$buefy.toast.open({
            message: `Error while creating call request! Check logs`,
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      } else {

        // UPDATE EXISTING CALL LIST REQUEST

        try {
          // Update the dateModified property
          this.callListRequest.dateModified = new Date()

          await this.$store.dispatch('callListRequests/updateCallListRequest', this.callListRequest)

          this.$buefy.toast.open({
            message: `Request updated`,
            position: 'is-bottom',
            type: 'is-success',
          })

          this.clearAndClose()

        } catch (error) {
          console.error(error.message)

          this.$buefy.toast.open({
            message: `Error while creating call request! Check logs`,
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      }
    },

    materialsOfType (type) {
      return this.$store.getters['materials/materialsByCategory'](type)
    },

    clearAndClose () {
      this.customer = { ...this.emptyCustomer }
      this.$emit('closeCallListDialog')
    },

    setMaterialsLoading (value) {
      this.materialsLoading = Boolean(value)
    },

    setCategoriesLoading (value) {
      this.categoriesLoading = Boolean(value)
    }
  },

  watch: {
    show () {
      if (this.passRequest && !this.isNew) {
        this.callListRequest = { 
          ...this.passRequest,
          dateCreated: new Date(this.passRequest.dateCreated) 
        }
      } else {
        this.callListRequest = { ...this.emptyCallListRequest }
      }
    },

    // categories () {
    //   console.log('CATEGORIES: ', this.categories)
    // },

    callListRequest: {
      deep: true,
      handler () {
        if (this.callListRequest.material) {
          // console.log('callListRequest.material changed to: ', this.callListRequest.material)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
footer {
  padding: 10pt;
  display: flex;
  justify-content: flex-end;
}
.cancelBtn {
  background-color: rgb(49, 49, 49);
  color: white;
  margin-right: 8pt;
}
.card-content *:not(:last-child) {
  margin-bottom: 16pt;
}
</style>