
// CARPORTS > MUTATIONS

export default {
  setCarports (state, carports) {
    state.carports = carports
  },

  addCarport (state, carport) {
    state.carports.push(carport)
  },

  updateCarport (state, payloadCarport) {
    // console.log('[updateCarport mutations.js]: Inside mutation, updating store...')
    let index = state.carports.findIndex(carport => carport._id === payloadCarport._id)
    state.carports.splice(index, 1, payloadCarport)
  },

  removeCarport (state, delCarport) {
    let index = state.carports.findIndex(carport => carport._id === delCarport._id)
    state.carports.splice(index, 1)
  }
}