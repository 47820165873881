
// SHARED NOTES > MUTATIONS

export default {

  setSharedNotes (state, noteContent) {
    state.sharedNotes = noteContent
  },

  updateSharedNotes (state, noteContent) {
    state.sharedNotes = noteContent
  }
}