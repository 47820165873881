<template>
<div class="ticketWrapper">
  <header class="content">
    <h4 style="text-align: center;">C&H Delivery Ticket</h4>
    <h5 style="text-align: center;">{{ formatShortDate(printDelivery.deliveryDate) }}</h5>
  </header>

  <br/>
  <br/>

  <div style="display: flex; justify-content: space-around;">
    <table id="customerInfoTable">
      <tr>
        <td><strong>Customer: </strong></td>
        <td>
          <div style="white-space: pre;">
            {{ printDelivery.customer }}
          </div>
        </td>
      </tr>

      <tr>
        <td><strong>Phone: </strong></td>
        <td>
          <div style="white-space: pre;">
            {{ printDelivery.phone }}
          </div>
        </td>
      </tr>

      <tr>
        <td><strong>Address: </strong></td>
        <td>
          <div style="white-space: pre;">
            {{ printDelivery.address }}
          </div>
        </td>
      </tr>
    </table>

    <div>
      <strong>Product: </strong><br/>
      <table>
        <tr>
          <td>
            <div style="white-space: pre; margin-right: 10pt;">
              {{ printDelivery.product }}
            </div>
          </td>
          <td>
            <div style="white-space: pre">
              {{ printDelivery.quantity }}
            </div>
          </td>
        </tr>
      </table>

      <br/>

      <table id="deliveryDateTable">
        <tr>
          <td><strong>Deliver: </strong></td>
          <td>{{ formatDate(printDelivery.deliveryDate) }}</td>
        </tr>
        <br/>
        <tr>
          <td><strong>Paid: </strong></td>
          <td>
            <b-icon :icon="formatPaid(printDelivery.paid)" size="is-medium"/>
          </td>
        </tr>
      </table>

      <br/>

      <div class="check-boxes-wrapper">
        <div class="check-box-wrapper" style="margin-right: 15pt;">
          <div style="font-size: 12pt;">Give Receipt</div>
          <div class="check-box"></div>
        </div>

        <div class="check-box-wrapper">
          <div style="font-size: 12pt;">E-mail Receipt</div>
          <div class="check-box"></div>
        </div>
      </div>


    </div>
  </div>

  <br/>
  <br/>

  <div class="content" style="position: relative; left: 150pt;">
    <div style="display: flex;">
      <div style="white-space: pre; line-height: 1.7; text-align: left;">
        {{ printDelivery.directions }}
      </div>
    </div>
  </div>

  <div id="done-wrapper">
    <div id="completed-text">Completed</div>
    <div id="done-checkbox">
    </div>
  </div>


</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data: () => ({

  }),

  mounted () {
    // Hide copyright banner
      document.getElementById('copyrightFooter').style.display = 'none'
  },
  
  computed: {
    printDelivery () {
      return this.$store.getters['deliveries/printDelivery']
    }
  },

  methods: {
    formatDate (date) {
      return dayjs(date).format('ddd MM/DD/YY')
    },

    formatShortDate (date) {
      return dayjs(date).format('ddd MM/DD')
    },

    formatPaid (paid) {
      return paid ? 'check-bold' : ''
    }
  }
}
</script>

<style lang="scss" scoped>
#customerInfoTable tr td {
  padding: 8pt !important;
}
.ticketWrapper {
  font-size: 16pt;
  // margin-top: -40pt;
}
#deliveryDateTable tr td:nth-child(2) {
  // border: 2px solid red;
  padding-left: 8pt;
}

#done-wrapper {
  display: flex;
  justify-content: space-between;
  position: absolute;
  right: 0;
  top: 45pt;
  margin-right: 20pt;
  margin-bottom: 20pt;
  border: 1px solid black;
  width: 20px;
  height: 20px;
  z-index: 100;
}

#done-checkbox {
  border: 2px solid black;
  width: 100%;
  height: 100%;
}

#completed-text {
  font-size: 10pt;
  position: relative;
  // top: 5pt;
  right: 60pt;
}

.check-boxes-wrapper {
  display: flex;
  align-items: center;
}

.check-box {
  border: 1px solid black;
  width: 15pt;
  height: 15pt;
  margin-left: 15pt;
}

.check-box-wrapper {
  display: flex;
  align-items: center;
  // border: 1px solid black;
}
</style>