
// VENDOR PRODUCTS > MUTATIONS

export default {

  setVendorProducts (state, products) {
    // console.log('Populating state with vendor products: ')
    // console.log(products)
    state.vendorProducts = products
  },

  // setSelectedProduct (state, productId) {
  //   state.selectedProduct = state.vendorProducts.find(product => product._id === productId)
  // },

  setPayRates (state, rates) {
    state.pay_rates = rates
  },

  addPayRates (state, rates) {
    state.pay_rates = [
      ...state.pay_rates,
      rates
    ]
  },

  addVendorProduct (state, product) {
    // console.log('[vendorProducts mutation addVendorProduct()]: Adding vendor product to state...')
    state.vendorProducts.push(product)
  },

  updateVendorProduct (state, payloadVendorProduct) {
    let index = state.vendorProducts.findIndex(product => product._id === payloadVendorProduct._id)
    state.vendorProducts.splice(index, 1, payloadVendorProduct)
  },

  removeVendorProduct (state, vendorProductId) {
    // console.log('[vendorProduct mutations]: inside removeVendorProduct with id: ' + vendorProductId)
    let index = state.vendorProducts.findIndex(product => product._id === vendorProductId)
    state.vendorProducts.splice(index, 1)
  }
}