<template>
<div>
  <b-modal v-model="show" :width="1260" scroll="keep">
    <!-- TITLE -->
    <div class="card">
      <header class="card-header">
        <p class="card-header-title" v-if="isNew">New Carport</p>
        <p class="card-header-title" v-else>Edit Carport</p>
      </header>

      <section class="card-content">
        <div class="media">
          
          <!-- IMAGE COLUMN -->
          <div class="media-left">
            <b-field class="file is-primary" :class="{'has-name': !!imageFile}">
              <label v-if="isNew" for="image" style="margin-right: 10pt;">Upload Image: </label>
              <label v-else for="image" class="p-mr-3">Upload a different Image: </label>
              <input type="file" name="image" id="image" @change="convertToBase64AndDisplay()" placeholder="Image"/>
            </b-field>

            <figure class="image is-square">
              <img :src="imagePreviewSrc" style="max-width: 90%; max-height: 90%;">
            </figure>

            <b-tag v-if="showNewImageWarning"
              type="is-danger"
              closable
              aria-close-label="Close tag"
              @close="showNewImageWarning = false">
              You've selected a new image, don't forget to save these changes!
            </b-tag>
          </div>

          <!-- CONTENT COLUMN -->
          <div class="media-content">

            <p class="notice"></p>

            <b-message v-if="serverError" type="is-danger is-small" aria-close-label="Close message">
              Oops! There seems to be a problem with the request sent to the server. Contact the developer if the problem persists.
            </b-message>

            <b-field label="Name" expanded label-position="on-border">
              <b-input v-model="carport.name" required autofocus></b-input>
            </b-field>

            <b-field label="Size" label-position="on-border">
              <b-input v-model="carport.size"></b-input>
            </b-field>

            <!-- Image upload here?? -->

            <b-field label="Description" label-position="on-border">
              <b-input v-model="carport.description" type="textarea" rows="2"></b-input>
            </b-field>


            <b-switch 
              v-model="carport.isFeatured"
              type="is-info"
              style="margin-right: 10pt;" 
            >
              Featured
            </b-switch>

          </div>
          
        </div>
      </section>

      <footer>
        <div class="footerWrapper">
          <b-field grouped>
            <b-field>
              <b-button @click="clearAndClose">Cancel</b-button>
            </b-field>
            <b-field>
              <b-button 
                v-if="isNew" 
                type="is-primary"
                icon-left="content-save" 
                @click="createCarport"
              >
                Create
              </b-button>

              <b-button 
                v-else 
                type="is-primary"
                icon-left="content-save" 
                @click="saveCarport"
              >
                Save
              </b-button>
            </b-field>
          </b-field>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>

export default {
  props: [ 'show', 'isNew', 'passCarport' ],

  emits: [ 'closeCarportDialog' ],

  data: () => ({
    imageFile: null,
    imageSrc: '',
    showNewImageWarning: false,
    serverError: false,
    
    carport: {
      name: '',
      size: '',
      image: '',
      description: '',
      isFeatured: false
    },

    emptyCarport: {
      name: '',
      size: '',
      image: '',
      description: '',
      isFeatured: false
    }
  }),

  computed: {
    imagePreviewSrc () {
      if (this.imageSrc && this.imageSrc !== null && this.imageSrc !== '' && this.imageSrc !== undefined) {
        return this.imageSrc
      } else {
      return this.carport.image
      }
    },
  },

  methods: {

    clearAndClose () {
      this.carport = { ...this.emptyCarport }
      this.$emit('closeCarportDialog')
    },

    convertToBase64AndDisplay () {
      // console.log('inside convertToBase64AndDisplay...')
      let imgInput = document.getElementById('image')

      const file = imgInput.files[0],
            reader = new FileReader()

      const that = this
      reader.onloadend = function () {
        let b64 = reader.result.replace(/^data:.+;base64,/, '')
        that.imageBuffer = b64
        that.imageSrc = `data:image/jpg;base64,${that.imageBuffer}`
        that.showNewImageWarning = true
      }
      reader.readAsDataURL(file);
    },

    async createCarport () {

      // Get a reference to the new image (if chosen)
      const imageFile = document.querySelector('#image').files[0]

      // If a new image exists, continue with request
      if (imageFile) {
        // Get filename
        const fileName = imageFile.name

        // Ask the user if they for sure want to override the existing image
        let confirmNewImage = confirm('WARNING: Selecting OK will OVERRIDE the existing image (if there is one). Selecting CANCEL will update everything except the image')

        if (confirmNewImage) {
          // continue, approve of new image (append to state.carport)
          this.carport.image = this.imageBuffer
          this.carport.imageName = fileName || ''

          // console.log('New carport to send to server: ')
          // console.log(this.carport)
        } else {
          // do nothing; fall through to dispatch without image on this.carport
        }
      } else {
        // No new image detected, fall through to dispatch without image on this.carport
      }

      // Make the create request
      try {
        await this.$store.dispatch('carports/createCarport', this.carport)
        this.$buefy.toast.open({
          message: `Carport created`,
          position: 'is-bottom',
          type: 'is-success'
        })

        // Clear form and close dialog
        this.showNewImageWarning = false
        this.clearAndClose()

      } catch (error) {
        console.error(error.message)

        this.serverError = true
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error while trying to create new material! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    async saveCarport () {
      // Get a reference to the new image (if chosen)
      const imageFile = document.querySelector('#image').files[0]

      // If a new image exists, continue with request
      if (imageFile) {
        // Get filename
        const fileName = imageFile.name

        // Ask the user if they for sure want to override the existing image
        let confirmNewImage = confirm('WARNING: Selecting OK will OVERRIDE the existing image (if there is one). Selecting CANCEL will update everything except the image')

        if (confirmNewImage) {
          // continue, approve of new image (append to matObj)
          this.carport.image = this.imageBuffer
          this.carport.imageName = fileName || ''

          // console.log('Patch object (this.carport) to send to server: ')
          // console.log(this.carport)
        } else {
          // do nothing; fall through to dispatch without image on this.carport
        }
      } else {
        // No new image detected, fall through to dispatch without image on this.carport
        // console.log('Image to keep: ', this.carport.image)
      }

      // Create the patch request
      try {
        let cport = undefined
        if (!imageFile) {
          cport = {
            _id: this.carport._id,
            name: this.carport.name,
            size: this.carport.size,
            description: this.carport.description,
            isFeatured: this.carport.isFeatured
          }
          // console.log('Using existing image: ', this.carport.image)
          await this.$store.dispatch('carports/updateCarport', cport)
        } else {
          // console.log('Using new image, carport to submit: ', this.carport)
          await this.$store.dispatch('carports/updateCarport', this.carport)
        }

        this.$buefy.toast.open({
          message: `Carport updated`,
          position: 'is-bottom',
          type: 'is-success'
        })

        // Clear the form and close the modal
        this.showNewImageWarning = false
        this.clearAndClose()

      } catch (error) {
        console.error(error.message)
        
        this.serverError = true
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error while updating material! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    }
  },

  watch: {
    show () {
      // console.log('Checking for carports...')
      if (this.isNew) {
        // console.log('Using emptyCarport...')
        this.carport = { ...this.emptyCarport }
      } else {
        // console.log('Received passCarport: ', this.passCarport)
        this.carport = { ...this.passCarport }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.footerWrapper {
  display: flex;
  justify-content: flex-end;
  padding: 10pt;
}
</style>