
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state () {
    return {
      deliveries: [],
      employeeNotes: undefined,
      deliveryCustomer: {},
      printDelivery: {}
    }
  },
  actions,
  getters,
  mutations
}