
// CATEGORIES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  
  fetchCategories (context) {
    axios.get(`${API_URL}/categories`).then(res => {
      const categories = res.data

      context.commit('setCategories', categories)
    })
  },

  createCategory (context, category) {
    axios.post(`${API_URL}/categories`, category).then(res => {
      const newCategory = res.data

      // console.log('newCategory from POST request: ')
      // console.log(newCategory)
      context.commit('addCategory', newCategory)
    }).catch(err => console.error(err.message))
  }
}