
// OFFICE > TASKS > GETTERS

export default {
 allTasks (state) {
   return state.tasks
 },

 tasksByUserId: (state) => (userId) => {
   return state.tasks.filter(task => task.userId === userId)
 },

 hasActiveTasks (state) {
  return state.tasks && state.tasks.length > 0
 },

 getTaskText: (state) => (taskId) => {
  const task = state.tasks.find(task => task._id === taskId)
  return task.text
 },

 getTaskById: (state) => (taskId) => {
   const task = state.tasks.find(task => task._id === taskId)
   return task
 }
}