<template>
<div>
  <section class="hero ch-hero no-print">
    <div class="hero-body no-print">
      <p class="title no-print" style="color: white;">Workday Check</p>
      <div style="display: flex; justify-content: space-between">
        <span class="text-white">Use this checklist to help catch mistakes before you view the report</span>
        <div style="display: flex; justify-content: flex-end" v-if="hasActions">
          <b-button type="is-danger" icon-left="shield-alert" style="margin: 0; padding-left: 8pt; padding-right: 8pt;" @click="confirmSkip">Skip Checks</b-button>
        </div>
      </div>
    </div>
  </section>

  <section class="section is-small" style="border: 1px solid gray;">

    <b-steps
      v-model="activeStep"
      icon-prev='chevron-left'
      icon-next='chevron-right'
      label-position='bottom'
      mobile-mode='minimalist'
    >
      <!-- 1. WORKDAY NOTES -->
      <b-step-item step="1" label="Workday Notes" :icon="iconWorkdayNotes" :clickable="true">
        <br/>
        <h1 class="subtitle center forceBold">Check Workday Notes</h1>
        
        <section class="section is-small shortTop">
          <div class="card">
            <div class="card-header">
              <div class="card-title text-white bold p-2">Brandon Belvin</div>
            </div>
            <div class="card-content">
              <WorkdayNote 
                v-for="workday in workdaysInRange"
                :key="workday._id"
                :date="workday.workDate" 
                :message="workday.dayNotes" 
              />

              <!-- <WorkdayNote date="" note="Ask if he has missing MMM ticket for today" /> -->

            </div>
            <div class="card-footer" style="padding: 10pt;">
              <b-button type="is-success" icon-left="check-bold" @click="() => { notesComplete = true; activeStep=1; findMissingWorkdays() }">Notes Reviewed</b-button>
            </div>
          </div>
        </section>

      </b-step-item>

      <!-- 2. MISSING WORKDAYS -->
      <b-step-item step="2" label="Missing Workdays" :icon="iconMissingWorkdays" :clickable="true">
        <br/>
        <h1 class="subtitle center forceBold">Missing Workdays</h1>

        <section class="section is-small shortTop" v-if="!missingWorkdaysComplete">

          <p style="font-style: italic; color: #f37231;">Don't create workdays for Saturdays or Sundays (unless actually worked)</p>
          <br/>

          <div v-if="missingWorkdays.length > 0">
            <MissingWorkday 
              v-for="(workday, index) in missingWorkdays"
              :key="index"
              :driver="driver" 
              :date="workday"
            />
          </div>

          <div v-else>
            <CheckComplete />
            <br/>
            <div style="text-align: center;">
              <b-button type="is-success" icon-right="arrow-right" @click="() => { missingWorkdaysComplete = true; activeStep=2; }">Continue</b-button>
            </div>
          </div>
        </section>


      </b-step-item>

      <!-- 3. CH HOURS -->
      <b-step-item step="3" label="CH Hours" :icon="iconCHHours" :clickable="true">
        <br/>
        <h1 class="subtitle center forceBold">Check Missing CH Hours</h1>

        <section class="section is-small shortTop" v-if="!chHoursComplete">
          <div v-if="missingCHHours.length > 0">
            <MissingCHHours 
              v-for="(workday, index) in missingCHHours"
              :key="index"
              :driver="driver" 
              :date="workday"
            />
          </div>

          <div v-else>
            <CheckComplete />
            <br/>
            <div style="text-align: center;">
              <b-button type="is-success" icon-right="arrow-right" @click="() => { chHoursComplete = true; activeStep=3; }">Continue</b-button>
            </div>
          </div>



        </section>

        <CheckComplete v-else/>
      </b-step-item>

      <!-- 4. NON-COMM -->
      <b-step-item step="4" label="Non-Commission" :icon="iconNonComm" :clickable="true">
        <br/>
        <p class="subtitle center forceBold" style="margin-bottom: 0;">Check Non-Commission (NC)</p>
        <p class="center">Correct workdays missing either NC Hours or NC Reasons</p>
        <br/>

        <section class="section is-small shortTop" v-if="!nonCommComplete">
          <div style="display: flex; flex-wrap: wrap; justify-content: flex-start;" v-if="missingNCHours.length > 0">
            <MissingNonComm 
              v-for="workday in missingNCHours"
              :key="workday._id"
              :driver="driver" 
              :workday="workday"/>
          </div>
          <div v-else>
            <CheckComplete/>
            <div style="text-align: center; margin-top: 10pt;">
              <b-button type="is-success" icon-right="arrow-right" @click="() => { nonCommComplete = true; activeStep=4; }">Continue</b-button>
            </div>
          </div>
        </section>

        <CheckComplete v-else/>
      </b-step-item>

      <!-- 5. CHECK COMPLETE -->
      <b-step-item step="5" label="Hauls & Workday" :icon="iconWorkdayComplete" :clickable="true">
        <br/>
        <h1 class="subtitle center forceBold">Check Hauls & Workday Complete</h1>

        <section class="section is-small shortTop">
          <div v-if="missingWorkdayComplete.length > 0">
            <MissingWorkdayComplete
              v-for="workday in missingWorkdayComplete"
              :key="workday._id"
              :driver="driver"
              :workday="workday"
            />
          </div>
          <div v-else>
            <div>
              <CheckComplete/>
              <div style="text-align: center; margin-top: 10pt;">
                <b-button 
                  type="is-success" 
                  icon-right="arrow-right" 
                  @click="() => { 
                    workdayComplete = true;
                    this.continueToReport()
                  }"
                >Continue to Report</b-button>
              </div>
            </div>
          </div>
        </section>
      </b-step-item>

    </b-steps>
  </section>
</div>
</template>

<script>
import CheckComplete from './components/CheckComplete'
import MissingWorkday from './components/MissingWorkday'
import MissingCHHours from './components/MissingCHHours'
import MissingNonComm from './components/MissingNonComm'
import MissingWorkdayComplete from './components/MissingWorkdayComplete'
import WorkdayNote from './components/WorkdayNote'
// import dayjs from 'dayjs'

export default {
  components: {
    CheckComplete,
    MissingWorkday,
    MissingCHHours,
    MissingNonComm,
    MissingWorkdayComplete,
    WorkdayNote
  },
  data: () => ({
    activeStep: 0,
    driver: null,
    dateRange: null,
    notesComplete: false,
    missingWorkdaysComplete: false,
    chHoursComplete: false,
    nonCommComplete: false,
    workdayComplete: false
  }),


  computed: {

    workdaysInRange () {
      if (this.dateRange && this.driver) {
        let workdays = this.$store.getters['workdays/workdaysByRangeAndDriver']({range: this.dateRange, driverId: this.driver._id})
        
        // console.log('DateRange: ', this.dateRange)
        // console.log('Workdays Found: ')
        // console.log(workdays)
        
        return workdays
      } else {
        console.log('Cannot retrieve workdaysInRange yet, either dateRange or driver is falsey or empty')
        return []
      }
    },

    hasActions () {
      if (!this.notesComplete || !this.missingWorkdaysComplete || !this.chHoursComplete || !this.nonCommComplete || !this.workdayComplete) {
        return true
      }
      return false
    },

    missingCHHours () {
      if (this.driver && this.dateRange) {
        return this.$store.getters['workdays/missingCHHours']({
          range: this.dateRange,
          driverId: this.driver._id
        })
      } else {
        console.error('[computedProp missingCHHours]: Driver and/or dateRange has not been assigned yet...')
        return []
      }
    },

    missingNCHours () {
      if (this.driver && this.dateRange) {
        return this.$store.getters['workdays/missingNCHours']({
          range: this.dateRange,
          driverId: this.driver._id
        })
      } else {
        console.error('[computedProp missingNCHours]: Driver and/or dateRange has not been assigned yet...')
        return []
      }
    },

    missingWorkdayComplete () {
      if (this.driver && this.dateRange) {
        return this.$store.getters['workdays/missingWorkdayComplete']({
          range: this.dateRange,
          driverId: this.driver._id
        })
      } else {
        console.error('[computedProp missingWorkdayComplete]: Driver and/or dateRange has not been assigned yet...')
        return []
      }
    },

    missingWorkdays () {
      return this.$store.getters['workdays/missingWorkdays']
    },

    // #region ICONS FOR STEPS COMPONENT

    iconWorkdayNotes () {
      if (this.notesComplete) {
        return 'check-bold'
      }
      return 'magnify'
    },

    iconMissingWorkdays () {
      if (this.missingWorkdaysComplete) {
        return 'check-bold'
      }
      return 'magnify'
    },

    iconCHHours () {
      if (this.chHoursComplete) {
        return 'check-bold'
      }
      return 'magnify'
    },

    iconNonComm () {
      if (this.nonCommComplete) {
        return 'check-bold'
      }
      return 'magnify'
    },

    iconWorkdayComplete () {
      if (this.workdayComplete) {
        return 'check-bold'
      }
      return 'magnify'
    }
    // #endregion
  },

  methods: {

    continueToReport () {
      // console.log('Prepare report...')

      /*
        1. If necessary, save any data to the store and/or local storage
            a. Driver
            b. Date Range
            c. Hauls in Range (using store getter)
            d. Workdays in Range (this.workdaysInRange)
        2. Push router to /office/hauls/report
        3. In that component, create() all fetch requests, then mount() to DOM using computed properties and methods
      */

     // STEP 1
     let data = {
       driver: this.driver,
       dateRange: this.dateRange,
       haulsInRange: this.$store.getters['hauls/haulsByRangeAndDriver']({
         range: this.dateRange,
         driverId: this.driver._id
       }),
       workdaysInRange: this.workdaysInRange
     }
     localStorage.setItem('haulSummaryReportData', JSON.stringify(data))

     // STEP 2
     this.$router.push('/office/hauls/report')

    },

    findMissingWorkdays () {
      // Dispatch store event to calculate missing workdays

      if (this.driver && this.dateRange) {
        this.$store.dispatch('workdays/calculateMissingWorkdays', {
          range: this.dateRange,
          driverId: this.driver._id
        })
      } else {
        console.error('[findMissingWorkdays()]: Driver and/or dateRange has not been assigned yet...')
      }
    },

    confirmSkip () {
      this.$buefy.dialog.confirm({
        title: 'Skip Checks?',
        message: `Are you sure you want to <strong>skip checks?</strong> This is not recommended and may result in report errors`,
        confirmText: 'Yes, Skip',
        type: 'is-danger',
        cancelText: 'Cancel',
        hasIcon: true,
        onConfirm: () => {
          this.workdayComplete = true;
          this.continueToReport()
        }
      })
    },

    async fetchData () {
      await this.$store.dispatch('drivers/fetchDrivers')
      await this.$store.dispatch('workdays/fetchWorkdays')
      await this.$store.dispatch('hauls/fetchHauls')
    },

    init () {
      // Get driver and date range from localStorage
      let driver = undefined
      let dateRange = undefined

      // Get & parse driverSelected from localStorage, if it exists, or null
      if (localStorage.getItem('driverSelected')) {
        let driverId = localStorage.getItem('driverSelected')
        driver = this.$store.getters['drivers/driverById'](driverId)
      } else {
        console.error('[WorkdayCheck init()]: Driver could not be loaded from store...')
        driver = undefined
      }

      if (localStorage.getItem('reportDateRange')) {
        dateRange = localStorage.getItem('reportDateRange')

        if (!dateRange) {
          console.error('[WorkdayCheck init()]: reportDateRange could not be found in localStorage...')
        }
      }

      // The results return a comma-separated list, split() the list and convert the date range to  Date
      dateRange = dateRange.split(',')
      for (let i=0; i<dateRange.length; i++) {
        dateRange[i] = new Date(dateRange[i])
      }

      // Assign to local state the results from above
      this.driver = { ...driver }
      this.dateRange = [ ...dateRange ]
    },

    async start () {
      await this.fetchData()
      await this.init()
    }
  },

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
    
    this.start()
  }
}
</script>

<style lang="scss" scoped>

.forceBold {
  font-weight: bold !important;
}

.shortTop {
  padding-top: 8pt;
}
// .nonCommCard {
//   max-width: 400pt;
//   margin-right: 20pt;
// }
</style>
