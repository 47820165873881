
// FREIGHTROUTES > MUTATIONS

export default {

  setFreightRoutes (state, freightRoutes) {
    state.freightRoutes = freightRoutes
  },

  addFreightRoute (state, freightRoute) {
    state.freightRoutes.push(freightRoute)
  },

  updateFreightRoute (state, payloadFreightRoute) {
    let index = state.freightRoutes.findIndex(route => route._id === payloadFreightRoute._id)
    state.freightRoutes.splice(index, 1, payloadFreightRoute)
  },

  removeFreightRoute (state, freightRouteId) {
    let index = state.freightRoutes.findIndex(route => route._id === freightRouteId)
    state.freightRoutes.splice(index, 1)
  }
}