<template>
<div>
  <section class="section container">
    <br/>
    <div class="columns">
      <div class="column">

        <!-- LOADING CARPORTS (SKELETON LOADER) -->
        <FeaturedEagle/>


      </div>
      <div class="column">
        <header class="content">
          <h1 class="sectionTitle">Eagle Carports & Metal Buildings</h1>
        </header>

        <article class="articleText">
          <p class="mb-4 articleBody">
            C&H is an authorized dealer for Eagle Carports. Protect your investments with customizable carports, garages, RV covers, Combo Units (carport and storage), horse barns and more.
          </p>

          <p class="mb-4 articleBody">
            Click the link below for additional pictures and general information about the carports, including rent-to-own, and financing options that are available. 
          </p>

          <p class="mb-4 articleBody">
            Visit our office for the most recent brochure on pricing, sizing & customizations, and delivery information.
          </p>

          <br/>
          
          <b-button class="learnMoreBtn" tag="router-link" type="is-primary" outlined to="/carports">Learn More</b-button> 
        </article>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import FeaturedEagle from './FeaturedEagle/FeaturedEagle'

export default {

  components: {
    FeaturedEagle
  },

  data: () => ({
    // loading: null
  }),

  computed: {
    // featuredCarports () {
    //   const carports = this.$store.getters['carports/featuredCarports']
    //   if (carports && carports.length > 0) {
    //     console.log('CARPORTS: ', carports)
    //     this.setLoading(false)
    //     return carports
    //   } else {
    //     console.log('Carports empty or still loading...')
    //     return []
    //   }
    // }
  },

  mounted () {
    window.scrollTo(0, 0)
    // this.loading = true
    // this.$store.dispatch('carports/fetchCarports')
  },

  methods: {
    // setLoading (val) {
    //   this.loading = Boolean(val)
    //   console.log('LOADING was set to: ', this.loading)
    // }
  }
}
</script>

<style lang="scss" scoped>
.articleText p {
  font-size: 14pt;
}
.skeletonLoader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media screen and (max-width: 425px) {
  .sectionTitle {
    // border: 5px solid red;
    font-size: 13pt;
    font-weight: bold;
  }

  .articleBody {
    font-size: 12pt !important;
  }

  section.section.container {
    padding: 0 !important;
    padding-bottom: 20pt !important;
  }

  #resourceLeftColumn {
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 320px) {
  .learnMoreBtn {
    margin-bottom: 15pt;
    text-align: center;
  }
}
</style>