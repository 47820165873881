// SITESETTINGS > ACTIONS

import axios from 'axios'

// // const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {

  fetchSettings (context) {
    axios.get(`${API_URL}/siteSettings/settings`).then(res => {
      const settings = res.data

      context.commit('setSettings', settings)
    })
  },

  updateSettings (context, modifiedProperty) {
    // modifiedProperty:  Object
    // console.log('SITE SETTINGS modifiedProperty: ', modifiedProperty)

    axios.post(`${API_URL}/siteSettings/settings`, modifiedProperty).then(res => {
      const data = res.data
      console.log('SITE SETTINGS SERVER REPONSE res.data: ', data)

      context.commit('setSettings', data)
    }).catch(err => console.error(err.message))
  },

  // TODO: Finish implementation
  setCustomHeader (context, payload) {
    // Payload (customHeader) { name: String, image: String }
    axios.post(`${API_URL}/siteSettings/custom-header`, payload).then(res => {
      const settings = res.data

      // console.log('[SET CUSTOM HEADER] SERVER RESPONSE: ', settings)

      context.commit('setSettings', settings)
    })
  }
}