<template>
<div>
  <b-modal 
    v-model="show"
    :width="900"
    scroll="keep"
    auto-focus
    trap-focus
    :can-cancel="['escape', 'x']"
    active
    style="z-index: 100"
    append-to-body
  >
    <section class="card">
      <header class="card-header">
        <p class="card-header-title">New Order</p>
      </header>

      <div class="card-content">
        <b-field label="Vendor ID" label-position="on-border">
          <b-input v-model="form.vendorId" disabled />
        </b-field>

        <b-field label="Vendor" label-position="on-border" expanded>
          <b-autocomplete 
            v-model="form.vendorName"
            :open-on-focus="openOnFocus"
            :data="filteredDataObj"
            field="name"
            @select="option => setVendorChosenId(option)"
            :clearable="clearable"
            expanded
            autofocus
          />
        </b-field>

        <b-field label="Destination" label-position="on-border">
          <b-autocomplete 
            v-model="form.destination" 
            :open-on-focus="openOnFocus"
            :data="filteredDestinationObj"
            field="destination"
            @select="option => { return selected = option }"
            :clearable="clearable"
            expanded
          />
        </b-field>

        <b-field label="Material" label-position="on-border">
          <b-autocomplete 
            v-model="form.material" 
            :open-on-focus="openOnFocus"
            :data="filteredMaterialObj"
            field="material"
            @select="option => selectMaterial(option)"
            :clearable="clearable"
            expanded
          />
        </b-field>

        <b-field label="Product/Tn" label-position="on-border">
          <b-input type="text" v-model="form.product" />
        </b-field>

        <b-field label="Freight/Tn" label-position="on-border">
          <b-input type="text" v-model="form.freight" />
        </b-field>

        <b-field label="Notes" label-position="on-border">
          <b-input type="textarea" v-model="form.notes" style="white-space: pre;" />
        </b-field>
      </div>

      <!-- FOOTER -->
      <footer>
        <div class="level">

          <!-- Spacer (empty) -->
          <div class="level-left"></div>

          <!-- Buttons -->
          <div class="level-right">
            <div class="footer-btns">

              <!-- Save Existing Order  -->
              <b-button
                v-if="editOrder"
                class="footer-createBtn" 
                icon-left="content-save" 
                style="margin-right: 4pt;"
                @click="updateOrder"
              >
                Save
              </b-button>

              <!-- Create Order  -->
              <b-button
                v-else
                class="footer-createBtn" 
                icon-left="content-save" 
                style="margin-right: 4pt;"
                @click="createOrder"
              >
                Create
              </b-button>

              <!-- Cancel Button -->
              <b-button 
                v-if="editOrder"
                class="footer-closeBtn" 
                icon-left="close-thick" 
                @click="cancelEditDialog"
              >
                Cancel
              </b-button>
              <b-button 
                v-else
                class="footer-closeBtn" 
                icon-left="close-thick" 
                @click="cancelDialog"
              >
                Cancel
              </b-button>
            </div>
          </div>

        </div>
      </footer>
    </section>
  </b-modal>
</div>
</template>

<script>
import _ from 'lodash'

export default {
  props: [ 'show', 'editOrder' ],

  data: () => ({
    form: {
      orderId: '',
      vendorId: '',
      vendorName: '',
      destination: '',
      material: '',
      product: '',
      freight: '',
      notes: ''
    },
    emptyForm: {
      orderId: '',
      vendorId: '',
      vendorName: '',
      destination: '',
      material: '',
      product: '',
      freight: '',
      notes: ''
    },
    openOnFocus: true,
    clearable: true,
    selectedMaterial: undefined
  }),

  mounted () {
    this.init()
  },

  methods: {
    async createOrder () {
      // console.log('FORM TO SUBMIT: ')
      // console.log(this.form)
      try {
        // Attempt to create the order on the server
        await this.$store.dispatch('orders/createOrder', this.form)

        // this.$emit('manageOrdersUpdate')

        // Clear the form and close
        this.$emit('')
        this.cancelDialog(true)

        // Display success message
        this.$buefy.toast.open({
          message: 'Order created',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error occured while creating order! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 8000
        })
      }
    },

    cancelDialog (didUpdate) {
      this.form = {...this.emptyForm }

      if (!didUpdate)
        didUpdate = false

      if (!didUpdate) {
        this.$emit('cancelOrderDialog')
      } else {
        this.$emit('cancelOrderDialog', true)
      }
    },

    cancelEditDialog () {
      // console.log('cancelEditDialog...')
      this.form = { ...this.emptyForm }
      this.$emit('cancelOrderDialog')
    },

    setVendorChosenId (option) {
      // option => (selected = option)
      if (option) {
        // console.log('[NewOrder]: setVendorChosenId Option: ', option)
        this.form.vendorId = option._id
      }
    },

    async selectMaterial (option) {
      this.selectedMaterial = option      
    },

    async updateOrder () {
      try {
        await this.$store.dispatch('orders/updateOrder', this.form)
        this.$buefy.toast.open({
          message: 'Order updated',
          type: 'is-success',
          position: 'is-bottom'
        })
        this.cancelEditDialog()
      } catch (error) {
        console.error(error) 
        this.$buefy.toast.open({
          message: 'Order updated',
          type: 'is-success',
          position: 'is-bottom'
        })
      }
    },

    async init () {
      try {
        await this.$store.dispatch('vendors/fetchVendors')
        await this.$store.dispatch('vendorProducts/fetchVendorProducts')
        await this.$store.dispatch('orders/fetchOrders')
      } catch (error) {
        console.error(error)
      }
    }
  },

  computed: {
    data () {
      const data = this.$store.getters['vendors/allVendors']
      // console.log('DATA: ', data)
      return data
    },

    orderData () {
      const data = this.$store.getters['orders/allOrders']
      return data
    },

    vendorProducts () {
      return this.$store.getters['vendorProducts/allVendorProducts']
    },

    filteredDataObj() {
      if (this.data) {
        return this.data.filter(option => {
          // console.log('DATAOBJ OPTION: ', option)
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.form.vendorName.toLowerCase()) >= 0
          )
        })
      } else {
        return []
      }
    },

    filteredMaterialObj() {
      // console.log('ORDER DATA: ', this.orderData)
      if (this.orderData) {

        const matData = this.orderData.filter(option => {
          // console.log('OPTION: ', option)
          if (option.material && option.material.length > 0) {
            return (
              option.material
                .toString()
                .toLowerCase()
                .indexOf(this.form.material.toLowerCase()) >= 0
                && option.vendorId === this.form.vendorId
            )
          }

          if (option.status && option.status === 200) {
            return (
              option.data.material
                .toString()
                .toLowerCase()
                .indexOf(this.form.material.toLowerCase()) >= 0
                && option.data.vendorId === this.form.vendorId
            )
          }
        })
  
        // console.log('MatData: ', matData)

        const uMats = _.uniqBy(matData, (e) => {
          return e.material
        })

        // console.log('uMats: ', uMats)

        return uMats
      } else {
        return []
      }
    },

    filteredDestinationObj () {
      if (this.orderData) {      
        // Filter against what user types in (look for similar under matching vendorId)
        const data = this.orderData.filter(option => {
          // console.log('DESTINATION OPTION: ', option)

          if (option.destination && option.destination.length > 0) {
            return (
              option.destination
                .toString()
                .toLowerCase()
                .indexOf(this.form.destination.toLowerCase()) >= 0
                && option.vendorId === this.form.vendorId
            )
          }

          if (option.status && option.status === 200) {
            return (
              option.data.destination
                .toString()
                .toLowerCase()
                .indexOf(this.form.destination.toLowerCase()) >= 0
                && option.data.vendorId === this.form.vendorId
            )
          }
        })

        // Sometimes there will be multiple orders with the same destination.
        // We don't want to display more than once in the dropdown, so only
        // keep 1 per match

        let destinations = []
        let keepArray = []

        data.map(order => {
          if (!destinations.includes(order.destination)) {
            destinations.push(order.destination)
            keepArray.push(order)
          }
        })
  
        return keepArray
      } else {
        return []
      }
    }
  },

  watch: {
    editOrder () {
      // console.log('Loading order for editing: ', this.editOrder._id)
      this.form = {...this.editOrder}
      this.form.orderId = this.editOrder.orderId
      // console.log('Loading: ', this.editOrder)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-btns {
  padding: 6pt;
}
</style>
