<template>
  <section class="hero is-primary" id="settingsHero">
      <div class="hero-body">
        <p class="title">{{ title }}</p>
      </div>
    </section>
</template>

<script>
export default {
  props: [ 'title' ],
  data: () => ({
    claims: null
  }),

  computed: {
    customHeaderImageUrl () {
      if (this.claims && this.claims.sub) {
        // console.log('Returning matching userId..', this.claims.sub)
        let header = this.$store.getters['siteSettings/customHeaderImageUrl'](this.claims.sub)
        // console.log('HEADER: ', header)
        return header
      } else {
        // console.log('Not loaded yet..')
        return null
      }
    }
  },

  mounted () {
    // Hide copyright banner
    this.$store.dispatch('siteSettings/fetchSettings')
    document.getElementById('copyrightFooter').style.display = 'none'
    this.setup()
  },

  methods: {
    async setup () {
      // console.log('Loading user...')
      this.claims = await this.$auth.getUser()
      // console.log('CLAIMS: ', this.claims)
    }
  },

  watch: {
    customHeaderImageUrl () {
      if (this.customHeaderImageUrl) {
        // console.log('Setting URL for custom header: ', this.customHeaderImageUrl.imageUrl)

        document.getElementById('settingsHero').style.background = '#021f4f';
        document.getElementById('settingsHero').style.backgroundImage = `linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url("${this.customHeaderImageUrl.imageUrl}")`;
        document.getElementById('settingsHero').style.backgroundAttachment = 'fixed';
        document.getElementById('settingsHero').style.backgroundSize = 'cover';
        document.getElementById('settingsHero').style.backgroundPositionY = '-140pt';
        document.getElementById('settingsHero').style.zIndex = 10;

      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>