
// SITESETTINGS > MUTATIONS

export default {

  setSettings (state, settings) {
    state.settings = { ...settings }
  },

  setStoreOpen (state, newStatus) {
    // console.log('MUTATION: Setting storeStatus to: ', newStatus)
    state.settings.storeOpen = newStatus
  }
}