<template>
<div>
  <section class="hero is-primary" id="deliveryHero">
    <div class="hero-body">
      <p class="title">Delivery Information</p>
    </div>
  </section>

  <section class="section">
    <p class="bold center" style="font-size: 18pt;">We can deliver most of our <span class="important">bulk materials</span> <span class="lessImportant">(**topsoil, sand, compost, mulch, and gravel)</span></p>
  </section>

  <section class="section">
    <div class="columns">
      
      <!-- SMALL DELIVERIES -->
      <div class="column is-half">
        <div class="title" style="margin-bottom: 0;">Small Deliveries</div>
        <div style="margin: 8pt auto;">
          <small style="font-style: italic;">Ideal for small projects (1/2yd to 12yds)</small>
        </div>

        <div class="paragraph">
          We contract with a retired gentleman who has a 3/4-ton pickup truck with a 14’ dump trailer.
        </div>

        <div class="paragraph">
          He can haul any of our bulk materials for a fee (per trip) depending on your location. <br/>Call us for his rate.
          <strong>Please note, he does not handle the material, apart from delivering and dumping it.</strong>
        </div>

        <div class="paragraph">
          His trailer can haul up to the following limits PER TRIP:
        </div>

        <b-table :data="deliveryMaterials" :columns="columns">
        </b-table>
        <br/>

        <b-message type="is-info" has-icon>
          <p class="bold">
            Some materials weigh more when they are wet. If you must purchase bulk materials during or after a rain, 
            he may haul less than the limits listed above.
          </p>
        </b-message>

        <br/>

        <div class="card">
          <header class="card-header">
            <p class="card-header-title">Delivery Checklist</p>
          </header>

          <div class="card-content">

            <p class="paragraph" style="margin-top: 0;">Click to read:</p>

            <!-- STEP 1 - What Do I Need? -->

            <b-collapse animation="slide" :open="checklistOpen_1" aria-id="checklistContent_1">
              <template #trigger="props">
                <div class="step" aria-controls="checklistContent_1">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  <strong>Step 1. What Do I Need?</strong>
                </div>
              </template>

              <div style="margin-left: 8pt;">
                <p>You may already know what you need, but if you're unsure you may want to speak with a landscaper or contractor. They can advise you on the best material for your project, how much you'll need, and other project specifics that may be otherwise overlooked.</p>
                <br/>
              </div>
            </b-collapse>


            <!-- STEP 2 - How much do I need? -->

            <b-collapse animation="slide" :open="checklistOpen_2" aria-id="checklistContent_2">
              <template #trigger="props">
                <div class="step" aria-controls="checklistContent_2">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  <strong>Step 2. How Much Do I Need?</strong>
                </div>
              </template>

              <div style="margin-left: 8pt;">
                <p class="paragraph">
                  For our bulk materials, you can use our <strong><a href="/calculator">online bulk materials calculator</a></strong> to help
                  determine how many cubic yards of material you may need. Just be sure to read the disclaimer on that page.
                </p>
                <p class="paragraph">
                  For our pallets of stone and rock, we will soon place a chart here which will help you estimate how much you
                  need. Coming Soon!
                </p>
              </div>
            </b-collapse>


            <!-- STEP 3 - Where will it be dumped? -->

            <b-collapse animation="slide" :open="checklistOpen_3" aria-id="checklistContent_3">
              <template #trigger="props">
                <div class="step" aria-controls="checklistContent_3">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  <strong>Step 3. Where will it be dumped?</strong>
                </div>
              </template>

              <div style="margin-left: 8pt;">
                <p class="paragraph">
                  You will need to pick a suitable place with clear access that our carrier can dump your material:
                </p>

                <div class="paragraph">
                  <strong>Ground:</strong>
                  
                  <div style="margin-left: 10pt;">
                    <p class="paragraph">
                      The location <strong><u>must be dry, firm ground.</u></strong>
                      The combined weight of his truck and trailer (full) exceeds 20,000 lbs!
                    </p>
                    
                    <p class="paragraph">
                      <strong>To help prevent getting stuck, he will not drive across soft or wet ground, uncured asphalt, or uncured concrete.</strong>
                    </p>

                    <p class="paragraph">
                      <b-message type="is-info" has-icon>
                        <strong>We strongly advise picking a 2nd dump location </strong>just in case, since Delivery fees are charged per trip.
                      </b-message>
                    </p>
                  </div>
                </div>

                <p class="paragraph">
                  <strong>Fences & Gates:</strong> Openings must be 10' (ten feet) or larger for his truck and trailer to clear.
                </p>

                <p class="paragraph">
                  <strong>Obstacles:</strong> His trailer lifts vertically 14' (fourteen feet), please ensure there are no low-hanging wires, tree limbs, or other obstalces.
                </p>
              </div>
            </b-collapse>


            <!-- STEP 4 - Material in Stock? -->

            <b-collapse animation="slide" :open="checklistOpen_4" aria-id="checklistContent_4">
              <template #trigger="props">
                <div class="step" aria-controls="checklistContent_4">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  <strong>Step 4. Is the material in stock?</strong>
                </div>
              </template>

              <div style="margin-left: 8pt;">
                <p class="paragraph">
                  Check out the <strong><a href="/materials">materials page</a></strong> to see if we have the material in stock. We update the inventory on the website as often as we can, but you may want to call and double check, 903-334-7350.
                </p>
              </div>
            </b-collapse>


            <!-- STEP 5 - How do I pay? -->

            <b-collapse animation="slide" :open="checklistOpen_5" aria-id="checklistContent_5">
              <template #trigger="props">
                <div class="step" aria-controls="checklistContent_5">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  <strong>Step 5. How do I pay?</strong>
                </div>
              </template>

              <div style="margin-left: 8pt;">
                <p class="paragraph">
                  When you call to schedule a delivery (next step), we will quote you for the price of the material, the delivery fee based on location, and tax (if applicable.)
                </p>

                <div class="paragraph">
                  You may pay in the office (cash, card, or check), over the phone with a card, or give payment to our carrier (cash or check only.)<br/>
                </div>
              </div>
            </b-collapse>


            <!-- STEP 6 - Call to schedule a delivery -->

            <b-collapse animation="slide" :open="checklistOpen_6" aria-id="checklistContent_6">
              <template #trigger="props">
                <div class="step" aria-controls="checklistContent_6">
                  <b-icon :icon="!props.open ? 'menu-down' : 'menu-up'"></b-icon>
                  <strong>Step 6. Call to Schedule Delivery</strong>
                </div>
              </template>

              <div style="margin-left: 8pt;">
                <p class="paragraph">
                  Once you have completed steps 1-5, you’re ready to call us and schedule the delivery. We'll need the following information:
                </p>

                <div style="margin-left: 8pt;">
                  <div>Name</div>
                  <div>Phone Number</div>
                  <div>Address (of delivery location)</div>
                  <div>Which material you'd like</div>
                  <div>Number of cubic yards you'd like (up to his weight capacity. See above)</div>
                </div>

                <div class="paragraph">
                  We will try to match your delivery date and time, keep in mind he may have other deliveries ahead of yours, and the weather may also affect delivery dates and times.
                </div>

                <div class="paragraph">
                  Our carrier hauls until about 3:00pm during the week, deliveries pushing into this timeframe may have to be rescheduled.
                </div>
              </div>
            </b-collapse>
          </div>


        </div>

      </div>

      <!-- LARGE DELIVERIES -->
      <div class="column is-half">
        <div class="title" style="margin-bottom: 0;">Large Deliveries</div>
        <div style="margin: 8pt auto;">
          <small style="font-style: italic;">Ideal for large projects (18yds and up)</small>
        </div>

        <b-message type="is-warning" has-icon>
          ** Our end dumps lack a special liner that is needed to haul topsoil.
        </b-message>

        <p class="paragraph">
          If you need large quantities of material (25-26 tons, roughly 19-20 yards per truck) we recommend considering a tractor-trailer delivery.
        </p>

        <p class="paragraph">
          Your location must be easily accessible and able to accommodate an <strong>End Dump Tractor Trailer</strong> rig, such as this one:
        </p>

        <figure>
          <img src="@/assets/enddump_rig.jpg" alt="End-Dump Tractor Trailer" />
        </figure>

        <b-message>
          Our rigs will not traverse grass or soft ground, they must remain on gravel or cured asphalt/concrete at all times.
        </b-message>

        <p class="paragraph">
          For more information or to schedule a delivery, please call us at 903-334-7350.<br/><br/>
          <strong>We cannot give a quote over the phone</strong>. We will give your information to our dispatcher who will call you with a quote once he coordinates pricing with the pit or quarry. It could take anywhere from a few hours to a couple of days, depending on product availability and truck scheduling.

          <br/><br/>
          We will need the following information:
        </p>

        <div class="paragraph">
          <div style="margin-left: 8pt;">
            <div>Name</div>
            <div>Phone Number</div>
            <div>Address (or cross-roads) of delivery location</div>
            <div>The material you'd like</div>
            <div>Date you'd like it delivered</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
</template>

<script>
export default {
  data: () => ({
    deliveryMaterials: [
      { id: 'a001', name: 'Topsoil', capacity: '4 cubic yards' },
      { id: 'a002', name: 'Compost', capacity: '6 cubic yards' },
      { id: 'a003', name: 'Sand', capacity: '4 cubic yards' },
      { id: 'a004', name: 'Mulch (any)', capacity: '8-10 cubic yards' },
      { id: 'a005', name: 'Gravel (any)', capacity: '4 cubic yards' },
    ],

    columns: [
      { field: 'name', label: 'Material' },
      { field: 'capacity', label: 'Maximum Quantity Per Trip (Dry)' },
    ],

    checklistOpen_1: false,
    checklistOpen_2: false,
    checklistOpen_3: false,
    checklistOpen_4: false,
    checklistOpen_5: false,
    checklistOpen_6: false,
  })
}
</script>

<style lang="scss" scoped>
.important {
  font-weight: bold;
  color: rgb(41, 92, 202);
  text-decoration: double underline;
}
.lessImportant {
  color: rgb(165, 165, 165);
}
.paragraph {
  margin: 8pt auto;
}
.step {
  margin-bottom: 10pt;
  background-color:rgb(211, 225, 235);
  padding: 4pt;
}
#deliveryHero {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/trucking_banner_c.jpg');
  background-attachment: fixed;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: -100pt;
  // height: 20vh;
  // margin-bottom: 20pt;
  z-index: 10;  
}
</style>