<template>
<div>
  <b-modal v-model="show" :width="800" :height="800" scroll="keep">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title" v-if="isNewMessage">New Message</p>
        <p class="card-header-title" v-else>Edit Message</p>
      </div>

      <div class="card-content">
        <div class="media">
          
          <!-- CONTENT COLUMN -->
          <div class="media-content">

            <!-- <b-message type="is-warning is-small" aria-close-label="Close message">
              Leave a field blank if it's not applicable, aside from required fields
            </b-message> -->

            <b-message v-if="serverError" type="is-danger is-small" aria-close-label="Close message">
              Oops! There seems to be a problem with the request sent to the server. Contact the developer if the problem persists.
            </b-message>

            <div class="b-field-wrapper">
              <b-field label="Title" expanded label-position="on-border">
                <!-- <b-input v-if="editMessage" v-model="editMessage.title" required></b-input> -->
                <b-input v-model="siteMessage.title" required></b-input>
              </b-field>

              <b-field label="Message" label-position="on-border">
                <!-- <b-input v-if="editMessage" type="textarea" rows="3" v-model="editMessage.message" required></b-input> -->
                <b-input type="textarea" rows="3" v-model="siteMessage.message" required></b-input>
              </b-field>

              <b-field grouped>
                <b-field label="Start Date" label-position="on-border">
                  <b-datepicker
                    v-model="siteMessage.dateStart"
                    placeholder="Type or select start date"
                    icon="calendar-today"
                    :locale="undefined"
                    :show-week-number="false"
                    editable
                    trap-focus
                    required
                  >
                  </b-datepicker>
                </b-field>

                <b-field label="End Date" label-position="on-border">
                  <b-datepicker
                    v-model="siteMessage.dateEnd"
                    :disabled="isNoEndDate"
                    placeholder="Type or select end date"
                    icon="calendar-today"
                    :locale="undefined"
                    :show-week-number="false"
                    editable
                    trap-focus
                  >
                  </b-datepicker>
                </b-field>

                <b-field grouped style="position: relative; top: 5pt;">
                  <b-field>
                    <div>No End Date</div>
                  </b-field>
                  <b-field>
                    <b-checkbox v-model="siteMessage.isNoDateEnd"></b-checkbox>
                  </b-field>
                </b-field>
              </b-field>

              <b-field label="Page URL" placeholder="/materials" label-position="on-border">
                <b-input v-model="siteMessage.page" required></b-input>
              </b-field>

              <b-field grouped>
                <b-field grouped>
                  <b-field>
                    <span>Message Active: </span>
                  </b-field>
                  <b-field>
                    <b-checkbox v-model="siteMessage.active"></b-checkbox>
                  </b-field>
                </b-field>

                <b-field grouped>
                  <b-field>
                    <span>Show Date Posted: </span>
                  </b-field>
                  <b-field>
                    <b-checkbox v-model="siteMessage.showPosted"></b-checkbox>
                  </b-field>
                </b-field>
              </b-field>
            </div>



          </div>
        </div>
      </div>

      <br/>
      <br/>
      <br/>

      <!-- DIALOG FOOTER -->
      <footer>
        <div class="level">
          <div class="level-left"></div>
          <div class="level-right">
            <div class="footer-btns">
              <b-button 
                v-if="isNewMessage" 
                class="footer-createBtn" 
                icon-left="content-save" 
                @click="createMessage"
              >
                Create
              </b-button>

              <b-button 
                v-else 
                class="footer-saveBtn" 
                icon-left="content-save" 
                @click="saveMessage"
              >
                Save
              </b-button>

              <b-button 
                class="footer-clearBtn"
                @click="clearForm"
              >
                Clear
              </b-button>
              <b-button 
                class="footer-closeBtn" 
                icon-left="close-thick" 
                @click="cancelDialog"
              >
                Cancel
              </b-button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'isNewMessage', 'editMessage' ],

  emits: [ 'closeSiteMessageDialog' ],

  data: () => ({
    serverError: false,
    isNoEndDate: false,
    siteMessage: {
      title: '',
      message: '',
      dateCreated: new Date(),
      dateStart: new Date(),
      dateEnd: null,
      isNoDateEnd: false,
      showPosted: true,
      page: '',
      active: true
    },
    emptySiteMessage: {
      title: '',
      message: '',
      dateCreated: new Date(),
      dateStart: new Date(),
      dateEnd: null,
      isNoDateEnd: false,
      showPosted: true,
      page: '',
      active: true
    }
  }),

  watch: {
    isNoEndDate () {
      if (this.isNoEndDate === true) {
        if (this.isNewMessage) {
          this.siteMessage.dateEnd = null
        } else {
          this.editMessage.dateEnd = null
        }
      }
    },

    editMessage () {
      if (this.editMessage) {
        // console.log('----------')
        // console.log('Message to edit: ')
        // console.log(this.editMessage)
        this.siteMessage = {
          _id: this.editMessage._id,
          title: this.editMessage.title,
          message: this.editMessage.message,
          dateCreated: new Date(this.editMessage.dateCreated),
          dateStart: new Date(this.editMessage.dateStart),
          dateEnd: new Date(this.editMessage.dateEnd),
          isNoDateEnd: this.editMessage.isNoDateEnd,
          showPosted: this.editMessage.showPosted,
          page: this.editMessage.page,
          active: this.editMessage.active,
        }

        if (this.siteMessage.isNoDateEnd === true) {
          this.siteMessage.dateEnd = undefined
        }
      }
    },

    noDateEnd () {
      // console.log('noDateEnd changed...')
      if (this.noDateEnd === true) {
        this.isNoEndDate = true
      } else {
        this.isNoEndDate = false
      }
    }
  },

  computed : {
    noDateEnd () {
      return this.siteMessage.isNoDateEnd
    }
  },

  methods: {

    clearForm () {
      this.$buefy.dialog.confirm({
        title: 'Clear The Form?',
        message: `This action cannot be undone, are you sure you want to <strong>clear the form?</strong>`,
        cancelText: 'Cancel',
        confirmText: 'Yes',
        type: 'is-warning',
        onConfirm: () => {
          this.siteMessage = { ...this.emptySiteMessage }
        }
      })
    },

    checkEndDatePassed () {
      // console.log('End Date: ' + this.siteMessage.dateEnd)
      if ((this.siteMessage.dateEnd === null || this.siteMessage.dateEnd === undefined) && !this.siteMessage.isNoDateEnd) {
        return false
      }

      return true
    },

    cancelDialog () {
      this.siteMessage = { ...this.emptySiteMessage }
      this.$emit('closeSiteMessageDialog')
    },

    async saveMessage () {
      // console.log('save message...')
      // Check if no end date checked, if so null it out
      if (this.isNoEndDate) {
        this.siteMessage.dateEnd = null
      }

      if (this.checkEndDatePassed()) {
        try {
          await this.$store.dispatch('siteMessages/updateSiteMessage', this.siteMessage)
          this.$buefy.toast.open({
            message: `Message updated`,
            position: 'is-bottom',
            type: 'is-success'
          })
  
          // Clear the form and close
          this.siteMessage = { ...this.emptySiteMessage }
          this.$emit('closeSiteMessageDialog')
  
        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: `Error while trying to update message! Check logs`,
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      } else {
        alert('End Date missing')
      }
    },

    async createMessage () {
      // console.log('create message...')

      // Check if no end date checked, if so null it out
      if (this.isNoEndDate) {
        this.siteMessage.dateEnd = null
      }

      if (this.checkEndDatePassed()) {
        try {
          await this.$store.dispatch('siteMessages/createSiteMessage', this.siteMessage)
          this.$buefy.toast.open({
            message: `Message created`,
            position: 'is-bottom',
            type: 'is-success'
          })
  
          // Clear the form and close
          this.siteMessage = { ...this.emptySiteMessage }
          this.$emit('closeSiteMessageDialog')
  
        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: `Error while trying to create new message! Check logs`,
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      } else {
        alert('End Date missing')
      }

    }
  },

  async mounted () {
    await this.$store.dispatch('siteMessages/fetchSiteMessages')
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.b-field-wrapper * {
  margin-bottom: 20pt;
}

.notice {
  color: red;
  font-weight: bold;
}

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>