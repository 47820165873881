// HAULS > index.js
import actions from './actions'
import getters from './getters'
import mutations from './mutations'

export default {
  namespaced: true,
  state () {
    return {
      hauls: [],
      filteredHauls: [],
      reportDriver: undefined,
      reportHauls: [],
      reportWorkdays: [],
      reportDateStart: undefined,
      reportDateEnd: undefined
    }
  },
  actions,
  getters,
  mutations
}