<template>
<div>
  <footer id="resourcesContainer">
    <div class="container">
      <div class="columns">
        <!-- LEFT COLUMN -->
        <div class="column">
          <header class="content white" id="resourceLeftColumn">
            <div>
              <h1 id="titleHowMuchDoINeed">How Much Do I Need?</h1>
              <p class="resourceFooterLeft">
                Try our 
                <a href="/calculator">
                  <strong style="color: #93D27B; text-decoration: underline">material calculator</strong>
                </a> for help with loose materials.
                <br/>For stone, visit the 
                <a href="/help"><strong style="color: #5ACDDB; text-decoration: underline;">
                  help page</strong>
                </a> for coverage information.
              </p>
            </div>
          </header>
        </div>

        <!-- RIGHT COLUMN -->
        <div class="column">
          <div id="contactUsWrapper">
            <section class="content white" id="contactUsSection">
              <div>
                <header id="contactUsHeader">
                  <h1 id="titleContactUs">Contact Us</h1>
                </header>

                <div id="phoneAndAddress">
                  <a href="tel:9033347350" style="text-decoration: none; color: inherit;" id="phoneNumber">
                    <div style="display: flex;">
                      <div>
                        <b-icon icon="phone" class="resourceIcon"></b-icon>
                      </div>
                      <div class="resourceInnerText">903-334-7350</div>
                    </div>
                  </a>


                  <a href="https://goo.gl/maps/3nbuG1zE9oyTDgUT6" target="_blank" style="text-decoration: none; color: inherit;">
                    <div style="display: flex;">
                      <div>
                        <b-icon icon="map-marker-outline" class="resourceIcon"></b-icon>
                      </div>
                      <div class="resourceInnerText">5725 W 7th St<br/>Texarkana, TX 75501</div>
                    </div>          
                  </a>
                </div>
              </div>
            </section>
          </div>
        </div>

      </div>
    </div>
  </footer>
</div>
</template>

<script>
export default {
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style lang="scss" scoped>

.articleText p {
  font-size: 14pt;
}

footer#resourcesContainer {
  background-color: #494949;
}

.white {
  color: white;
}

header#resourceLeftColumn {
  display: flex;
  padding-top: 20pt !important;
  padding-bottom: 20pt !important;
  margin-left: 50pt;
}

div#contactUsWrapper {
  display: flex;
  padding-top: 40pt !important;
  padding-left: 0;
  // border: 5px solid red;
  margin-top: 20pt;
}

#contactUsSection {
  // border: 5px solid blue;
  padding-top: 20pt !important;
}

#phoneAndAddress {
  display: flex;
  justify-content: space-between;
}

#phoneNumber {
  margin-right: 22pt;
}

#titleContactUs {
  color: white;
  margin-left: 30pt;
  margin-bottom: 16pt;
}

#phoneAndAddress {
  margin-left: 30pt;
}


#titleHowMuchDoINeed {
  color: white;
}

#contactUsSection {
  margin-left: 50pt;
}

.resourceInnerText {
  font-weight: bold;
  color: white;
}

.resourceFooterLeft {
  // border: 4px solid blue;
}

@media screen and (min-width: 1440px) {
  #resourcesContainer {
    // border: 2px solid orange;
    padding-top: 35pt !important;
    padding-bottom: 25pt !important;
  }

  section#contactUsSection {
    padding-top: 0 !important;
  }

  h1#titleHowMuchDoINeed, h1#titleContactUs {
    font-size: 24pt !important;
  }

  h1#titleContactUs {
    margin-bottom: 13.333px;
  }
}

@media screen and (min-width: 1024px) {

  #resourcesContainer {
    padding-top: 20pt !important;
  }

  #resourceLeftColumn {
    // border: 1px solid red;
    padding-left: 15pt !important;
    padding-right: 15pt !important;
  }

  p.resourceFooterLeft {
    font-size: 11pt !important;
    // color: red;
  }

  section#contactUsSection {
    padding-top: 0 !important;
  }

  h1#titleHowMuchDoINeed, h1#titleContactUs {
    font-size: 20pt;
  }

  h1#titleContactUs {
    margin-bottom: 13.333px;
  }
  
}

@media screen and (min-width: 768px) {
  #resourceLeftColumn {
    // border: 1px solid cyan;
    padding-right: 0 !important;
  }

  #resourceLeftColumn {
    padding-top: 30pt !important;
    padding-bottom: 10pt !important;
  }

  #contactUsSection {
    padding-top: 10pt !important;
    padding-bottom: 10pt !important;
  }

  #contactUsWrapper {
    padding-top: 10pt !important;
    padding-bottom: 20pt !important;
  }
}

@media screen and (min-width: 425px) {
  h1#titleHowMuchDoINeed {
    font-size: 18pt;
  }

  #titleContactUs {
    // border: 2px solid red;
    font-size: 18pt;
  }

  p.resourceFooterLeft {
    font-size: 11pt;
  }

  .resourceInnerText {
    font-size: 10pt;
  }

  footer#resourcesContainer {
    padding-top: 20pt !important;
    padding-bottom: 20pt !important;
  }

  header#resourceLeftColumn {
    margin-left: 0;
  }

  #contactUsSection {
    margin-left: 0pt;
  }

  h1#titleContactUs {
    margin-left: 0;
  }

  #contactUsSection, #phoneAndAddress {
    margin-left: 0pt;
  }

  p.resourceFooterLeft {
    padding-top: 10pt;
  }

  #titleHowMuchDoINeed {
    font-size: 40pt;
  }
}

@media screen and (min-width: 375px) {
  #titleHowMuchDoINeed {
    font-size: 18pt;
  }

  #titleContactUs {
    font-size: 18pt;
  }

  p.resourceFooterLeft {
    font-size: 11pt;
  }

  .resourceInnerText {
    font-size: 10pt;
  }

  #resourcesContainer {
    padding-top: 16pt !important;
    padding-bottom: 16pt !important;
  }
}

@media screen and (min-width: 320px) {
  #titleHowMuchDoINeed, #titleContactUs {
    font-size: 18pt;
  }

  .resourceFooterLeft {
    font-size: 11pt;
  }

  .resourceInnerText {
    font-size: 10pt;
  }

  div#contactUsWrapper {
    padding-bottom: 12pt;
    padding-top: 0pt !important;
  }

  #resourceLeftColumn {
    margin: 0 !important;
  }

  #contactUsSection {
    margin: 0 !important;
  }

  h1#titleContactUs {
    margin: 0;
  }

  #phoneAndAddress {
    margin-left: 0;
    margin-top: 10pt;
    padding-bottom: 10pt;
  }

}

</style>