
// DELIVERIES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchDeliveries (context) {
    axios.get(`${API_URL}/deliveries`).then(res => {
      const deliveries = res.data

      context.commit('setDeliveries', deliveries)
    })
  },

  fetchEmployeeNotes (context) {
    axios.get(`${API_URL}/deliveries/employeeNotes`).then(res => {
      const notes = res.data

      context.commit('setEmployeeNotes', notes)
    })
  },

  setEmployeeNotes (context, notes) {
    // console.log('Attempting to setEmployeeNotes..')
    axios.post(`${API_URL}/deliveries/employeeNotes`, {
      text: notes
    }).then(res => {
      const the_notes = res.data

      // console.log('Setting employeeNotes to: ', the_notes)

      context.commit('setEmployeeNotes', the_notes)
    })
  },

  setDeliveries (context, deliveries) {
    context.commit('setDeliveries', deliveries)
  },

  fetchIncompleteDeliveries (context) {
    axios.get(`${API_URL}/deliveries`).then(res => {
      const deliveries = res.data

      const incomplete = deliveries.filter(d => d.completed === false)

      context.commit('setDeliveries', incomplete)
    })
  },

  setDeliveryCustomer (context, customer) {
    // console.log('[deliveries/actions/setDeliveryCustomer]: committing setDeliveryCustomer...')
    context.commit('setDeliveryCustomer', customer)
  },

  createDelivery (context, delivery) {
    axios.post(`${API_URL}/deliveries`, delivery).then(res => {
      const theDelivery = res.data

      context.commit('addDelivery', theDelivery)
    })
  },

  updateDelivery (context, delivery) {
    // let deliveryMinusId = {
    //   deliveryDate: delivery.deliveryDate,
    //   customerId: delivery.customerId,
    //   customer: delivery.customer,
    //   phone: delivery.phone,
    //   address: delivery.address,
    //   product: delivery.product,
    //   quantity: delivery.quantity,
    //   carrier: delivery.carrier,
    //   notes: delivery.notes,
    //   directions: delivery.directions,
    //   completed: delivery.completed,
    //   paid: delivery.paid,
    //   dateCreated: delivery.dateCreated,
    //   dateModified: delivery.dateModified,
    //   directionsReminder: delivery.directionsReminder
    // }
    axios.patch(`${API_URL}/deliveries/${delivery._id}`, delivery).then(res => {
      const newDelivery = res.data

      context.commit('updateDelivery', newDelivery)
    })
  },

  copyDelivery (context, deliveryId) {
    // console.log('Copying delivery ID: ' + deliveryId)
    axios.post(`${API_URL}/deliveries/${deliveryId}/copy`).then(res => {
      const cpyDelivery = res.data

      // console.log('COPIED DELIVERY: ')
      // console.log(cpyDelivery)
      context.commit('addDelivery', cpyDelivery)
    })
  },

  deleteDelivery (context, deliveryId) {
    axios.delete(`${API_URL}/deliveries/${deliveryId}`).then(res => {
      const delivery = res.data
      context.commit('removeDelivery', delivery)
    })
  }
}