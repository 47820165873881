
// CARPORTS > GETTERS

export default {
  allCarports (state) {
    return state.carports
  },

  activeCarports (state) {
    return state.carports.filter(c => c.isActive === true)
  },

  featuredCarports (state) {
    return state.carports.filter(c => c.isFeatured === true)
  },

  unfeaturedCarports (state) {
    return state.carports.find(c => c.isFeatured === false)
  },
}