
// ORDERS > MUTATIONS

export default {

  setOrders (state, orders) {
    state.orders = orders
  },

  addOrder (state, order) {
    state.orders.push(order)
  },

  updateOrder (state, order) {
    console.log('MUTATION ORDER: ', order)
    let index = state.orders.findIndex(ord => ord._id === order._id)
    state.orders.splice(index, 1, order)
  },

  removeOrder (state, order) {
    let index = state.orders.findIndex(ord => ord._id === order._id)
    state.orders.splice(index, 1)
  }
}