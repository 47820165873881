<template>
<div class="card" style="max-height: 400pt; height: 400pt; overflow-y: scroll;">

  <CallListDialog
    :show="showCallListDialog"
    :isNew="isNewCallListRequest"
    :passRequest="requestToEdit"
    @closeCallListDialog="closeCallListDialog"
  />

  <header class="deskHeader">
    <div class="level">
      <div class="level-left">
        <p class="card-header-title deskCardTitle">Call List (May Be In)</p>
      </div>
      <div class="level-right p-2">
        <b-button type="is-light" size="is-small" class="deskBtn deskManageBtn" style="margin-right: 8pt;" tag="router-link" to="/office/call-list">Manage</b-button>
        <b-button type="is-light" size="is-small" class="deskBtn deskActionBtn" icon-left="plus-thick" @click="openCallListDialog(null)"></b-button>
      </div>
    </div>
  </header>
  
  <div>
    <b-table
      :data="filteredRequests"
      :loading="requestsLoading"
      ref="table"
      detailed
      detail-key="_id"
      :opened-detailed="defaultOpenedDetails"
      :detail-transition="transitionName"
      :show-detail-icon="showDetailIcon"
      :mobile-cards="true"
      narrowed
    >
      <b-table-column field="customer" label="Customer" v-slot="props">
        <span style="white-space: pre;">
          {{ props.row.customerName }}
        </span>
      </b-table-column>

      <b-table-column field="product" label="Product" v-slot="props">
        <span style="white-space: pre;">
          {{ materialNameFromId(props.row.material) }}
        </span>
      </b-table-column>

      <b-table-column field="quantity" label="QTY" v-slot="props">
        <span style="white-space: pre;">
          {{ props.row.quantity }}
        </span>
      </b-table-column>

      <template #detail="props">
        <b-table>
            <tr>
              <td class="tableText"><strong>STATUS: </strong></td>
              <td class="tableText" style="white-space: pre;">
                <span class="statusText" :class="props.row.status">
                  {{ formatStatus(props.row.status) }}
                </span>
              </td>
            </tr>

            <tr>
              <td class="tableText"><strong>CUSTOMER: </strong></td>
              <td class="tableText" style="white-space: pre;">{{ props.row.customerName }}</td>
            </tr>

            <tr>
              <td class="tableText"><strong>CONTACT: </strong></td>
              <td class="tableText" style="white-space: pre;">{{ props.row.customerContact }}</td>
            </tr>

            <tr>
              <td class="tableText"><strong>PRODUCT: </strong></td>
              <td class="tableText" style="white-space: pre;">
                {{ formatQuantity(props.row.quantity) }}
                {{ materialNameFromId(props.row.material) }}
              </td>
            </tr>

            <tr>
              <td class="tableText"><strong>NOTES: </strong></td>
              <td class="tableText" style="white-space: pre;">{{ props.row.notes }}</td>
            </tr>
        </b-table>

        <br/>

          <div>
            <b-button
              type="is-danger"
              icon-left="delete"
              @click="confirmDeleteRequest(props.row._id)"
              style="margin-right: 8pt;"
            >Delete</b-button>
            <b-button icon-left="pencil" class="deskBtn" @click="openCallListDialog(props.row)">Edit</b-button>
          </div>
      </template>
    </b-table>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'
import CallListDialog from '@/views/office/CallList/CallListDialog'

export default {
  components: {
    CallListDialog
  },

  data: () => ({
    requestsLoading: false,
    defaultOpenedDetails: [1],
    showDetailIcon: true,
    useTransition: true,
    isNewCallListRequest: undefined,
    requestToEdit: undefined,
    showCallListDialog: false
  }),

  mounted () {
    this.loadData()
  },

  computed: {
    activeRequests () {
      this.setRequestsLoading(false)
      return this.$store.getters['callListRequests/allActiveRequests']
    },

    filteredRequests () {
      let requests = this.activeRequests
      let matsIn = []

      for (let i=0; i<requests.length; i++) {
        // console.log(requests[i])
        if (requests[i].material && requests[i].material !== '') {
          let mat = this.$store.getters['materials/materialById'](requests[i].material)
          if (mat) {
            // console.log('MAT INVENTORY FOUND: ', mat.stock)
            if (mat.stock === 'new' || mat.stock === 'in' || mat.stock === 'low') {
              matsIn.push(mat._id)
              // console.log('Pushing: ', { _id: mat._id, name: mat.name})
              // matsIn.push({ _id: mat._id, name: mat.name})
            }
          } else {
            // console.log('WARNING: Could not load requests[i] material..')
          }
        } else {
          console.error('WARNING material may be empty or falsey')
          // console.error(requests[i])
        }
      }

      if (matsIn && matsIn.length > 0) {
        // console.log('found some mats..')
        // console.log(requests)
        return requests.filter(req => matsIn.includes(req.material))
      } else {
        // console.error('ERROR: No mats found..')
        return []
      }
    },

    transitionName () {
      if (!this.useTransition)
        return
  
      return 'fade'
    }
  },

  methods: {
    loadData () {
      this.setRequestsLoading(true)
      this.$store.dispatch('deliveries/fetchDeliveries')
      this.$store.dispatch('materials/fetchMaterials')
      this.$store.dispatch('callListRequests/fetchCallListRequests')
    },

    formatStatus (status) {
      // Status options: 'waiting', 'waitingmaterial' (same one, use 'waiting please), 'informed' 'leftmsg', 'waitcall'
      switch (status) {
        case 'waiting':
          return 'Waiting'
        case 'waitingmaterial':
          return 'Waiting'
        case 'informed':
          return 'Informed'
        case 'leftmsg':
          return 'Left Message'
        case 'waitcall':
          return 'Awaiting our call'
      }
    },

    formatShortDate (date) {
      return dayjs(date).format('MM/DD')
    },

    createCallListRequest () {
      // console.log('TODO: createCallListRequest()')
    },

    materialNameFromId (id) {
      let mat = this.$store.getters['materials/materialById'](id)
      if (!mat) {
        // console.error('ERROR: Cannot retrieve material, not loaded yet OR possibly an incorrect id')
        return undefined
      }

      return mat.name
    },

    setRequestsLoading (loading) {
      this.requestsLoading = Boolean(loading)
    },

    formatQuantity (qty) {
      let quantity = qty.toString().toLowerCase()

      // console.log('QUANTITY: ', quantity)
      let parts = quantity.split(' ')
      // console.log('PARTS: ', parts)

      // Check if contains yd/yds, etc WITHOUT a space
      if (!quantity.includes('yd') || !quantity.includes('yds')) {
        // console.log('YD: ', quantity.includes('yd'))
        // console.log('YDS: ', quantity.includes('yds'))
        if (parts.length === 1 && typeof(parseFloat(parts[0])) === 'number') {
          if (parseFloat(parts[0]) > 1) {
            return quantity + ' yds'
          } else {
            return quantity + ' yd'
          }
        } else {
          return quantity
        }
          
      } else {
        return quantity
      }
    },

    openCallListDialog (request) {
      if (request) {
        // console.log('Preparing to edit request: ', request)
        this.isNewCallListRequest = false
        this.requestToEdit = { ...request }
        this.showCallListDialog = true
      } else {
        // console.log('Preparing to open NEW request dialog...')
        this.isNewCallListRequest = true
        this.requestToEdit = null
        this.showCallListDialog = true
      }
    },

    closeCallListDialog () {
      this.isNewCallListRequest = false
      this.showCallListDialog = false
      this.requestToEdit = undefined
    },

    confirmDeleteRequest (requestId) {
      this.$buefy.dialog.confirm({
        title: 'Delete This Call List Request?',
        message: `Are you sure you want to <strong>permanently delete</strong> this request? This action cannot be undone!`,
        type: 'is-danger',
        hasIcon: true,
        confirmText: 'Delete Request',
        onConfirm: () => { this.deleteRequest(requestId) }
      })
    },

    async deleteRequest (requestId) {
      // console.log('Delete requestId: ', requestId)
      try {

        await this.$store.dispatch('callListRequests/deleteCallListRequest', requestId)

        this.$buefy.toast.open({
          message: `Request deleted`,
          type: 'is-success',
          position: 'is-bottom'
        })
        
      } catch (error) {
        console.error(error.message)

        this.$buefy.toast.open({
          message: `Error while trying to delete the request! Check logs`,
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000
        })
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.statusText {
  font-weight: bold;
}
.waiting, .waitingmaterial {
  color: red;
}
.informed {
  color: blue;
}
.leftmsg {
  color: orange;
}
.waitcall {
  color: green;
}
</style>