<template>
<div>
  <b-modal
    v-model="show"
    width="600pt"
  >
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">Edit Profile</p>
      </header>
      <section class="card-content">

        <!-- First Name, Last Name, Mobile Phone -->
        <b-field grouped>
          <!-- First Name -->
          <b-field label="First Name" label-position="on-border">
            <b-input v-model="user.firstName"/>
          </b-field>

          <!-- Last Name -->
          <b-field label="Last Name" label-position="on-border">
            <b-input v-model="user.lastName"/>
          </b-field>

            <!-- Mobile Phone -->
          <b-field label="Mobile Phone" label-position="on-border">
            <b-input type="phone" v-model="user.mobilePhone"/>
          </b-field>
        </b-field>        

        <!-- Street Address -->
        <b-field label="Street Address" label-position="on-border">
          <b-input v-model="user.postalAddress" style="width: 500pt;"/>
        </b-field>

        <!-- State & Zip Code -->
        <b-field grouped>
          <!-- State -->
          <b-field label="State" label-position="on-border">
            <b-select
              v-model="user.state"
            >
              <option 
                v-for="(state, index) in states" 
                :key="state.abbrev + '-' + index"
              >
                {{ state.name }}
              </option>
            </b-select>
          </b-field>

          <!-- Zip Code -->
          <b-field label="Zip Code" label-position="on-border">
            <b-input v-model="user.zipCode"/>
          </b-field>
        </b-field>

        <!-- Organization -->
        <b-field label="Organization" label-position="on-border">
          <b-input v-model="user.organization" style="width: 500pt;"/>
        </b-field>

        <!-- Email -->
        <b-field label="Email" label-position="on-border">
          <b-input type="email" v-model="user.email" style="width: 500pt;"/>
        </b-field>
      </section>
      <footer class="card-footer">
        <a href="#" class="card-footer-item cancelBtn" @click="$emit('closeProfileDialog')">Cancel</a>
        <a href="#" class="card-footer-item saveBtn" @click="saveProfile">Save</a>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
// import axios from 'axios'

export default {
  emits: [ 'closeProfileDialog' ],

  props: [ 'show', 'userInfo' ],

  data: () => ({
    OKTA_DOMAIN: 'dev-2897504.okta.com',
    API_KEY: '00FHJJavcyZHzDXA-c9dfIA-A8xzNQkTELOdKIMeGo',
    user: {
      firstName: '',
      lastName: '',
      mobilePhone: '',
      postalAddress: '',
      state: '',
      zipCode: '',
      organization: '',
      email: '',
      id: ''
    },
    
    emptyUser: {
      firstName: '',
      lastName: '',
      mobilePhone: '',
      postalAddress: '',
      state: '',
      zipCode: '',
      organization: '',
      email: '',
      id: ''
    },

    states: [
      {
          "name": "Alabama",
          "abbrev": "AL"
      },
      {
          "name": "Alaska",
          "abbrev": "AK"
      },
      {
          "name": "American Samoa",
          "abbrev": "AS"
      },
      {
          "name": "Arizona",
          "abbrev": "AZ"
      },
      {
          "name": "Arkansas",
          "abbrev": "AR"
      },
      {
          "name": "California",
          "abbrev": "CA"
      },
      {
          "name": "Colorado",
          "abbrev": "CO"
      },
      {
          "name": "Connecticut",
          "abbrev": "CT"
      },
      {
          "name": "Delaware",
          "abbrev": "DE"
      },
      {
          "name": "District Of Columbia",
          "abbrev": "DC"
      },
      {
          "name": "Federated States Of Micronesia",
          "abbrev": "FM"
      },
      {
          "name": "Florida",
          "abbrev": "FL"
      },
      {
          "name": "Georgia",
          "abbrev": "GA"
      },
      {
          "name": "Guam",
          "abbrev": "GU"
      },
      {
          "name": "Hawaii",
          "abbrev": "HI"
      },
      {
          "name": "Idaho",
          "abbrev": "ID"
      },
      {
          "name": "Illinois",
          "abbrev": "IL"
      },
      {
          "name": "Indiana",
          "abbrev": "IN"
      },
      {
          "name": "Iowa",
          "abbrev": "IA"
      },
      {
          "name": "Kansas",
          "abbrev": "KS"
      },
      {
          "name": "Kentucky",
          "abbrev": "KY"
      },
      {
          "name": "Louisiana",
          "abbrev": "LA"
      },
      {
          "name": "Maine",
          "abbrev": "ME"
      },
      {
          "name": "Marshall Islands",
          "abbrev": "MH"
      },
      {
          "name": "Maryland",
          "abbrev": "MD"
      },
      {
          "name": "Massachusetts",
          "abbrev": "MA"
      },
      {
          "name": "Michigan",
          "abbrev": "MI"
      },
      {
          "name": "Minnesota",
          "abbrev": "MN"
      },
      {
          "name": "Mississippi",
          "abbrev": "MS"
      },
      {
          "name": "Missouri",
          "abbrev": "MO"
      },
      {
          "name": "Montana",
          "abbrev": "MT"
      },
      {
          "name": "Nebraska",
          "abbrev": "NE"
      },
      {
          "name": "Nevada",
          "abbrev": "NV"
      },
      {
          "name": "New Hampshire",
          "abbrev": "NH"
      },
      {
          "name": "New Jersey",
          "abbrev": "NJ"
      },
      {
          "name": "New Mexico",
          "abbrev": "NM"
      },
      {
          "name": "New York",
          "abbrev": "NY"
      },
      {
          "name": "North Carolina",
          "abbrev": "NC"
      },
      {
          "name": "North Dakota",
          "abbrev": "ND"
      },
      {
          "name": "Northern Mariana Islands",
          "abbrev": "MP"
      },
      {
          "name": "Ohio",
          "abbrev": "OH"
      },
      {
          "name": "Oklahoma",
          "abbrev": "OK"
      },
      {
          "name": "Oregon",
          "abbrev": "OR"
      },
      {
          "name": "Palau",
          "abbrev": "PW"
      },
      {
          "name": "Pennsylvania",
          "abbrev": "PA"
      },
      {
          "name": "Puerto Rico",
          "abbrev": "PR"
      },
      {
          "name": "Rhode Island",
          "abbrev": "RI"
      },
      {
          "name": "South Carolina",
          "abbrev": "SC"
      },
      {
          "name": "South Dakota",
          "abbrev": "SD"
      },
      {
          "name": "Tennessee",
          "abbrev": "TN"
      },
      {
          "name": "Texas",
          "abbrev": "TX"
      },
      {
          "name": "Utah",
          "abbrev": "UT"
      },
      {
          "name": "Vermont",
          "abbrev": "VT"
      },
      {
          "name": "Virgin Islands",
          "abbrev": "VI"
      },
      {
          "name": "Virginia",
          "abbrev": "VA"
      },
      {
          "name": "Washington",
          "abbrev": "WA"
      },
      {
          "name": "West Virginia",
          "abbrev": "WV"
      },
      {
          "name": "Wisconsin",
          "abbrev": "WI"
      },
      {
          "name": "Wyoming",
          "abbrev": "WY"
      }
  ]
  }),

  methods: {
    cancelDialog () {
      this.$emit('closeProfileDialog')
    },

    async saveProfile () {
      try {

        this.$store.dispatch('profile/updateProfile', {
          profile: {
            firstName: this.user.firstName,
            lastName: this.user.lastName,
            mobilePhone: this.user.mobilePhone,
            organization: this.user.organization,
            postalAddress: this.user.postalAddress,
            state: this.user.state,
            zipCode: this.user.zipCode,
            email: this.user.email
          }
        })

        this.$buefy.toast.open({
          message: 'Profile updated',
          type: 'is-success',
          position: 'is-bottom',
          duration: 2000
        })

        this.clearAndClose()

      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while updating profile! Check logs',
          type: 'is-danger',
          position: 'is-bottom',
          duration: 8000
        })
      }
    },

    clearAndClose () {
      this.user = { ...this.emptyUser }
      this.$emit('closeProfileDialog')
    }
  },

  watch: {
    show () {
      // console.log('USER INFO: ', this.userInfo)
      this.user.firstName = this.userInfo.profile.firstName
      this.user.lastName = this.userInfo.profile.lastName
      this.user.mobilePhone = this.userInfo.profile.mobilePhone
      this.user.postalAddress = this.userInfo.profile.postalAddress
      this.user.state = this.userInfo.profile.state
      this.user.zipCode = this.userInfo.profile.zipCode
      this.user.organization = this.userInfo.profile.organization
      this.user.email = this.userInfo.profile.email
      this.user.id = this.userInfo.id
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    color:white;
    background-color: rgb(28, 70, 94);
  }
}
.cancelBtn {
  background-color: rgb(88, 88, 88);
  color: white;
  font-weight: bold;

  &:hover {
    color:white;
    background-color: rgb(68, 68, 68);
  }
}
</style>