
// VENDORSITES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchVendorSites (context) {
    axios.get(`${API_URL}/vendorSites`).then(res => {
      const vendorSites = res.data

      context.commit('setVendorSites', vendorSites)
    })
  },

  createVendorSite (context, payload) {
    return axios.post(`${API_URL}/vendorSites`, payload).then(res => {
      const vendorSite = res.data

      context.commit('addVendorSite', vendorSite)
      return vendorSite
    })
  },

  updateVendorSite (context, vendorSite) {
    axios.patch(`${API_URL}/vendorSites/${vendorSite._id}`, { 
      vendorId: vendorSite.vendorId, 
      name: vendorSite.name, 
    })
    .then(res => {
      const updatedVendorSite = res.data

      context.commit('updateVendorSite', updatedVendorSite)
    })
  },

  deleteVendorSite (context, vendorSiteId) {
    axios.delete(`${API_URL}/vendorSites/${vendorSiteId}`).then(() => {
      context.commit('removeVendorSite', vendorSiteId)
    })
  }
}