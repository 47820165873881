
// FAQS > GETTERS

export default {
  allFAQS (state) {
    return state.FAQS
  },

  activeFAQS (state) {
    return state.FAQS.filter(faq => faq.isActive === true)
  },

  faqsByCategory (state, category) {
    return state.FAQS.filter(faq => faq.category === category)
  }
}