<template>
<section class="bulletinWrapper" v-if="messages.length > 0 || !storeOpen">
  <section class="panel is-danger" v-if="!storeOpen">
    <p class="panel-heading" v-if="!storeOpen && reasonClosed && reasonClosed.length >= 0">
      <b-icon icon="alert" style="margin-right: 8pt;"></b-icon>
      Store Closed
    </p>
    <p class="panel-heading" v-else>
      <b-icon icon="alert" style="margin-right: 8pt;"></b-icon>
      Store May Be Closed
    </p>
    <div class="panel-block" style="background-color: white;">
      <div class="p-2">
        <p v-if="!storeOpen && reasonClosed && reasonClosed.length <= 0">We might be closed, please call us at 903-334-7350</p>
        <p v-else-if="!storeOpen && reasonClosed && reasonClosed.length >= 0">{{ reasonClosed }}</p>
        <p v-else>We <em>might</em> be closed, please call us at 903-334-7350</p>
      </div>
    </div>
  </section>

  <br/>

  <div class="bulletinPanel" v-if="messages.length > 0">
    <div class="panel-heading bulletinHeading">
      <b-icon icon="bell-ring" style="margin-right: 5pt;"></b-icon>
      Bulletin
    </div>
    <div class="panel-block textContainer">

      <div class="container">
        <div v-for="message in messages" :key="message._id">
          <div class="panel-heading" id="panelHeading">
            <div class="level">
              <div class="level-left">
                <span class="bulletinMessageTitle">{{ message.title }}</span>
              </div>
              
              <div class="level-right" id="postedNotice">
                <span 
                  v-if="message.showPosted"
                  style="font-size: 10pt !important; font-weight: 500 !important;"
                >Posted {{ formatDate(message.dateStart) }}</span>
              </div>
            </div>
          </div>
          <div class="panel-block">
            <div class="bulletinMessage">
              {{ message.message }}
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
</template>

<script>
import dayjs from 'dayjs'

export default {
  data: () => ({
  }),

  computed: {
    messages () {
      const messages = this.$store.getters['siteMessages/allSiteMessages']
      const bulletinMessages = messages.filter(msg => msg.page === '/' && msg.active)
      return bulletinMessages
    },

    storeOpen () {
      return this.$store.getters['siteSettings/storeOpen']
    },

    reasonClosed () {
      return this.$store.getters['siteSettings/reasonClosed']
    }
  },

  methods: {
    formatDate (date) {
      return dayjs(date).format('M/DD/YY')
    }
  },

  mounted () {
    this.$store.dispatch('siteMessages/fetchSiteMessages')
    this.$store.dispatch('siteSettings/fetchSettings')
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

#panelHeading {
  background-color: transparent;
  padding: 8pt;
  padding-bottom: 0;
}

.bulletinWrapper {
  background-color: #d8d8d8;
  border-top: 8px solid #6b94b9;
}
.bulletinPanel {
  background-color: transparent;
}
.bulletinHeading {
  background-color: $primary;
  color: white;
  margin-top: 0;
}

.textContainer {
  background-color: white;
}

@media screen and (min-width: 1440px) {
  .bulletinWrapper {
    // border: 5px solid green !important;
  }

  .bulletinPanel {
    // border: 2px solid orange;
    max-width: 60%;
    margin: 0 auto;
    margin-bottom: 10pt;
  }
}

@media screen and (min-width: 768px) {
  .bulletinWrapper {
    // border: 1px solid red;
    // padding: 10pt;
    padding: 20pt 30pt;
  }
}

@media screen and (min-width: 425px) {
  .bulletinWrapper {
    // border: 1px solid cyan;
    // padding: 10pt;
    padding: 20pt 30pt;
  }
}

@media screen and (min-width: 375px) {
  .bulletinWrapper {
    // border: 1px solid cyan;
    // padding: 10pt;
    padding: 20pt 30pt;
  }

  #postedNotice {
    margin-top: 0 !important;
  }
  .bulletinMessageTitle {
    font-size: 12pt;
  }
  .bulletinMessage {
    font-size: 10pt;
  }
}

@media screen and (min-width: 320px) {
  .bulletinWrapper {
    padding-bottom: 20pt !important;
    // border: 5px solid red;
  }

  .title.introTitle {
    font-size: 8pt;
  }
}
</style>
