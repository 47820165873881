
// WORKDAYS > ACTIONS

import axios from 'axios'

import dayjs from 'dayjs'
import DayJSWeekday from 'dayjs/plugin/weekday'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchWorkdays (context) {
    // console.log('...fetching workdays...')
    axios.get(`${API_URL}/workdays`).then(res => {
      const workdays = res.data

      // console.log('\nINSIDE FETCH WORKDAYS: ')
      // console.log(workdays)

      context.commit('setWorkdays', workdays)
    })
  },

  getWorkdaysForDriver (_, payload) {
    // payload:  { driverId, workDate }
    // console.log('inside getWorkdaysForDriver action with following payload: ')
    // console.log(payload)

    return axios.post(`${API_URL}/workdays/getWorkday`, {
      driverId: payload.driverId,
      workDate: payload.workDate
    }).then(res => {
      const workday = res.data
      
      // console.log('WORKDAY FROM SERVER: ')
      // console.log(workday)
      return workday
    })

    // return workdays
  },

  setCurrentWorkday (context, workday) {
    // console.log('Setting currentWorkday to: ')
    // console.log(workday)
    context.commit('setCurrentWorkday', workday)
  },

  calculateMissingWorkdays (context, payload) {
    // payload: { range, driverId }

    // console.log('inside calculateMissingWorkdays with payload: ')
    // console.log(payload)

    dayjs.extend(DayJSWeekday)

    // Convert ranges provided from template into DayJS dates
    let rangeStart = dayjs(payload.range[0])
    let rangeEnd = dayjs(payload.range[1])
    let driverId = payload.driverId
    
    // Get the number of days between this range
    let diff = Math.abs(rangeStart.diff(rangeEnd, 'day'))

    // console.log('Diff: ', diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(rangeStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // console.log('Daylist: ')
    // console.log(daylist)

    // NEEDED: { daylist, driverId }
    context.commit('setMissingWorkdays', { daylist, driverId })
    
  },

  saveOrCreateWorkday (context, workdayPayload) {

    // workdayPayload: { driverId, workday (workday object) }

    /* TODO: Implement new logic (create/save workday in database, 
      then dispatch store event to remove this workDate from state.missingWorkdays) */

    // console.log('ACTION createOrSaveWorkday payload: ')
    // console.log(workdayPayload)

    let workload = {
      ...workdayPayload.workday
    }

    // console.log('WORKLOAD: ')
    // console.log(workload)

    axios.post(`${API_URL}/workdays`, workload).then(res => {
      const workday = res.data

      // console.log('WORKDAY FROM SERVER: ')
      // console.log(workday)

      context.commit('createOrSaveWorkday', workday)
      context.commit('removeMissingWorkday', workday)
    })
  },

  updateWorkday (context, workdayPayload) {
    // console.log('[updateWorkday()]: workdayPayload: ')
    // console.log(workdayPayload)
    axios.patch(`${API_URL}/workdays`, workdayPayload).then(res => {
      const updatedWorkday = res.data

      context.commit('updateWorkday', updatedWorkday)
    })
  },

  removeWorkday (context, workdayId) {
    axios.delete(`${API_URL}/workdays`, workdayId).then(res => {
      const removedWorkday = res.data

      context.commit('removeWorkday', removedWorkday._id)
    })
  },

  test ({ dispatch, getters }, info) {
    dayjs.extend(DayJSWeekday)


    // const driverId = info.driverId
    // console.log('inside workdays/test action...')
    // console.log('Date Start: ' + info.dateStart)
    // console.log('Date End: ' + info.dateEnd)
    const dateStart = dayjs(info.dateStart)
    const dateEnd = dayjs(info.dateEnd)

    // console.log('Date Start: ' + dateStart)
    // console.log('Date End: ' + dateEnd)

    // Convert ranges provided from template into DayJS dates
    
    // Get the number of days between this range
    let diff = Math.abs(dateStart.diff(dateEnd, 'day'))

    // console.log('Diff: ' + diff)

    // For each day of difference in the range, append the date to a list
    let daylist = []
    for (let i=0; i<=diff; i++) {
      daylist.push(dateStart.add(i, 'day').format('YYYY-MM-DD'))
    }

    // const listInfo = {
    //   driverId: info.driverId,
    //   daylist: daylist
    // }

    // console.log('LIST INFO: ')
    // console.log(listInfo)

    dispatch('fetchWorkdays')

    const workdaysFinal = getters.allWorkdays

    // console.log(workdaysFinal)

    return workdaysFinal

    // context.commit('setWorkdayList', listInfo)
  }
}