<template>
<div class="top-wrapper" style="height: 110vh;">
  <CustHeaderComp title="Cost Calculator" />

  <section class="section">
    <div class="columns">
      <!-- FORM -->
      <div class="column">

        <!-- Input Form -->
        <section class="card">
          <header class="card-header">
            <p class="card-header-title">Input</p>
          </header>
          <div class="card-content">
            <b-tabs v-model="activeTab">

              <!-- TAB : INPUT -->
              <b-tab-item label="Input">
                <!-- QTY (tons) -->
                <b-field label="Qty (tons)" label-position="on-border">
                  <b-input
                    v-model="qtyTons"
                  />
                </b-field>

                <!-- Product -->
                <b-field label="Product" label-position="on-border">
                  <b-autocomplete
                    v-model="product"
                    placeholder="Start typing product name"
                    :data="filteredProduct"
                    field="name"
                    @select="option => (selected = option)"
                    :clearable="clearable"
                  />
                </b-field>

                <!-- Cost Per Ton -->
                <b-field label="Cost Per Ton" label-position="on-border">
                  <b-input
                    v-model="costPerTon"
                    disabled
                  />
                </b-field>

                <!-- Total Cost (ton) -->
                <b-field label="Total Cost (ton)" label-position="on-border">
                  <b-input
                    v-model="totalCostTons"
                    disabled
                  />
                </b-field>

                <!-- Print Button & Include Profit Margins Checkbox -->
                <b-field grouped>
                  <b-field>
                    <b-button type="is-primary" icon-left="printer" @click="print" :disabled="hasFormInput">Print</b-button>
                  </b-field>
                  <b-field>
                    <b-checkbox 
                      v-model="doPrintProfitMargin"
                      type="is-danger"
                      style="margin-top: 6pt;"
                      @input="checkForProfitMargins()"
                      :disabled="hasProfitMargins"
                    >
                      Print Profit Margins
                    </b-checkbox>
                  </b-field>
                </b-field>
              </b-tab-item>

              <!-- TAB : PROFIT MARGIN -->
              <b-tab-item label="Profit Margin" :disabled="hasFormInput">
                <div class="content">
                <div class="columns">
                  <div class="column">
                    <b-field label="C&H Price Retail" label-position="on-border">
                      <b-input v-model="chPriceRetail"/>
                    </b-field>
                    <b-field label="C&H Price Wholesale" label-position="on-border">
                      <b-input v-model="chPriceWholesale"/>
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Suggested Retail" label-position="on-border">
                      <b-input v-model="chSuggestedRetail" disabled />
                    </b-field>
                    <b-field label="Suggested Wholesale" label-position="on-border">
                      <b-input v-model="chSuggestedWholesale" disabled />
                    </b-field>
                  </div>
                  <div class="column">
                    <b-field label="Actual Retail %" label-position="on-border">
                      <b-input v-model="chActualRetail" disabled />
                    </b-field>
                    <b-field label="Actual Wholesale %" label-position="on-border">
                      <b-input v-model="chActualWholesale" disabled />
                    </b-field>
                  </div>
                </div>
              </div>
              </b-tab-item>
              
              <!-- TAB : OVERRIDE -->
              <b-tab-item label="Override">
                <b-field label="Override Product" label-position="on-border">
                  <b-input v-model="overrideProduct"/>
                </b-field>
                <b-field label="Override Freight" label-position="on-border">
                  <b-input v-model="overrideFreight"/>
                </b-field>
                <b-button type="is-primary" @click="calculateOverride">Calculate</b-button>
                <b-button type="is-secondary" @click="resetOverride" tabindex="-1">Reset</b-button>
              </b-tab-item>

            </b-tabs>
          </div>
        </section>

        <br/>

      </div>

      <!-- BREAKDOWN -->
      <div class="column">
        <section class="card">
          <header class="card-header" style="background-color: #635751;">
            <p class="card-header-title">Breakdown</p>
          </header>
          <div class="card-content">
            <table>
              <tr>
                <td v-if="selected"><span style="margin-right: 6pt; font-weight: bold;">NAME: </span></td>
                <td v-if="selected"><strong>{{ this.selected.name }}</strong></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">PRODUCT: </span></td>
                <td v-if="selected && !override"><span>${{ this.selected.product }}/T</span></td>
                <td v-else><span class="overrideText">${{ this.overrideProduct }}/T</span></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">FREIGHT: </span></td>
                <td v-if="selected && !override"><span>${{ this.selected.freight }}/T</span></td>
                <td v-else><span class="overrideText">${{ this.overrideFreight }}/T</span></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">TONS: </span></td>
                <td v-if="selected && !override"><span>{{ this.qtyTons }} T</span></td>
              </tr>
              
              <br/>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">VENDOR FSC: </span></td>
                <td v-if="selected"><span>${{ this.vendorFuelSurcharge }}/T (${{ calculateVendorFuelSurcharge() }})</span></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">CHT FSC: </span></td>
                <td v-if="selected"><span>${{ this.chtFuelSurcharge }}/T (${{ calculateChtFuelSurcharge() }})</span></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">TOTAL FSC: </span></td>
                <td v-if="selected"><span>${{ getTotalFuelSurcharge() }}/T (${{ calculateTotalFuelSurcharge() }})</span></td>
              </tr>

              <br/>

              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">QTY (YDS): </span></td>
                <td><span v-if="selected">{{ calculateCostPerTon() }} yds</span></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">COST PER YARD: </span></td>
                <td><span v-if="selected">${{ calculateCostPerYard() }}/yd</span></td>
              </tr>
              <tr>
                <td><span style="margin-right: 6pt; font-weight: bold;">TOTAL COST (YD): </span></td>
                <td><span v-if="selected">${{ totalCostTons }}</span></td>
              </tr>
            </table>
          </div>
        </section>
      </div>
    </div>
  </section>
</div>
</template>

<script>
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'

export default {
  components: {
    CustHeaderComp
  },

  data: () => ({
    activeTab: 0,
    doPrintProfitMargin: false,
    qtyTons: '',
    product: '',
    costPerTon: '',
    totalCostTons: '',
    selected: null,

    /* fuelSurcharge: '', */
    vendorFuelSurcharge: '',
    chtFuelSurcharge: '',

    clearable: true,
    override: false,
    overrideProduct: '',
    overrideFreight: '',
    isOpen: false,

    chPriceWholesale: '',
    chPriceRetail: '',
    chSuggestedWholesale: '',
    chSuggestedRetail: '',
    chActualWholesale: '',
    chActualRetail: ''
  }),

  mounted () {
    this.$store.dispatch('vendorProducts/fetchVendorProducts')
    this.$store.dispatch('vendors/fetchVendors')

    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
  },

  computed: {
    products () {
      return this.$store.getters['vendorProducts/allVendorProducts']
    },

    filteredProduct () {
      return this.products.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.product.toLowerCase()) >= 0 ||
          option.code
            .toString()
            .toLowerCase()
            .indexOf(this.product.toLowerCase()) >= 0
        )
      })
    },

    hasProfitMargins () {
      if (this.chPriceWholesale === '' || this.chPriceRetail === '') {
        return true
      } else {
        return false
      }
    },

    hasFormInput () {
      if (this.qtyTons !== '' && this.product !== '') {
        return false
      } else {
        return true
      }
    }
  },

  methods: {

    print () {
      // 1. Set store info
      this.$store.dispatch('billprep/setData', {
        printProfitMargin: this.doPrintProfitMargin,
        vendorName: this.selected.vendorName,
        qtyTons: this.qtyTons,
        product: this.product,
        costPerTon: this.costPerTon,
        totalCostTons: this.totalCostTons,


        /* fuelSurcharge: this.fuelSurcharge, */
        vendorFuelSurcharge: this.vendorFuelSurcharge,
        chtFuelSurcharge: this.chtFuelSurcharge,
        totalVendorFuelSurcharge: this.calculateVendorFuelSurcharge(),
        totalChtFuelSurcharge: this.calculateChtFuelSurcharge(),


        override: this.override,
        qtyYards: this.calc_qtyYds(),
        costPerYard: this.calc_costPerYd(),
        givenProduct: this.selected.product,
        givenFreight: this.selected.freight,
        overrideProduct: this.overrideProduct,
        overrideFreight: this.overrideFreight,
        chPriceWholesale: this.chPriceWholesale,
        chPriceRetail: this.chPriceRetail,
        chSuggestedWholesale: this.chSuggestedWholesale,
        chSuggestedRetail: this.chSuggestedRetail,
        chActualWholesale: this.chActualWholesale,
        chActualRetail: this.chActualRetail,
        printProfitMargins: this.doPrintProfitMargin
      })

      // 2. Push to print route (/office/cost-calculator/print ?)
      this.$router.push('/office/cost-calculator/print')
    },

    calculateCostPerTon () {
      const product = parseFloat(this.selected.product)
      const freight = parseFloat(this.selected.freight)
      const FSC = parseFloat(this.chtFuelSurcharge)

      // return this.toFixed(product + freight + FSC, 2)
      return (product + freight + FSC).toFixed(2)
    },

    calculateCostPerYard () {
      const product = parseFloat(this.selected.product)
      const freight = parseFloat(this.selected.freight)
      const FSC = parseFloat(this.chtFuelSurcharge)

      // console.log(`Product: ${product}`)
      // console.log(`Freight: ${freight}`)
      // console.log(`FSC: ${FSC}`)

      // return this.toFixed(((product + freight + FSC) * 1.35), 5)
      return ((product + freight + FSC) * 1.35).toFixed(4)
    },

    checkForProfitMargins () {
      if (this.doPrintProfitMargin && (this.chPriceWholesale === '' || this.chPriceRetail === '')) {
        this.$buefy.dialog.alert({
          title: 'Missing Data',
          message: 'You are missing <strong>Wholesale</strong> or <strong>Retail</strong> input on the "Profit Margins" tab',
          type: 'is-danger',
          hasIcon: true,
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },

    resetOverride () {
      // console.log('reset...')
      this.overrideProduct = ''
      this.overrideFreight = ''
      this.override = false
      this.isOpen = false
      this.calculateSelected()
    },

    calc_qtyYds () {
      return (parseFloat(this.qtyTons) / parseFloat(1.35)).toFixed(2)
    },

    calc_costPerYd () {
      // return (parseFloat(this.totalCostTons) / parseFloat(this.calc_qtyYds())).toFixed(2)
      // return this.toFixed(parseFloat(this.totalCostTons) / parseFloat(this.calc_qtyYds()), 5)
      return (parseFloat(this.totalCostTons) / parseFloat(this.calc_qtyYds())).toFixed(5)
    },

    // toFixed (num, precision ) {
    //   return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    // },

    calculateOverride () {
      if (this.overrideProduct === '' || this.overrideFreight === '') {
        this.$buefy.dialog.alert({
          title: 'Missing Override Info',
          message: `Override is missing <strong>product</strong> or <strong>freight</strong>. You must provide both fields, even if one isn't being changed.`
        })
      } else {
        this.override = true
        // console.log('OVERRIDE PRODUCT: ', this.overrideProduct)
        // console.log('OVERRIDE FREIGHT: ', this.overrideFreight)
        this.costPerTon = (parseFloat(this.overrideFreight) + parseFloat(this.overrideProduct)).toFixed(2)
        this.totalCostTons = (parseFloat(this.qtyTons) * parseFloat(this.costPerTon)).toFixed(2)
      }
    },

    calculateSelected () {
      // this.costPerTon = (parseFloat(this.selected.freight) + parseFloat(this.selected.product)).toFixed(2)
      // this.calculateChtFuelSurcharge()
      // this.costPerTon = (parseFloat(this.selected.freight) + parseFloat(this.selected.product) + parseFloat(this.chtFuelSurcharge)).toFixed(2)
      // console.log(`Cost per Ton: $${this.costPerTon}`)
      // this.totalCostTons = (parseFloat(this.qtyTons) * parseFloat(this.costPerTon)).toFixed(2)

      const fFreight = parseFloat(this.selected.freight)
      const fProduct = parseFloat(this.selected.product)
      const fTons = parseFloat(this.qtyTons)
      const fFSC = parseFloat(this.chtFuelSurcharge)

      // console.log(`Freight: ${fFreight}`)
      // console.log(`Product: ${fProduct}`)
      // console.log(`Tons: ${fTons}`)
      // console.log(`FSC: ${fFSC}`)

      this.costPerTon = ((fFreight + fProduct) + fFSC).toFixed(2)

      // this.totalCostTons = ((parseFloat(this.selected.freight) * this.selected.qtyTons) + parseFloat(this.selected.product)).toFixed(2)
      this.totalCostTons = ((fFreight * fTons) + (fProduct * fTons)).toFixed(2)
    },

    calculateSuggestedWholesale () {
      this.chSuggestedWholesale = (parseFloat(this.calc_costPerYd()) / 0.7).toFixed(2)
    },

    calculateSuggestedRetail () {
      this.chSuggestedRetail = (parseFloat(this.calc_costPerYd()) / 0.6).toFixed(2)
    },

    calculateActualWholesale () {
      const rate = parseFloat(this.calc_costPerYd()) / parseFloat(this.chPriceWholesale)
      const answer = (1 - rate).toFixed(2)
      this.chActualWholesale = (answer * 100).toFixed(2)
    },

    calculateActualRetail () {
      const rate = parseFloat(this.calc_costPerYd()) / parseFloat(this.chPriceRetail)
      const answer = (1 - rate).toFixed(2)
      this.chActualRetail = (answer * 100).toFixed(2)
    },

    getTotalFuelSurcharge () {
      return (parseFloat(this.vendorFuelSurcharge) + parseFloat(this.chtFuelSurcharge)).toFixed(2)
    },

    calculateVendorFuelSurcharge () {
      return (parseFloat(this.vendorFuelSurcharge) * parseFloat(this.qtyTons)).toFixed(2)
    },

    calculateChtFuelSurcharge () {
      return (parseFloat(this.chtFuelSurcharge) * parseFloat(this.qtyTons)).toFixed(2)
    },

    calculateTotalFuelSurcharge () {
      const rate = (parseFloat(this.calculateVendorFuelSurcharge()) + parseFloat(this.calculateChtFuelSurcharge())).toFixed(2)
      // console.log('Rate: ', rate)
      return rate 
    }
  },

  watch: {
    selected () {
      // console.log('Selected: ', this.selected)
      // const fuelSurcharge = this.$store.getters['vendors/fuelSurchargeByVendorId'](this.selected.vendorId)

      // TODO: Check changes!
      this.vendorFuelSurcharge = this.$store.getters['vendors/vendorFuelSurchargeByVendorId'](this.selected.vendorId)
      this.chtFuelSurcharge = this.$store.getters['vendors/chtFuelSurchargeByVendorId'](this.selected.vendorId)
      // console.log('Vendor Fuel Surcharge: $', this.vendorFuelSurcharge)
      // console.log('CHT Fuel Surcharge: $', this.chtFuelSurcharge)

      this.calculateSelected()
    },

    chPriceWholesale () {
      this.calculateSuggestedWholesale()
      this.calculateActualWholesale()
    },

    chPriceRetail () {
      this.calculateSuggestedRetail()
      this.calculateActualRetail()
    }
  }
}
</script>

<style lang="scss" scoped>
.overrideText {
  color:red;
}
.card-footer-item {
  transition: all 0.25s;
}
.card-footer-item:hover {
  background-color: rgb(214, 214, 214);
  transition: all 0.25s;
}
</style>