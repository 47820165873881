<template>
<div>

  <WorkdayDialog :show="showWorkdayDialog" :dateFor="dateFor" :isNewWorkday="isNewWorkday" :driver="driverSelected" :workdayModel="workdaySelected" @closeWorkdayDialog="closeWorkdayDialog" @forceCheckIsComplete="forceCheckIsComplete"/>

  <section class="hero ch-hero">
    <div class="hero-body">
      <p class="title" style="color: white;">Workdays</p>
      <b-button 
        tag="router-link"
        to="/office/hauls"
        icon-left="arrow-left-thick"
      >
        Hauls
      </b-button>
    </div>
  </section>

  <section class="section" style="padding-bottom: 16pt;">

    <b-field label="View Week (starting Sundays)" label-position="on-border">
      <b-datepicker
        v-model="startDate"
        icon="calendar-today"
        placeholder="Choose..."
        :unselectable-days-of-week="[1, 2, 3, 4, 5, 6]"
        :nearby-selectable-month-days="true"
        @input="makeList"
        trap-focus
      >
      </b-datepicker>
    </b-field>
  </section>

  <!-- <section class="section is-small" style="border: 1px solid black;">
    <div v-if="worklist !== null">
      {{ worklist }}
    </div>
  </section> -->

  <section class="section" style="padding-top: 10pt;">
    <b-table
      :loading="loading"
      :data="isEmpty ? [] : worklist"
      striped
      bordered
    >
      <b-table-column label="Driver">
        <template #header="{ column }">
          <div :label="column.label">
            {{ column.label }}
          </div>
        </template>
        <template v-slot="props">
          <strong>{{ props.row.name }}</strong>
        </template>
      </b-table-column>


      <!-- SUNDAY -->
      <b-table-column label="Sun" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[0]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[0]">
              <b-icon 
                :icon="checkComplete(props.row.workweek[0])" 
                :class="checkCompleteClass(props.row.workweek[0])"
                class="day is-small"
                size="is-medium"
                @click="openWorkdayDialog(props.row.workweek[0], props.row.driverId, datesList[0])"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[0])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[0])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>

      <!-- MONDAY -->
      <b-table-column label="Mon" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[1]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[1]" @click="openWorkdayDialog(props.row.workweek[1], props.row.driverId, datesList[1])">
              <b-icon 
                :icon="checkComplete(props.row.workweek[1])" 
                :class="checkCompleteClass(props.row.workweek[1])"
                class="day is-small"
                size="is-medium"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[1])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[1])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>

      <!-- TUESDAY -->
      <b-table-column label="Tues" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[2]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[2]" @click="openWorkdayDialog(props.row.workweek[2], props.row.driverId, datesList[2])">
              <b-icon 
                :icon="checkComplete(props.row.workweek[2])" 
                :class="checkCompleteClass(props.row.workweek[2])"
                class="day is-small"
                size="is-medium"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[2])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[2])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>

      <!-- WEDNESDAY -->
      <b-table-column label="Wed" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[3]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[3]" @click="openWorkdayDialog(props.row.workweek[3], props.row.driverId, datesList[3])">
              <b-icon 
                :icon="checkComplete(props.row.workweek[3])" 
                :class="checkCompleteClass(props.row.workweek[3])"
                class="day is-small"
                size="is-medium"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[3])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[3])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>

      <!-- THURSDAY -->
      <b-table-column label="Wed" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[4]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[4]" @click="openWorkdayDialog(props.row.workweek[4], props.row.driverId, datesList[4])">
              <b-icon 
                :icon="checkComplete(props.row.workweek[4])" 
                :class="checkCompleteClass(props.row.workweek[4])"
                class="day is-small"
                size="is-medium"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[4])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[4])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>

      <!-- FRIDAY -->
      <b-table-column label="Wed" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[5]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[5]" @click="openWorkdayDialog(props.row.workweek[5], props.row.driverId, datesList[5])">
              <b-icon 
                :icon="checkComplete(props.row.workweek[5])" 
                :class="checkCompleteClass(props.row.workweek[5])"
                class="day is-small"
                size="is-medium"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[5])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[5])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>

      <!-- SATURDAY -->
      <b-table-column label="Wed" class="dayColumn" style="text-align: center !important;">
        <template #header="{ column }">
          <div :label="column.label">
            {{ showShortDate(datesList[6]) }}
          </div>
        </template>

        <template v-slot="props">
          <!-- RENDER: IF DRIVER & WORKDATE EXISTS -->
          <div v-if="props.row && props.row.driverId">
            <div v-if="props.row.workweek[6]" @click="openWorkdayDialog(props.row.workweek[6], props.row.driverId, datesList[6])">
              <b-icon 
                :icon="checkComplete(props.row.workweek[6])" 
                :class="checkCompleteClass(props.row.workweek[6])"
                class="day is-small"
                size="is-medium"
              >
              </b-icon>
            </div>

            <div v-else>
              <b-button 
                type="is-primary" 
                icon-left="plus-thick" 
                @click="openWorkdayDialog(null, props.row.driverId, datesList[6])"
                outlined
              ></b-button>
            </div>
          </div>

          <!-- ELSE RENDER: NEW WORKDAY BUTTON -->
          <div v-else>
            <b-button 
              type="is-primary" 
              icon-left="plus-thick" 
              @click="openWorkdayDialog(null, props.row.driverId, datesList[6])"
              outlined
            ></b-button>
          </div>
        </template>
      </b-table-column>
    </b-table>
  </section>
</div>
</template>

<script>
import dayjs from 'dayjs'
import DayJSWeekday from 'dayjs/plugin/weekday'
import WorkdayDialog from '@/views/office/Hauls/Workdays/WorkdayDialog'

export default {
  components: {
    WorkdayDialog
  },

  data: () => ({
    startDate: null,
    worklist: [],
    isNewWorkday: false,
    isEmpty: false,
    loading: false,
    showWorkdayDialog: false,
    driverSelected: null,
    workdaySelected: null,
    datesList: [],
    dateFor: '',
    emptyWorkday: {
      workDate: '',
      driverId: '',
      chhours: '',
      nchours: '',
      ncreasons: '',
      haulsEntered: false,
      dayNotes: '',
      fuelTicket: false,
      offDuty: false,
      offDutyReason: '',
      dayComplete: false
    }
  }),

  computed: {
    drivers () {
      return this.$store.getters['drivers/activeDrivers']
    },
  },

  methods: {

    forceCheckIsComplete () {
      // console.log('TODO: forceCheckIsComplete...')

      // Re-draw the checkmark or red x (check if complete or not)
      // (can't get anything to work...)
    },

    async loadData () {
      this.loading = true

      try {
        await this.$store.dispatch('drivers/fetchActiveDrivers')
        this.loading = false
      } catch (error) {
        console.error(error.message)
        this.loading = false
      }

      try {
        await this.$store.dispatch('workdays/fetchWorkdays')
        this.loading = false
      } catch (error) {
        console.error(error.message)
        this.loading = false
      }
    },

    openCreateWorkdayDialog (driverId) {
      // console.log('Create workday dialog.. ')
      // console.log('DriverID: ' + driverId)
      this.driverSelected = this.$store.getters['drivers/driverById'](driverId)
      this.workdaySelected = {
        ...this.emptyWorkday
      }
      this.isNewWorkday = true
      this.showWorkdayDialog = true
      this.isNewWorkday = false
    },

    openWorkdayDialog (data, driverId, dateFor) {
      // console.log('inside openWorkdayDialog with following data argument: ', data, ' as tyepof: ', typeof(data))
      // console.log('DriverID: ', driverId)
      // console.log('dateFor: ', dayjs(dateFor).format('MM/DD/YYYY'))

      // const dataDump = {
      //   driverSelected: this.$store.getters['drivers/driverById'](driverId),
      //   driverId: driverId,
      //   isNewWorkday: this.isNewWorkday,
      //   workdaySelected: this.workdaySelected
      // }

      // console.log('Current data: ', dataDump)

      if (data) {
        this.driverSelected = this.$store.getters['drivers/driverById'](driverId)
        this.workdaySelected = data
        this.isNewWorkday = false
        this.dateFor = dateFor // TODO: Error check if missing
        this.showWorkdayDialog = true
      } else {
        this.driverSelected = this.$store.getters['drivers/driverById'](driverId)
        this.workdaySelected = {
          ...this.emptyWorkday
        }
        this.isNewWorkday = true
        this.dateFor = dateFor // TODO: Error check if missing
        this.showWorkdayDialog = true
        // this.isNewWorkday = false
      }
    },

    closeWorkdayDialog () {
      this.showWorkdayDialog = false
    },

    async makeList () {
      // console.log('Start date changed to: ')
      // console.log(this.startDate)
      this.worklist = []
      await this.loadData()

      if (this.drivers && this.drivers.length > 0) {
        for (let i=0; i<this.drivers.length; i++) {
          this.worklist.push({
            driverId: this.drivers[i]._id,
            name: `${this.drivers[i].firstName} ${this.drivers[i].lastName}`,
            workweek: this.$store.getters['workdays/workweekByDriver'](this.drivers[i]._id, this.startDate)
          })
        }

        // Generate date list for column header -----------------------------
        dayjs.extend(DayJSWeekday)

        let dateStart = dayjs(this.startDate)
        let dateEnd = dayjs(this.startDate).add(6, 'day')
        
        // Get the number of days between this range
        let diff = Math.abs(dateStart.diff(dateEnd, 'day'))

        // For each day of difference in the range, append the date to a list
        let daylist = []
        for (let i=0; i<=diff; i++) {
          daylist.push(dateStart.add(i, 'day').format('MM/DD/YYYY'))
        }

        this.datesList = daylist
        // --------------------------------------------------------------------
      }
    },

    checkComplete (data) {
      // console.log('\n-----------------------------------------------------------------')
      // console.log('Workdays checkComplete DATA: ', data)

      if (data) {
        if (data.dayComplete) {
          return 'check-bold'
        } else {
          return 'close-thick'
        }
      } else {
        return 'minus'
      }

    },

    checkCompleteClass (data) {
      if (data) {
        if (data.dayComplete) {
          return 'day-complete'
        } else {
          return 'day-incomplete'
        }
      } else {
        return ''
      }
    },

    showShortDate (date) {
      return dayjs(date).format('ddd M/DD')
    }
  },

  watch: {
    startDate () {
      if (this.worklist === []) {
        this.makeList()
      }

      if (this.startDate && this.startDate !== '' && this.startDate !== null && this.startDate !== undefined) {
        localStorage.setItem('workdaySelectorStartDate', this.startDate)
      }
    },
  },

  mounted () {

    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'

    this.loadData()

    if (localStorage.getItem('workdaySelectorStartDate')) {
      this.startDate = new Date(localStorage.getItem('workdaySelectorStartDate'))
    }
  }
}
</script>

<style lang="scss" scoped>
.day {
  color: rgb(223, 223, 223);
  cursor: pointer;
}

.day-complete {
  color: rgb(35, 150, 31); 
}

.day-incomplete {
  color: rgb(189, 69, 69);
}
</style>