<template>
<div>
  <b-modal v-model="show" :width="900">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title" v-if="isNew">New Freight Route</p>
        <p class="card-header-title" v-else>Edit Freight Route</p>
      </header>
    
      <div class="card-content content">
        <b-field label="Vendor Site" label-position="on-border" expanded>
          <b-select placeholder="Choose..." v-model="form.vendorSiteId" :loading="vendorSitesLoading" required expanded>
            <option v-for="vendorSite in vendorSites" 
              :key="vendorSite._id" 
              :value="vendorSite._id"
            >
            {{ vendorSite.name }}: ({{ vendorSiteIdToName(vendorSite.vendorId) }})
            </option>
          </b-select>
        </b-field>

        <b-field label="Destination" label-position="on-border">
          <b-input type="text" placeholder="i.e. TEC-Nash, TX" v-model="form.destination" required />
        </b-field>

        <b-field label="Freight" label-position="on-border">
          <b-input type="text" placeholder="i.e. 6.00" v-model="form.freight" required />
        </b-field>
      </div>

      <footer class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="footer-btns">
            <b-button 
              class="footer-closeBtn" 
              @click="clearAndClose"
            >Cancel</b-button>
          </div>

          <div class="footer-btns">
            <b-button 
              class="footer-saveBtn" 
              icon-left="content-save" 
              @click="saveFreightRoute"
            >Save</b-button>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: ['show', 'isNew', 'passRoute'],
  data: () => ({
    form: {
      vendorSiteId: '',
      destination: '',
      freight: ''
    },
    emptyForm: {
      vendorSiteId: '',
      destination: '',
      freight: ''
    },
    vendorsLoading: false,
    vendorSitesLoading: false
  }),

  mounted () {
    this.setVendorsLoading(true)
    this.setVendorSitesLoading(true)
    this.$store.dispatch('vendors/fetchVendors')
    this.$store.dispatch('vendorSites/fetchVendorSites')
  },

  computed: {
    vendors () {
      const vendors = this.$store.getters['vendors/allVendors']
      this.setVendorsLoading(false)
      return vendors
    },

    vendorSites () {
      const vendorSites = this.$store.getters['vendorSites/allVendorSites']
      this.setVendorSitesLoading(false)
      return vendorSites
    }
  },

  methods: {
    clearAndClose () {
      this.form = {...this.emptyForm}
      this.$emit('closeFreightRouteDialog')
    },

    async saveFreightRoute () {
      if (!this.form.vendorSiteId || !this.form.destination || !this.form.freight) {
        return alert('Missing fields')
      }

      if (this.isNew) {
        // Save NEW Freight Route
        // console.log('Saving NEW Freight Route...')

        try {
          await this.$store.dispatch('freightRoutes/createFreightRoute', this.form)
          this.$buefy.toast.open({
            message: `Freight route created!`,
            position: 'is-bottom',
            type: 'is-success',
          })
  
          this.clearAndClose()
        } catch (error) {
          console.error(error)
          this.$buefy.toast.open({
            message: `Error while saving Freight Route! Check logs`,
            position: 'is-bottom',
            type: 'is-danger',
            duration: 8000
          })
        }
      } else {
        // Update existing Freight Route
        // console.log('Updating EXISTING Freight Route...')

        try {
          await this.$store.dispatch('freightRoutes/updateFreightRoute', this.form)
          this.$buefy.toast.open({
            message: `Freight route updated!`,
            position: 'is-bottom',
            type: 'is-success',
          })
  
          this.clearAndClose()
        } catch (error) {
          console.error(error)
          this.$buefy.toast.open({
            message: `Error while updating Freight Route! Check logs`,
            position: 'is-bottom',
            type: 'is-danger',
            duration: 8000
          })
        }
      }

    },

    setVendorsLoading (val) {
      this.vendorsLoading = Boolean(val)
    },

    setVendorSitesLoading (val) {
      this.vendorSitesLoading = Boolean(val)
    },

    vendorSiteIdToName (vendorId) {
      const vendor = this.vendors.find(vendor => vendor._id === vendorId)
      return vendor.name
    }
  },

  watch: {
    show () {
      if (!this.isNew && this.passRoute !== null) {
        this.form = {...this.passRoute}
      }

      if (this.isNew) {
        this.form = {...this.emptyForm}
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>