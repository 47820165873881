<template>
<div>
  <b-modal v-model="show" :width="900">
    <div class="card">
      <header class="card-header">
        <p class="card-header-title" v-if="isNew">New Vendor Product</p>
        <p class="card-header-title" v-if="!isNew && product && passVendor">Edit {{ product.name }} ({{ passVendor.name }})</p>
      </header>

      <div class="card-content">
        <div class="content">
          <b-field grouped>

            <!-- TODO: Fix (value keeps sticking) -->

            <b-field label="Vendor" label-position="on-border" v-if="isNew && passVendor">
              <b-input v-model="passVendor.name" disabled/>
            </b-field>
            
            <b-field label="Vendor" label-position="on-border" v-else-if="passVendor">
              <b-input v-model="passVendor.name" disabled/>
            </b-field>


            <b-field label="Name" label-position="on-border">
              <b-input v-model="product.name" type="text"/>
            </b-field>

            <b-field label="Code" label-position="on-border">
              <b-input v-model="product.code" type="text"/>
            </b-field>
          </b-field>

          <b-field grouped>
            <b-field label="Product" label-position="on-border">
              <b-input v-model="product.product" type="text"/>
            </b-field>

            <b-field label="Freight" label-position="on-border">
              <b-input v-model="product.freight" type="text"/>
            </b-field>

            <b-field grouped>
              <b-field style="margin-top: 0.6em">
                <b-checkbox v-model="product.toYard">To the yard</b-checkbox>
              </b-field>
            </b-field>

            <!-- <b-field label="Fuel Surcharge" label-position="on-border">
              <b-input v-model="product.fuelSurcharge" type="text"/>
            </b-field> -->
          </b-field>

          <b-field label="Notes" label-position="on-border">
            <b-input v-model="product.notes" type="textarea"/>
          </b-field>

          <!-- <b-field grouped>
            <b-field>
              <b-checkbox v-model="product.toYard">To the yard</b-checkbox>
            </b-field>
          </b-field> -->
        </div>
      </div>

      <div class="level">
        <div class="level-left"></div>
        <div class="level-right">
          <div class="footer-btns">
            <b-button 
              class="footer-closeBtn" 
              @click="clearAndClose"
            >Cancel</b-button>
          </div>

          <div class="footer-btns">
            <b-button 
              class="footer-saveBtn" 
              icon-left="content-save" 
              @click="saveProduct"
            >Save</b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</div>
</template>

<script>
// import dayjs from 'dayjs'

export default {

  props: [ 'show', 'isNew', 'passProduct', 'passVendor' ],

  emits: [ 'closeProductDialog' ],

  data: () => ({
    product: {
      name: '',
      vendorName: '',
      code: '',
      product: '',
      freight: '',
      notes: '',
      toYard: undefined
    },

    emptyProduct: {
      name: '',
      vendorName: '',
      code: '',
      product: '',
      freight: '',
      notes: '',
      toYard: undefined
    }
  }),

  mounted () {
    // console.log('')
  },

  computed: {
    vendorName () {
      let vendor = this.$store.getters['vendors/vendorById'](this.passVendor._id)
      return vendor.name
    }
  },

  methods: {
    async saveProduct () {

      this.product.vendorId = this.passVendor._id

      if (this.isNew) {

        this.product.dateCreated = new Date()
        this.product.dateModified = undefined
        this.product.vendorName = this.passVendor.name
        
        await this.$store.dispatch('vendorProducts/createVendorProduct', this.product)
        this.$buefy.toast.open({
          message: `${this.product.name} created`,
          position: 'is-bottom',
          type: 'is-success',
        })

        // Clear the form & close
        this.clearAndClose()
  
      } else {
        try {
  
          // Update last modified date
          this.product.dateModified = new Date()
  
          // console.log('Notes to save: ')
          // console.log(this.product.notes)
  
          await this.$store.dispatch('vendorProducts/updateVendorProduct', this.product)
  
          // Show success message
          this.$buefy.toast.open({
            message: `${this.product.name} saved`,
            position: 'is-bottom',
            type: 'is-success',
          })

          // Clear the form & close
          this.clearAndClose()

        } catch (error) {
          console.log(error.message)
          
          // Show error message
          this.$buefy.toast.open({
            message: 'Error while saving product! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      }
    },

    clearAndClose () {
      this.product = {...this.emptyProduct}
      this.$emit('closeProductDialog')
    }
  },

  watch: {
    show () {
      if (!this.isNew && this.passProduct) {
        this.product = { ...this.passProduct }
      } else {
        this.product = { ...this.emptyProduct }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-createBtn {
  background-color: #1fa16b;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #178859;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>