
// CARPORTS > MUTATIONS

export default {
  setFAQS (state, faqs) {
    state.FAQS = faqs
  },

  addFAQ (state, faq) {
    state.FAQS.push(faq)
  },

  updateFAQ (state, payloadFAQ) {
    // console.log('[updateFAQ mutations.js]: Inside mutation, updating store...')
    let index = state.FAQS.findIndex(faq => faq._id === payloadFAQ._id)
    state.FAQS.splice(index, 1, payloadFAQ)
  },

  removeFAQ (state, delFAQ) {
    let index = state.FAQS.findIndex(faq => faq._id === delFAQ._id)
    state.FAQS.splice(index, 1)
  }
}