<template>
<b-field label="Material" label-position="on-border" expanded>
  <b-autocomplete
      id="formProduct"
      v-model="model"
      :keep-first="keepFirst"
      :open-on-focus="openOnFocus"
      :data="filteredDataObj"
      field="name"
      @select="option => doStuff(option)"
      :clearable="clearable"
      :disabled="disabled"
      expanded
  >
  </b-autocomplete>
</b-field>
</template>

<script>
export default {

  props: [ 'value', 'order', 'vendorSelected', 'disabled' ],

  mounted () {
    this.init()
  },

  data: () => ({
    keepFirst: false,
    openOnFocus: true,
    material: '',
    selected: null,
    clearable: false,
    data: null
  }),

  computed: {

    // NEW STUFF ---------
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    // -------

    filteredDataObj() {
      if (this.data) {
        return this.data.filter(option => {
          return (
            option.name
              .toString()
              .toLowerCase()
              .indexOf(this.value.toLowerCase()) >= 0
          )
        })
      } else {
        return []
      }
    }
  },

  methods: {
    doStuff (option) {
      // this.material = value
      console.log('MATERIAL OPTION: ', option)
      // this.selected = option
      this.material = option
      this.$emit('input', option)
      // this.$emit('updateHaulRate', option.freight)
    },

    async init () {
      try {
        await this.$store.dispatch('vendorProducts/fetchVendorProducts')
      } catch (error) {
        console.error(error)
      }

      try {
        await this.$store.dispatch('orders/fetchOrders')
      } catch (error) {
        console.error(error)
      }
    }
  },

  watch: {
    order () {
      if (this.order) {
        console.log('ORDER RECEIVED: ', this.order)
        // this.material = this.order.material
        // this.value = this.order.material
        // this.$emit('setMaterial', this.order.material)
        // this.$emit('setSelectedOrder', this.order)
        this.value = this.order.material
        
      } else {
        return null
      }
    },

    async vendorSelected () {
      console.log('[Hauls/v2/components/MaterialField]: vendorSelected is ', this.vendorSelected)

      // TODO: Get all VendorProducts by vendorId on this.vendorSelected and feed as data list to autocomplete
      try {
        await this.$store.dispatch('orders/fetchOrders')
        const orders = await this.$store.getters['orders/ordersByVendorId'](this.vendorSelected._id)
        console.log('ALL ORDERS: ', orders)
        for (let i=0; i<orders.length; i++) {
          orders[i].name = orders[i].material
        }
        this.data = orders
      } catch (error) {
        console.error('ERROR FROM HAULS/V2/COMPONENTS')
        console.error(error.message) 
      }
    },

    // selected () {
    //   console.log('Selected ORDER: ', this.selected)
    //   this.data.find(order => order.material === this.selected)
    //   // this.$emit('setSelectedOrderRate', this.selected.freight)
    //   this.$emit('setSelectedOrder', this.selected)
    // }
  }
}
</script>

<style lang="scss" scoped>
</style>
