<template>
<div>
  <b-field label="Vendor" label-position="on-border">
    <b-autocomplete
      v-model="vendorName"
      placeholder="Choose Vendor..."
      field="name"
      @select="option => selectVendor(option)"
      :loading="vendorsLoading"
      :keep-first="false"
      :select-on-click-outside="false"
      :open-on-focus="true"
      :data="filteredVendors"
      :clearable="true"
    >
      <template slot-scope="props">
        <div class="vendorNameDropdown">
          <strong>{{ props.option.name }}</strong>
        </div>
        <div class="vendorDetails">
          <div class="vendorDetailsWrapper">
            <div>Vendor Fuel Surcharge: ${{ props.option.vendorFuelSurcharge }}</div>
            <div>CHT Fuel Surcharge: ${{ props.option.chtFuelSurcharge }}</div>
          </div>
          <div v-if="enableEdit">
            <b-button @click.stop="openVendorDialog(props.option)" type="is-primary" icon-left="pencil"></b-button>
          </div>
        </div>
      </template>
    </b-autocomplete>
  </b-field>
</div>
</template>

<script>
export default {
  props: ['enableEdit'],
  data: () => ({
    vendorName: '',
    vendorChosen: null,
    vendorsLoading: false,
    vendor: null
  }),

  mounted () {
    this.setVendorsLoading(true)
    this.$store.dispatch('vendors/fetchVendors')
  },

  computed: {
    vendors () {
      const vendors = this.$store.getters['vendors/allVendors']
      const copyVendors = [...vendors]
      this.setVendorsLoading(false)
      // Return vendors sorted alphabetically
      return copyVendors.sort((a, b) => a.name.localeCompare(b.name))
    },

    filteredVendors () {
      return this.vendors.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.vendorName.toLowerCase()) >= 0
        )
      })
    }
  },

  methods: {
    setVendorsLoading (val) {
      this.vendorsLoading = Boolean(val)
    },

    selectVendor (option) {
      this.vendorChosen = option
      this.$emit('selectVendor', option)
    },

    openVendorDialog(option) {
      this.$emit('openVendorDialog', option)
    }
  }
}
</script>

<style lang="scss" scoped>
  .vendorNameDropdown {
    font-size: 1.1em;
  }
  .vendorDetails {
    font-size: 0.9em;
    display: flex;
    justify-content: space-between;
  }
</style>
