<template>
<div>
  <CustHeaderComp title="Vendors & Products" />

  <VendorDialog :show="showVendorDialog" :passVendor="dialogVendor" :isNew="isNewVendor" @closeVendorDialog="closeVendorDialog" @selectNewVendor="selectNewVendor"/>
  <VendorProductDialog :show="showVendorProductDialog" :passProduct="dialogProduct" :isNew="isNewProduct" :passVendor="vendorChosen" @closeProductDialog="closeProductDialog"/>

  <section class="container">
    <section class="section">
      <b-field grouped>
        <b-field label="Vendor" label-position="on-border">
          <b-select
            v-model="vendorChosen"
            :loading="vendorsLoading"
            placeholder="Choose..."
          >
            <option
              v-for="vendor in vendors"
              :key="vendor._id"
              :value="vendor"
            >
              {{ vendor.name }}
            </option>
          </b-select>
        </b-field>

        <b-field v-if="vendorChosen">
          <b-button icon-left="pencil" type="is-primary" @click="editVendor">Edit Vendor</b-button>
        </b-field>

        <b-field>
          <b-button icon-left="plus-thick" type="is-success" @click="newVendor">New Vendor</b-button>
        </b-field>

        <b-field v-if="vendorChosen">
          <b-button icon-left="delete" style="margin-left: 30pt;" @click="confirmDeleteVendor">Delete Vendor</b-button>
        </b-field>
      </b-field>


      <br/>
      <br/>


      <div class="level">
        <div class="level-left">
          <!-- <p class="vendorTableTitle" v-if="vendorChosen">{{ vendorChosen.name }}'s Products: </p> -->
        </div>
        <div class="level-right">
          <b-field v-if="vendorChosen">
            <b-button icon-left="plus-thick" type="is-info" @click="newVendorProduct">New Product</b-button>
          </b-field>
        </div>
      </div>
  
    
      <b-table
        :data="productData"
        :loading="productsLoading"
        striped
        hoverable
        focusable
        narrowed
        :sticky-header="true"
        height="800"
      >
        <b-table-column field="vendorName" label="Vendor" v-slot="props" searchable>
          <!-- {{ getVendorNameById(props.row.vendorId) }} -->
          {{ props.row.vendorName }}
        </b-table-column>

        <b-table-column field="name" label="Material" v-slot="props" searchable>
          {{ props.row.name }}
        </b-table-column>

        <b-table-column field="code" label="Code" v-slot="props" searchable>
          {{ props.row.code }}
        </b-table-column>

        <b-table-column field="product" label="Product" v-slot="props">
          ${{ props.row.product }}/t
        </b-table-column>

        <b-table-column field="freight" label="Freight" v-slot="props">
          ${{ props.row.freight }}/t
        </b-table-column>

        <!-- <b-table-column field="fuelSurcharge" label="Fuel Schg" v-slot="props">
          ${{ getFuelSurcharge(props.row) }}/t
        </b-table-column> -->

        <b-table-column field="total" label="Total" v-slot="props">
          ${{ calcTotal(props.row) }}/t
        </b-table-column>

        <!-- <b-table-column field="notes" label="Notes" v-slot="props">
          {{ props.row.notes }}
        </b-table-column> -->

        <b-table-column field="dateModified" label="Modified" v-slot="props">
          {{ formatDate(props.row.dateModified) }}
        </b-table-column>

        <b-table-column label="Actions" v-slot="props">
          <b-field grouped>
            <b-button type="is-primary" icon-right="pencil" @click="editProduct(props.row)" style="margin-right: 8pt;"></b-button>
            <b-button type="is-danger" icon-right="delete" @click="confirmDeleteProduct(props.row)"></b-button>
          </b-field>
        </b-table-column>
      </b-table>
    </section>
  </section>

</div>
</template>

<script>
import VendorDialog from './VendorDialog'
import VendorProductDialog from './VendorProductDialog'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import dayjs from 'dayjs'

export default {
  components: {
    VendorDialog,
    VendorProductDialog,
    CustHeaderComp
  },

  data: () => ({
    vendorsLoading: false,
    productsLoading: false,
    vendorChosen: undefined,
    showVendorProductDialog: false,
    showVendorDialog: false,
    dialogProduct: undefined,
    dialogVendor: undefined,
    isNewVendor: false,
    isNewProduct: false,
    vendorChosenObj: undefined
  }),

  mounted () {

    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'

    this.setVendorsLoading(true)
    this.$store.dispatch('vendors/fetchVendors')
    this.setProductsLoading(true)
    this.$store.dispatch('vendorProducts/fetchVendorProducts')
  },

  computed: {

    dataLoaded () {
      return (this.vendors.length > 0 && this.productData.length > 0) ? true : false
    },

    vendors () {
      const vendors = this.$store.getters['vendors/allVendors']
      this.setVendorsLoading(false)
      return vendors
    },

    productData () {
      /* TODO: Implement ASAP (problem is, b-table filters by ID instead of vendor name. In process of adding "vendorName" property 
      to all vendor products. Please reflect this in create/update forms as well ) */

      const products = this.$store.getters['vendorProducts/allVendorProducts']
      this.setProductsLoading(false)
      return products

      // if (this.vendorChosen) {
      //   const products = this.$store.getters['vendorProducts/productsByVendor'](this.vendorChosen._id)
      //   this.setProductsLoading(false)
      //   return products
      // } else {
      //   if (!this.vendorsLoading) {
      //     this.setProductsLoading(false)
      //   }
      //   return []
      // }
    },

    vendorChosenName () {
      if (this.vendorChosen) {
        let vendor = this.$store.getters['vendors/vendorById'](this.vendorChosen._id)[0]

        if (vendor) {
          // console.log('Vendor found: ', vendor[0])
          return vendor.name
        } else {
          this.setNullVendor()
          return null
        }
      } else {
        return ''
      }
    },

    vendorToEdit () {
      return this.$store.getters['vendors/vendorById'](this.vendorChosen._id)
    }
  },

  methods: {

    // getVendorNameById (vendorId) {
    //   const vendor = this.$store.getters['vendors/vendorById'](vendorId)[0]
    //   return vendor.name
    // },

    // getFuelSurcharge (product) {
    //   // console.log('GET FUEL SURCHARGE. Row: ', product)
    //   const surcharge = this.$store.getters['vendors/fuelSurchargeByVendorId'](product.vendorId)
    //   // console.log('Surcharge: ', surcharge)
    //   return surcharge
    // },

    setVendorsLoading (loading) {
      this.vendorsLoading = Boolean(loading)
    },

    setProductsLoading (loading) {
      this.productsLoading = Boolean(loading)
    },

    getVendorChosenObj () {
      return this.vendorChosen ? this.$store.getters['vendors/vendorById'](this.vendorChosen._id) : null
    },

    selectNewVendor (id) {
      // console.log('SELECT VENDOR WITH ID: ', id)
      this.vendorChosen = this.vendors.filter(v => v._id === id)
    },

    setNullVendor () {
      this.vendorChosen = null
    },

    calcTotal (data) {
      return (parseFloat(data.product) + parseFloat(data.freight)).toFixed(2)
    },

    formatDate (date) {
      return dayjs(date).format('M/D/YY')
    },

    newVendor () {
      this.isNewVendor = true
      this.showVendorDialog = true
    },

    newVendorProduct () {
      this.isNewProduct = true
      this.showVendorProductDialog = true
    },

    editVendor () {
      // console.log('Edit vendor...', this.vendorChosen)
      this.dialogVendor = this.$store.getters['vendors/vendorById'](this.vendorChosen._id)[0]
      // console.log('dialogVendor: ', this.dialogVendor)
      this.isNewVendor = false
      // console.log('isNewVendor: ', this.isNewVendor)
      this.showVendorDialog = true
    },

    editProduct (product) {
      // console.log('Edit product...', product)
      this.isNewProduct = false
      this.dialogProduct = { ...product }
      this.selectNewVendor(product.vendorId)
      this.vendorChosen = this.$store.getters['vendors/vendorById'](product.vendorId)[0]
      // console.log('vendorChosen: ', product.vendorId)
      this.vendorChosenObj = this.$store.getters['vendors/vendorById'](product.vendorId)[0]
      // console.log('vendorChosenObj: ', this.vendorChosenObj)
      this.showVendorProductDialog = true
    },

    confirmDeleteProduct (product) {
      this.$buefy.dialog.confirm({
        title: `Delete ${product.name}?`,
        message: `Are you sure you want to <b>permanently delete</b> ${product.name}? This action cannot be undone.`,
        confirmText: 'Delete Product',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {this.deleteProduct(product)}
      })
    },

    async deleteProduct (product) {
      // console.log('DELETING PRODUCT!!!', product)
      try {
        await this.$store.dispatch('vendorProducts/deleteVendorProduct', product._id)
        this.$buefy.toast.open({
          message: `${product.name} deleted`,
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (error) {
        console.log(error.message)
        this.$buefy.toast.open({
          message: `Error while deleting ${product.name}! Check logs`,
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }
    },

    closeProductDialog () {
      this.isNewProduct = false
      this.showVendorProductDialog = false
    },

    closeVendorDialog () {
      this.isNewVendor = false
      this.showVendorDialog = false
    },

    confirmDeleteVendor () {
      this.$buefy.dialog.confirm({
        title: 'Delete Selected Vendor?',
        message: `Are you sure you want to <strong style="color: red; font-weight: bold;">permanently delete the selected vendor?</strong>  This action cannot be undone! TODO: Ask if you want to delete associated products`,
        confirmText: 'Delete Vendor',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => { this.deleteVendor(); }
      })
    },

    async deleteVendor () {
        try {

          // console.log('Deleting Vendor with ID: ', this.vendorChosen)

          await this.$store.dispatch('vendors/deleteVendor', this.vendorChosen._id)

          this.$buefy.toast.open({
            message: 'Vendor deleted',
            position: 'is-bottom',
            type: 'is-success',
          })
        } catch (error) {
          console.log(error.message)
          this.$buefy.toast.open({
            message: 'Error while trying to delete vendor! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
    }
  },

  // watch: {
  //   passVendor () {
  //     this.vendorChosenObj = this.vendorChosen ? this.$store.getters['vendors/vendorById'](this.vendorChosen) : {}
  //   }
  // }
}
</script>

<style lang="scss" scoped>
.vendorTableTitle {
  font-size: 18pt;
  font-weight: bold;
  // border: 1px solid red;
}
</style>