
// VENDORS > GETTERS

export default {
  allVendors (state) {
    return state.vendors
  },

  vendorById:  (state) => (id) => {
    // console.log('Searching for vendorId ' + id)
    return state.vendors.filter(vendor => vendor._id === id)
  },

  vendorByName: (state) => (name) => {
    return state.vendors.find(vendor => vendor.name === name)
  },

  vendorFuelSurchargeByVendorId: (state) => (id) => {
    const ven = state.vendors.find(vendor => vendor._id === id)
    // console.log('VENDOR FOUND: ', ven)
    return ven.vendorFuelSurcharge
  },

  chtFuelSurchargeByVendorId: (state) => (id) => {
    const ven = state.vendors.find(vendor => vendor._id === id)
    // console.log('VENDOR FOUND: ', ven)
    return ven.chtFuelSurcharge
  },

  vendorNameById: (state) => (vendorId) => {
    let vendor = state.vendors.find(vendor => vendor._id === vendorId)
    return vendor.name
  }
}