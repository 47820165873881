
// DELIVERIES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchSiteMessages (context) {
    axios.get(`${API_URL}/sitemessages`).then(res => {
      const messages = res.data

      context.commit('setSiteMessages', messages)
    })
  },

  createSiteMessage (context, message) {
    axios.post(`${API_URL}/sitemessages`, message).then(res => {
      const theMessage = res.data

      context.commit('addSiteMessage', theMessage)
    })
  },

  updateSiteMessage (context, message) {
    // console.log('Updating site message ID: ')
    // console.log(message._id)
    axios.patch(`${API_URL}/sitemessages/${message._id}`, message).then(res => {
      const newMessage = res.data

      context.commit('updateSiteMessage', newMessage)
    })
  },

  copySiteMessage (context, messageId) {
    // console.log('Copying site message ID: ' + messageId)
    axios.post(`${API_URL}/sitemessages/${messageId}/copy`).then(res => {
      const cpyMessage = res.data

      // console.log('COPIED SITE MESSAGE: ')
      // console.log(cpyMessage)
      context.commit('addSiteMessage', cpyMessage)
    })
  },

  deleteSiteMessage (context, messageId) {
    axios.delete(`${API_URL}/sitemessages/${messageId}`).then(res => {
      const message = res.data
      context.commit('removeSiteMessage', message)
    })
  }
}