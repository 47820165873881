
// SITE MESSAGES > MUTATIONS

export default {
  setSiteMessages (state, messages) {
    state.siteMessages = messages
  },

  addSiteMessage (state, message) {
    state.siteMessages.push(message)
  },

  updateSiteMessage (state, payloadMessage) {
    let index = state.siteMessages.findIndex(msg => msg._id === payloadMessage._id)
    state.siteMessages.splice(index, 1, payloadMessage)
  },

  removeSiteMessage (state, delMessage) {
    let index = state.siteMessages.findIndex(msg => msg._id === delMessage._id)
    state.siteMessages.splice(index, 1)
  },

  setEditMessage (state, message) {
    state.editMessage = message
  }

}