<template>
<div>
  <section class="section">
    <div v-if="settings">
      <b-field>
        <b-field style="margin-top: 4pt;">
          <b-switch v-model="advertiseDeliveries" @input="toggleAdvertise">Advertise Deliveries</b-switch>
        </b-field>
      </b-field>
    </div>

    <div v-else>
      <p>No settings found</p>
    </div>
  </section>
</div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'
    
    this.init()
  },

  computed: {
    settings () {
      return this.$store.getters['siteSettings/settings']
    },

    advertiseDeliveries: {
      get () {
        const settings = this.$store.getters['siteSettings/settings']
        // console.log('STORE SETTINGS: ', settings)
        return settings.advertiseDeliveries
      },

      set (newVal) {
        this.$store.dispatch('siteSettings/updateSettings', { advertiseDeliveries: newVal })
      }
    }
  },

  methods: {
    async init () {
      // Fetch settings
      try {
        // console.log('Loading reasons...')
        this.loading = true
        await this.$store.dispatch('siteSettings/fetchSettings')
        // console.log('Done loading reasons!')
        this.loading = false
      } catch (error) {
        console.error(error.message)
      }
    },

    async toggleAdvertise () {
      try {
        // payload (dynamic): { property: value }
        await this.$store.dispatch('siteSettings/updateSettings', { advertiseDeliveries: !this.advertiseDeliveries })
        this.$buefy.toast.open({
          message: `Advertise deliveries set to <strong style="color: yellow">${!this.advertiseDeliveries}</strong>`,
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while trying to update Advertise Deliveries! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 8000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>