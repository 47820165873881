
// GALLERY > MUTATIONS

export default {
  setImages (state, images) {
    state.images = images
  },

  addImage (state, image) {
    state.images.push(image)
  },

  updateImage (state, payloadImage) {
    // console.log('[updateImage mutations.js]: Inside mutation, updating store...')
    let index = state.images.findIndex(img => img._id === payloadImage._id)
    state.images.splice(index, 1, payloadImage)
  },

  removeImage (state, delImage) {
    let index = state.images.findIndex(img => img._id === delImage._id)
    state.images.splice(index, 1)
  }
}