<template>
<div>
  <b-modal
    v-model="show"
    :width="800"
    scroll="keep"
  >
    <div class="card">
      <header class="card-header">
        <p class="card-header-title" v-if="isNew">New Delivery Customer</p>
        <p class="card-header-title" v-else>Edit {{ deliveryCustomer.name }}</p>
      </header>

      <section class="card-content">
        <b-field label="Name" label-position="on-border">
          <b-input v-model="deliveryCustomer.name" ref="name"/>
        </b-field>

        <b-field label="Phone(s)" label-position="on-border">
          <b-input v-model="deliveryCustomer.phone" type="textarea" rows="2" />
        </b-field>

        <b-field label="Address" label-position="on-border">
          <b-input v-model="deliveryCustomer.address" type="textarea" rows="3"/>
        </b-field>

        <b-field label="Coordinates" label-position="on-border">
          <b-input v-model="deliveryCustomer.coordinates" type="text" rows="3"/>
        </b-field>

        <b-field label="Directions" label-position="on-border">
          <b-input v-model="deliveryCustomer.directions" type="textarea" rows="6"/>
        </b-field>

        <b-field>
          <b-checkbox v-model="deliveryCustomer.isActive">Active Customer</b-checkbox>
        </b-field>
      </section>

      <footer>
        <div class="card">
          <div style="display: flex; justify-content: space-between; padding: 6pt;">
            <div>
            </div>
            <div>
              <b-button 
                id="cancelButton"
                type="is-primary" 
                @click="clearAndClose"
              >Cancel</b-button>
              <b-button 
                type="is-primary"
                @click="saveCustomer"
              >Save</b-button>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'isNew', 'passCustomer', 'passNewCustomer' ],

  emits: [ 'cancelDeliveryCustomerDialog' ],

  data: () => ({
    deliveryCustomer: {
      name: '',
      phone: '',
      address: '',
      coordinates: '',
      directions: '',
      isActive: true
    },

    emptyDeliveryCustomer: {
      name: '',
      phone: '',
      address: '',
      coordinates: '',
      directions: '',
      isActive: true
    }
  }),

  methods: {
    clearAndClose () {
      this.deliveryCustomer = { ...this.emptyDeliveryCustomer }
      this.$emit('cancelDeliveryCustomerDialog')
    },

    async saveCustomer () {

      if (this.isNew) {
        // Create new customer
        try {
          await this.$store.dispatch('deliveryCustomers/createDeliveryCustomer', this.deliveryCustomer)

          this.$buefy.toast.open({
            message: 'Delivery customer created',
            position: 'is-bottom',
            type: 'is-success',
          })

          this.clearAndClose()

        } catch (error) {
          console.error(error.message)
          this.$buefy.toast.open({
            message: 'Error while creating delivery customer! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      } else {
        // Update existing customer
        try {

          await this.$store.dispatch('deliveryCustomers/updateDeliveryCustomer', this.deliveryCustomer)

          this.$buefy.toast.open({
            message: 'Customer updated',
            position: 'is-bottom',
            type: 'is-success',
          })

          this.clearAndClose()

        } catch (error) {
          console.error(error.message)

          this.$buefy.toast.open({
            message: 'Error while updating customer! Check logs',
            position: 'is-bottom',
            type: 'is-danger',
            duration: 5000
          })
        }
      }
    }
  },

  watch: {
    show () {
      if (this.passCustomer) {
        this.deliveryCustomer = { ...this.passCustomer }
      } else {
        this.deliveryCustomer = { ...this.emptyDeliveryCustomer }
      }

      if (this.isNew) {
        if (this.passNewCustomer) {
          this.deliveryCustomer = { ... this.passNewCustomer }
        } else {
          this.deliveryCustomer = { ...this.emptyDeliveryCustomer }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#cancelButton {
  background-color: rgb(37, 37, 37);
  color: white;
  margin-right: 10pt;
}
</style>