
// FREIGHTROUTES > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchFreightRoutes (context) {
    axios.get(`${API_URL}/freightRoutes`).then(res => {
      const routes = res.data

      context.commit('setFreightRoutes', routes)
    })
  },

  createFreightRoute (context, payload) {
    return axios.post(`${API_URL}/freightRoutes`, payload).then(res => {
      const route = res.data

      context.commit('addFreightRoute', route)
      return route
    })
  },

  updateFreightRoute (context, routeForm) {
    axios.patch(`${API_URL}/freightRoutes/${routeForm._id}`, { 
      vendorSiteId: routeForm.vendorSiteId, 
      destination: routeForm.destination,
      freight: routeForm.freight
    })
    .then(res => {
      const updatedRoute = res.data

      context.commit('updateFreightRoute', updatedRoute)
    })
  },

  deleteFreightRoute (context, freightRouteId) {
    axios.delete(`${API_URL}/freightRoutes/${freightRouteId}`).then(() => {
      context.commit('removeFreightRoute', freightRouteId)
    })
  }
}