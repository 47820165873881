
// CALL LIST REQUESTS > MUTATIONS

export default {
  setRequests (state, requests) {
    state.requests = requests
  },

  addRequest (state, request) {
    state.requests.push(request)
  },

  updateRequest (state, payloadRequest) {
    // console.log('[updateRequest mutations.js]: Inside mutation, updating store...')
    let index = state.requests.findIndex(request => request._id === payloadRequest._id)
    state.requests.splice(index, 1, payloadRequest)
  },

  removeRequest (state, delRequest) {
    let index = state.requests.findIndex(request => request._id === delRequest._id)
    state.requests.splice(index, 1)
  },

  setNumRequestsReady (state, num) {
    state.setNumRequestsReady = num
  }
}