
// DRIVERS > index.js

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// import { v4 as uuidv4 } from 'uuidv4'

// Driver id is the following (all lowercase):
// firstName + lastName + dateHired as YYYYMMDD (no hyphens)

export default {
  namespaced: true,
  state () {
    return {
      drivers: [],
      currentDriver: {}
      // drivers: [
      //   {
      //     // id: 'mikelambert20180926',
      //     firstName: 'Mike',
      //     lastName: 'Lambert',
      //     defaultTruck: '687',
      //     dateHired: '2018-09-26',
      //     dateReleased: '',
      //     dateCreated: '2020-05-13',
      //     isActive: true
      //   },
      //   {
      //     // id: 'billwright20191120',
      //     firstName: 'Bill',
      //     lastName: 'Wright',
      //     defaultTruck: '710',
      //     dateHired: '2019-11-20',
      //     dateReleased: '2020-10-08',
      //     dateCreated: '2020-05-13',
      //     isActive: false
      //   },
      //   {
      //     // id: 'shawnross20191216',
      //     firstName: 'Shawn',
      //     lastName: 'Ross',
      //     defaultTruck: '351',
      //     dateHired: '2019-12-16',
      //     dateReleased: '2020-07-03',
      //     dateCreated: '2020-05-13',
      //     isActive: false
      //   },
      //   {
      //     // id: 'dwightcarver20180709',
      //     firstName: 'Dwight',
      //     lastName: 'Carver',
      //     defaultTruck: '606',
      //     dateHired: '2018-07-09',
      //     dateReleased: '2020-03-15',
      //     dateCreated: '2020-05-13',
      //     isActive: false
      //   },
      //   {
      //     // id: 'aaronervin20191007',
      //     firstName: 'Aaron',
      //     lastName: 'Ervin',
      //     defaultTruck: '710',
      //     dateHired: '2019-10-07',
      //     dateReleased: '2020-02-01',
      //     dateCreated: '2020-05-13',
      //     isActive: false
      //   },
      //   {
      //     // id: 'donalddaniel20200706',
      //     firstName: 'Donald',
      //     lastName: 'Daniel',
      //     defaultTruck: '710',
      //     dateHired: '2020-07-06',
      //     dateReleased: '',
      //     dateCreated: '2020-07-07',
      //     isActive: true
      //   },
      //   {
      //     // id: 'richardbush20190915',
      //     firstName: 'Richard',
      //     lastName: 'Bush',
      //     defaultTruck: '',
      //     dateHired: '2019-09-15',
      //     dateReleased: '',
      //     dateCreated: '2020-07-07',
      //     isActive: true
      //   },
      //   {
      //     // id: 'brandonbelvin20200824',
      //     firstName: 'Brandon',
      //     lastName: 'Belvin',
      //     defaultTruck: '606',
      //     dateHired: '2020-08-24',
      //     dateReleased: '',
      //     dateCreated: '2020-08-26',
      //     isActive: true
      //   },
      //   {
      //     // id: 'tonyadams20201110',
      //     firstName: 'Tony',
      //     lastName: 'Adams',
      //     defaultTruck: '351',
      //     dateHired: '2020-11-10',
      //     dateReleased: '',
      //     dateCreated: '2020-11-11',
      //     isActive: true
      //   },
      // ]
    }
  },
  actions,
  getters,
  mutations
}