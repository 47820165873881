
// MATERIALTAGS > MUTATIONS

export default {
  setTags (state, theTags) {
    state.tags = theTags
  },

  addTag (state, tag) {
    state.tags.push(tag)
  }
}