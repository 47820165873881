
// CATEGORIES > index.js

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// import { v4 as uuidv4 } from 'uuid'

export default {
  namespaced: true,
  state() {
    return {
      categories: []
    }
  },
  actions,
  getters,
  mutations
}