
// HAULS > ACTIONS

import axios from 'axios'
import dayjs from 'dayjs'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  
  fetchHauls (context) {
    // console.log('Fetching hauls...')
    axios.get(`${API_URL}/hauls`).then(res => {
      const hauls = res.data
      context.commit('setHauls', hauls)
    })
  },

  showInvoice (context, invoice) {
    let haul = context.getters.haulByInvoice(invoice)
    localStorage.setItem('showInvoice', invoice)
    context.commit('setHauls', haul)
  },

  createHaul (context, haul) {

    // Convert to format for use in some of the GETTERS
    // TODO: Remove this formatting for v2?
    haul.dateHaul = dayjs(haul.dateHaul).format('YYYY-MM-DD')

    axios.post(`${API_URL}/hauls`, haul).then(res => {
      let haul = res.data
      context.commit('addHaul', haul)
    }).catch(err => console.error(err.message))
  },

  updateHaul (context, haul) {
    // console.log('\n\nHaul to update: ')
    // console.log(haul)
    haul.dateHaul = dayjs(haul.dateHaul).format('YYYY-MM-DD')

    axios.patch(`${API_URL}/hauls/${haul._id}`, haul).then(res => {
      let haul = res.data
      context.commit('updateHaul', haul)
    }).catch(err => console.error(err.message))
  },

  deleteHaul (context, haulId) {
    axios.delete(`${API_URL}/hauls/${haulId}`).then(() => {

      context.commit('removeHaul', haulId)
    }).catch(err => console.error(err.message))
  },

  copyHaul (context, haulId) {
    axios.post(`${API_URL}/hauls/${haulId}/copy`).then(res => {
      const newHaul = res.data

      context.commit('addHaul', newHaul)
    })
  },

  setFilteredHauls (context, hauls) {
    context.commit('setFilteredHauls', hauls)
  },

  // ======================  HAUL SUMMARY REPORT  ==========================

  setSummaryHaulsList (context, haulsList) {
    context.commit('setReportHauls', haulsList)
  },

  setSummaryWorkdays (context, workdays) {
    context.commit('setReportWorkdays', workdays)
  },

  setSummaryDateStart (context, dateStart) {
    context.commit('setReportDateStart', dateStart)
  },

  setSummaryDateEnd (context, dateEnd) {
    context.commit('setReportDateEnd', dateEnd)
  },

  setSummaryDriver (context, driverId) {
    // console.log('setSummaryDriver action with payload: ')
    // console.log(driverId)
    axios.get(`${API_URL}/drivers/${driverId}`).then(res => {
      const driver = res.data
      // console.log('Driver from server: ')
      // console.log(driver)
      context.commit('setSummaryDriver', driver)
    })
  }
}