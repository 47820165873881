<template>
<div>
  <div class="notes-box" style="display: flex; align-items: center;">
    <div>
      <span style="margin-right: 20pt;"><strong>Driver: </strong>{{ driver.firstName }} {{ driver.lastName }}</span>
      <span><strong>Date: </strong>{{ formatDate(date.workDate) }}</span>
      <!-- <div>
        {{ date }}
      </div> -->
    </div>
    <div style="margin-left: 20pt;">
      <div style="display: flex;">
        <b-input 
          v-model="hours"
          type="text" 
          placeholder="Hours Worked" 
          style="margin-right: 10pt;"
        ></b-input>

        <b-button 
          type="is-primary"
          icon-left="content-save"
          @click="saveHours"
        >Save</b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: [ 'driver', 'date' ],
  data: () => ({
    hours: ''
  }),

  methods: {
    formatDate (date) {
      return dayjs(date).format('M/D/YY')
    },

    async saveHours () {
      let newWday = {
        ...this.date,
        chhours: this.hours
      }
      // console.log('newWday: ')
      // console.log(newWday)
      // this.$store.dispatch('workdays/updateWorkday', newWday)
      try {
        await this.$store.dispatch('workdays/saveOrCreateWorkday', {
          driverId: this.driver._id,
          workday: newWday
        })
        this.hours = ''
        this.$buefy.toast.open({
          message: 'Hours updated!',
          position: 'is-bottom',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: 'Error while updating hours! Check logs',
          position: 'is-bottom',
          type: 'is-danger',
          duration: 5000
        })
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.notes-box {
  border: 1px solid #d8d8d8; 
  padding: 10pt;
  margin-bottom: 10pt;
}
</style>