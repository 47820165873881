
// SITE MESSAGES > GETTERS

export default {

  allSiteMessages (state) {
    return state.siteMessages
  },

  activeSiteMessages (state) {
    return state.siteMessages.filter(msg => msg.active === true)
  },

  getEditMessage (state) {
    return state.editMessage
  }
}