<template>
<div>
  <section id="titleBackground" class="hero is-large ch-hero">
    <div class="hero-body">
      <div class="title center text-white" style="font-size: 25pt !important; margin-bottom: 22pt;">
        <div class="title-ch" style="font-size: 40pt;">C&H</div>
        <div class="title-lsmat" style="font-size: 22pt;">Landscape Materials</div>
      </div>
      <!-- <p class="subtitle center text-white">
        We provide landscape materials in the Texarkana area
      </p> -->
    </div>
  </section>

  <Bulletin/>

  <Intro/>
  <br/>

  <Delivery/>

  <Gardening/>

  <Eagle/>

  <Resources/>

</div>
</template>

<script>
import Bulletin from '@/views/Landing/Bulletin'
import Intro from '@/views/Landing/Intro'
import Delivery from '@/views/Landing/Delivery'
import Gardening from '@/views/Landing/Gardening'
import Eagle from '@/views/Landing/Eagle'
import Resources from '@/views/Landing/Resources'

export default {
  components: {
    Bulletin,
    Intro,
    Delivery,
    Gardening,
    Eagle,
    Resources
  }
}
</script>

<style lang="scss">
@import "@/styles/_variables.scss";

.text-white {
  color: white !important;
}

#titleBackground {
  background-image: url("../../assets/landing_banner.png");
  background-attachment: fixed;
}
</style>