<template>
<div>
  <b-modal v-model="show" :width="840" scroll="keep">
    <div class="card">
      <div class="card-header">
        <p class="card-header-title" v-if="isNewMaterialTag">Create New Material Tag</p>
        <p class="card-header-title" v-else>Edit Material Tag</p>
      </div>

      <div class="card-content">
        <b-field label="Name" label-position="on-border">
          <b-input v-model="tag.name"></b-input>
        </b-field>
      </div>

      <footer class="card-footer">
        <div class="footer-btns">
          <b-button class="footer-saveBtn" icon-left="content-save" @click="saveTag">Save</b-button>
          <b-button class="footer-closeBtn" icon-left="close-thick" @click="closeDialog">Close</b-button>
        </div>
      </footer>
    </div>
  </b-modal>
</div>
</template>

<script>
export default {
  props: [ 'show', 'isNewMaterialTag', 'editMaterialTag' ],

  emits: [ 'closeMaterialTagDialog' ],

  data: () => ({
    tag: {
      name: '',
    }
  }),

  methods: {
    async saveTag () {
      // Convert to lowercase
      this.tag.name = this.tag.name.toLowerCase()
      try {
        await this.$store.dispatch('materialTags/createMaterialTag', this.tag)
        this.$buefy.toast.open({
          duration: 3000,
          message: `Material tag created!`,
          position: 'is-bottom',
          type: 'is-success'
        })
        this.closeDialog()
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          duration: 5000,
          message: `Error while creating new material tag! Check logs`,
          position: 'is-bottom',
          type: 'is-danger'
        })
      }
    },

    closeDialog () {
      this.tag.name = ''
      this.$emit('closeMaterialTagDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.footer-btns {
  padding: 8pt;
}

.footer-btns * {
  margin-right: 10pt;
}

.footer-saveBtn {
  background-color: $primary;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #10455f;
    color: white;
  }

  &:focus {
    color: white;
  }
}

.footer-closeBtn {
  background-color: #5e5e5e;
  color: white;
  font-weight: bold;

  &:hover {
    background-color: #424242;
    color: white;
  }

  &:focus {
    color: white;
  }
}
</style>