
// FAQS > ACTIONS

import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  fetchFAQS (context) {
    // console.log('Fetch FAQs...')
    axios.get(`${API_URL}/faqs`).then(res => {
      const faqs = res.data
      context.commit('setFAQS', faqs)
    })
  },

  // TODO: IMPLEMENT FAQS INSTEAD OF CARPORTS...

  createFAQ (context, faq) {
    axios.post(`${API_URL}/faqs`, faq).then(res => {
      const theFAQ = res.data

      context.commit('addFAQ', theFAQ)
    })
  },

  updateFAQ (context, faq) {
    // console.log('inside updateFAQ action...')
    // console.log(faq)
    // console.log('FAQ ID: ' + faq._id)

    axios.patch(`${API_URL}/faqs/${faq._id}`, faq).then(res => {
      const newFAQ = res.data

      // console.log('FAQ updated to: ')
      // console.log(newFAQ)

      context.commit('updateFAQ', newFAQ)
    })
  },

  copyFAQ (context, faqId) {
    // console.log('Copying FAQ ID: ' + faqId)
    axios.post(`${API_URL}/faqs/${faqId}/copy`).then(res => {
      const cpyFAQ = res.data

      // console.log('COPIED FAQ: ')
      // console.log(cpyFAQ)
      context.commit('addFAQ', cpyFAQ)
    })
  },

  deleteFAQ (context, faqId) {
    axios.delete(`${API_URL}/faqs/${faqId}`).then(res => {
      const faq = res.data
      context.commit('removeFAQ', faq)
    })
  }
}