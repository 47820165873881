
// BILLPREP > ACTIONS

// import axios from 'axios'

// const API_URL = 'https://chlm2021server.herokuapp.com/api'
// const API_URL = 'https://chlm2021vue2.herokuapp.com/api'

export default {
  setData (context, data) {
    context.commit('setData', data)
  }
}