<template>
<div class="top-wrapper materialsWrapper">

  <b-loading :is-full-page="true" v-model="elementsLoaded" :can-cancel="true"></b-loading>

  <section class="hero is-primary" id="materialsHero">
    <div class="hero-body">
      <p class="title">Landscape Materials</p>
    </div>
  </section>

  <section class="section" style="padding: 10pt 20pt;">
    <div class="columns">
      <div class="column is-one-quarter">
        <b-field label="Search by Category">
          <b-select
            v-model="categorySelected"
            @input="showByCategory"
          >
            <option value="null">Choose...</option>
            <option 
              v-for="category in categories" 
              :key="category._id"
              :value="category.name"
            >
              {{ category.name }}
            </option>
          </b-select>
        </b-field>
      </div>
      <div class="column">
        <b-field label="or by Keyword" message="Suggestions: mulch, driveway gravel, dirt, garden, etc.">
          <b-taginput
            v-model="tags"
            :data="filteredTags"
            icon="label"
            placeholder="Search by keyword"
            aria-close-label="Remove this tag"
            @typing="getFilteredTags"
            @add="findByTags"
            @remove="findByTags"
            field="name"
            maxtags="1"
            allow-new
            autocomplete
          ></b-taginput>
        </b-field>
      </div>
    </div>
  </section>

  <section>
    <div style="margin-left: 20pt; margin-right: 20pt;">
      <b-message type="is-info" aria-close-label="Close message">
        <strong>Looking for Driveway Gravel?</strong><br/>
        <span style="font-size: 10pt;">
          Arkansas Class 5 (Bin #7) and Blue SB2 (Bin #12) are the best base gravels we carry; they contain fine screenings which eventually shift to the bottom and pack extremely well.
        </span>
      </b-message>
    </div>
  </section>

  <div class="section columns is-multiline">
    <div class="column is-3" v-for="material in filteredMaterials" :key="material._id">
      <div class="container">
        <div class="card">

          <div style="background-color: blue;">
            <div style="background-color: #125A7F; display: flex; justify-content: space-between; padding: 10pt; color: white; font-weight: bold;">
              <p class="cardHeader">{{ material.name }}</p>
              <p class="cardHeader" style="font-weight: 400">{{ material.size }}</p>
            </div>
          </div>
          <div>
            <b-image v-if="material.image" :src="material.image" :alt="material.name" ratio="4by4" />
            <img v-else src="@/assets/placeholder.png" style="width: 100%; height: 100%;" alt="Placeholder" ratio="4by4" />
          </div>
          <footer :class="materialStockStatus(material.stock)">
            <div style="display: flex; justify-content: space-between;">
              <div class="card-content">
                <strong style="color: white;">Stock:</strong> {{ formatStock(material.stock) }}
              </div>

              <div class="card-content" style="text-align: right;">
                <strong v-if="material.binNumber" style="color: white;">Bin #:</strong> {{ material.binNumber }}
              </div>
            </div>
          </footer>

          
          <!-- <header class="card-header">
            <div class="level" style="border: 1px solid white;">
              <div class="level-left" style="border: 1px solid cyan">
                <p class="card-header-title" style="color: white;">{{ material.name }}</p>
              </div>
              <div class="level-right" style="border: 1px solid yellow">
                <p class="card-header-title" style="color: white;">{{ material.size  }}</p>
              </div>
            </div>
          </header>

          <div>
            <b-image v-if="material.image" :src="material.image" :alt="material.name" ratio="4by4" />
            <img v-else src="@/assets/placeholder.png" style="width: 100%; height: 100%;" alt="Placeholder" ratio="4by4" />
          </div>

          <footer :class="materialStockStatus(material.stock)">
            <div style="display: flex; justify-content: space-between;">
              <div class="card-content">
                <strong style="color: white;">Stock:</strong> {{ formatStock(material.stock) }}
              </div>

              <div class="card-content" style="text-align: right;">
                <strong v-if="material.binNumber" style="color: white;">Bin #:</strong> {{ material.binNumber }}
              </div>
            </div>
          </footer> -->
        </div>
      </div>
    </div>

    <br/>

    <div class="is-hidden-desktop" style="display: flex; justify-content: center;">
      <b-button type="is-primary" icon-left="arrow-expand-up" @click="scrollToTop()">To Top</b-button>
    </div>

    <br/>
  </div>

</div>
</template>

<script>
export default {
  data: () => ({
    matsLoading: false,
    catsLoading: false,
    tags: [],
    filteredTags: [],
    filteredMaterials: null,
    categorySelected: "Soil"
  }),

  computed: {
    materials () {
      let mats = this.$store.getters['materials/activeMaterials']
      this.setLoading('mats', false)
      return mats
    },

    categories () {
      let cats = this.$store.getters['categories/allCategories']
      this.setLoading('cats', false)
      return cats
    },

    elementsLoaded () {
      return this.matsLoading && this.catsLoading
    }
  },

  mounted () {
    this.loading = true
    this.$store.dispatch('materials/fetchMaterials')
    this.$store.dispatch('categories/fetchCategories')
  },

  methods: {

    scrollToTop () {
      window.scrollTo(0, 0)
    },

    setLoading (data, loading) {
      if (data === 'cats') {
        this.catsLoading = Boolean(loading)
      }

      if (data === 'mats') {
        this.matsLoading = Boolean(loading)
      }
    },

    materialStockStatus (stock) {
      if (stock) {
        switch (stock) {
          case 'new':
            return 'stock-new'
          case 'in':
            return 'stock-in'
          case 'low':
            return 'stock-low'
          case 'out':
            return 'stock-out'
          case 'notavail':
            return 'stock-notavail'
        }
      } else {
        return ''
      }
    },

    formatStock (stock) {
      switch (stock) {
        case 'new':
          return 'New Shipment'
        case 'in':
          return 'In'
        case 'low':
          return 'Low'
        case 'out':
          return 'Out of Stock'
        case 'notavail':
          return 'Not Available'
      }
    },

    getFilteredTags (text) {
      this.filteredTags = this.materials.filter((material) => {
        // console.log('Material: ', material)
        return material.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },

    findByTags () {

      // Remove categorySelected while searching by tags
      this.categorySelected = null

      if (this.tags && this.tags.length > 0) {

        // Convert all tags to lowercase (for easier comparison)
        for (let i=0; i<this.tags.length; i++) {
          this.tags[i] = this.tags[i].toLowerCase()
        }
        // console.log('Tags: ', this.tags)

        let mats = []

        // Check tags against each material's keywords, keep any matches
        this.materials.filter(mat => {
          for (let i=0; i < mat.keywords.length; i++) {
            if (this.tags.includes(mat.keywords[i])) {
              mats.push(mat)
            }
          }
        })

        // Get rid of any duplicates and save to filteredMaterials
        this.filteredMaterials = [...new Set(mats)]

      } else {
        // console.log('No tags found, showing all active materials...')
        // console.log('Materials: ', this.materials)
        this.filteredMaterials = [...this.materials]
      }

    },

    showByCategory () {
      // Clear any search tags
      this.tags = []

      if (this.categorySelected) {
        // console.log('Showing materials by category: ', this.categorySelected)
        this.filteredMaterials = this.materials.filter(mat => mat.category.toLowerCase() === this.categorySelected.toLowerCase())
      }
    },

    removeCategory () {
      this.categorySelected = null
    },

    removeTags () {
      this.tags = []
    }
  },

  watch: {
    // categorySelected () {
    //   console.log('categorySelected: ', this.categorySelected)
    //   this.showByCategory()
    // },

    materials () {
      if (this.materials.length > 0) {
        this.filteredMaterials = this.materials.filter(mat => mat.category === "Soil")
      }
    },

    // tags () {
    //   this.findByTags
    // }
  }
}
</script>

<style lang="scss" scoped>
.stock-new {
  background-color: #3c6d3c;
  color: white;
}
.stock-in {
  background-color: #52463b;
  color: white;
}
.stock-low {
  background-color: rgb(209, 127, 33);
  color: white;
}
.stock-out {
  background-color: rgb(138, 34, 34);
  color: white;
}
.stock-notavail {
  background-color: gray;
  color: #d6d5d5;
}
.top-wrapper.materialsWrapper {
  background-color: #ececec;
}

#materialsHero {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/office.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position-y: -260pt;
  // height: 80vh;
  margin-bottom: 20pt;
  z-index: 10;  
}
</style>