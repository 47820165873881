<template>
<div id="printWrapper">
    <section class="hero is-primary is-small no-print">
    <div class="hero-body">
      <b-button type="is-primary" size="is-large" icon-left="printer" @click="print" class="no-print">Print</b-button>
    </div>
  </section>
  <div v-if="printData" id="printBox">
    <div class="columns">
      <!-- LEFT COL -->
      <div class="column is-half" style="border: 1px solid #B0B0B0">
        <div v-if="printData.product"><strong><u>{{ printData.product }}</u></strong> ({{ printData.vendorName }})</div>
        <div></div>
        <table>
          <tr>
            <td>
              <!-- <div>${{ printData.costPerTon }}/t</div> -->
              <div>${{ calculateCostPerTon() }}/t</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>${{ calculateCostPerYard() }}/yd</div>
            </td>
          </tr>
          
          <br/>

          <tr>
            <td>
              Vendor FSC:
            </td>
            <td>
              <span style="margin-left: 8pt;"></span>
              ${{ printData.totalVendorFuelSurcharge }} <span v-if="hasVendorFuelSurcharge">(${{ printData.vendorFuelSurcharge }}/T)</span>
            </td>
          </tr>
          <tr>
            <td>
              CHT FSC:
            </td>
            <td>
              <span style="margin-left: 8pt;"></span>
              ${{ printData.totalChtFuelSurcharge }} (${{ printData.chtFuelSurcharge }}/T)
            </td>
          </tr>
          <tr>
            <td>
              Total FSC:
            </td>
            <td>
              <span style="margin-left: 8pt;"></span>
              ${{ (parseFloat(printData.totalVendorFuelSurcharge) + parseFloat(printData.totalChtFuelSurcharge)).toFixed(2) }}
            </td>
          </tr>
        </table>
        
        <br/>
        <div v-if="printData.overrideProduct && printData.overrideFreight">
          <div>P: ${{ printData.overrideProduct }}</div>
          <div>F: ${{ printData.overrideFreight }}</div>
        </div>
        <div v-else>
          <table>
            <tr>
              <td>
                <div>P: ${{ printData.givenProduct }}&nbsp;&nbsp;</div>
              </td>
              <td>
                <span style="margin-left: 8pt;"></span>
                ${{ calcPerTon(printData.givenProduct) }}
              </td>
            </tr>
            <tr>
              <td>
                <div>F: ${{ printData.givenFreight }}&nbsp;&nbsp;</div>
              </td>
              <td>
                <span style="margin-left: 8pt;"></span>
                ${{ calcPerTon(printData.givenFreight) }}
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                +${{ (parseFloat(printData.totalVendorFuelSurcharge) + parseFloat(printData.totalChtFuelSurcharge)).toFixed(2) }} FSC
                <!-- +${{ calcTotalFuelSurcharge(printData.totalVendorFuelSurcharge, printData.totalChtFuelSurcharge) }} AA -->
              </td>
            </tr>

            <div style="margin-top: 6pt"></div>

            <tr>
              <td>=</td>
              <td>
                <span style="margin-left: 8pt;"></span>
                ${{ totalCostWithFuelSurcharge() }} &nbsp; (${{ totalCostWithoutFuelSurcharge() }} w/o FSC)
              </td>
            </tr>
          </table>
          
          
        </div>
      </div>

      <!-- MIDDLE COL -->
      <div class="column is-one-quarter" style="border: 1px solid #B0B0B0;">
        <div>{{ printData.qtyTons }} T</div>
        <div>= {{ printData.qtyYards }} yds</div>
      </div>

      <!-- RIGHT COL -->
      <div class="column is-one-quarter" style="border: 1px solid #B0B0B0">
        <!-- <div>Total Cost: ${{ printData.totalCostTons }}</div> -->
        <p style="padding-bottom: 4pt"><strong>BILLING </strong></p>

        <table>
          <tr>
            <td>Vendor: </td>
            <td>
              <span style="margin-left: 12pt;"></span>
              ${{ calcBillingVendor() }}
            </td>
          </tr>
          <tr>
            <td>CHT: </td>
            <td>
              <span style="margin-left: 12pt;"></span>
              ${{ calcBillingCHT() }}
            </td>
          </tr>

          <br/>

          <tr>
            <td>Total: </td>
            <td>
              <span style="margin-left: 12pt;"></span>
              ${{ totalCostWithFuelSurcharge() }}
              </td>
          </tr>
        </table>

        <section id="profitMarginWrapper" v-if="printData.printProfitMargins">
          <br/><br/><br/>

          <!-- Profit Margins -->
          <div class="content" id="profitMarginTable">
            <table size="is-small" narrowed>
              <tr>
                <th>Name</th>
                <th>Price</th>
                <th>Suggested</th>
                <th>Actual</th>
              </tr>
              <tr>
                <td>Retail</td>
                <td>${{ printData.chPriceRetail }}</td>
                <td>${{ printData.chSuggestedRetail }}</td>
                <td>{{ printData.chActualRetail }}%</td>
              </tr>
              <tr>
                <td>Wholesale</td>
                <td>${{ printData.chPriceWholesale }}</td>
                <td>${{ printData.chSuggestedWholesale }}</td>
                <td>{{ printData.chActualWholesale }}%</td>
              </tr>
            </table>
          </div>
        </section>
      </div>
    </div>
  </div>
  <div v-else>
    <p style="font-style: italic; margin: 40pt;">There is no data to display. <strong><a href="/office/cost-calculator">Try Again</a></strong></p>
  </div>
</div>
</template>

<script>
export default {
  data: () => ({}),

  computed: {
    printData () {
      const data = this.$store.getters['billprep/allData']
      // console.log(data)
      return data
    },

    hasVendorFuelSurcharge () {
      // console.log('HAS VENDOR FUEL CHARGE: ', this.printData.totalVendorFuelSurcharge)
      if (this.printData && 
        this.printData.totalVendorFuelSurcharge && 
        this.printData.totalVendorFuelSurcharge !== '0' && 
        this.printData.totalVendorFuelSurcharge !== '0.0' && 
        this.printData.totalVendorFuelSurcharge !== '0.00' && 
        this.printData.totalVendorFuelSurcharge) 
      {
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {

    calculateCostPerTon () {
      const product = parseFloat(this.printData.givenProduct)
      const freight = parseFloat(this.printData.givenFreight)
      const FSC = parseFloat(this.printData.chtFuelSurcharge)

      // return this.toFixed(product + freight + FSC, 2)
      return (product + freight + FSC).toFixed(2)
    },

    calculateCostPerYard () {
      const product = parseFloat(this.printData.givenProduct)
      const freight = parseFloat(this.printData.givenFreight)
      const FSC = parseFloat(this.printData.chtFuelSurcharge)

      // return this.toFixed(((product + freight + FSC) * 1.35), 5)
      return ((product + freight + FSC) * 1.35).toFixed(4)
    },

    // toFixed(num, precision ) {
    //   return (+(Math.round(+(num + 'e' + precision)) + 'e' + -precision)).toFixed(precision);
    // },

    calcPerTon (rate) {
      // return this.toFixed(parseFloat(rate) * parseFloat(this.printData.qtyTons), 2)
      return (parseFloat(rate) * parseFloat(this.printData.qtyTons)).toFixed(2)
    },

    print () {
      window.print()
    },

    costPlusFuelSurcharge (cost, fuelSurcharge) {
      // console.log('Cost: ', parseFloat(cost))
      // console.log('Schg: ', parseFloat(fuelSurcharge))
      // return (parseFloat(cost) + parseFloat(fuelSurcharge)).toFixed(3)
      // return this.toFixed(parseFloat(cost) + parseFloat(fuelSurcharge), 2)
      return (parseFloat(cost) + parseFloat(fuelSurcharge)).toFixed(2)
    },

    ydsWithFuelSurcharge (costPerYard, FSC) {
      // return (parseFloat(FSC) * 1.35).toFixed(3)
      // return this.toFixed(parseFloat(FSC) * 1.35, 2)
      return (parseFloat(FSC) * 1.35).toFixed(2)
    },

    fscTotal () {
      // return (parseFloat(this.printData.fuelSurcharge) * parseFloat(this.printData.qtyTons)).toFixed(3)
      // return this.toFixed(parseFloat(this.printData.fuelSurcharge) * parseFloat(this.printData.qtyTons), 2)
      return (parseFloat(this.printData.fuelSurcharge) * parseFloat(this.printData.qtyTons)).toFixed(2)
    },

    calcTotalFuelSurcharge () {
      // printData.totalVendorFuelSurcharge, printData.totalChtFuelSurcharge
      return (parseFloat(this.printData.totalVendorFuelSurcharge) + parseFloat(this.printData.totalChtFuelSurcharge)).toFixed(2)
      // return this.toFixed(parseFloat(vendorFSC) + parseFloat(chtFSC), 2)
    },

    totalCostWithFuelSurcharge () {
      // console.log('TotalCostTons: ', this.printData.totalCostTons)
      // console.log('FSC Total: ', this.fscTotal())
      // return (parseFloat(this.printData.totalCostTons) + parseFloat(this.fscTotal())).toFixed(3)
      // return (parseFloat(this.calcPerTon(this.printData.givenProduct)) + parseFloat(this.calcPerTon(this.printData.givenFreight))).toFixed(2)
      const product = parseFloat(this.calcPerTon(this.printData.givenProduct))
      const freight = parseFloat(this.calcPerTon(this.printData.givenFreight))
      const totalFsc = parseFloat(this.calcTotalFuelSurcharge())

      // return this.toFixed((product + freight + totalFsc), 2)
      return (product + freight + totalFsc).toFixed(2)
    },

    totalCostWithoutFuelSurcharge () {
      const product = parseFloat(this.calcPerTon(this.printData.givenProduct))
      const freight = parseFloat(this.calcPerTon(this.printData.givenFreight))
      // const totalFuelSurcharge = this.calcTotalFuelSurcharge()

      // console.log('P: ', product)
      // console.log('F: ', freight)
      // console.log('FSC: ', totalFuelSurcharge)
      // return this.toFixed((product + freight), 2)
      return (product + freight).toFixed(2)
    },

    calcBillingVendor () {
      const productPerTon = parseFloat(this.calcPerTon(this.printData.givenProduct))
      const vendorFSC = parseFloat(this.printData.totalVendorFuelSurcharge)
      return (productPerTon + vendorFSC).toFixed(2)
    },

    calcBillingCHT () {
      const freightPerTon = parseFloat(this.calcPerTon(this.printData.givenFreight))
      const chtFSC = parseFloat(this.printData.totalChtFuelSurcharge)
      // return this.toFixed((freightPerTon + chtFSC), 2)
      return (freightPerTon + chtFSC).toFixed(2)
    }
  }
}
</script>

<style lang="scss" scoped>
#printWrapper {
  display: relative;
}
#printBox {
  position: fixed;
  bottom: 0pt;
  width: 100%;
  margin-left: 10pt;
  margin-right: 10pt !important;
  // border: 1px solid red;
}
#profitMarginTable {
  position: relative;
  right: 50%;
}
#profitMarginTable * {
  font-size: 10pt !important;
}
</style>