<template>
<div>

  <FAQDialog
    :show="showFAQDialog"
    :isNew="isNewFAQ"
    :editFAQ="editFAQ"
    @closeFAQDialog="closeFAQDialog"
  />

  <CustHeaderComp title="FAQ"/>

  <section class="section">

    <p v-if="!isLoading">Advertising Deliveries: {{ isDeliveryEnabled }} </p>
    <br/>

    <b-button
      type="is-primary"
      icon-left="plus-thick"
      @click="openNewFAQDialog"
    >New FAQ</b-button>
  </section>

  <section class="section">
    <div class="content">
      <ul>
        <li v-for="(faq, i) in activeFAQs" :key="i" class="faqListItem">
          <div><strong>Question: </strong>{{ faq.question }}</div>
          <div class="content">
            <strong>Answer: </strong>
            <div style="white-space: pre-line;">{{ faq.answer }}</div>
          </div>
          <div><strong>Regarding Delivery: </strong>{{ faq.regardsDelivery }}</div>
          <div><strong>Category: </strong>{{ faq.category }}</div>
          <div><strong>URLS: </strong>{{ faq.urls }}</div>
          <div style="margin-top: 10pt;">
            <b-button 
              type="is-primary" 
              icon-left="pencil" 
              size="is-small"
              @click="openEditFAQDialog(faq)"
            ></b-button>

            <b-button
              type="is-danger"
              icon-left="delete"
              size="is-small"
              style="font-weight: bold; margin-left: 8pt;"
              @click="confirmDelete(faq)"
            ></b-button>
          </div>
        </li>
      </ul>
    </div>
  </section>
</div>
</template>

<script>
import FAQDialog from './FAQDialog'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'

export default {

  components: {
    FAQDialog,
    CustHeaderComp
  },

  data: () => ({
    isLoading: false,
    showFAQDialog: false,
    isNewFAQ: false,
    editFAQ: {}
  }),

  mounted () {
    this.init()
  },

  computed: {
    activeFAQs () {
      const faqs = this.$store.getters['faqs/activeFAQS']
      if (!this.isDeliveryEnabled) {
        return faqs.filter(f => f.regardsDelivery === false)
      } else {
        return faqs
      }
    },

    isDeliveryEnabled () {
      return this.$store.getters['siteSettings/advertiseDeliveries']
    },

    isStoreOpen () {
      return this.$store.getters['siteSettings/storeOpen']
    }
  },

  methods: {

    async init () {

      // Hide copyright banner
      document.getElementById('copyrightFooter').style.display = 'none'

      this.isLoading = true
      await this.$store.dispatch('siteSettings/fetchSettings')
      await this.$store.dispatch('faqs/fetchFAQS')
      this.isLoading = false
    },

    openNewFAQDialog () {
      this.isNewFAQ = true
      this.showFAQDialog = true
    },

    openEditFAQDialog (faq) {
      this.isNewFAQ = false
      this.editFAQ = {...faq}
      this.showFAQDialog = true
    },

    closeFAQDialog () {
      this.isNewFAQ = false
      this.showFAQDialog = false
    },

    confirmDelete (faq) {
      this.$buefy.dialog.confirm({
        title: 'Delete this FAQ?',
        message: 'Are you sure you want to <b>permanently delete</b> this FAQ? This action cannot be undone.',
        confirmText: 'Delete this FAQ',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => { this.deleteFAQ(faq) }
      })
    },

    deleteFAQ (faq) {
      this.$store.dispatch('faqs/deleteFAQ', faq._id)
    }
  }
}
</script>

<style lang="scss" scoped>
.faqListItem {
  margin-bottom: 20pt !important;
  border: 1px solid rgb(202, 202, 202);
  padding: 8pt;
  list-style-type: none;
}
</style>