
// CARPORTS > GETTERS

export default {
  allRequests (state) {
    return state.requests
  },

  allActiveRequests (state) {
    return state.requests.filter(request => request.isActive === true)
  },

  featured (state) {
    return state.carports.find(c => c.isFeatured === true)
  }
}