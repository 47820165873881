<template>
<div>
  <CustHeaderComp title="Vendors & Products Rework" />

    <div class="container" style="margin-top: 0.5em;">
      <a href="/office/old-vendors-and-products">
        <b-button type="is-danger is-outlined" icon-left="arrow-left">Visit Archived Vendors & Products Page</b-button>
      </a>
    </div>

    <!-- #region DIALOGS -->
    <VendorDialogV2 
      :show="showVendorDialog" 
      :edit="edit" 
      :vendorCopy="vendor" 
      @closeVendorDialog="closeVendorDialog" 
    />
    
    <VendorProductDialog 
      :show="showVendorProductDialog" 
      :isNew="isNewVendorProduct" 
      :passProduct="vendorProduct" 
      :passVendor="vendorChosen" 
      @closeProductDialog="closeVendorProductDialog" 
    />

    <FreightRoutesDialog 
      :show="showFreightRoutesDialog"
      :isNew="isNewFreightRoute"
      :passRoute="passFreightRoute"
      @closeFreightRouteDialog="closeFreightRouteDialog"
    />
    <!-- #endregion -->

  <section class="container">
    <!-- VENDORS & VENDOR SITES -->
    <section class="section content section-body">
      <!-- LEFT SIDE || VENDORS -->
      <div class="section-vendors">
        <div class="card">
          <header class="card-header vendor-header">
            <div class="vendor-header-title">Vendors</div>
          </header>

          <div class="card-content">
            <div style="display: flex; width: 100%;">
              <b-button type="is-primary" icon-left="plus" @click="openVendorDialog(null)">New</b-button>
              
              <!-- CHOOSE A VENDOR -->
              <VendorSelector 
                :enableEdit="true" 
                @selectVendor="selectVendor" 
                @openVendorDialog="openVendorDialog" 
                style="width: 100%; margin-left: 1em;" 
              />
            </div>

            <br/><br/>

            <!-- VENDOR DETAILS -->
            <table v-if="vendorChosen">
              <tr>
                <th>Name</th>
                <!-- <th>Short Name</th> -->
                <th>Vendor Fuel Schg</th>
                <th>CHT Fuel Schg</th>
              </tr>
              <tr>
                <td>{{ vendorChosen.name }}</td>
                <!-- <td>{{ vendorChosen.shortName }}</td> -->
                <td>${{ vendorChosen.vendorFuelSurcharge }}/t</td>
                <td>${{ vendorChosen.chtFuelSurcharge }}/t</td>
              </tr>
            </table>

            <br/>

            <!-- VENDOR PRODUCTS LIST -->
            <div style="display: flex; justify-content: space-between;">
              <h3 v-if="vendorChosen">Products</h3>
              <b-button v-if="vendorChosen" type="is-primary" icon-left="plus" @click="openNewVendorProductDialog">New Product</b-button>
            </div>
            <section class="vendor-products-table" v-if="vendorChosen">
              <table v-if="vendorChosen">
                <tr>
                  <th>Name</th>
                  <th>Code</th>
                  <th>Product</th>
                  <th>Actions</th>
                </tr>
                <tr v-for="product in filteredVendorProducts" :key="product._id">
                  <td>{{ product.name }}</td>
                  <td>{{ product.code }}</td>
                  <td>${{ product.product }}/t</td>
                  <td>
                    <b-button 
                      type="is-primary" 
                      size="is-small" 
                      icon-left="pencil" 
                      style="margin-right: 0.5em"
                      @click="openEditVendorProductDialog(product)"
                    />
                    <b-button 
                      type="is-danger" 
                      size="is-small" 
                      icon-left="delete" 
                      @click="confirmDeleteVendorProduct(product)"
                    />
                  </td>
                </tr>
              </table>
            </section>
          </div>
        </div>
      </div>

      <!-- RIGHT SIDE || VENDOR SITES -->
      <div class="section-vendorSites">
        <div class="card">
          <header class="card-header vendorSites-header">
            <div class="vendor-header-title">Vendor Sites</div>
          </header>

          <div class="card-content">
            <div>
              <VendorSites :vendorChosen="vendorChosen" />
            </div>
          </div>
        </div>        
      </div>
    </section>

    <br/>

    <!-- BOTTOM ROW || FREIGHT ROUTES -->
    <section class="section content section-freightRoutes">
      <div class="card" style="width: 100%">
        <header class="card-header freightRoutes-header">
          <div class="vendor-header-title">Freight Routes</div>
          <b-button type="is-light" icon-left="plus" @click="openNewFreightRoutesDialog">New</b-button>
        </header>

        <div class="content">
          <b-table
            :data="filteredFreightRoutes || []"
            :loading="freightRoutesLoading"
            :debounce-search="1000"
          >
            <!-- <b-table-column field="_id" label="ID" v-slot="props">{{ props.row._id  }}</b-table-column> -->

            <b-table-column field="vendorSiteId" label="Vendor Site">
              <template v-slot="props">
                {{ vendorSiteIdToName(props.row.vendorSiteId) }}
              </template>
            </b-table-column>

            <b-table-column 
              field="destination" 
              label="Destination" 
              v-slot="props" searchable
            >
              {{ props.row.destination  }}
            </b-table-column>

            <b-table-column 
              field="freight" 
              label="Freight" 
              v-slot="props"
            >
              {{ props.row.freight }}
            </b-table-column>

            <b-table-column
              label="Actions"
              v-slot="props"
            >
              <b-button type="is-primary" icon-left="pencil" @click="openEditFreightRoutesDialog(props.row)" style="margin-right: 0.5em" />
              <b-button type="is-danger" icon-left="delete" @click="confirmDeleteFreightRoute(props.row)" />
            </b-table-column>
          </b-table>

        </div>
      </div>
    </section>
    <br/><br/><br/>
  </section>

</div>
</template>

<script>
import VendorSelector from './components/VendorSelector'
import VendorSites from './components/VendorSites'
import VendorDialogV2 from './components/VendorDialogV2'
import CustHeaderComp from '@/views/office/SiteSettings/CustomHeaderComp'
import VendorProductDialog from './VendorProductDialog'
import FreightRoutesDialog from './components/FreightRoutesDialog'

export default {
  components: {
    VendorSelector,
    VendorSites,
    VendorDialogV2,
    VendorProductDialog,
    FreightRoutesDialog,
    CustHeaderComp
  },

  data: () => ({
    showVendorDialog: false,
    showVendorProductDialog: false,
    showFreightRoutesDialog: false,
    isNewVendorProduct: true,
    isNewFreightRoute: true,
    vendorProduct: null,
    passFreightRoute: null,
    edit: false,
    vendorChosen: null,
    vendor: null,
    vendorsLoading: false,
    vendorSitesLoading: false,
    vendorProductsLoading: false,
    freightRoutesLoading: false,
    form: {
      vendorSiteName: ''
    },

    searchValue: '',
    vendorMatchingSearch: '',

    columns: [
      {
        field: '_id',
        label: 'ID',
      },
      {
        field: 'vendorSiteId',
        label: 'Vendor Site',
        searchable: true
      },
      {
        field: 'destination',
        label: 'Destination',
        searchable: true
      },
      {
        field: 'freight',
        label: 'Freight',
        searchable: true
      }
    ]
  }),

  mounted () {
    // Hide copyright banner
    document.getElementById('copyrightFooter').style.display = 'none'

    // #region Data fetching & load spinners
    this.setVendorsLoading(true)
    this.setVendorSitesLoading(true)
    this.setVendorProductsLoading(true)
    this.setFreightRoutesLoading(true)
    this.$store.dispatch('vendors/fetchVendors')
    this.$store.dispatch('vendorProducts/fetchVendorProducts')
    this.$store.dispatch('vendorSites/fetchVendorSites')
    this.$store.dispatch('freightRoutes/fetchFreightRoutes')
    // #endregion
  },

  computed: {
    vendors () {
      const vendors = this.$store.getters['vendors/allVendors']
      this.setVendorsLoading(false)
      return vendors
    },

    vendorSites () {
      const vendorSites = this.$store.getters['vendorSites/allVendorSites']
      this.setVendorSitesLoading(false)
      return vendorSites
    },

    vendorProducts () {
      const vendorProducts = this.$store.getters['vendorProducts/allVendorProducts']
      this.setVendorProductsLoading(false)
      const sortedVendorProducts = vendorProducts.sort((a, b) => a.name.localeCompare(b.name))
      return sortedVendorProducts
    },

    freightRoutes () {
      const freightRoutes = this.$store.getters['freightRoutes/allFreightRoutes']
      this.setFreightRoutesLoading(false)
      return freightRoutes
    },

    filteredFreightRoutes () {
      if (!this.vendorChosen) {
        return this.freightRoutes
      } else {
        const vendorId = this.vendorChosen._id

        const matchingVendorSites = this.vendorSites.filter(site => site.vendorId === vendorId)
        const rawVendorSiteIds = []
        
        for (let i=0; i<matchingVendorSites.length; i++) {
          rawVendorSiteIds.push(matchingVendorSites[i]._id)
        }

        return this.freightRoutes.filter(route => rawVendorSiteIds.includes(route.vendorSiteId))
      }
    },

    filteredVendorProducts () {
      if (this.vendorChosen) {
        const products = this.vendorProducts.filter(product => product.vendorId === this.vendorChosen._id && product.toYard === true)
        return products
      } else {
        return []
      }
    }
  },

  methods: {
    openVendorDialog (option) {
      if (option) {
        this.edit = true
        this.vendor = option
      } else {
        this.edit = false
        this.vendor = null
      }
      this.showVendorDialog = true
    },

    closeVendorDialog () {
      this.edit = false
      this.showVendorDialog = false
    },

    openEditVendorProductDialog (vendorProduct) {
      // console.log('VENDOR PRODUCT: ')
      // console.log(vendorProduct)
      this.isNewVendorProduct = false
      this.vendorProduct = vendorProduct
      this.showVendorProductDialog = true
    },

    openNewVendorProductDialog () {
      this.isNewVendorProduct = true
      this.vendorProduct = null
      this.showVendorProductDialog = true
    },

    closeVendorProductDialog () {
      this.vendorProduct = null
      this.showVendorProductDialog = false
    },

    openNewFreightRoutesDialog () {
      this.passFreightRoute = null
      this.isNewFreightRoute = true
      this.showFreightRoutesDialog = true
    },

    openEditFreightRoutesDialog (freightRoute) {
      // console.log('[VendorsAndProductsRework openEditFreightRoutesDialog]: Attempting to edit following route: ')
      // console.log({...freightRoute})
      this.passFreightRoute = {...freightRoute}
      this.isNewFreightRoute = false  // true for now (debug), replace w/ actual logic
      this.showFreightRoutesDialog = true
    },

    closeFreightRouteDialog () {
      this.showFreightRoutesDialog = false
    },

    confirmDeleteVendorProduct (product) {
      this.$buefy.dialog.confirm({
        title: `Delete ${product.name}?`,
        message: `Are you sure you want to <b>permanently delete</b> ${product.name}? This action cannot be undone.`,
        confirmText: 'Delete Product',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {this.deleteVendorProduct(product)}
      })
    },

    async deleteVendorProduct (product) {
      try {
        await this.$store.dispatch('vendorProducts/deleteVendorProduct', product._id)
        this.$buefy.toast.open({
          message: `${product.name} deleted`,
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: `Error while deleting ${product.name}! Check logs`,
          position: 'is-bottom',
          type: 'is-danger',
          duration: 8000
        })
      }
    },

    confirmDeleteFreightRoute (freightRoute) {
      this.$buefy.dialog.confirm({
        title: `Delete This Freight Route?`,
        message: `Are you sure you want to <strong style="color: red">permanently delete</strong> <strong style="color: blue">${this.vendorSiteIdToName(freightRoute.vendorSiteId)} to ${freightRoute.destination}</strong>? This action cannot be undone.`,
        confirmText: 'Delete Route',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {this.deleteFreightRoute(freightRoute)}
      })
    },

    async deleteFreightRoute (freightRoute) {
      try {
        await this.$store.dispatch('freightRoutes/deleteFreightRoute', freightRoute._id)
        this.$buefy.toast.open({
          message: `Freight route deleted`,
          position: 'is-bottom',
          type: 'is-success',
        })
      } catch (error) {
        console.error(error.message)
        this.$buefy.toast.open({
          message: `Error while deleting freight route! Check logs`,
          position: 'is-bottom',
          type: 'is-danger',
          duration: 8000
        })
      }
    },

    selectVendor (vendor) {
      this.vendorChosen = vendor
    },

    setVendorsLoading (val) {
      this.vendorsLoading = Boolean(val)
    },

    setVendorSitesLoading (val) {
      this.vendorSitesLoading = Boolean(val)
    },

    setVendorProductsLoading (val) {
      this.vendorProductsLoading = Boolean(val)
    },

    setFreightRoutesLoading (val) {
      this.freightRoutesLoading = Boolean(val)
    },

    vendorSiteIdToName (vendorSiteId) {
      const vendorSite = this.vendorSites.find(site => site._id === vendorSiteId)
      const vendor = this.vendors.find(vendor => vendor._id === vendorSite.vendorId)
      const value = `${vendor.name}: ${vendorSite.name}`
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
  .section-body {
    display: flex;
    justify-content: space-between;
    // border: 3px solid green;
    padding: 2em 0;
    padding-bottom: 0;
  }
  .section-vendors {
    // border: 3px solid red;
    width: 100%;
    padding: 1.4em;
  }
  .section-vendorSites {
    // border: 3px solid blue;
    width: 100%;
    padding: 1.4em;
  }
  .section-freightRoutes {
    // border: 3px solid orange;
    width: 100%;
    padding: 1.4em;
  }
  .vendor-header-title {
    font-weight: bold;
    color: white;
  }
  .vendor-header {
    background-color: rgb(27, 72, 122);
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
    align-items: center;
  }
  .vendorSites-header {
    background-color: rgb(96, 76, 111);
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
    align-items: center;
  }
  .vendor-header * {
    color: white;
  }
  .freightRoutes-header {
    background-color: rgb(169, 87, 32);
    display: flex;
    justify-content: space-between;
    padding: 0.5em 1em;
    align-items: center;
  }
  .vendor-products-table {
    // border: 2px solid rgb(62, 100, 153);
    padding: 0em;
    max-height: 300px;
    overflow: scroll;
  }
</style>