
// OFFICE > TASKS > index.js

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// import dayjs from 'dayjs'

export default {
  namespaced: true,
  state () {
    return {
      tasks: [],
      taskLists: []
    }
  },
  actions,
  getters,
  mutations
}