<template>
<div>
  <section class="hero is-primary" id="helpHero">
    <div class="hero-body">
      <p class="title">How can we help you?</p>
    </div>
  </section>

  <!-- SEARCH SECTION -->
  <section class="section" style="background-color: #EEEEEE;">
    <!-- HELP SEARCH -->
      <div style="margin-left: 40pt; width: 40%;">
        <b-field label="Search" label-position="on-border">

          <b-autocomplete
            v-model="searchBox"
            :data="filteredDataObj"
            dropdown-position="bottom"
            field="question"
            placeholder="Type your question or keywords here"
            clearable
            size="is-medium"
            expanded
            max-height="300"
            id="theSearchBox"
          >
            <template slot-scope="props">
              <div class="media" style="padding: 10pt;" @click="setSelectedFAQ(props.option)">

                <div class="media-left">
                  <div style="display: flex; justify-content: space-between; align-items: center;">
                    <b-icon icon="information" style="margin-right: 10pt;"></b-icon>
                    <div>
                      <p><strong>{{ props.option.question }}</strong></p>
                      <p style="font-size: 9pt; font-style: italic;">Category: {{ props.option.category }}</p>
                      <!-- <p>{{ props.option.answer }}</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template #empty>No information found</template>
          </b-autocomplete>
        </b-field>
      </div>

      <div class="section" v-if="selectedFAQ && searchBox">
        <!-- {{ selectedFAQ }} -->
        <div class="card">
          <div class="card-content">
            <div><strong>{{ selectedFAQ.question }}</strong></div>
            <br/>
            <div style="white-space: pre-line;">{{ selectedFAQ.answer }}</div>

            <div class="faqRelatedLinks" v-if="selectedFAQ.urls && selectedFAQ.urls.length > 0">
              <br/>
              <div>Related Links: </div>
              <ul>
                <li v-for="(url, i) in selectedFAQ.urls" :key="i">
                  <a :href="url" target="_blank">{{ url }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
  </section>


  <!-- HELP SECTION -->
  <section>
    <!-- GENERAL INFO -->
    <section class="section" style="padding-top: 10pt;">
      <b-collapse class="card" animation="slide" aria-id="header_GeneralInformation">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="header_GeneralInformation">
            <p class="card-header-title">
              GENERAL INFORMATION
            </p>
            <a class="card-header-icon">
              <b-icon style="color: white;"
                  :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <div class="container">
              <b-message type="is-danger" has-icon icon-size="is-medium">
                <div style="margin-top: 4pt;">
                  <strong>CALL FOR PRICING!</strong>
                </div>
              </b-message>
            </div>

            <br/>

            <p class="center">Generally speaking, we have two types of products: <strong class="tableSubtitle_Primary">bulk materials</strong> and <strong class="tableSubtitle_Primary">palletized materials</strong></p>
            <br/>
            
            <div class="columns">
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">BULK MATERIALS</h1>
                  <p><strong class="tableSubtitle_Primary">Loose materials that we load with a skid-steer using a 1/2-yard bucket:</strong></p>
                  <p>Topsoil, Compost, Sand, Mulch, Gravel</p>
                </div>
              </div>
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">PALLETIZED MATERIALS</h1>
                  <p><strong class="tableSubtitle_Primary">Materials that are packaged onto wooden pallets which we load with a forklift:</strong></p>
                  <p>Flagstone, Chopped Stone, Creek Rock, Boulders, and Colorado Rock</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-collapse>
    </section>

    <!-- LOADING -->
    <section class="section" style="padding-top: 10pt;">
      <b-collapse class="card" animation="slide" aria-id="header_LoadingInformation">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="header_LoadingInformation">
            <p class="card-header-title">
              LOADING
            </p>
            <a class="card-header-icon">
              <b-icon style="color: white;"
                  :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            
            <div class="columns">
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">PICKUP TRUCKS</h1>
                  <br/>

                  <p><strong class="tableSubtitle_Primary">1/2 TON PICKUP TRUCKS</strong></p>
                  <p>We can typically load a 1/2 yard of our heavy materials (topsoil, sand, and gravels) into the bed, or usually 1 yard of the lighter materials (compost and mulch.) Low-pressure tires carry less and could be potentially dangerous.</p>

                  <br/>

                  <p><strong class="tableSubtitle_Primary">3/4 TON & 1-TON PICKUP TRUCKS</strong></p>
                  <p>We can typically load 1 yard of our heavy materials (topsoil, sand, and gravels) into the bed, or 1.5 to 2 yards of the lighter materials (compost and mulch.) Low-pressure tires carry less and could be potentially dangerous.</p>


                </div>
              </div>
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">TRAILERS</h1>
                  <br/>

                  <p>The amount we'll load may depend on several factors: the number of axles your trailer has, the length of your trailer, the condition of your tires, and whether or not you bring a tarp or have sides you can put on your trailer (to contain loose materials.) Low-pressure tires carry less and could be potentially dangerous.</p>

                  <p><strong class="tableSubtitle_Primary">AXLES AND WEIGHT</strong></p>

                  <p>Typical trailer axles are rated to carry about 3,500 lbs per axle (7,000 lbs total for trailers with two axles, also called <em>dual axle</em> or <em>tandem axle.</em>)</p>
                  
                  <p>Check the weights section above to find out how heavy the material is that you want to load onto your trailer.</p>

                  <p><strong class="tableSubtitle_Primary">TARPS AND SIDES</strong></p>
                  
                  <p>Please bring a tarp with straps OR put sides on your trailer when you buy our bulk materials. This will help ensure you don’t lose product while driving down the road, potentially causing harm to other drivers!</p>
                  
                  <p><strong><u>We are not responsible for any lost material while driving.</u> You also put others at risk on the road when you have gravel or other material flying off the trailer. Please tarp your load or put sides on your trailer prior to loading.</strong></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-collapse>
    </section>

    <!-- WEIGHTS -->
    <section class="section" style="padding-top: 10pt;">
      <b-collapse class="card" animation="slide" aria-id="header_Weights">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="header_Weights">
            <p class="card-header-title">
              WEIGHTS
            </p>
            <a class="card-header-icon">
              <b-icon style="color: white;"
                  :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            
            <div class="columns">
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">BULK MATERIALS WEIGHTS</h1>
                  <br/>
                  <div><strong>* We load with a 1/2 cubic yard bucket</strong></div>
                  <br/>
                  <table>
                    <tr>
                      <th>Material</th>
                      <th>1 YARD<br/><span style="font-size: 9pt;">2 buckets</span></th>
                      <th>1/2 YARD<br/><span style="font-size: 9pt;">1 bucket</span></th>
                    </tr>
                    <tr>
                      <td>Topsoil</td>
                      <td>2,800 lbs</td>
                      <td>1,400 lbs</td>
                    </tr>
                    <tr>
                      <td>Masonry Sand</td>
                      <td>2,800 lbs</td>
                      <td>1,400 lbs</td>
                    </tr>
                    <tr>
                      <td>Compost</td>
                      <td>1,100 lbs</td>
                      <td>550 lbs</td>
                    </tr>
                    <tr>
                      <td>Washed Gravels</td>
                      <td>2,600 lbs</td>
                      <td>1,300 lbs</td>
                    </tr>
                    <tr>
                      <td>Base Gravels<br/><span style="font-size: 9pt;">AR Class 5 and Blue SB2</span></td>
                      <td>2,800 lbs</td>
                      <td>1,400 lbs</td>
                    </tr>
                    <tr>
                      <td>Blue Fines and Crushed Granite</td>
                      <td>2,800 lbs</td>
                      <td>1,400 lbs</td>
                    </tr>
                    <tr>
                      <td>Mulch<br/><span style="font-size: 9pt;">Except Pinebark, about 500 lbs/yd</span></td>
                      <td>700 lbs</td>
                      <td>350 lbs</td>
                    </tr>
                  </table>

                </div>
              </div>
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">PALLETIZED MATERIALS WEIGHT</h1>
                  <p>Our pallets range 1.75 to 2 tons each. <strong>1 ton weighs 2,000 lbs.</strong></p>

                  <p><strong>So in <u>most cases</u></strong>, pallets range 3,500 lbs to 4,000 lbs each (some may weigh more, some less!)</p>

                  <p>Once you visit the lot and see a pallet you like, we can give you more accurate pricing and coverage information for that particular pallet.</p>

                  <p style="font-weight: bold;">
                    IMPORTANT: Pallets are ideally for contractors to buy in bulk. While we will sell them to the general public, please be aware that some pallets are packaged in a manner that is not safe to open without risking injury or cracked/damaged goods. In this event, we may opt to only sell the pallet in its entirety.
                  </p>

                  <p style="font-weight: bold;">
                    (This is mainly pointed at boulders, specifically pallets of small and medium boulders)
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-collapse>
    </section>

    <!-- COVERAGES -->
    <section class="section" style="padding-top: 10pt;">
      <b-collapse class="card" animation="slide" aria-id="header_Coverages">
        <template #trigger="props">
          <div
            class="card-header"
            role="button"
            aria-controls="header_Coverages">
            <p class="card-header-title">
              COVERAGES
            </p>
            <a class="card-header-icon">
              <b-icon style="color: white;"
                  :icon="props.open ? 'menu-down' : 'menu-up'">
              </b-icon>
            </a>
          </div>
        </template>
        <div class="card-content">
          <div class="content">
            <div class="columns">

              <!-- STONE COVERAGE (PALLETS) -->
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">STONE COVERAGE (PALLETS)</h1>

                  <b-message type="is-danger" has-icon icon-size="is-medium">
                    <strong style="margin-top: 4pt;">Full pallets typically weigh 1.75 to 2 tons each</strong>
                  </b-message>

                  <!-- FLAGSTONE -->
                  <h4><span class="tableSubtitle_Primary">FLAGSTONE</span>  <span class="tableSubtitle_Secondary">(PER TON)</span></h4>
                  <table>
                    <tr>
                      <th>THICKNESS</th>
                      <th>COVERAGE</th>
                    </tr>
                    <tr>
                      <td>2" - 2.5"</td>
                      <td>70-80 sq ft/ton</td>
                    </tr>
                    <tr>
                      <td>1" - 1.5"</td>
                      <td>110-125 sq ft/ton</td>
                    </tr>
                    <tr>
                      <td>1/2" - 3/4"</td>
                      <td>130-150 sq ft/ton</td>
                    </tr>
                  </table>

                  <br/>

                  <!-- CHOPPED STONE -->
                  <h4><span class="tableSubtitle_Primary">CHOPPED STONE</span>  <span class="tableSubtitle_Secondary">(PER TON)</span></h4>
                  <table>
                    <tr>
                      <th>SIZE</th>
                      <th>PLACEMENT</th>
                      <th>SQUARE FEET</th>
                      <th>LINEAR FEET</th>
                    </tr>
                    <tr>
                      <td>4"x4"</td>
                      <td>with mortar</td>
                      <td>40 sq ft/ton</td>
                      <td>100+ ft/ton</td>
                    </tr>
                    <tr>
                      <td>4"x4"</td>
                      <td>dry stack</td>
                      <td>35 sq ft/ton</td>
                      <td></td>
                    </tr>
                    <tr style="padding-top: 14pt;">
                      <td style="padding-top: 14pt;">4"x6"</td>
                      <td style="padding-top: 14pt;">with mortar</td>
                      <td style="padding-top: 14pt;">35 sq ft/ton</td>
                      <td style="padding-top: 14pt;">60+ ft/ton</td>
                    </tr>
                    <tr>
                      <td>4"x6"</td>
                      <td>dry stack</td>
                      <td>30 sq ft/ton</td>
                      <td></td>
                    </tr>
                    <tr style="padding-top: 14pt;">
                      <td style="padding-top: 14pt;">4"x8"</td>
                      <td style="padding-top: 14pt;">with mortar</td>
                      <td style="padding-top: 14pt;">30 sq ft/ton</td>
                      <td style="padding-top: 14pt;">35+ sq ft/ton</td>
                    </tr>
                    <tr>
                      <td>4"x8"</td>
                      <td>dry stack</td>
                      <td>25 sq ft/ton</td>
                      <td></td>
                    </tr>
                  </table>

                  <br/>

                  <h1 class="titleMain">ROCK COVERAGE  (PALLETS)</h1>
                  <br/>

                  <h4><span class="tableSubtitle_Primary">RIVER ROCK</span> <span class="tableSubtitle_Secondary">(PER TON)</span></h4>
                  <table>
                    <tr>
                      <th>THICKNESS</th>
                      <th>COVERAGE</th>
                    </tr>
                    <tr>
                      <td>2" - 3"</td>
                      <td>35 - 40 sq ft/ton</td>
                    </tr>
                    <tr>
                      <td>4" - 8"</td>
                      <td>110 - 125 sq ft/ton</td>
                    </tr>
                  </table>

                  <br/>

                  <h4><span class="tableSubtitle_Primary">WATERFALL ROCK</span> <span class="tableSubtitle_Secondary">(PER TON)</span></h4>
                  <table>
                    <tr>
                      <th>THICKNESS</th>
                      <th>COVERAGE</th>
                    </tr>
                    <tr>
                      <td>1" - 3"</td>
                      <td>75 sq ft/ton</td>
                    </tr>
                    <tr>
                      <td>4" - 8"</td>
                      <td>35 - 40 sq ft/ton</td>
                    </tr>
                  </table>

                  <br/>

                  <h4><span class="tableSubtitle_Primary">SMALL FLAT CREEK ROCK</span> <span class="tableSubtitle_Secondary">(PER TON)</span></h4>
                  <p>About 100 - 110 sq ft/ton</p>

                </div>
              </div>
              <div class="column">
                <div class="content">
                  <h1 class="titleMain">BULK MATERIALS COVERAGE</h1>

                  <h3 class="tableSubtitle_Primary">SOILS, SAND, MULCH, & 1" GRAVELS</h3>
                  <p style="color: #AC7C64; font-weight: bold;">1 cubic yard roughly covers a 10' x 16' area at 2" deep <br/>(about the size of a parking space)</p>

                  <br/>

                  <!-- TODO: FINISH THIS SECTION -->

                  <p>Try our <a href="/calculator" target="_blank"><strong>bulk material calculator</strong></a> to help you estimate how many cubic yards of material you might need for your project.</p>

                  <p>JUST KEEP IN MIND...</p>

                  <p>The larger the material, the less square-foot coverage you'll get for the same weight.</p>

                  <p>Example: Assuming equal weights, if you compare a 1/2 yard of a 1" gravel to a 1/2 yard of a 3" gravel, you will get more square-foot coverage out of the smaller 1" gravel.</p>

                  <b-button type="is-primary" @click="$router.push('/calculator')">Try our <strong>Bulk Material Calculator</strong></b-button>
                </div>
              </div>
            </div>

          </div>
        </div>
      </b-collapse>
    </section>

  </section>
</div>
</template>

<script>
// import FAQ from './faq'

export default {
  components: {
    // FAQ
  },

  data: () => ({
    isLoading: false,
    isOpen: 0,
    searchBox: '',
    selectedFAQ: ''
  }),

  mounted () {
    this.init()
  },

  computed: {
    FAQS () {
      const faqs = this.$store.getters['faqs/activeFAQS']

      if (!this.isDeliveryEnabled) {
        return faqs.filter(f => f.regardsDelivery === false)
      } else {
        return faqs
      }
    },

    isDeliveryEnabled () {
      return this.$store.getters['siteSettings/advertiseDeliveries']
    },

    filteredDataObj() {
      return this.FAQS.filter(option => {
        return (
          option.question
            .toString()
            .toLowerCase()
            .indexOf(this.searchBox.toLowerCase()) >= 0
        )
      })
    }
  },

  methods: {
    setSelectedFAQ (faq) {
      // console.log('Selected FAQ: ', faq)
      this.selectedFAQ = {...faq}
    },

    async init () {
      // Hide copyright banner
      document.getElementById('copyrightFooter').style.display = 'none'

      this.isLoading = true
      await this.$store.dispatch('siteSettings/fetchSettings')
      await this.$store.dispatch('faqs/fetchFAQS')
      this.isLoading = false
    },
  },

  watch: {
    searchBox () {
      if (this.searchBox === '') {
        this.selectedFAQ = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#theSearchBox {
  border: 2px solid red !important;
}
.faqItem {
  margin-bottom: 18pt;
  display: block;
  float: left;
  clear: left;
}
.faqItem * {
  text-align: left;
}
.faqQuestion {
  cursor: pointer;
}
#helpHero {
  position: relative;
  background: rgb(2,31,79);
  // background: linear-gradient(138deg, rgba(2,31,79,0.52) 0%, rgba(17,90,127,0.52) 59%, rgba(16,143,168,0.52) 100%);
  background-image: linear-gradient(138deg, rgba(2,31,79,0.92) 0%, rgba(17,90,127,0.92) 59%, rgba(16,143,168,0.92) 100%), url('../../assets/questions_banner.jpg');
  background-attachment: fixed;
  background-size: cover;
  background-position-y: -160pt;
  // height: 80vh;
  // margin-bottom: 20pt;
  z-index: 10;
}
.titleMain {
  color: #815E4E;
  font-weight: bold;
  border-bottom: 8px solid #815E4E;
}
.tableSubtitle_Primary {
  color: #2b5ea1;
  font-weight: bold;
}
.tableSubtitle_Secondary {
  color: #20a377;
  font-weight: bold;
}
</style>