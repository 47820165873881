
// VENDORSITES > MUTATIONS

export default {

  setVendorSites (state, vendorSites) {
    state.vendorSites = vendorSites
  },

  addVendorSite (state, vendorSite) {
    state.vendorSites.push(vendorSite)
  },

  updateVendorSite (state, payloadVendorSite) {
    let index = state.vendorSites.findIndex(vendorSite => vendorSite._id === payloadVendorSite._id)
    state.vendorSites.splice(index, 1, payloadVendorSite)
  },

  removeVendorSite (state, vendorSiteId) {
    let index = state.vendorSites.findIndex(vendorSite => vendorSite._id === vendorSiteId)
    state.vendorSites.splice(index, 1)
  }
}